import {
  Row,
  Col,
  Nav,
  Card,
  Button,
  NavItem,
  NavLink,
  TabPane,
  CardBody,
  Container,
  CardHeader,
  TabContent,
} from "reactstrap";
import {
  GetAllCategory,
  getEventDetails,
  getEventProducts,
  deleteEventProduct,
  AddNewVendorRequest,
} from "../../../../service/service";
import moment from "moment";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import ProductModal from "../../Components/Modal/ProductModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import ProductUpdateModel from "../../Components/Modal/ProductUpdateModel";
import { toast } from "react-toastify";

const VendorEventDetails = ({ products, company, siteSettings }) => {
  const { slug } = useParams();
  const [event, setEvent] = useState([]);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");
  const [categories, setCategories] = useState([]);
  const [eventProduct, setEventProduct] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [productUpdate, setProductUpdate] = useState({});
  const [discountRange, setDiscountRange] = useState({});
  const [selectedCategories, setSelectedCategories] = useState([]);

  const fetchData = async () => {
    const response = await getEventDetails(slug);
    if (response && response.isSuccess) {
      setEvent(response.data.event);
      setDiscountRange(response.data.event.discountRange);
      setSelectedCategories(response.data.event.selectedCategories);
    }

    const response01 = await getEventProducts({
      event: response.data.event._id,
    });
    if (response01 && response01.isSuccess) {
      setEventProduct(response01.data.products);
    }

    const response02 = await GetAllCategory();
    if (response02 && response02.isSuccess) {
      setCategories(response02.data.categories);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [eventProduct]);

  const confirmDelete = async (id) => {
    console.log(company.permissionType.eventManagement.deleteEventProduct);

    if (
      !siteSettings.features.vendorAccessManagement ||
      !company.isVendor ||
      (company.permissionType &&
        company.permissionType.eventManagement.deleteEventProduct === 3)
    ) {
      console.log(company.permissionType.eventManagement.deleteEventProduct);
      const response = await deleteEventProduct(id);
      if (response && response.isSuccess) {
        toast.success("Product Deleted from event", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setEventProduct(
          eventProduct.filter(
            (product) => product._id !== response.data.eventProduct._id
          )
        );
      }
    } else if (
      company.permissionType &&
      company.permissionType.eventManagement.deleteEventProduct === 2
    ) {
      console.log(company.permissionType.eventManagement.deleteEventProduct);

      const response = await AddNewVendorRequest({
        vendor: company._id,
        requestType: "deleteEventProduct",
        collectionName: "EventProduct",
        docID: id,
      });

      console.log(response);

      if (response && response.isSuccess) {
        toast.success("Product Delete Requested! Wait for Admin Approval", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }
  };

  const handleDelete = (id) => {
    SweetAlert.fire({
      icon: "warning",
      reverseButtons: true,
      title: "Are you sure?",
      showCancelButton: true,
      cancelButtonText: "cancel",
      confirmButtonText: "confirm",
      text: "Once deleted, you will not be able to recover this product!",
    }).then((result) => {
      if (result.value) {
        console.log(company.permissionType.eventManagement.deleteEventProduct);

        confirmDelete(id);
      } else {
        SweetAlert.fire("Event is safe!");
      }
    });
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleCloseModal = () => {
    setUpdateModal(false);
  };

  return (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" sm="3">
              <Breadcrumbs title="Event Details" parent="Dashboard" />
            </Col>
          </Row>
        </div>
      </Container>
      {loading ? (
        <ComponentLoader height="80vh" />
      ) : (
        <div className="row">
          <div className="col-lg-12 col-xl-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Event Banner
                </h6>
              </div>
              <div className="card-body">
                <div className="w-100 text-center">
                  <img
                    width="60%"
                    alt="event banner"
                    className="mb-2 img-fluid"
                    src={
                      event.banners &&
                      imageExists(
                        config.fileServer + config.imageContainer + event.banner
                      )
                        ? config.fileServer +
                          config.imageContainer +
                          event.banners[0]?.url
                        : noImage
                    }
                  />
                </div>
              </div>
            </div>
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Event Details
                </h6>
              </div>
              <div className="card-body">
                <div className="table-responsive text-capitalize">
                  <table className="table table-md">
                    <thead>
                      <tr>
                        <th className="border">Event Title</th>
                        <th className="border">Event Status</th>
                        <th className="border">Discount Range</th>
                        <th className="border">Event Participation</th>
                        <th className="border">Created At</th>
                        <th className="border">Updated At</th>
                        <th className="border">Started At</th>
                        <th className="border">Expires At</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border">{event.title}</td>
                        <td className="border">{event.status}</td>
                        <td className="border">
                          {event.discountRange && (
                            <>
                              <span>
                                {event.discountRange.min &&
                                  event.discountRange.min}
                              </span>
                              {"-"}
                              <span>
                                {event.discountRange.max &&
                                  event.discountRange.max}
                              </span>
                              {"%"}
                            </>
                          )}
                        </td>
                        <td className="border">{event.participation}</td>
                        <td className="border">
                          {moment(event.createdAt).format("D MMM YYYY")}
                        </td>
                        <td className="border">
                          {moment(event.updatedAt).format("D MMM YYYY")}
                        </td>
                        <td className="border">
                          {moment(event.startedAt).format("D MMM YYYY")}
                        </td>
                        <td className="border">
                          {moment(event.expiresAt).format("D MMM YYYY")}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <Card>
              <CardHeader>
                <h5 className="m-0 font-weight-bold text-primary">
                  Event Product Details
                </h5>
              </CardHeader>
              <CardBody className="pt-2">
                <div className="tabbed-card">
                  <Nav
                    tabs
                    className="pull-right nav-tabs border-tab nav-primary"
                  >
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => setActiveTab("1")}
                      >
                        My Products
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={activeTab === "2" ? "active" : ""}
                        onClick={() => setActiveTab("2")}
                      >
                        Other Products
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <>
                        {(!siteSettings.features.vendorAccessManagement ||
                          !company.isVendor ||
                          (company.permissionType &&
                            company.permissionType.eventManagement
                              .addEventProduct !== 1)) && (
                          <Button
                            color="primary"
                            className="btn-square mb-2"
                            onClick={() => setModal(true)}
                          >
                            <FontAwesomeIcon className="mr-1" icon={faSave} />
                            ADD PRODUCT
                          </Button>
                        )}
                        <div className="table-responsive text-capitalize text-center">
                          <table className="table table-md">
                            <thead>
                              <tr>
                                <th className="border w-10">Image</th>
                                <th className="border w-10">Product Name</th>
                                <th className="border w-10">Vendor Name</th>
                                <th className="border">Price</th>
                                <th className="border">Discount</th>
                                <th className="border">Product Sold</th>
                                <th className="border">Stock Limit</th>
                                <th className="border">Status</th>
                                <th className="border">Created At</th>
                                <th className="border">Updated At</th>
                                {(!siteSettings.features
                                  .vendorAccessManagement ||
                                  !company.isVendor ||
                                  (company.permissionType &&
                                    company.permissionType.eventManagement
                                      .updateEventProduct !== 1)) && (
                                  <th className="border">Action</th>
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <ComponentLoader height="80vh" />
                              ) : (
                                <>
                                  {eventProduct
                                    .filter(
                                      (product) =>
                                        product.product.vendor._id ===
                                        company._id
                                    )
                                    .map((product) => {
                                      return (
                                        <tr key={product._id}>
                                          <td className="border">
                                            <img
                                              alt="event cover"
                                              width="40px"
                                              src={
                                                product.product.photos &&
                                                product.product.photos[0] &&
                                                product.product.photos[0].url &&
                                                imageExists(
                                                  config.fileServer +
                                                    config.thumbnailContainer +
                                                    product.product.photos[0]
                                                      .url
                                                )
                                                  ? config.fileServer +
                                                    config.thumbnailContainer +
                                                    product.product.photos[0]
                                                      .url
                                                  : noImage
                                              }
                                            />
                                          </td>
                                          <td className="border">
                                            {product.product.name}
                                          </td>
                                          <td className="border">
                                            {product.product.vendor.name}
                                          </td>
                                          <td className="border">
                                            {product.product.price}
                                          </td>
                                          <td className="border">{`${product.discount}%`}</td>
                                          <td className="border">
                                            {product.sold}
                                          </td>
                                          <td className="border">
                                            {product.stockLimit}
                                          </td>
                                          <td className="border">
                                            {product.activeStatus ? "✅" : "🚫"}
                                          </td>
                                          <td className="border">
                                            {moment(product.createdAt).format(
                                              "D MMM YYYY"
                                            )}
                                          </td>
                                          <td className="border">
                                            {moment(product.updatedAt).format(
                                              "D MMM YYYY"
                                            )}
                                          </td>
                                          {(!siteSettings.features
                                            .vendorAccessManagement ||
                                            !company.isVendor ||
                                            (company.permissionType &&
                                              company.permissionType
                                                .eventManagement
                                                .deleteEventProduct !== 1)) && (
                                            <td className="border">
                                              <div className="button-list">
                                                {(!siteSettings.features
                                                  .vendorAccessManagement ||
                                                  !company.isVendor ||
                                                  (company.permissionType &&
                                                    company.permissionType
                                                      .eventManagement
                                                      .updateEventProduct !==
                                                      1)) && (
                                                  <button
                                                    href="#"
                                                    className="btn btn-dark p-0"
                                                    onClick={() => {
                                                      setProductUpdate(product);
                                                      setUpdateModal(
                                                        !updateModal
                                                      );
                                                    }}
                                                  >
                                                    <i
                                                      className="fa fa-pencil"
                                                      style={{
                                                        width: 35,
                                                        padding: 11,
                                                        fontSize: 16,
                                                        color:
                                                          "rgb(40, 167, 69)",
                                                      }}
                                                    ></i>
                                                  </button>
                                                )}
                                                {(!siteSettings.features
                                                  .vendorAccessManagement ||
                                                  !company.isVendor ||
                                                  (company.permissionType &&
                                                    company.permissionType
                                                      .eventManagement
                                                      .deleteEventProduct !==
                                                      1)) && (
                                                  <button
                                                    href="#"
                                                    onClick={(e) =>
                                                      handleDelete(product._id)
                                                    }
                                                    className="btn btn-text p-0"
                                                  >
                                                    <i
                                                      className="fa fa-trash"
                                                      style={{
                                                        width: 35,
                                                        padding: 11,
                                                        fontSize: 16,
                                                        color: "#e4566e",
                                                      }}
                                                    ></i>
                                                  </button>
                                                )}
                                              </div>
                                            </td>
                                          )}
                                        </tr>
                                      );
                                    })}
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="table-responsive text-capitalize">
                        <table className="table table-md">
                          <thead>
                            <tr>
                              <th className="border w-10">Iamge</th>
                              <th className="border w-10">Product Name</th>
                              <th className="border w-10">Vendor Name</th>
                              <th className="border">Product Price</th>
                              <th className="border">Product Discount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <ComponentLoader height="80vh" />
                            ) : (
                              <>
                                {eventProduct
                                  .filter(
                                    (product) =>
                                      product.product.vendor._id !== company._id
                                  )
                                  .map((product) => {
                                    return (
                                      <tr key={product._id}>
                                        <td className="border">
                                          <img
                                            alt="event cover"
                                            width="40px"
                                            src={
                                              product.product.photos &&
                                              product.product.photos[0] &&
                                              product.product.photos[0].url &&
                                              imageExists(
                                                config.fileServer +
                                                  config.thumbnailContainer +
                                                  product.product.photos[0].url
                                              )
                                                ? config.fileServer +
                                                  config.thumbnailContainer +
                                                  product.product.photos[0].url
                                                : noImage
                                            }
                                          />
                                        </td>
                                        <td className="border">
                                          {product.product.name}
                                        </td>
                                        <td className="border">
                                          {product.product.vendor.name}
                                        </td>
                                        <td className="border">
                                          {product.product.price}
                                        </td>
                                        <td className="border">{`${product.discount}%`}</td>
                                      </tr>
                                    );
                                  })}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </TabPane>
                    {modal && (
                      <ProductModal
                        open={modal}
                        event={event}
                        company={company}
                        products={products}
                        status={event.status}
                        categories={categories}
                        handleClose={handleClose}
                        siteSettings={siteSettings}
                        discountRange={discountRange}
                        selectedCategories={selectedCategories}
                      />
                    )}
                  </TabContent>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
      {updateModal && (
        <ProductUpdateModel
          company={company}
          open={updateModal}
          product={productUpdate}
          siteSettings={siteSettings}
          discountRange={discountRange}
          handleClose={handleCloseModal}
        />
      )}
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    products: state.mainReducers.main.products,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

export default connect(mapStateToProps)(VendorEventDetails);
