import { connect } from "react-redux";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { bindActionCreators } from "redux";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import { AddNewPackage } from "../../../../service/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button, Col, Container, Row, Table } from "reactstrap";
import { currencies } from "../../../../service/commondata";

const AddPackage = (props) => {
  const { siteSettings } = props;
  const [name, setName] = useState(null);
  const [error, setError] = useState({});
  const [price, setPrice] = useState(null);
  const [isB2B, setIsB2B] = useState(false);
  const [isB2C, setIsB2C] = useState(false);
  const [type, setType] = useState("prepaid");
  const [isFree, setIsFree] = useState(false);
  const [duration, setDuration] = useState(null);
  const [displayProducts, setDisplayProducts] = useState(null);
  const [featuredProducts, setFeaturedProducts] = useState(null);
  const [searchRanking, setSearchRanking] = useState(null);
  const [postBuyRequirement, setPostBuyRequirement] = useState(null);
  const [postSellOffer, setPostSellOffer] = useState(null);
  const [reRankProductDay, setReRankProductDay] = useState(null);
  const [websiteLink, setWebsiteLink] = useState(null);
  const [rankInSearchEngines, setRankInSearchEngines] = useState(null);
  const [tradeAlert, setTradeAlert] = useState(null);
  const [customerService, setCustomerService] = useState(null);
  const [priceUnit, setPriceUnit] = useState(null);
  const [lifeTime, setLifeTime] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [description, setDescription] = useState("");
  const [activeStatus, setActiveStatus] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [quotationPerMonth, setQuotationPerMonth] = useState(null);

  const handleAddFacs = (event) => {
    event.preventDefault();
    setFacilities([...facilities, event.target.facility.value]);

    event.target.facility.value = "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !description) {
      setError({
        name: !name ? true : false,
        description: !description ? true : false,
      });
    } else {
      setButtonLoading(true);
      const data = {
        type: type,
        name: name,
        displayProducts: displayProducts,
        status: activeStatus,
        facilities: facilities,
        isB2B: isB2B ? isB2B : false,
        duration: !lifeTime ? duration : 0,
        isLifetime: lifeTime ? lifeTime : false,
        description: description,
        quotationPerMonth: isB2B ? quotationPerMonth : 0,
        price: isFree || type === "postpaid" ? 0 : price,
        searchRanking: isB2B ? searchRanking : 0,
        postBuyRequirement: isB2B ? postBuyRequirement : 0,
        postSellOffer: isB2B ? postSellOffer : 0,
        reRankProductDay: isB2B ? reRankProductDay : 0,
        websiteLink: isB2B ? websiteLink : 0,
        rankInSearchEngines: isB2B ? rankInSearchEngines : 0,
        tradeAlert: isB2B ? tradeAlert : 0,
        customerService: isB2B ? customerService : 0,
        featuredProducts: featuredProducts,
        priceUnit: priceUnit,
      };

      const response = await AddNewPackage(data);

      // console.log(data);
      console.log(response);
      if (response && response.isSuccess) {
        toast.success("Package Saved!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        props.history.push("/dashboard/subscription-packages");
      }

      setButtonLoading(false);
    }
  };

  return (
    <div>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="8" sm="3">
              <Breadcrumbs title="Add Package" parent="Dashboard" />
            </Col>
            <Col lg="4" sm="3">
              {buttonLoading ? (
                <Button color="primary btn-block" disabled={buttonLoading}>
                  SAVING...
                </Button>
              ) : (
                <Button onClick={handleSubmit} color="primary btn-block">
                  <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                </Button>
              )}
            </Col>
          </Row>
        </div>
        <div className="contentbar">
          <div className="row">
            <div className="col-lg-8">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Package Details
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      htmlFor="productTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Title
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control-sm form-control"
                        name="name"
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                      <span className="text-danger">
                        {error.name && "Title is required"}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      for="productTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Description
                    </label>
                    <div className="col-sm-12">
                      <textarea
                        className="form-control-sm form-control"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        rows="4"
                        name="description"
                        placeholder="Short Description About Package"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <label
                        className="d-block font-weight-bold"
                        for="chk-ani-price"
                      >
                        <input
                          type="checkbox"
                          value={isFree}
                          checked={
                            isFree ? true : type === "postpaid" ? true : false
                          }
                          id="chk-ani-price"
                          className="checkbox_animated"
                          onChange={() => setIsFree(!isFree)}
                        />
                        Free
                      </label>
                    </div>
                    {type === "postpaid" ||
                      (!isFree && (
                        <>
                          <label
                            for="productTitle"
                            className="col-sm-12 font-weight-bold small"
                          >
                            Price
                          </label>
                          <div className="col-sm-12">
                            <div className="d-flex">
                              <input
                                required
                                name="price"
                                type="number"
                                placeholder="500"
                                value={price && price < 0 ? 0 : price}
                                onChange={(e) => setPrice(e.target.value)}
                                className="form-control-sm form-control font-20"
                              />
                              <select
                                className="form-select form-control-sm form-control ml-1 w-25"
                                onChange={(e) => setPriceUnit(e.target.value)}
                                value={priceUnit}
                              >
                                <option value="-1">None</option>
                                {currencies.map((currency) => (
                                  <option
                                    value={currency.code}
                                    key={currency.code}
                                  >
                                    {currency.name} ({currency.code})
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <label
                        className="d-block font-weight-bold"
                        for="chk-ani-lifeTime"
                      >
                        <input
                          type="checkbox"
                          value={lifeTime}
                          id="chk-ani-lifeTime"
                          className="checkbox_animated"
                          checked={lifeTime ? true : false}
                          onChange={() => setLifeTime(!lifeTime)}
                        />
                        Life Time
                      </label>
                    </div>
                    {!lifeTime && (
                      <>
                        <label
                          for="productTitle"
                          className="col-sm-12 font-weight-bold small"
                        >
                          Duration
                        </label>
                        <div className="col-sm-12">
                          <select
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                            className="form-select form-control-sm form-control mb-2"
                          >
                            <option selected value={30}>
                              Monthly
                            </option>
                            <option selected value={365}>
                              Yearly
                            </option>
                          </select>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="form-group row">
                    <label
                      for="productTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      displayProducts
                    </label>
                    <div className="col-sm-12">
                      <input
                        required
                        type="number"
                        name="displayProducts"
                        value={displayProducts}
                        placeholder="Number of displayProducts Allowed"
                        onChange={(e) => setDisplayProducts(e.target.value)}
                        className="form-control-sm form-control font-20"
                      />
                    </div>
                  </div>
                  {siteSettings.features.b2b && isB2B && (
                    <div className="form-group row">
                      <label
                        for="productTitle"
                        className="col-sm-12 font-weight-bold small"
                      >
                        Quotation per Month
                      </label>
                      <div className="col-sm-12">
                        <input
                          required
                          type="text"
                          name="quotationPerMonth"
                          placeholder="Number of displayProducts Allowed"
                          className="form-control-sm form-control font-20"
                          onChange={(e) => setQuotationPerMonth(e.target.value)}
                          value={
                            quotationPerMonth && quotationPerMonth < 0
                              ? 0
                              : quotationPerMonth
                          }
                        />
                      </div>
                    </div>
                  )}
                  {siteSettings.features.b2b && isB2B && (
                    <div className="form-group row">
                      <label
                        for="productTitle"
                        className="col-sm-12 font-weight-bold small"
                      >
                        Featured Products
                      </label>
                      <div className="col-sm-12">
                        <input
                          required
                          type="number"
                          name="featuredProducts"
                          placeholder="Number of Featured Products Allowed"
                          className="form-control-sm form-control font-20"
                          onChange={(e) => setFeaturedProducts(e.target.value)}
                          value={
                            featuredProducts && featuredProducts < 0
                              ? 0
                              : featuredProducts
                          }
                        />
                      </div>
                    </div>
                  )}
                  {siteSettings.features.b2b && isB2B && (
                    <div className="form-group row">
                      <label
                        for="productTitle"
                        className="col-sm-12 font-weight-bold small"
                      >
                        Priority in Search Ranking
                      </label>
                      <div className="col-sm-12">
                        <select
                          value={searchRanking}
                          onChange={(e) => setSearchRanking(e.target.value)}
                          className="form-select form-control-sm form-control mb-2"
                        >
                          <option value="low">No Priority</option>
                          <option value="medium">Priority</option>
                          <option value="high">High Priority</option>
                        </select>
                      </div>
                    </div>
                  )}
                  {siteSettings.features.b2b && isB2B && (
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label
                          className="d-block font-weight-bold"
                          for="chk-ani-price"
                        >
                          <input
                            type="checkbox"
                            value={postBuyRequirement}
                            checked={postBuyRequirement}
                            id="chk-ani-price"
                            className="checkbox_animated"
                            onChange={() =>
                              setPostBuyRequirement(!postBuyRequirement)
                            }
                          />
                          Post Buy Requirements
                        </label>
                      </div>
                    </div>
                  )}
                  {siteSettings.features.b2b && isB2B && (
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label
                          className="d-block font-weight-bold"
                          for="chk-ani-price"
                        >
                          <input
                            type="checkbox"
                            value={postSellOffer}
                            checked={postSellOffer}
                            id="chk-ani-price"
                            className="checkbox_animated"
                            onChange={() => setPostSellOffer(!postSellOffer)}
                          />
                          Post Sell Offers
                        </label>
                      </div>
                    </div>
                  )}
                  {siteSettings.features.b2b && isB2B && (
                    <div className="form-group row">
                      <label
                        for="productTitle"
                        className="col-sm-12 font-weight-bold small"
                      >
                        Re Reank Products After
                      </label>
                      <div className="col-sm-12">
                        <input
                          required
                          type="number"
                          name="reRankProductDay"
                          placeholder="Leave it empty if re rank is not allowed"
                          className="form-control-sm form-control font-20"
                          onChange={(e) => setReRankProductDay(e.target.value)}
                          value={
                            reRankProductDay && reRankProductDay < 0
                              ? 0
                              : reRankProductDay
                          }
                        />
                      </div>
                    </div>
                  )}
                  {siteSettings.features.b2b && isB2B && (
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label
                          className="d-block font-weight-bold"
                          for="chk-ani-price"
                        >
                          <input
                            type="checkbox"
                            value={websiteLink}
                            checked={websiteLink}
                            id="chk-ani-price"
                            className="checkbox_animated"
                            onChange={() => setWebsiteLink(!websiteLink)}
                          />
                          Show Direct Website Link
                        </label>
                      </div>
                    </div>
                  )}
                  {siteSettings.features.b2b && isB2B && (
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label
                          className="d-block font-weight-bold"
                          for="chk-ani-price"
                        >
                          <input
                            type="checkbox"
                            value={rankInSearchEngines}
                            checked={rankInSearchEngines}
                            id="chk-ani-price"
                            className="checkbox_animated"
                            onChange={() =>
                              setRankInSearchEngines(!rankInSearchEngines)
                            }
                          />
                          Rank in Top Search Engines
                        </label>
                      </div>
                    </div>
                  )}
                  {siteSettings.features.b2b && isB2B && (
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label
                          className="d-block font-weight-bold"
                          for="chk-ani-price"
                        >
                          <input
                            type="checkbox"
                            value={tradeAlert}
                            checked={tradeAlert}
                            id="chk-ani-price"
                            className="checkbox_animated"
                            onChange={() => setTradeAlert(!tradeAlert)}
                          />
                          Trade Alert
                        </label>
                      </div>
                    </div>
                  )}
                  {siteSettings.features.b2b && isB2B && (
                    <div className="form-group row">
                      <div className="col-sm-12">
                        <label
                          className="d-block font-weight-bold"
                          for="chk-ani-price"
                        >
                          <input
                            type="checkbox"
                            value={customerService}
                            checked={customerService}
                            id="chk-ani-price"
                            className="checkbox_animated"
                            onChange={() =>
                              setCustomerService(!customerService)
                            }
                          />
                          Customer Service
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Status</h6>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    {siteSettings.features.b2b && (
                      <div className="col-sm-12 mb-2">
                        <div className="d-flex justify-content-around">
                          <label
                            className="d-block font-weight-bold"
                            for="chk-b2b"
                          >
                            <input
                              id="chk-b2b"
                              type="checkbox"
                              value={isB2B}
                              checked={isB2B}
                              className="checkbox_animated"
                              onChange={() => {
                                setIsB2B(true);
                                // setIsB2C(false);
                              }}
                            />
                            B2B
                          </label>
                          <label
                            className="d-block font-weight-bold"
                            for="chk-b2c"
                          >
                            <input
                              id="chk-b2c"
                              type="checkbox"
                              value={!isB2B}
                              checked={!isB2B}
                              className="checkbox_animated"
                              onChange={() => {
                                setIsB2B(false);
                              }}
                            />
                            B2C
                          </label>
                        </div>
                      </div>
                    )}
                    <div className="custom-control custom-checkbox ml-3">
                      <input
                        id="active"
                        type="checkbox"
                        checked={activeStatus}
                        className="custom-control-input"
                        onChange={() => setActiveStatus(!activeStatus)}
                      />
                      <label className="custom-control-label" htmlFor="active">
                        Active
                      </label>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-sm-12 font-weight-bold small">
                      Package Type
                    </label>
                    <select
                      className="form-select form-control-sm form-control mb-2"
                      onChange={(e) => setType(e.target.value)}
                      value={type}
                    >
                      <option selected value="prepaid">
                        Prepaid
                      </option>
                      <option selected value="postpaid">
                        Postpaid
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Facilities
                  </h6>
                </div>
                <div className="card-body">
                  <div className="product-facilities">
                    <Table className="table table-responsive-sm">
                      <tbody>
                        {facilities.map((facility) => (
                          <tr>
                            <td className="width-10 p-1">
                              <button
                                type="button"
                                className="btn btn-icon-split btn-xs"
                                onClick={() =>
                                  setFacilities(
                                    facilities.filter((t) => t !== facility)
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faTimes} size="xs" />
                              </button>
                            </td>
                            <td>{facility}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="add-product-facilities">
                    <form onSubmit={handleAddFacs}>
                      <div className="input-group input-group-sm">
                        <input
                          type="text"
                          className="form-control-sm form-control"
                          placeholder="Add Tags"
                          name="facility"
                        />
                        <div className="input-group-append">
                          <button className="input-group-text" type="submit">
                            Add
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    products: state.mainReducers.main.products,
    categories: state.mainReducers.main.categories,
    siteSettings: state.mainReducers.main.siteSettings,
    subCategories: state.mainReducers.main.subCategories,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPackage);
