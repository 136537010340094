import jsPDF from "jspdf";
import { Fragment } from "react";
import html2canvas from "html2canvas";
import React from "react";

import {
  Col,
  Row,
  Modal,
  Media,
  ModalBody,
  Container,
  ModalHeader,
} from "reactstrap";
import config from "../../../../../service/api/config";

const Invoice = (props) => {
  const { open, handleInvoiceModal, shipmentAddress, order, buyer } = props;

  console.log(order);
  const generatePDF = () => {
    html2canvas(document.querySelector("#invoice")).then((canvas) => {
      document.body.appendChild(canvas);
      const imgData = canvas.toDataURL("image/png");
      // const pdf = new jsPDF("portrait", "pt", "a4", true);
      const pdf = new jsPDF();
      const imgHeight = (canvas.height * 208) / canvas.width;
      pdf.addImage(imgData, "JPEG", 0, 0, 208, imgHeight);
      pdf.save("invoice.pdf");
    });
  };
  console.log("products", order.products);
  return (
    <Modal size="lg" centered isOpen={open} toggle={handleInvoiceModal}>
      <ModalHeader toggle={handleInvoiceModal}>Invoice</ModalHeader>
      <ModalBody>
        <Fragment>
          <Container
            fluid={false}
            id="invoice"
            className="p-2"
            style={{ color: "#000", fontSize: "1rem" }}
          >
            <Row className="mt-2">
              <Col sm="12">
                <div className="invoice">
                  <div>
                    <div>
                      <Row>
                        <Col sm="6">
                          <Media>
                            <Media left>
                              <Media
                                className="media-object img-60"
                                src={
                                  config.fileServer +
                                  config.thumbnailContainer +
                                  order.seller.logo
                                }
                                alt=""
                              />
                            </Media>
                            <Media
                              body
                              className="m-l-20 justify-content-center"
                            >
                              <h5 className="media-heading mb-1">
                                {order.seller.name}
                              </h5>
                              <p>{order.seller.email}</p>
                            </Media>
                          </Media>
                        </Col>
                        <Col sm="6">
                          <div className="text-md-right">
                            <h3>
                              Invoice #
                              <span className="digits counter">
                                {order.orderID}
                              </span>
                            </h3>
                            <p>
                              Issued: {order.createdAt.toString().slice(0, 10)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    <hr />

                    <Row className="mt-2">
                      <Col md="6">
                        <div className="text-md-left" id="project">
                          <h6>Customer Details</h6>
                          <p className="m-0">
                            <strong>Name:</strong>
                            {` ${buyer?.userInfo?.firstName} ${buyer?.userInfo?.lastName}`}
                          </p>
                          <p className="m-0">
                            <strong>Email:</strong>
                            {` ${buyer?.email}`}
                          </p>
                          <p className="m-0">
                            <strong>Phone:</strong>{" "}
                            {` ${shipmentAddress?.phone}`}
                          </p>
                        </div>
                      </Col>
                      <Col md="6">
                        <div className="text-md-right" id="project">
                          <h6>Shipping Details</h6>
                          <p className="m-0">
                            <strong>Organization Name: </strong>
                            {shipmentAddress?.orgName &&
                              shipmentAddress?.orgName}
                          </p>
                          <p className="m-0">
                            <strong>Flat Number: </strong>
                            {shipmentAddress?.flatNumber &&
                              shipmentAddress?.flatNumber}
                          </p>
                          <p className="m-0">
                            <strong>Additional Address: </strong>
                            {shipmentAddress?.additionalInfo &&
                              shipmentAddress?.additionalInfo}
                          </p>
                        </div>
                      </Col>
                    </Row>

                    <div className="mt-4">
                      <div
                        className="table-responsive invoice-table"
                        id="table"
                      >
                        <table
                          style={{ color: "#000", fontWeight: "500" }}
                          className="table table-md text-primary"
                        >
                          <tbody>
                            <tr>
                              <td className="border">Product Name</td>
                              <td className="border">Price</td>
                              <td className="border">Qty</td>
                              <td className="border">Sub-total</td>
                            </tr>
                            {order.products.map((product) => (
                              <tr key={product._id}>
                                <td className="border">{product.name}</td>
                                <td className="border">
                                  {product.price.toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td className="border">{product.qty}</td>
                                <td className="border">
                                  {(
                                    Number(product.price) * Number(product.qty)
                                  ).toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              </tr>
                            ))}
                            <tr>
                              <td className="border"></td>
                              <td className="border"></td>
                              <td className="border"></td>
                              <td className="border"></td>
                            </tr>
                            <tr>
                              <td className="border">Amount</td>
                              <td className="border"></td>
                              <td className="border"></td>
                              <td className="border">
                                {order.shipping
                                  ? order.amount - order.shipping
                                  : order.amount}
                                {/* {console.log(order.amount - order.shipping)} */}
                              </td>
                            </tr>
                            <tr>
                              <td className="border">Shipping</td>
                              <td className="border"></td>
                              <td className="border"></td>
                              <td className="border">
                                {" "}
                                {order.shipping ? order.shipping : 0}
                              </td>
                            </tr>
                            <tr>
                              <td className="border">Offer Amount</td>
                              <td className="border"></td>
                              <td className="border"></td>
                              <td className="border">
                                {" "}
                                -
                                {order.offerAmount.toLocaleString("en-IN", {
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                            </tr>
                            <tr>
                              <td className="border">Total Amount</td>
                              <td className="border"></td>
                              <td className="border"></td>
                              {order.totalAmount && (
                                <td className="border">
                                  {" "}
                                  {order.totalAmount.toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                              )}
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <Row>
                        <Col md="8">
                          <div>
                            <p
                              className="legal mt-1 text-justify"
                              style={{ color: "#000", fontSize: "0.85rem" }}
                            >
                              <strong>Thank you for your business!</strong>
                              Payment is expected within 31 days; please process
                              this invoice within that time. There will be a 5%
                              interest charge per month on late invoices.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <button
            type="button"
            onClick={() => generatePDF()}
            className="btn btn-primary w-100 rounded-0"
          >
            Download Invoice
          </button>
        </Fragment>
      </ModalBody>
    </Modal>
  );
};

export default Invoice;
