import React, { useState, useEffect } from "react";
import config from "../../../../service/api/config";
import noImage from "../../../../assets/images/notFound.png";
import { Col, Card, CardBody, CardHeader } from "reactstrap";
import { imageExists } from "../../../../service/imageExists";
import { GetEventProductDetails } from "../../../../service/service";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const EventComponent = ({ vendorRequest }) => {
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const response = await GetEventProductDetails(vendorRequest.docID);
    if (response && response.isSuccess) {
      setProduct(response.data.product);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      vendorRequest.requestType === "updateEventProduct" ||
      vendorRequest.requestType === "deleteEventProduct"
    )
      fetchData();
    else setLoading(false);
  }, []);

  const details = (value) => {
    switch (value) {
      case "addEventProduct":
        return (
          <>
            {loading ? (
              <ComponentLoader height="40vh" />
            ) : (
              <div className="table-responsive">
                <table className="table table-hover table-md text-center">
                  <thead>
                    <tr className="font-01 weight">
                      <th className="border">Field Name</th>
                      <th className="border">New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="border font-01 weight">Stock Limit</th>
                      <td className="border">
                        {vendorRequest.requestData.stockLimit}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Discount</th>
                      <td className="border">
                        {vendorRequest.requestData.discount}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Active Status</th>
                      <td className="border">
                        {vendorRequest.requestData.activeStatus
                          ? "Active"
                          : "Inactive"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        );
      case "updateEventProduct":
        return (
          <>
            {loading ? (
              <ComponentLoader height="40vh" />
            ) : (
              <div className="table-responsive">
                <table className="table table-hover table-md text-center">
                  <thead>
                    <tr className="font-01 weight">
                      <th className="border">Field Name</th>
                      <th className="border">Original Value</th>
                      <th className="border">New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="border font-01 weight">Stock Limit</th>
                      <td className="border">{product.stockLimit}</td>
                      <td className="border">
                        {vendorRequest.requestData.stockLimit}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Discount</th>
                      <td className="border">{product.discount}</td>
                      <td className="border">
                        {vendorRequest.requestData.discount}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Active Status</th>
                      <td className="border">
                        {product.activeStatus ? "Active" : "Inactive"}
                      </td>
                      <td className="border">
                        {vendorRequest.requestData.activeStatus
                          ? "Active"
                          : "Inactive"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        );
      case "deleteEventProduct":
        return (
          <>
            {loading ? (
              <ComponentLoader height="40vh" />
            ) : (
              <div className="table-responsive">
                <table className="table table-hover table-md text-center">
                  <thead>
                    <tr className="font-01 weight">
                      <th className="border">Field Name</th>
                      <th className="border">Original Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="border font-01 weight">Stock Limit</th>
                      <td className="border">
                        {product && product.stockLimit}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Discount</th>
                      <td className="border">{product && product.discount}</td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Active Status</th>
                      <td className="border">
                        {product && product.activeStatus
                          ? "Active"
                          : "Inactive"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Col sm="12">
        <Card>
          <CardHeader>
            <h6 className="m-0 font-weight-bold text-primary">
              {`Requested Data Details - ${vendorRequest.requestType}`}
            </h6>
          </CardHeader>
          <CardBody>{details(vendorRequest.requestType)}</CardBody>
        </Card>
        {loading ? (
          <ComponentLoader height="40vh" />
        ) : (
          <>
            {(vendorRequest.requestType === "deleteEventProduct" &&
              vendorRequest.status === "approved") ||
            vendorRequest.requestType === "addEventProduct" ? null : (
              <Card>
                <CardHeader>
                  <h6 className="m-0 font-weight-bold text-primary">
                    Product Details
                  </h6>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <table className="table table-hover table-md text-center">
                      <thead>
                        <tr className="font-01 weight">
                          <th className="border">Product Image</th>
                          <th className="border">Product Name</th>
                          <th className="border">Vendor Name</th>
                          <th className="border">Categories</th>
                          <th className="border">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border">
                            <img
                              src={
                                product.product.logo &&
                                imageExists(
                                  config.fileServer +
                                    config.thumbnailContainer +
                                    product.product.logo
                                )
                                  ? config.fileServer +
                                    config.thumbnailContainer +
                                    product.product.logo
                                  : noImage
                              }
                              width="40px"
                              className="img-fluid"
                              alt={product.product.name}
                            />
                          </td>
                          <td className="border">
                            {product && product.product.name}
                          </td>
                          <td className="border">
                            {product && product.product.vendor.name}
                          </td>
                          <td className="border">
                            {product &&
                              product.product.categories.map((category) => (
                                <>
                                  {category.name && (
                                    <li key={category._id}>{category.name}</li>
                                  )}
                                </>
                              ))}
                          </td>
                          <td className="border">
                            {product && product.product.price}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            )}
          </>
        )}
      </Col>
    </>
  );
};

export default EventComponent;
