import {
  BuyPackage,
  GetAllSubscriptionPackages,
} from "../../../../service/service";
import CountUp from "react-countup";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { bindActionCreators } from "redux";
import operations from "../../../../redux/Main/operations";
import React, { Fragment, useEffect, useState } from "react";
import waiting from "../../../../assets/images/student-time-management.png";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { currencies } from "../../../../service/commondata";

const PendingState = () => {
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header text-center">
          <img
            src={waiting}
            alt="waiting logo"
            className="second-search vh-50"
          />
          <h4 className="mt-4">
            Please Wait. Your Subscription is under review{" "}
          </h4>
        </div>
      </Container>
    </Fragment>
  );
};

const BuyPackagePage = (props) => {
  const [packages, setPackages] = useState([]);
  const { setSubscription, company, subscription } = props;
  const [filteredPackages, setFilteredPackages] = useState([]);

  const fetchData = async () => {
    const response = await GetAllSubscriptionPackages();
    if (response && response.isSuccess) {
      setPackages(response.data.packages);
    }
  };

  useEffect(() => {
    if (packages) {
      setFilteredPackages([]);
      if (company.isB2B) {
        setFilteredPackages(packages.filter((item) => item.isB2B));
      } else {
        setFilteredPackages(packages.filter((item) => !item.isB2B));
      }
    }
  }, [company, packages]);

  const confirmBuy = async (id) => {
    const data = {
      vendor: company._id,
      package: id,
    };
    const response = await BuyPackage(data);
    console.log(response);
    if (response && response.isSuccess) {
      setSubscription(response.data.subscription);
    }
  };

  const buyPackage = async (pack) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "You Want to buy this package!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmBuy(pack._id).then(() => {
          SweetAlert.fire(
            "Package Buy Success!",
            "Continue your Business!",
            "success"
          );
        });
      } else {
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (subscription && subscription.status === "active") {
    return <Navigate to="/dashboard" />;
  }

  if (subscription && subscription.status === "pending") {
    return <PendingState />;
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header"></div>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Buy Package</h5>
              </CardHeader>
              <CardBody className="m-auto">
                <div className="table-responsive table-bordered table-compare-list mb-10 border-0">
                  <table className="table text-center">
                    <tbody>
                      <tr>
                        <th className="min-width-200">Package</th>
                        {filteredPackages.map((pack) => (
                          <td className={`text-center`}>
                            <h3 className="font-weight-bold mb-3">
                              {pack.name}
                            </h3>
                            <p className="text-secondary m-0">
                              {pack.description}
                            </p>
                            <h4 className="text-primary font-weight-bold m-0 mb-3">
                              <b>
                                {pack.priceUnit
                                  ? currencies.find(
                                      (item) => item.code === pack.priceUnit
                                    ).symbol
                                    ? currencies.find(
                                        (item) => item.code === pack.priceUnit
                                      ).symbol
                                    : pack.priceUnit
                                  : "৳"}
                              </b>
                              {pack.price
                                ? pack.price.toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                  })
                                : 0}
                              <span className="font-size-8 text-secondary">
                                /{pack.duration === 30 ? "Month" : "Year"}
                              </span>
                            </h4>

                            <button
                              type="button"
                              onClick={() => buyPackage(pack)}
                              className="btn btn-block btn-primary"
                              disabled={!pack.status}
                            >
                              {pack.status ? "Buy" : "Not Available"}
                            </button>
                          </td>
                        ))}
                      </tr>
                      <tr>
                        <th
                          colSpan={filteredPackages.length + 1}
                          className="min-width-200 text-right"
                        >
                          Package Features
                        </th>
                      </tr>
                      {/* <tr>
              <th>Post Products</th>
              {filteredPackages.map((pack) => (
                <td
                  className={`text-center ${
                    selectedPackage === pack._id ? "bg-active" : ""
                  }`}
                >
                  <div className="product-price">Unlimited</div>
                </td>
              ))}
            </tr> */}
                      {company.isB2B && (
                        <tr>
                          <th>Search Ranking</th>
                          {filteredPackages.map((pack) => (
                            <td className={`text-center `}>
                              <div className="product-price">
                                {pack.searchRanking === "medium"
                                  ? "Ranking Priority"
                                  : pack.searchRanking === "top"
                                  ? "Top Priority Ranking"
                                  : "-"}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}
                      {company.isB2B && (
                        <tr>
                          <th>Display Product</th>
                          {filteredPackages.map((pack) => (
                            <td className={`text-center `}>
                              <div className="product-price">
                                {pack.displayProducts
                                  ? pack.displayProducts
                                  : "Unlimited"}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}
                      {company.isB2B && (
                        <tr>
                          <th>Featured Product</th>
                          {filteredPackages.map((pack) => (
                            <td className={`text-center `}>
                              <div className="product-price">
                                {pack.featuredProducts
                                  ? pack.featuredProducts
                                  : "x"}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}
                      {company.isB2B && (
                        <tr>
                          <th>Post Buy Requirement</th>
                          {filteredPackages.map((pack) => (
                            <td className={`text-center `}>
                              <div className="product-price">
                                {pack.postBuyRequirement ? "√" : "x"}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}
                      {company.isB2B && (
                        <tr>
                          <th>Post Sell Offer</th>
                          {filteredPackages.map((pack) => (
                            <td className={`text-center `}>
                              <div className="product-price">
                                {pack.postSellOffer ? "√" : "x"}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}
                      {company.isB2B && (
                        <tr>
                          <th>Re-Rank Products</th>
                          {filteredPackages.map((pack) => (
                            <td className={`text-center `}>
                              <div className="product-price">
                                {pack.reRankProductDay
                                  ? "After " + pack.reRankProductDay + " Days"
                                  : "x"}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}

                      {company.isB2B && (
                        <tr>
                          <th>Direct Website Link</th>
                          {filteredPackages.map((pack) => (
                            <td className={`text-center `}>
                              <div className="product-price">
                                {pack.websiteLink ? "√" : "x"}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}
                      {company.isB2B && (
                        <tr>
                          <th>Rank High in top search engines</th>
                          {filteredPackages.map((pack) => (
                            <td className={`text-center `}>
                              <div className="product-price">
                                {pack.rankInSearchEngines ? "√" : "x"}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}
                      {company.isB2B && (
                        <tr>
                          <th>Trade Alert</th>
                          {filteredPackages.map((pack) => (
                            <td className={`text-center `}>
                              <div className="product-price">
                                {pack.tradeAlert ? "√" : "x"}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}
                      {company.isB2B && (
                        <tr>
                          <th>Customer service</th>
                          {filteredPackages.map((pack) => (
                            <td className={`text-center `}>
                              <div className="product-price">
                                {pack.customerService
                                  ? "Dedicated account manager"
                                  : "x"}
                              </div>
                            </td>
                          ))}
                        </tr>
                      )}

                      {/* {company.isB2B && (
              <tr>
                <th>Respond to Quotation per month</th>
                {filteredPackages.map((pack) => (
                  <td
                    className={`text-center ${
                      selectedPackage === pack._id ? "bg-active" : ""
                    }`}
                  >
                    <div className="product-price">{pack.quotationPerMonth}</div>
                  </td>
                ))}
              </tr>
            )} */}

                      {/* <tr>
              <th>Other Benifits</th>
              {packages.map((pack) => (
                <td
                  className={`text-center ${
                    selectedPackage === pack._id ? "bg-active" : ""
                  }`}
                >
                  <ul>
                    {pack.facilities.map((fac, index) => (
                      <li>
                        <span className="">
                          {index + 1}. {fac}
                        </span>
                      </li>
                    ))}
                  </ul>
                </td>
              ))}
            </tr> */}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    orders: state.mainReducers.main.orders,
    company: state.mainReducers.main.company,
    subscription: state.mainReducers.main.subscription,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BuyPackagePage);
