import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { DeletePhoto, UploadPhoto } from "../../../../../service/service";
import { UpdateBrandApi } from "../../../../../service/service";
import config from "../../../../../service/api/config";

const UpdateBrand = ({ open, toggle, data, setUpdate }) => {
  const [photo, setPhoto] = useState(data?.brandImg?.fileName);
  const [brandName, setBrandName] = useState(data?.brandName);
  const [description, setDescription] = useState(data?.description);
  const [brandImg, setBrandImg] = useState(
    data?.brandImg === undefined ? {} : data?.brandImg
  );
  const [url, setUrl] = useState(data?.url);
  const [isPhotoDeleted, setIsPhotoDeleted] = useState(false);
  const id = data?._id;

  const handleUploadPhoto = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setPhoto(response.data.fileName);
          setBrandImg(response.data);
          setIsPhotoDeleted(true);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleDeletePhoto = async (photo) => {
    await DeletePhoto({
      fileName: photo,
      container: "ivcommerceoriginalcontainer",
    });
    setPhoto(null);
  };

  const handleSubmission = async (e) => {
    e.preventDefault();
    if (brandName && brandImg && url && description) {
      console.log(brandName, brandImg, url, description);

      const data = {
        brandName: brandName,
        description: description,
        url: url,
        brandImg: brandImg,
      };

      const response = await UpdateBrandApi(id, data);

      if (response && response.isSuccess) {
        toggle();
        toast.success("Brand Updated", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setUpdate(true);
      }
    } else {
      toast.warning("Please provide all detals ", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  return (
    <Modal isOpen={open} centered>
      <ModalHeader toggle={toggle}>Create Brand</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmission}>
          <FormGroup>
            <Label for="brandBame">Brand name</Label>

            <Input
              id="brandName"
              name="brandName"
              placeholder="Enter name"
              type="text"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label for="brandDescription">Description</Label>

            <Input
              id="brandDescription"
              name="brandDescription"
              placeholder="Enter Description"
              type="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="brandUrl">URL</Label>

            <Input
              id="brandUrl"
              name="brandUrl"
              placeholder="Enter Url"
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label for="brandImage">Image</Label>
            <div>
              {photo ? (
                <div className="w-100 text-center">
                  <div className="w-100">
                    <img
                      src={
                        isPhotoDeleted
                          ? config.fileServer + config.imageContainer + photo
                          : data.brandImg.url
                      }
                      alt=""
                      className="mb-2"
                      width="50%"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-icon-split btn-sm btn-danger"
                    onClick={() => handleDeletePhoto(photo)}
                  >
                    Remove Photo
                  </button>
                </div>
              ) : (
                <Dropzone
                  onChangeStatus={handleUploadPhoto}
                  accept="image/*"
                  PreviewComponent={null}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                />
              )}
            </div>
          </FormGroup>

          <Button type="submit" color="primary btn-block">
            <FontAwesomeIcon className="mr-1" icon={faSave} /> Update
          </Button>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default UpdateBrand;
