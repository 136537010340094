import React, { useState, useEffect } from "react";
import { Col, Row, Container, Card, CardBody, Table } from "reactstrap";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { GetAllServices } from "../../../../service/service";
import ServiceTable from "./components/ServiceTable";
import errorImg from "../../../../assets/images/search-not-found.png";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const Service = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [deleteService, setDeleteService] = useState(false);

  const fetchData = async () => {
    const response = await GetAllServices();
    if (response && response.isSuccess) {
      setLoading(false);
      setData(response.data.allServices);
    } else {
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [deleteService]);

  console.log(data);

  return (
    <>
      <Container>
        <div className="page-header">
          <Row>
            <Col lg="8" xs="12">
              <Breadcrumbs title="Services" parent="Dashboard" />
            </Col>
            <Col lg="4" xs="12">
              <Link
                to="/dashboard/create-service"
                className="btn btn-primary btn-block"
              >
                <FontAwesomeIcon className="mr-1" icon={faPlus} />
                Add Service
              </Link>
            </Col>
          </Row>
        </div>
        <Row>
          {loading ? (
            <ComponentLoader height="80vh" />
          ) : error ? (
            <div className="search-not-found text-center my-auto">
              <div>
                <img
                  className="img-fluid second-search"
                  src={errorImg}
                  alt=""
                />
                <p>Sorry, We didn't find any Services</p>
              </div>
            </div>
          ) : (
            <Card>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th className="border"> Image </th>
                      <th className="border"> Service Name </th>
                      <th className="border"> Description </th>
                      <th className="border"> Actions </th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((d) => (
                      <ServiceTable
                        data={d}
                        key={d._id}
                        setDelete={setDeleteService}
                      />
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Service;
