import { toast } from "react-toastify";
import React, { useState } from "react";
import { db, firestore } from "../../../../../service/firebase";
import { collectIdsAndDocs } from "../../../../../service/utils";
import { RespondRequirement } from "../../../../../service/service";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

export default function ResponseToRequirement(props) {
  const {
    open,
    handleClose,
    requirement,
    company,
    buyer,
    history,
    siteSettings,
  } = props;
  const navigate = useNavigate();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [message, setMessage] = useState(null);

  const photo =
    buyer && buyer.userInfo.photoURL
      ? buyer.userInfo.photoURL
      : "Photo is missing";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (message) {
      setButtonLoading(true);
      const response = await RespondRequirement(
        { seller: siteSettings?.vendor?._id },
        requirement._id
      );
      console.log(
        "🚀 ~ file: ResponseToRequirement.jsx:28 ~ handleSubmit ~ response",
        response
      );
      if (response && response.isSuccess) {
        handleClose();
        toast.success("Responded!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        const q = query(
          collection(firestore, "conversations"),
          where("buyer", "==", requirement.buyer),
          where("seller", "==", siteSettings?.vendor?._id)
        );

        const querySnapshot = await getDocs(q);
        console.log(
          "🚀 ~ file: ResponseToRequirement.jsx:45 ~ handleSubmit ~ querySnapshot",
          querySnapshot
        );
        const chatInfo = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(
          "🚀 ~ file: ResponseToRequirement.jsx:50 ~ chatInfo ~ chatInfo",
          chatInfo
        );

        let conversation;

        if (chatInfo[0]) {
          conversation = chatInfo[0].id;
        } else {
          const docRef = await addDoc(collection(firestore, "conversations"), {
            lastMessage: message,

            buyer: buyer ? buyer._id : null,
            buyerName:
              buyer.userInfo.firstName || buyer.userInfo.lastName
                ? `${buyer.userInfo.firstName} ${buyer.userInfo.lastName}`
                : "NO Name" + buyer.email
                ? buyer.email
                : buyer.phone,
            buyerPhoto: photo,
            seller: siteSettings?.vendor?._id,
            sellerName: siteSettings?.vendor?.name,
            sellerPhoto: siteSettings?.vendor?.logo,
            createdAt: new Date(),
            updatedAt: new Date(),
          });

          conversation = docRef.id;
        }

        await addDoc(
          collection(firestore, "conversations", conversation, "messages"),
          {
            user: siteSettings?.vendor?._id,
            userName: siteSettings?.vendor?.name,
            userPhoto: siteSettings?.vendor?.logo,
            text: message,
            conversation: conversation,
            createdAt: new Date(),
          }
        )
          .then((ref) => {
            // setLoading(false)
            navigate("/dashboard/message-center?conversation=" + conversation);
          })
          .catch((error) => console.log(error));
      }

      setButtonLoading(false);
    }
  };

  return (
    <Modal isOpen={open} toggle={handleClose} centered size="md">
      <ModalHeader toggle={handleClose}>Respond</ModalHeader>
      <ModalBody>
        <div className="">
          <p className="font-size-14 m-0 p-0">
            <b>Similar to:</b>{" "}
            {requirement.productType && requirement.productType.name}
          </p>
          <p className="font-size-14 m-0 p-0">
            <b>Posted on:</b> {requirement.createdAt.toString().slice(0, 10)}{" "}
            {requirement.status === "closed" &&
              " | " + requirement.closedAt.toString().slice(0, 10)}
          </p>
          <p className="font-size-14 m-0 p-0">
            <b>Prefered Quantity:</b> {requirement.quantity}
          </p>
          <p className="font-size-14 m-0 p-0">
            <b>Contact Number:</b> {requirement.contactNumber}
          </p>
          <p className="font-size-14 m-0 p-0">
            <b>Contact Email:</b> {requirement.contactEmail}
          </p>
        </div>
        <div className="border-top pt-2">
          <div className="h6 section-header font-weight-bold">
            Send Response
          </div>
          <textarea
            className="form-control-sm form-control mb-2"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows="4"
            name="message"
            placeholder="Short Description About Product"
          />
          {buttonLoading ? (
            <Button color="primary btn-block" disabled={buttonLoading}>
              SAVING...
            </Button>
          ) : (
            <Button onClick={handleSubmit} color="primary btn-block">
              Send
            </Button>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
}
