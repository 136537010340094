import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CreateVendorPermission } from "../../../../../service/service";

export default function CreateNewPermission(props) {
  const {
    open,
    handleClose,
    setVendorPermissions,
    vendorPermissions,
    company,
  } = props;
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.title.value) {
      setButtonLoading(true);
      const data = {
        title: e.target.title.value,
        company: company,
        productManagement: {
          addProduct: e.target.addProduct.value,
          updateProduct: e.target.updateProduct.value,
          deleteProduct: e.target.deleteProduct.value,
        },
        stockManagement: {
          updateStock: e.target.updateStock.value,
        },
        orderManagement: {
          addOrder: e.target.addOrder.value,
          updateOrder: e.target.updateOrder.value,
          deleteOrder: e.target.deleteOrder.value,
        },
        eventManagement: {
          addEventProduct: e.target.addEventProduct.value,
          updateEventProduct: e.target.updateEventProduct.value,
          deleteEventProduct: e.target.deleteEventProduct.value,
        },
      };
      const response = await CreateVendorPermission(data);
      if (response && response.isSuccess) {
        setVendorPermissions([
          response.data.vendorPermission,
          ...vendorPermissions,
        ]);
        handleClose();
        toast.success("New Permission Created!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      console.log(data);
      setButtonLoading(false);
    } else {
      toast.error("Title is Required", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  return (
    <Modal isOpen={open} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>Create Vendor Permission</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            <label
              htmlFor="productTitle"
              className="col-sm-12 font-weight-bold small"
            >
              Title
            </label>
            <div className="col-sm-12 mb-2">
              <input
                type="text"
                name="title"
                placeholder="Title"
                className="form-control-sm form-control"
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-6">
              <label
                htmlFor="addProduct"
                className="col-sm-12 font-weight-bold small"
              >
                Add Product
              </label>
            </div>
            <div className="col-6">
              <div className="col-sm-12 mb-2">
                <select
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control w-100"
                  defaultValue={1}
                  name="addProduct"
                >
                  <option disabled>Open this select menu</option>
                  <option value={1}>Restricted</option>
                  <option value={2}>Need Aproval</option>
                  <option value={3}>Aproved</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-6">
              <label
                htmlFor="updateProduct"
                className="col-sm-12 font-weight-bold small"
              >
                Update Product
              </label>
            </div>
            <div className="col-6">
              <div className="col-sm-12 mb-2">
                <select
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control w-100"
                  defaultValue={1}
                  name="updateProduct"
                >
                  <option disabled>Open this select menu</option>
                  <option value={1}>Restricted</option>
                  <option value={2}>Need Aproval</option>
                  <option value={3}>Aproved</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-6">
              <label
                htmlFor="deleteProduct"
                className="col-sm-12 font-weight-bold small"
              >
                Delete Product
              </label>
            </div>
            <div className="col-6">
              <div className="col-sm-12 mb-2">
                <select
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control w-100"
                  defaultValue={1}
                  name="deleteProduct"
                >
                  <option disabled>Open this select menu</option>
                  <option value={1}>Restricted</option>
                  <option value={2}>Need Aproval</option>
                  <option value={3}>Aproved</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-6">
              <label
                htmlFor="updateStock"
                className="col-sm-12 font-weight-bold small"
              >
                Update Stock
              </label>
            </div>
            <div className="col-6">
              <div className="col-sm-12 mb-2">
                <select
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control w-100"
                  defaultValue={1}
                  name="updateStock"
                >
                  <option disabled>Open this select menu</option>
                  <option value={1}>Restricted</option>
                  <option value={2}>Need Aproval</option>
                  <option value={3}>Aproved</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-6">
              <label
                htmlFor="addOrder"
                className="col-sm-12 font-weight-bold small"
              >
                Add Order
              </label>
            </div>
            <div className="col-6">
              <div className="col-sm-12 mb-2">
                <select
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control w-100"
                  defaultValue={1}
                  name="addOrder"
                >
                  <option disabled>Open this select menu</option>
                  <option value={1}>Restricted</option>
                  <option value={2}>Need Aproval</option>
                  <option value={3}>Aproved</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-6">
              <label
                htmlFor="updateOrder"
                className="col-sm-12 font-weight-bold small"
              >
                Update Order
              </label>
            </div>
            <div className="col-6">
              <div className="col-sm-12 mb-2">
                <select
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control w-100"
                  defaultValue={1}
                  name="updateOrder"
                >
                  <option disabled>Open this select menu</option>
                  <option value={1}>Restricted</option>
                  <option value={2}>Need Aproval</option>
                  <option value={3}>Aproved</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-6">
              <label
                htmlFor="deleteOrder"
                className="col-sm-12 font-weight-bold small"
              >
                Delete Order
              </label>
            </div>
            <div className="col-6">
              <div className="col-sm-12 mb-2">
                <select
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control w-100"
                  defaultValue={1}
                  name="deleteOrder"
                >
                  <option disabled>Open this select menu</option>
                  <option value={1}>Restricted</option>
                  <option value={2}>Need Aproval</option>
                  <option value={3}>Aproved</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-6">
              <label
                htmlFor="addEventProduct"
                className="col-sm-12 font-weight-bold small"
              >
                Add Event Product
              </label>
            </div>
            <div className="col-6">
              <div className="col-sm-12 mb-2">
                <select
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control w-100"
                  defaultValue={1}
                  name="addEventProduct"
                >
                  <option disabled>Open this select menu</option>
                  <option value={1}>Restricted</option>
                  <option value={2}>Need Aproval</option>
                  <option value={3}>Aproved</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-6">
              <label
                htmlFor="updateEventProduct"
                className="col-sm-12 font-weight-bold small"
              >
                Update Event Product
              </label>
            </div>
            <div className="col-6">
              <div className="col-sm-12 mb-2">
                <select
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control w-100"
                  defaultValue={1}
                  name="updateEventProduct"
                >
                  <option disabled>Open this select menu</option>
                  <option value={1}>Restricted</option>
                  <option value={2}>Need Aproval</option>
                  <option value={3}>Aproved</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-6">
              <label
                htmlFor="deleteEventProduct"
                className="col-sm-12 font-weight-bold small"
              >
                Delete Event Product
              </label>
            </div>
            <div className="col-6">
              <div className="col-sm-12 mb-2">
                <select
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control w-100"
                  defaultValue={1}
                  name="deleteEventProduct"
                >
                  <option disabled>Open this select menu</option>
                  <option value={1}>Restricted</option>
                  <option value={2}>Need Aproval</option>
                  <option value={3}>Aproved</option>
                </select>
              </div>
            </div>
          </div>

          {buttonLoading ? (
            <Button color="primary btn-block" disabled={buttonLoading}>
              SAVING...
            </Button>
          ) : (
            <Button type="submit" color="primary btn-block">
              <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
            </Button>
          )}
        </form>
      </ModalBody>
    </Modal>
  );
}
