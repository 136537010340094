import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../Components/Breadcrumbs";
import {
  Col,
  Row,
  Button,
  Container,
  Card,
  CardBody,
  CardHeader,
} from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { DeleteSlider, GetAllSliders } from "../../../../service/service";
import { imageExists } from "../../../../service/imageExists";
import config from "../../../../service/api/config";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import noImage from "../../../../assets/images/notFound.png";
import errorImg from "../../../../assets/images/search-not-found.png";
import CreateSlider from "./Components/CreateSlider";
import UpdateSlider from "./Components/UpdateSlider";
import operations from "../../../../redux/Main/operations";

import SweetAlert from "sweetalert2";
import { set } from "react-hook-form";
import ConfirmDelete from "../../Components/ConfirmDelete";

function Slider(props) {
  const { sliders, setSliders } = props;
  console.log("🚀 ~ file: Slider.jsx:33 ~ Slider ~ sliders:", sliders);

  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [sliderToUpdate, setSliderToUpdate] = useState({});
  const [sliderMainData, setSliderMainData] = useState([]);
  const [sliderAllData, setSliderAllData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");

  const handleClose = () => {
    setOpenCreate(false);
    setOpenUpdate(false);
    setOpenDelete(false);
  };
  const handleClickOpenCreate = () => {
    setOpenCreate(true);
  };

  const getData = async () => {
    console.log("searchKey", searchKey);
    const data = {
      // status: status ? status : null,
      searchKey: searchKey,
    };
    const response = await GetAllSliders(data);

    setSliders(response.data);
    setSliderMainData(response.data.events);
    setSliderAllData(response.data.events);
    setLoading(false);
  };
  const confirmDelete = async (id) => {
    const response = await DeleteSlider(id);

    if (response && response.isSuccess) {
      setSliders(sliders.filter((slider) => slider._id !== id));
      handleClose();
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    // return;
  };
  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this category!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Slider deleted.", "success");
        });
      } else {
        SweetAlert.fire("Category is safe!");
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <Container>
      <div className="page-header">
        <Row>
          <Col lg="8" xs="12">
            <Breadcrumbs title="Slider" parent="Dashboard" />
          </Col>
          <Col lg="4" xs="12" className="d-flex justify-content-end">
            <Button onClick={handleClickOpenCreate} color="primary btn-block">
              <FontAwesomeIcon className="mr-1" icon={faPlus} /> Add Slider
            </Button>
            <CreateSlider
              open={openCreate}
              sliders={sliders}
              handleClose={handleClose}
              setSliders={setSliders}
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col sm="12">
          <Card>
            {/* <CardHeader>
              <Row>
                <Col lg="8" md="6" sm="12" className="text-right">
                  <form
                    className="form-inline search-form"
                    //   onSubmit={handleSearch}
                  >
                    <div className="form-group w-100">
                      <input
                        type="text"
                        id="search"
                        name="search"
                        //   value={searchKey}
                        placeholder="Search.."
                        className="form-control-plaintext rounded-0"
                        //   onChange={(e) => setSearchKey(e.target.value)}
                      />
                    </div>
                  </form>
                </Col>

                <Col lg="4" md="6" sm="12" className="text-right">
                  <form
                    className="form-inline search-form"
                    onSubmit={handleSearch}
                  >
                    <div className="form-group w-100 border">
                      <select
                        // onChange={(e) => {
                        //   if (e.target.value) {
                        //     setSliders(
                        //       sliderMainData &&
                        //         sliderMainData?.filter(
                        //           (item) => item?.status === e.target.value
                        //         )
                        //     );
                        //   } else {
                        //     setSliders(sliderAllData);
                        //   }
                        // }}
                        className="form-control-plaintext rounded-0"
                      >
                        <option value="">All Sliders</option>
                        <option value={"true"}>Active</option>
                        <option value={"false"}>In Active</option>
                      </select>
                    </div>
                  </form>
                </Col>
              </Row>
            </CardHeader> */}
            {loading ? (
              <ComponentLoader height="80vh" />
            ) : (
              <CardBody>
                {sliders.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-hover table-md text-center">
                      <thead>
                        <tr>
                          <th className="border">Slider</th>
                          <th className="border">Active Status</th>
                          <th className="border"> Url</th>

                          <th className="border">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sliders &&
                          sliders.map((slider) => {
                            return (
                              <tr key={slider._id} className="text-capitalize">
                                <td className="border">
                                  {console.log(
                                    config.fileServer +
                                      config.imageContainer +
                                      slider.sliderImg
                                  )}
                                  <img
                                    alt="event cover"
                                    width="80px"
                                    src={
                                      slider.sliderImg &&
                                      imageExists(
                                        config.fileServer +
                                          config.imageContainer +
                                          slider.sliderImg
                                      )
                                        ? config.fileServer +
                                          config.imageContainer +
                                          slider.sliderImg
                                        : noImage
                                    }
                                  />
                                </td>
                                <td className="border">
                                  {slider.isActive ? (
                                    <span className="text-success bold">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="text-danger">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                                <td className="border">
                                  {slider.url && slider.url}
                                </td>

                                <td className="border">
                                  <div className="button-list">
                                    <button
                                      onClick={() => {
                                        setSliderToUpdate(slider);
                                        setOpenUpdate(true);
                                      }}
                                      className="btn btn-text p-0"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          width: 35,
                                          fontSize: 16,
                                          padding: 11,
                                          color: "rgb(40, 167, 69)",
                                        }}
                                      ></i>
                                    </button>
                                    <button
                                      href="#"
                                      onClick={(e) => handleDelete(slider._id)}
                                      className="btn btn-text p-0"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        style={{
                                          width: 35,
                                          padding: 11,
                                          fontSize: 16,
                                          color: "#e4566e",
                                        }}
                                      ></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="search-not-found text-center">
                    <div>
                      {/* <img
                        className="img-fluid second-search"
                        src={errorImg}
                        alt=""
                      /> */}
                      <p>Sorry, We didn't find any Slider</p>
                    </div>
                  </div>
                )}
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <ConfirmDelete
        open={openDelete}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
      <UpdateSlider
        open={openUpdate}
        handleClose={handleClose}
        sliderToUpdate={sliderToUpdate}
        setSliders={setSliders}
        sliders={sliders}
      />
    </Container>
  );
}
function mapStateToProps(state, props) {
  return {
    sliders: state.mainReducers.main.sliders,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Slider);
