import moment from "moment";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import { getEventsVendor } from "../../../../service/service";
import errorImg from "../../../../assets/images/search-not-found.png";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { Row, Col, Button, Container, Card, CardBody } from "reactstrap";

const VendorEventsPage = ({ vendors, company, products, companySubCs }) => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const response = await getEventsVendor({
      vendor: company._id,
      categories: companySubCs,
    });
    if (response && response.isSuccess) {
      setEvents(response.data.events);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" sm="3">
              <Breadcrumbs title="Vendor Events" parent="Dashboard" />
            </Col>
          </Row>
        </div>
      </Container>
      {loading ? (
        <ComponentLoader height="80vh" />
      ) : (
        <>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {events && events.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-hover table-md text-center">
                        <thead>
                          <tr>
                            <th className="border">Event Poster</th>
                            <th className="border">Event Title</th>
                            <th className="border">Event Discount Range</th>
                            <th className="border">Event Status</th>
                            <th className="border">Event Start Time</th>
                            <th className="border">Event Expire Time</th>
                            <th className="border">Event Details</th>
                          </tr>
                        </thead>
                        <tbody>
                          {events &&
                            events.map((event) => {
                              console.log("event", event);
                              return (
                                <tr key={event._id} className="text-capitalize">
                                  <td className="border">
                                    <img
                                      alt="event cover"
                                      width="60px"
                                      src={
                                        event.banners &&
                                        imageExists(
                                          config.fileServer +
                                            config.thumbnailContainer +
                                            event.banners
                                        )
                                          ? config.fileServer +
                                            config.thumbnailContainer +
                                            event?.banners[0]?.url
                                          : noImage
                                      }
                                    />
                                  </td>
                                  <td className="border">
                                    {event.title && event.title}
                                  </td>
                                  <td className="border">
                                    {event.discountRange && (
                                      <>
                                        <span>
                                          {event.discountRange.min &&
                                            event.discountRange.min}
                                        </span>
                                        {"-"}
                                        <span>
                                          {event.discountRange.max &&
                                            event.discountRange.max}
                                        </span>
                                        {"%"}
                                      </>
                                    )}
                                  </td>
                                  <td className="border">
                                    {event.status && event.status}
                                  </td>
                                  <td className="border">
                                    {moment(event.startedAt).format(
                                      "D MMM YYYY"
                                    )}
                                  </td>
                                  <td className="border">
                                    {moment(event.expiresAt).format(
                                      "D MMM YYYY"
                                    )}
                                  </td>
                                  <td className="border">
                                    <div className="button-list">
                                      <Link
                                        to={`/dashboard/event-details/${event.slug}`}
                                      >
                                        <Button
                                          href="#"
                                          className="btn btn-dark p-0"
                                        >
                                          <i
                                            className="fa fa-pencil"
                                            style={{
                                              width: 35,
                                              fontSize: 16,
                                              padding: 11,
                                              color: "rgb(40, 167, 69)",
                                            }}
                                          ></i>
                                        </Button>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="search-not-found text-center">
                      <div>
                        <img
                          className="img-fluid second-search"
                          src={errorImg}
                          alt="error svg"
                        />
                        <p>Sorry, We didn't find any events</p>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    vendors: state.mainReducers.main.vendors,
    company: state.mainReducers.main.company,
    products: state.mainReducers.main.products,
    companySubCs: state.mainReducers.main.companySubCs,
  };
}

export default connect(mapStateToProps)(VendorEventsPage);
