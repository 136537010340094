import {
  CreateAttribute,
  UpdateAttribute,
} from "../../../../../service/service";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";
import config from "../../../../../service/api/config";
import { faSave, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

export default function CreateCategor(props) {
  const {
    open,
    handleClose,
    attributeToUpdate,
    company,
    variants,
    setVariants,
    filteredVariants,
    setFilteredVariants,
    setSelectedCategory,
    setCategories,
    categories,
    selectedCategory,
    parentCat,
    setParentCat,
    setAttributeToUpdate,
    fetchData,
  } = props;

  const [photo, setPhoto] = useState(null);
  const [isRoot, setIsRoot] = useState(false);
  const [isLeaf, setIsLeaf] = useState(false);
  const [parentAttribute, setParentAttribute] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  const [selectItem, setSelectItem] = useState([]);

  useEffect(() => {
    if (attributeToUpdate._id) {
      setIsLeaf(attributeToUpdate.isLeaf);
      setIsRoot(attributeToUpdate.isRoot);
      setParentAttribute(
        attributeToUpdate.parentVariant ? attributeToUpdate.parentVariant : null
      );
    }
  }, [attributeToUpdate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.name.value) {
      if (!isRoot && !parentAttribute) {
        toast.error("Attribute must be root or parent attribute", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        setButtonLoading(true);
        let dataArray = [];
        for (var o in selectItem) {
          dataArray.push(selectItem[o]?._id);
        }

        const data = {
          name: e.target.name.value,
          isLeaf: isLeaf,
          isRoot: isRoot,
          parentAttribute: isRoot ? null : parentAttribute,
          categories: dataArray,
        };
        console.log(
          "🚀 ~ file: UpdateAttribute.jsx:72 ~ handleSubmit ~ dataArray",
          dataArray
        );
        const response = await UpdateAttribute(attributeToUpdate._id, data);
        if (response && response.isSuccess) {
          setVariants([
            response.data.attribute,
            ...variants.filter(
              (attr) => attr._id !== response.data.attribute._id
            ),
          ]);
          handleClose();
          toast.success("Attribute Updated!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          fetchData();
          setAttributeToUpdate({});
          setSelectItem([]);
          setSelectedCategory([]);
        }
        setButtonLoading(false);
      }
    } else {
      toast.error("Name & Unit fields can't be empty !!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  const handleSelectedCat = async (data) => {
    if (!selectItem?.find((item) => item?._id === data)) {
      let pp = categories?.filter((val) => val?._id === data);

      setSelectItem([...selectItem, pp[0]]);
      // setSelectedCategory([...selectedCategory, data]);
      setCategories(categories?.filter((item) => item?._id !== data));
    }
  };

  useEffect(() => {
    setParentCat(categories.filter((pCat) => pCat.isRoot === true));
  }, [categories, selectedCategory]);

  const handleDeletePaCat = (data) => {
    const newSelectItem = selectItem.filter((dItem) => dItem._id !== data._id);
    setSelectItem(newSelectItem);

    setCategories([...categories, data]);
  };

  useEffect(() => {
    if (attributeToUpdate?.categories) {
      if (attributeToUpdate?.categories) {
        setSelectItem([...attributeToUpdate?.categories]);
      }

      function getDifference(array1, array2) {
        return array1.filter((object1) => {
          return !array2.some((object2) => {
            return object1._id === object2._id;
          });
        });
      }

      let saveCat = getDifference(parentCat, attributeToUpdate?.categories);

      setCategories(saveCat);
    }

    setSelectedCategory(attributeToUpdate.categories);
  }, [attributeToUpdate]);

  console.log("sweer", selectItem);

  return (
    <Modal isOpen={open} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>Update Attribute</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            <label
              htmlFor="productAttribute"
              className="col-sm-12 font-weight-bold small"
            >
              Name
            </label>
            <div className="col-sm-12 mb-2">
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="form-control-sm form-control"
                defaultValue={attributeToUpdate && attributeToUpdate.name}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-6 mb-2">
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isRoot"
                    checked={isRoot}
                    onChange={() => {
                      setIsRoot(!isRoot);
                      setIsLeaf(false);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="isRoot">
                    Attribute Type
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-2">
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isLeaf"
                    checked={isLeaf}
                    onChange={() => {
                      setIsLeaf(!isLeaf);
                      setIsRoot(false);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="isLeaf">
                    Attribute Value
                  </label>
                </div>
              </div>
            </div>
          </div>
          {isRoot && (
            <div className="d-flex flex-wrap">
              {selectItem &&
                selectItem.map((sCat) => {
                  return (
                    <h6 className="px-3 py-2 bg-light text-dark  me-2 mb-3 border-0">
                      {sCat.name}
                      <FontAwesomeIcon
                        icon={faXmark}
                        className="ms-2 cursor-pointer"
                        onClick={() => handleDeletePaCat(sCat)}
                      />
                    </h6>
                  );
                })}
            </div>
          )}

          {isRoot && (
            <div className="form-group row">
              <label
                htmlFor="productAttriute"
                className="col-sm-12 font-weight-bold small"
              >
                Parent Category
              </label>
              <div className="col-sm-12 mb-2">
                <select
                  onChange={(e) => handleSelectedCat(e.target.value)}
                  aria-label="Default select example"
                  className="form-select form-control-sm form-control mb-2"
                >
                  <option>Open this select menu</option>
                  {parentCat &&
                    parentCat.map((cat) => (
                      <option value={cat._id} key={cat?._id}>
                        {cat.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          {!isRoot && (
            <div className="form-group row">
              <label
                htmlFor="productAttriute"
                className="col-sm-12 font-weight-bold small"
              >
                Attribute Type
              </label>
              <div className="col-sm-12 mb-2">
                <select
                  value={parentAttribute}
                  aria-label="Default select example"
                  onChange={(e) => setParentAttribute(e.target.value)}
                  className="form-select form-control-sm form-control mb-2"
                >
                  <option>Open this select menu</option>
                  {variants
                    .filter((item) => !item.isLeaf)
                    .map((cat) => (
                      <option value={cat._id} key={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          {buttonLoading ? (
            <Button color="primary btn-block" disabled={buttonLoading}>
              SAVING...
            </Button>
          ) : (
            <Button type="submit" color="primary btn-block">
              <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
            </Button>
          )}
        </form>
      </ModalBody>
    </Modal>
  );
}
