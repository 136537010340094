import { connect } from "react-redux";
import { Pagination } from "@material-ui/lab";
import React, { Fragment, useState } from "react";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { bindActionCreators } from "redux";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import ConfirmDelete from "../../Components/ConfirmDelete";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CreateCategory from "./Components/CreateSubCategory";
import noImage from "../../../../assets/images/notFound.png";
import UpdateSubCategor from "./Components/UpdateSubCategory";
import { imageExists } from "../../../../service/imageExists";
import { DeleteSubCategory } from "../../../../service/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getComparator, stableSort } from "../../../../service/sort";
import errorImg from "../../../../assets/images/search-not-found.png";

const SubCategoriesPage = (props) => {
  const { subCategories, setSubCategories, categories } = props;
  const [page, setPage] = useState(1);
  const [filteredSubCategories, setFilteredSubCategories] = useState(null);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [subCategorieyToUpdate, setSubCategoryToUpdate] = React.useState(null);

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");

  const handleClickOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleClose = () => {
    setOpenCreate(false);
    setOpenUpdate(false);
    setOpenDelete(false);
  };

  const confirmDelete = async (id) => {
    const response = await DeleteSubCategory(id);
    if (response && response.isSuccess) {
      console.log(response.data);
      setSubCategories(
        subCategories.filter((cat) => cat._id !== response.data.subCategory._id)
      );

      handleClose();
    }
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this sub-category!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Sub-Category deleted.", "success");
        });
      } else {
        SweetAlert.fire("Sub-Category is safe!");
      }
    });
  };

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "name": {
        setOrderBy(property);
        setOrder("asc");
        break;
      }
      default:
        break;
    }
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleSearch = async (searchKey) => {
    if (searchKey.length > 0) {
      setFilteredSubCategories(
        subCategories.filter((cat) =>
          cat.name.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilteredSubCategories(null);
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="Sub-Categories" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12">
              <Button onClick={handleClickOpenCreate} color="primary btn-block">
                <FontAwesomeIcon className="mr-1" icon={faPlus} /> Create
                Sub-Category
              </Button>
              <CreateCategory
                open={openCreate}
                handleClose={handleClose}
                setSubCategories={setSubCategories}
                subCategories={subCategories}
                categories={categories}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="10" sm="12" className="text-right">
                    <form className="form-inline search-form">
                      <div className="form-group w-100">
                        <input
                          type="search"
                          id="search"
                          className="form-control-plaintext"
                          placeholder="Search.."
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                  </Col>
                  <Col md="2" sm="12" className="products-total">
                    <div className="select2-drpdwn-product select-options d-inline-block">
                      <div className="form-group w-100">
                        <select
                          className="form-control btn-square"
                          name="select"
                          onChange={(e) => handleRequestSort(e, e.target.value)}
                        >
                          <option value="createdAt" selected>
                            Latest
                          </option>
                          <option value="name">Name</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {(filteredSubCategories ? filteredSubCategories : subCategories)
                  .length > 0 ? (
                  <div className="table-responsive">
                    <Table hover size="small">
                      <thead>
                        <tr>
                          <th style={{ width: "65px" }} className="text-center">
                            #
                          </th>
                          <th>Name</th>
                          <th>Category</th>
                          <th className="text-right">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stableSort(
                          filteredSubCategories
                            ? filteredSubCategories
                            : subCategories,
                          getComparator(order, orderBy)
                        )
                          .slice(page * 10 - 10, page * 10)
                          .map((sub) => (
                            <tr>
                              <td>
                                <img
                                  src={
                                    sub.photo && imageExists(sub.photo)
                                      ? sub.photo
                                      : noImage
                                  }
                                  className="img-fluid"
                                  alt="Cat logo"
                                  width="35"
                                />
                              </td>
                              <td>{sub.name}</td>
                              <td>{sub.category.name}</td>

                              <td className="text-right">
                                <div className="button-list">
                                  <button
                                    onClick={() => {
                                      setSubCategoryToUpdate(sub);
                                      setOpenUpdate(true);
                                    }}
                                    href="#"
                                    className="btn btn-text p-0"
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        fontSize: 16,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                      }}
                                    ></i>
                                  </button>
                                  <button
                                    href="#"
                                    onClick={() => {
                                      handleDelete(sub._id);
                                    }}
                                    className="btn btn-text p-0"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        width: 35,
                                        fontSize: 16,
                                        padding: 11,
                                        color: "#e4566e",
                                      }}
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        {subCategories.length > 10 && (
                          <tr>
                            <td colspan="6" align="right">
                              <Pagination
                                count={
                                  filteredSubCategories
                                    ? (
                                        filteredSubCategories.length / 10
                                      ).toFixed(0)
                                    : (subCategories.length / 10).toFixed(0)
                                }
                                page={page}
                                onChange={handlePageChange}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="search-not-found text-center">
                    <div>
                      <img
                        className="img-fluid second-search"
                        src={errorImg}
                        alt=""
                      />
                      <p>Sorry, We didn't find any sub-category</p>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <UpdateSubCategor
        open={openUpdate}
        handleClose={handleClose}
        subCategorieyToUpdate={subCategorieyToUpdate}
        setSubCategories={setSubCategories}
        categories={categories}
        subCategories={subCategories}
      />
      <ConfirmDelete
        open={openDelete}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    categories: state.mainReducers.main.categories,
    subCategories: state.mainReducers.main.subCategories,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoriesPage);
