import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import config from "../../../../service/api/config";
const CommonModal = (props) => {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img
            src={config?.fileServer + config?.imageContainer + props?.fileName}
            alt=""
            className="img-fluid"
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CommonModal;
