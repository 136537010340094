import React, { useState, useEffect } from "react";
import config from "../../../../service/api/config";
import { getProductById } from "../../../../service/service";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import { Col, Card, CardBody, CardHeader } from "reactstrap";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const ProductComponent = ({ vendorRequest }) => {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);

  const fetchData = async () => {
    const response = await getProductById(vendorRequest.docID);
    if (response && response.isSuccess) {
      setProduct(response.data.product);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      vendorRequest.requestType === "updateProduct" ||
      vendorRequest.requestType === "deleteProduct"
    )
      fetchData();
    else setLoading(false);
  }, []);

  const details = (value) => {
    switch (value) {
      case "addProduct":
        return (
          <>
            {loading ? (
              <ComponentLoader height="40vh" />
            ) : (
              <div className="table-responsive">
                <table className="table table-hover table-md text-center">
                  <thead>
                    <tr className="font-01 weight">
                      <th className="border">Field Name</th>
                      <th className="border">New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="border font-01 weight">Image</th>
                      <td className="border">
                        {vendorRequest.requestData.photos.map((photo) => (
                          <img
                            alt={photo.name}
                            src={
                              photo.url &&
                              imageExists(
                                config.fileServer +
                                  config.thumbnailContainer +
                                  photo.url
                              )
                                ? config.fileServer +
                                  config.thumbnailContainer +
                                  photo.url
                                : noImage
                            }
                            width="60px"
                          />
                        ))}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Name</th>
                      <td className="border">
                        {vendorRequest.requestData.name}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">
                        Short Description
                      </th>
                      <td className="border">
                        {vendorRequest.requestData.shortDescription}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Categories</th>
                      <td className="border">
                        {vendorRequest.requestData.categories
                          ? vendorRequest.requestData.categories.map(
                              (category) => (
                                <li key={category._id}>{category.name}</li>
                              )
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Price</th>
                      <td className="border">
                        {vendorRequest.requestData.price}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        );
      case "updateProduct":
        return (
          <>
            {loading ? (
              <ComponentLoader height="40vh" />
            ) : (
              <div className="table-responsive">
                <table className="table table-hover table-md text-center">
                  <thead>
                    <tr className="font-01 weight">
                      <th className="border">Field Name</th>
                      <th className="border">Original Value</th>
                      <th className="border">New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="border font-01 weight">Image</th>
                      <td className="border">
                        {product.photos.map((photo) => (
                          <img
                            alt={photo.name}
                            src={
                              photo.url &&
                              imageExists(
                                config.fileServer +
                                  config.thumbnailContainer +
                                  photo.url
                              )
                                ? config.fileServer +
                                  config.thumbnailContainer +
                                  photo.url
                                : noImage
                            }
                            width="60px"
                          />
                        ))}
                      </td>
                      <td className="border">
                        {vendorRequest.requestData.photos
                          ? vendorRequest.requestData.photos.map((photo) => (
                              <img
                                alt={photo.name}
                                src={
                                  photo.url &&
                                  imageExists(
                                    config.fileServer +
                                      config.thumbnailContainer +
                                      photo.url
                                  )
                                    ? config.fileServer +
                                      config.thumbnailContainer +
                                      photo.url
                                    : noImage
                                }
                                width="60px"
                              />
                            ))
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Name</th>
                      <td className="border">{product.name}</td>
                      <td className="border">
                        {vendorRequest.requestData.name
                          ? vendorRequest.requestData.name
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">
                        Short Description
                      </th>
                      <td className="border">{product.shortDescription}</td>
                      <td className="border">
                        {vendorRequest.requestData.shortDescription
                          ? vendorRequest.requestData.shortDescription
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Categories</th>
                      <td className="border">
                        {product.categories
                          ? product.categories.map((category) => (
                              <li key={category._id}>{category.name}</li>
                            ))
                          : ""}
                      </td>
                      <td className="border">
                        {vendorRequest.requestData.categories
                          ? vendorRequest.requestData.categories.map(
                              (category) => (
                                <>
                                  {category.name ? (
                                    <li key={category._id}>
                                      {category.name ? category.name : ""}
                                    </li>
                                  ) : (
                                    ""
                                  )}
                                </>
                              )
                            )
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">
                        Min Order Quantity
                      </th>
                      <td className="border">{product.minOrderQuantity}</td>
                      <td className="border">
                        {vendorRequest.requestData.minOrderQuantity
                          ? vendorRequest.requestData.minOrderQuantity
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Price</th>
                      <td className="border">{product.price}</td>
                      <td className="border">
                        {vendorRequest.requestData.price
                          ? vendorRequest.requestData.price
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        );
      case "deleteProduct":
        return (
          <>
            {loading ? (
              <ComponentLoader height="40vh" />
            ) : (
              <>
                {vendorRequest.requestType === "deleteProduct" &&
                vendorRequest.status === "approved" ? null : (
                  <div className="table-responsive">
                    <table className="table table-hover table-md text-center">
                      <thead>
                        <tr className="font-01 weight">
                          <th className="border">Field Name</th>
                          <th className="border">Original Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th className="border font-01 weight">Image</th>
                          <td className="border">
                            {product && product.photos
                              ? product.photos.map((photo) => (
                                  <img
                                    alt={photo.name}
                                    src={
                                      photo.url &&
                                      imageExists(
                                        config.fileServer +
                                          config.thumbnailContainer +
                                          photo.url
                                      )
                                        ? config.fileServer +
                                          config.thumbnailContainer +
                                          photo.url
                                        : noImage
                                    }
                                    width="60px"
                                  />
                                ))
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <th className="border font-01 weight">Name</th>
                          <td className="border">{product && product.name}</td>
                        </tr>
                        <tr>
                          <th className="border font-01 weight">
                            Short Description
                          </th>
                          <td className="border">
                            {product && product.shortDescription}
                          </td>
                        </tr>
                        <tr>
                          <th className="border font-01 weight">Categories</th>
                          <td className="border">
                            {product && product.categories
                              ? product.categories.map((category) => (
                                  <li key={category._id}>{category.name}</li>
                                ))
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <th className="border font-01 weight">
                            Min Order Quantity
                          </th>
                          <td className="border">
                            {product && product.minOrderQuantity
                              ? product.minOrderQuantity
                              : ""}
                          </td>
                        </tr>
                        <tr>
                          <th className="border font-01 weight">Price</th>
                          <td className="border">{product && product.price}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Col sm="12">
        <Card>
          <CardHeader>
            <h6 className="m-0 font-weight-bold text-primary">
              {`Requested Data Details - ${vendorRequest.requestType}`}
            </h6>
          </CardHeader>
          <CardBody>{details(vendorRequest.requestType)}</CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ProductComponent;
