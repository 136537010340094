import {
  Col,
  Row,
  Card,
  Table,
  Button,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Pagination } from "@material-ui/lab";
import React, { Fragment, useEffect, useState } from "react";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import UpdateCategor from "./Components/UpdateCategory";
import CreateCategory from "./Components/CreateCategory";
import ConfirmDelete from "../../Components/ConfirmDelete";
import operations from "../../../../redux/Main/operations";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { DeleteCategory, GetAllCategory } from "../../../../service/service";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import errorImg from "../../../../assets/images/search-not-found.png";
import { getComparator, stableSort } from "../../../../service/sort";
import TreeComponents from "./Components/TreeComponents";
import "./Components/tree.css";

const CategoriesPage = (props) => {
  const { categories, setCategories, company } = props;

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [categoryToUpdate, setCategoryToUpdate] = useState({});
  const [filteredCategories, setFilteredCategories] = useState(null);
  const [isTableView, setIsTableView] = useState(true);
  const [isTreeView, setIsTreeView] = useState(false);

  const handleClickOpenCreate = () => {
    setOpenCreate(true);
  };
  const getData = async () => {
    const data = {
      sortBy: {
        field: orderBy,
        order: order,
      },
    };
    const response = await GetAllCategory(data);
    setCategories(response?.data?.categories);
  };

  useEffect(() => {
    getData();
  }, [order, orderBy]);
  const handleClose = () => {
    setOpenCreate(false);
    setOpenUpdate(false);
    setOpenDelete(false);
  };

  const confirmDelete = async (id) => {
    const response = await DeleteCategory(id);
    if (response && response.isSuccess) {
      setCategories(
        categories.filter((cat) => cat._id !== response.data.category._id)
      );
      handleClose();
    }
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this category!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Category deleted.", "success");
        });
      } else {
        SweetAlert.fire("Category is safe!");
      }
    });
  };

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "name": {
        setOrderBy(property);
        setOrder("asc");
        break;
      }
      default:
        break;
    }
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleSearch = async (searchKey) => {
    if (searchKey.length > 0) {
      setFilteredCategories(
        categories.filter((cat) =>
          cat.name.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilteredCategories(null);
    }
  };

  const handleFilterCategories = async (value) => {
    if (value === "root") {
      setFilteredCategories(categories.filter((cat) => cat.isRoot));
    } else {
      setFilteredCategories(null);
    }
  };

  const dataTransformation = (data) => {
    const rootNode = data.filter((c) => c.isRoot && !c.parentCategory);
    const subRoots = data.filter((c) => c.parentCategory);

    const dataArray = [];

    rootNode.map((item) => {
      const obj = {
        name: item.name,
        children: [],
      };
      for (let i = 0; i < subRoots.length; i++) {
        if (subRoots[i].parentCategory.name === item.name) {
          const newObj = {
            name: subRoots[i].name,
            children: [],
          };
          obj.children.push(newObj);
        }
      }
      dataArray.push(obj);
    });

    return dataArray;
  };

  const dataTree = dataTransformation(categories);

  const tableButton = (e) => {
    e.preventDefault();
    setIsTableView(!isTableView);
    setIsTreeView(!isTreeView);
  };

  const treeButton = (e) => {
    e.preventDefault();
    setIsTableView(!isTableView);
    setIsTreeView(!isTreeView);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="Categories" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12">
              <Button onClick={handleClickOpenCreate} color="primary btn-block">
                <FontAwesomeIcon className="mr-1" icon={faPlus} /> Create
                Category
              </Button>
              <CreateCategory
                open={openCreate}
                company={company}
                categories={categories}
                handleClose={handleClose}
                setCategories={setCategories}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <div className="d-flex justify-content-end mb-1">
              <Button
                onClick={tableButton}
                className="me-2"
                disabled={isTableView}
              >
                <i className="icofont icofont-list"></i>
              </Button>
              <Button onClick={treeButton} disabled={isTreeView}>
                <i className="icofont icofont-chart-flow"></i>
              </Button>
            </div>
            <Card>
              {isTableView ? (
                <>
                  <CardHeader>
                    <Row>
                      <Col md="6" sm="12" className="text-right">
                        <div className="form-inline search-form">
                          <div className="form-group w-100">
                            <input
                              type="search"
                              id="search"
                              className="form-control-plaintext"
                              placeholder="Search.."
                              onChange={(e) => {
                                handleSearch(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                      <Col md="3" sm="12" className="products-total">
                        <div className="select2-drpdwn-product select-options d-inline-block w-100">
                          <div className="form-group w-100">
                            <select
                              name="createdAt"
                              className="form-control btn-square"
                              onChange={(e) =>
                                handleFilterCategories(e.target.value)
                              }
                            >
                              <option value="all">All Categories</option>
                              <option value="root">Root Categories</option>
                            </select>
                          </div>
                        </div>
                      </Col>
                      <Col md="3" sm="12" className="products-total">
                        <div className="select2-drpdwn-product select-options d-inline-block w-100">
                          <div className="form-group w-100">
                            <select
                              name="createdAt"
                              className="form-control btn-square"
                              onChange={(e) =>
                                handleRequestSort(e, e.target.value)
                              }
                            >
                              <option value="createdAt">Latest</option>
                              <option value="name">Name</option>
                            </select>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {(filteredCategories ? filteredCategories : categories)
                      .length > 0 ? (
                      <div className="table-responsive">
                        <Table hover size="small">
                          <thead>
                            <tr>
                              <th
                                style={{ width: "65px" }}
                                className="text-center border"
                              >
                                #
                              </th>
                              <th className="border">Name</th>
                              <th className="border">Type</th>
                              <th className="border">Parent</th>
                              <th className="text-right border">Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {stableSort(
                              filteredCategories
                                ? filteredCategories
                                : categories,
                              getComparator(order, orderBy)
                            )
                              .slice(page * 10 - 10, page * 10)
                              .map((cat, index) => (
                                <tr key={index}>
                                  <td className="border">
                                    <img
                                      width="35"
                                      alt="cat logo"
                                      src={
                                        cat.photo &&
                                        imageExists(
                                          config.fileServer +
                                            config.thumbnailContainer +
                                            cat.photo
                                        )
                                          ? config.fileServer +
                                            config.thumbnailContainer +
                                            cat.photo
                                          : noImage
                                      }
                                      className="img-fluid"
                                    />
                                  </td>
                                  <td className="border">{cat.name}</td>
                                  <td className="border">
                                    {cat.isRoot ? "Root" : ""}{" "}
                                    {cat.isLeaf ? "Leaf" : ""}
                                  </td>

                                  <td className="border">
                                    {!cat.isRoot
                                      ? cat.parentCategory &&
                                        cat.parentCategory.name
                                      : ""}
                                  </td>

                                  <td className="text-right border">
                                    <div className="button-list">
                                      <button
                                        onClick={() => {
                                          setCategoryToUpdate(cat);
                                          setOpenUpdate(true);
                                        }}
                                        className="btn btn-text p-0"
                                      >
                                        <i
                                          className="fa fa-pencil"
                                          style={{
                                            width: 35,
                                            fontSize: 16,
                                            padding: 11,
                                            color: "rgb(40, 167, 69)",
                                          }}
                                        ></i>
                                      </button>
                                      <button
                                        onClick={() => {
                                          handleDelete(cat._id);
                                        }}
                                        className="btn btn-text p-0"
                                      >
                                        <i
                                          className="fa fa-trash"
                                          style={{
                                            width: 35,
                                            fontSize: 16,
                                            padding: 11,
                                            color: "#e4566e",
                                          }}
                                        ></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            {categories.length > 10 && (
                              <tr>
                                <td colSpan="6" align="right">
                                  <Pagination
                                    count={parseInt(
                                      filteredCategories
                                        ? filteredCategories.length / 10 + 1
                                        : categories.length / 10 + 1
                                    )}
                                    page={page}
                                    onChange={handlePageChange}
                                  />
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    ) : (
                      <div className="search-not-found text-center">
                        <div>
                          <img
                            className="img-fluid second-search"
                            src={errorImg}
                            alt="Error Logo"
                          />
                          <p>Sorry, We didn't find any category</p>
                        </div>
                      </div>
                    )}
                  </CardBody>{" "}
                </>
              ) : (
                <CardBody>
                  <TreeComponents data={dataTree} />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <UpdateCategor
        open={openUpdate}
        handleClose={handleClose}
        categoryToUpdate={categoryToUpdate}
        setCategories={setCategories}
        categories={categories}
        filteredCategories={filteredCategories}
        setFilteredCategories={setFilteredCategories}
      />
      <ConfirmDelete
        open={openDelete}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    categories: state.mainReducers.main.categories,
    company: state.mainReducers.main.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPage);
