import moment from "moment";
import SweetAlert from "sweetalert2";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import errorImg from "../../../../assets/images/search-not-found.png";
import { getAllEvents, deleteEvent } from "../../../../service/service";
import { Row, Col, Card, CardBody, Container, CardHeader } from "reactstrap";

const AllEventsPage = () => {
  const [status, setStatus] = useState("");
  const [events, setEvents] = useState([]);
  const [eventMainData, setEventMainData] = useState([]);
  const [eventAllData, setEventAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");

  const getData = async () => {
    console.log("searchKey", searchKey);
    const data = {
      // status: status ? status : null,
      searchKey: searchKey,
    };
    const response = await getAllEvents(data);
    console.log(
      "🚀 ~ file: AllEventsPage.jsx:25 ~ getData ~ response",
      response
    );
    if (response && response.isSuccess) {
      setEvents(response.data.events);
      setEventMainData(response.data.events);
      setEventAllData(response.data.events);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [searchKey]);

  const handleSearch = (e) => {
    e.preventDefault();
    // return;
  };

  const confirmDelete = async (id) => {
    const response = await deleteEvent(id);
    if (response && response.isSuccess) {
      setEvents(
        events.filter((event) => event._id !== response.data.event._id)
      );
    }
  };

  const handleDelete = (id) => {
    SweetAlert.fire({
      icon: "warning",
      reverseButtons: true,
      title: "Are you sure?",
      showCancelButton: true,
      cancelButtonText: "cancel",
      confirmButtonText: "confirm",
      text: "Once deleted, you will not be able to recover this event!",
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Event deleted.", "success");
        });
      } else {
        SweetAlert.fire("Event is safe!");
      }
    });
  };

  return (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="All Events" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12">
              <Link
                to="/dashboard/add-event"
                className="btn btn-primary btn-block"
              >
                <i className="fa fa-plus mr-1"></i>
                <span className="text">Add Events</span>
              </Link>
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col lg="8" md="6" sm="12" className="text-right">
                    <form
                      className="form-inline search-form"
                      onSubmit={handleSearch}
                    >
                      <div className="form-group w-100">
                        <input
                          type="text"
                          id="search"
                          name="search"
                          value={searchKey}
                          placeholder="Search.."
                          className="form-control-plaintext rounded-0"
                          onChange={(e) => setSearchKey(e.target.value)}
                        />
                      </div>
                    </form>
                  </Col>

                  <Col lg="4" md="6" sm="12" className="text-right">
                    <form
                      className="form-inline search-form"
                      onSubmit={handleSearch}
                    >
                      <div className="form-group w-100 border">
                        <select
                          onChange={(e) => {
                            if (e.target.value) {
                              setEvents(
                                eventMainData &&
                                  eventMainData?.filter(
                                    (item) => item?.status === e.target.value
                                  )
                              );
                            } else {
                              setEvents(eventAllData);
                            }
                          }}
                          className="form-control-plaintext rounded-0"
                        >
                          <option value="">All Events</option>
                          <option value={"active"}>Active</option>
                          <option value={"scheduled"}>Scheduled</option>
                          <option value={"expired"}>Expired</option>
                        </select>
                      </div>
                    </form>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? (
                <ComponentLoader height="80vh" />
              ) : (
                <CardBody>
                  {events.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-hover table-md text-center">
                        <thead>
                          <tr>
                            <th className="border">Banner</th>
                            <th className="border">Event Title</th>
                            <th className="border">Discount Range</th>
                            <th className="border">Selected Vendors</th>
                            <th className="border">Selected Categories</th>
                            <th className="border">Event Status</th>
                            <th className="border">Start Time</th>
                            <th className="border">Expire Time</th>
                            <th className="border">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {events &&
                            events.map((event) => {
                              return (
                                <tr key={event._id} className="text-capitalize">
                                  <td className="border">
                                    {console.log()}
                                    <img
                                      alt="event cover"
                                      width="80px"
                                      src={
                                        event.banners &&
                                        imageExists(
                                          config.fileServer +
                                            config.thumbnailContainer +
                                            event.banners[0].url
                                        )
                                          ? config.fileServer +
                                            config.thumbnailContainer +
                                            event.banners[0].url
                                          : noImage
                                      }
                                    />
                                  </td>
                                  <td className="border">
                                    {event.title && event.title}
                                  </td>
                                  <td className="border">
                                    {event.discountRange && (
                                      <>
                                        <span>
                                          {event.discountRange.min &&
                                            event.discountRange.min}
                                        </span>
                                        {"-"}
                                        <span>
                                          {event.discountRange.max &&
                                            event.discountRange.max}
                                        </span>
                                        {"%"}
                                      </>
                                    )}
                                  </td>
                                  <td className="border">
                                    {event.selectedVendors &&
                                      event.selectedVendors.map((vendor) => {
                                        return (
                                          <span key={vendor._id}>
                                            {vendor.name}
                                            {", "}
                                          </span>
                                        );
                                      })}
                                  </td>
                                  <td className="border">
                                    {event.selectedCategories &&
                                      event.selectedCategories.map(
                                        (category) => {
                                          return (
                                            <span key={category._id}>
                                              {category.name}
                                              {", "}
                                            </span>
                                          );
                                        }
                                      )}
                                  </td>
                                  <td className="border">
                                    {event.status && event.status}
                                  </td>
                                  <td className="border">
                                    {moment(event.startedAt).format(
                                      "D MMM YYYY"
                                    )}
                                  </td>
                                  <td className="border">
                                    {moment(event.expiresAt).format(
                                      "D MMM YYYY"
                                    )}
                                  </td>
                                  <td className="border">
                                    <div className="button-list">
                                      <Link
                                        to={`/dashboard/update-event/${event.slug}`}
                                      >
                                        <button
                                          href="#"
                                          className="btn btn-dark p-0"
                                        >
                                          <i
                                            className="fa fa-pencil"
                                            style={{
                                              width: 35,
                                              fontSize: 16,
                                              padding: 11,
                                              color: "rgb(40, 167, 69)",
                                            }}
                                          ></i>
                                        </button>
                                      </Link>
                                      <button
                                        href="#"
                                        onClick={(e) => handleDelete(event._id)}
                                        className="btn btn-text p-0"
                                      >
                                        <i
                                          className="fa fa-trash"
                                          style={{
                                            width: 35,
                                            padding: 11,
                                            fontSize: 16,
                                            color: "#e4566e",
                                          }}
                                        ></i>
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="search-not-found text-center">
                      <div>
                        <img
                          className="img-fluid second-search"
                          src={errorImg}
                          alt=""
                        />
                        <p>Sorry, We didn't find any events</p>
                      </div>
                    </div>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AllEventsPage;
