import { toast } from "react-toastify";
import React, { useState } from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { UpdatePassword } from "../../../../service/service";
import { Col, Container, Row, Card, CardBody } from "reactstrap";

const UpdateUserPassword = () => {
  const [passwordButton, setPasswordButton] = useState(false);

  const handleUpdatePassword = async (e) => {
    e.preventDefault();
    if (
      !e.target.oldPassword.value ||
      !e.target.newPassword.value ||
      !e.target.confirmPassword.value
    ) {
      toast.error("Password fields can't be empty !!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else if (e.target.newPassword.value !== e.target.confirmPassword.value) {
      toast.error("Password didn't match !!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      setPasswordButton(true);
      const data = {
        password: e.target.oldPassword.value,
        newPassword: e.target.newPassword.value,
      };
      const response = await UpdatePassword(data);
      if (response && response.isSuccess) {
        toast.success("Password Updated Successfuly !!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
      setPasswordButton(false);
    }
  };

  return (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" sm="3">
              <Breadcrumbs title="Update Password" parent="Dashboard" />
            </Col>
          </Row>
        </div>
      </Container>
      <Card>
        <CardBody>
          <form onSubmit={handleUpdatePassword} className="login">
            <div className="form-group">
              <div className="js-form-message js-focus-state">
                <label htmlFor="signinEmail" className="mb-1">
                  Old Password
                </label>
                <div className="input-group mb-2">
                  <input
                    name="oldPassword"
                    type="password"
                    id="signinfirstName"
                    className="form-control"
                    placeholder="Old Password"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="js-form-message js-focus-state">
                <label htmlFor="signinEmail" className="mb-1">
                  New Password
                </label>
                <div className="input-group mb-2">
                  <input
                    name="newPassword"
                    type="password"
                    id="signinlastName"
                    className="form-control"
                    placeholder="New Password"
                  />
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="js-form-message js-focus-state">
                <label htmlFor="signinEmail" className="mb-1">
                  Confirm Password
                </label>
                <div className="input-group mb-2">
                  <input
                    name="confirmPassword"
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                  />
                </div>
              </div>
            </div>
            <div className="mb-2">
              <button
                type="submit"
                disabled={passwordButton}
                className="btn btn-block btn-sm btn-primary transition-3d-hover"
              >
                {!passwordButton ? "Update Password" : "Loading"}
              </button>
            </div>
          </form>
        </CardBody>
      </Card>
    </>
  );
};

export default UpdateUserPassword;
