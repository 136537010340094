import React, { useEffect, useState } from "react";
import PageHeader from "../ServicePage/components/PageHeader";
import { Container, Form, FormGroup, Input, Label } from "reactstrap";
import CreteShipping from "./components/CreteShipping";
import ShowShippingInfo from "./components/ShowShippingInfo";
import { GetAllShipping } from "../../../../service/service";

const Shipping = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [shippingInfo, setShippingInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isUpdated, setIsUpdated] = useState(true)

  const toggle = () => setIsOpen(!isOpen);

  const GetAllShippingData = async () => {
    setLoading(true);
    const response = await GetAllShipping();
    if (response && response.isSuccess) {
      setShippingInfo(response.data.allShipping);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetAllShippingData();
  }, [isUpdated]);

  return (
    <Container fluid={true}>
      <PageHeader
        title="Shipping"
        buttonText="Create Shipping"
        handleSubmitForm={toggle}
      />

      {/* Create Shiiping */}
      <CreteShipping isOpen={isOpen} toggle={toggle} setIsUpdated={setIsUpdated} isUpdated={isUpdated} />
      <ShowShippingInfo shippingInfo={shippingInfo} setShippingInfo={setShippingInfo} />
    </Container>
  );
};

export default Shipping;
