import { timeConvert } from "./timeConvert";

export const dateTime = (datetime) => {
  const t = new Date(datetime);
  const timeS = t.toTimeString().split(" ");
  const time = timeConvert(timeS[0]);
  const date = t.toDateString();
  return {
    time,
    date,
  };
};
