import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ScrollToBottom from "react-scroll-to-bottom";
import firebaseApp, { db, firestore } from "../../../../service/firebase";
import operations from "../../../../redux/Main/operations";
import noImage from "../../../../assets/images/notFound.png";
import React, { Fragment, useEffect, useState } from "react";
import { collectIdsAndDocs } from "../../../../service/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  UploadFile,
  DeletePhoto,
  UploadPhoto,
} from "../../../../service/service";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Col,
  Card,
  Row,
  Form,
  Media,
  Input,
  Button,
  CardBody,
  Container,
  FormGroup,
  InputGroup,
} from "reactstrap";
import { Picker } from "emoji-mart";
import queryString from "query-string";
import config from "../../../../service/api/config";
import { timeConvert } from "../../../../service/timeConvert";
import { imageExists } from "../../../../service/imageExists";
import errorImg from "../../../../assets/images/search-not-found.png";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import {
  faClose,
  faFileUpload,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  Query,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import { Avatar } from "@material-ui/core";
import { getDatabase, onValue, ref } from "firebase/database";
import { useRef } from "react";
import CommonModal from "../../Components/CommonModal/CommonModal";

const InboxPage = (props) => {
  let history = useNavigate();
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // const [conversations, setConversations] = useState([]);
  const { conversations, location, company, siteSettings } = props;
  const [imgModalFile, setImgModalFile] = useState("");
  const [imgModal, setImgModal] = useState(false);
  const ENDPOINT = "message";
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");
  const [snapShot, setSnapShot] = useState(null);
  const [messages, setMessages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [activeChat, setActiveChat] = useState(null);
  const [chatDetails, setChatDetails] = useState([]);
  const [uploadFileLoading, setUploadFileLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [modalShow, setModalShow] = React.useState(false);

  // const [messageInput, setMessageInput] = useState("");
  const { conversation } = queryString.parse(useLocation()?.search);

  // const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [filteredConversations, setFilteredConversations] = useState(null);

  const hiddenFileInput = React.useRef(null);

  // const handleClick = (event) => {
  //   hiddenFileInput.current.click();
  // };

  const uploadFile = async (e) => {
    setUploadFileLoading(true);
    try {
      const response = await UploadPhoto(e.target.files[0]);

      if (response && response.status) {
        setFile(response.data.fileName);
        setFileName(response.data.fileName);
      }
    } catch (err) {
      console.log(
        "🚀 ~ file: ChatBodyComponent.js:34 ~ uploadFileHandler ~ err",
        err
      );
    }

    setUploadFileLoading(false);
  };

  const handleFileDelete = async (file) => {
    DeletePhoto({
      fileName: file,
      container: "ivcommercefiles",
    });
    setFile(null);
    setFileName(null);
  };

  const fetchMessages = async () => {
    const q = query(
      collection(firestore, "conversations", conversation, "messages"),
      orderBy("createdAt", "asc")
    );
    const unsuscribe = onSnapshot(q, (querySnapshot) => {
      setChatDetails(querySnapshot.docs?.map((item) => item.data()));
    });
    return () => {
      unsuscribe();
    };
  };

  useEffect(() => {
    if (activeChat && conversation) {
      fetchMessages();
    }
  }, [activeChat, conversation]);

  const handleSetConversation = (e, conv) => {
    history("/dashboard/message-center?conversation=" + conv.id);
  };

  const sendMessage = async (event) => {
    event.preventDefault();

    if ((message || fileName) && conversation) {
      await addDoc(
        collection(firestore, "conversations", conversation, "messages"),
        {
          user: siteSettings?.vendor._id,
          userName: siteSettings?.vendor?.name,
          userPhoto: siteSettings?.vendor?.logo,
          text: message,
          fileName: fileName,
          // createdAt: new Date(),
          conversation: conversation,
          createdAt: new Date(),
        }
      )
        .then((ref) => {
          setMessage("");
          setFileName("");
          fetchMessages();
          // dispatch(getChatDetails(userInfo, conversation));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // const addEmoji = (emoji) => {
  //   const text = `${messageInput}${emoji.native}`;
  //   setShowEmojiPicker(false);
  //   setMessageInput(text);
  // };

  // const toggleEmojiPicker = () => {
  //   setShowEmojiPicker(!showEmojiPicker);
  // };

  // const selectedChat = null;

  const handleSearch = async (e) => {
    setSearchInput(e.target.value);
    let inputValue = e.target.value;

    if (e.target.value) {
      setFilteredConversations(
        conversations?.filter((item) =>
          item?.buyerName?.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    } else {
      setFilteredConversations(null);
    }
  };

  useEffect(() => {
    if (conversation && conversations) {
      let initialChat = conversations.filter(
        (item) => item.id === conversation
      );
      setActiveChat(initialChat[0]);
    }
  }, [conversation, conversations]);

  useEffect(() => {
    scrollToBottom();
  }, [conversation, activeChat]);

  return (
    <Fragment>
      <Container fluid={true}>
        <Row className="pt-4">
          <Col sm="12" className="call-chat-sidebar">
            <Card>
              <CardBody className="chat-body">
                <div className="chat-box">
                  <div className="chat-left-aside">
                    <div className="people-list custom-scrollbar">
                      <div className="search">
                        <Form className="theme-form">
                          <FormGroup className="form-group">
                            <Input
                              className="form-control"
                              type="text"
                              placeholder="search"
                              onChange={handleSearch}
                            />
                            <i className="fa fa-search"></i>
                          </FormGroup>
                        </Form>
                      </div>
                      {(filteredConversations
                        ? filteredConversations
                        : conversations
                      ).length > 0 ? (
                        <ul className="list">
                          {(filteredConversations
                            ? filteredConversations
                            : conversations
                          ).map((item, i) => {
                            return (
                              <li
                                className={`clearfix d-flex `}
                                key={i}
                                onClick={(e) => {
                                  handleSetConversation(e, item);
                                  setActiveChat(item);
                                }}
                              >
                                {item.buyerPhoto ? (
                                  <Avatar
                                    src={
                                      config.fileServer +
                                      config.thumbnailContainer +
                                      item?.buyerPhoto
                                    }
                                  />
                                ) : (
                                  <Avatar></Avatar>
                                )}

                                <div className="about flex-grow-1">
                                  <div className="font-size-16 font-weight-bold">
                                    {item.buyerName}
                                  </div>
                                  <div className="status d-flex w-100">
                                    <div className="flex-grow-1">
                                      {item.lastMessage &&
                                        item.lastMessage.substring(0, 20)}
                                      {item.lastMessage &&
                                      item.lastMessage.length > 15
                                        ? "..."
                                        : ""}
                                    </div>
                                    <div className="text-indigo">
                                      {timeConvert(
                                        `${item.updatedAt
                                          ?.toDate()
                                          .getHours()
                                          .toString()}:${item.updatedAt
                                          ?.toDate()
                                          .getMinutes()
                                          .toString()}`
                                      )}
                                      ,{" "}
                                      {`${item.updatedAt
                                        ?.toDate()
                                        .toString()
                                        .slice(4, 10)}`}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        <Media
                          className="img-fluid m-auto"
                          // src={errorImg}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card className="w-100">
              <CardBody className="p-0">
                {conversation ? (
                  <>
                    <div
                      style={{ borderBottom: "1px solid gray" }}
                      className="d-flex align-items-center p-3 "
                    >
                      {activeChat?.buyerPhoto ? (
                        <>
                          <Avatar
                            src={
                              config?.fileServer +
                              config?.thumbnailContainer +
                              activeChat?.buyerPhoto
                            }
                            className="rounded-circle "
                            alt=""
                          />
                          <div style={{ marginLeft: "10px" }} className="ml-2">
                            {activeChat && activeChat.buyerName}
                          </div>
                        </>
                      ) : (
                        <>
                          <Avatar
                            src={
                              config?.fileServer +
                              config?.thumbnailContainer +
                              activeChat?.buyerPhoto
                            }
                            className="rounded-circle"
                            alt=""
                          />
                          <div style={{ marginLeft: "10px" }} className=" pl-2">
                            {activeChat && activeChat.buyerName}
                          </div>
                        </>
                      )}
                    </div>
                    <div
                      style={{ height: "60vh", overflowY: "scroll" }}
                      className=""
                    >
                      {" "}
                      <ScrollToBottom className="chat-history chat-msg-box custom-scrollbar mb-6">
                        {loading ? (
                          <ComponentLoader height="80vh" />
                        ) : (
                          <ul className="pt-4 pr-2 w-100">
                            {activeChat ? (
                              chatDetails &&
                              chatDetails?.map((item, index) => {
                                return (
                                  <li
                                    key={index}
                                    style={
                                      item?.user === siteSettings?.vendor?._id
                                        ? {
                                            display: "flex",
                                            alignItems: "end",
                                            justifyContent: "end",
                                          }
                                        : {
                                            display: "flex",
                                            justifyItems: "start",
                                          }
                                    }
                                    className="  "
                                  >
                                    <div
                                      style={
                                        item?.user === siteSettings?.vendor?._id
                                          ? {
                                              width: "fit-content",
                                              backgroundColor: "#00A884",
                                              color: "white",
                                            }
                                          : {
                                              width: "fit-content",
                                            }
                                      }
                                      className="border m-2 p-2"
                                    >
                                      <div
                                        className={`message my-message ${
                                          item?.user !==
                                          siteSettings?.vendor?._id
                                            ? ""
                                            : "float-right"
                                        }`}
                                      >
                                        <Media
                                          src={
                                            item?.user !==
                                            siteSettings?.vendor?._id
                                              ? imageExists(
                                                  config?.fileServer +
                                                    config?.thumbnailContainer +
                                                    config?.fileServer +
                                                    config?.thumbnailContainer +
                                                    activeChat?.buyerPhoto
                                                )
                                                ? config?.fileServer +
                                                  config?.thumbnailContainer +
                                                  config?.fileServer +
                                                  config?.thumbnailContainer +
                                                  activeChat?.buyerPhoto
                                                : noImage
                                              : imageExists(
                                                  config?.fileServer +
                                                    config?.thumbnailContainer +
                                                    config?.fileServer +
                                                    config?.thumbnailContainer +
                                                    siteSettings?.vendor?.logo
                                                )
                                              ? config?.fileServer +
                                                config?.thumbnailContainer +
                                                config?.fileServer +
                                                config?.thumbnailContainer +
                                                siteSettings?.vendor?.logo
                                              : noImage
                                          }
                                          className={`rounded-circle ${
                                            item?.user !==
                                            siteSettings?.vendor?._id
                                              ? "float-left"
                                              : "float-right"
                                          } chat-user-img img-30`}
                                          alt=""
                                        />

                                        <div className="w-100">
                                          {item?.product && (
                                            <div className="d-flex w-100 bg-gray-1 p-1">
                                              <img
                                                onClick={() =>
                                                  setModalShow(true)
                                                }
                                                src={
                                                  imageExists(
                                                    config?.fileServer +
                                                      config?.thumbnailContainer +
                                                      item?.product?.photo?.url
                                                  )
                                                    ? config?.fileServer +
                                                      config?.thumbnailContainer +
                                                      item?.product?.photo?.url
                                                    : noImage
                                                }
                                                className="p-image-chat"
                                                alt=""
                                              />
                                              <div className="ml-1">
                                                <div className="name font-weight-bold">
                                                  {item?.product?.name}
                                                </div>
                                                <div className="status">
                                                  <b>৳</b>{" "}
                                                  {item.product.price.toLocaleString(
                                                    "en-IN",
                                                    {
                                                      maximumFractionDigits: 2,
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        <div className="w-100">
                                          {item?.fileName && (
                                            <div className="w-100 bg-gray-1 p-1 text-center">
                                              {item?.fileName
                                                .split(".")
                                                .pop()
                                                .toLowerCase() === "jpg" ||
                                              item?.fileName
                                                .split(".")
                                                .pop()
                                                .toLowerCase() === "jpeg" ||
                                              item?.fileName
                                                .split(".")
                                                .pop()
                                                .toLowerCase() === "png" ||
                                              item?.fileName
                                                .split(".")
                                                .pop()
                                                .toLowerCase() === "webp" ? (
                                                <a
                                                  rel="noopener noreferrer"
                                                  target="_blank"
                                                  href={item?.file}
                                                  className="inbox-uploaded-media"
                                                >
                                                  <img
                                                    onClick={() => {
                                                      setModalShow(true);
                                                      setImgModalFile(
                                                        item?.fileName
                                                      );
                                                    }}
                                                    src={
                                                      config?.fileServer +
                                                      config?.imageContainer +
                                                      item?.fileName
                                                    }
                                                    alt=""
                                                    className="inbox-uploaded-media"
                                                  />
                                                </a>
                                              ) : (
                                                <a
                                                  rel="noopener noreferrer"
                                                  target="_blank"
                                                  href={
                                                    config?.fileServer +
                                                    config?.imageContainer +
                                                    item?.file
                                                  }
                                                  className="inbox-uploaded-media text-indigo font-weight-bold"
                                                >
                                                  {item?.fileName}
                                                </a>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                        <div className="w-100 d block">
                                          {item?.text}
                                        </div>
                                      </div>

                                      <div className="message-data text-right">
                                        <span
                                          style={{
                                            fontSize: "12px",
                                            textAlign: "right",
                                          }}
                                          className="message-data-time "
                                        >
                                          {timeConvert(
                                            `${item?.createdAt
                                              ?.toDate()
                                              .getHours()
                                              .toString()}:${item?.createdAt
                                              ?.toDate()
                                              .getMinutes()
                                              .toString()}`
                                          )}
                                          ,{" "}
                                          {`${item?.createdAt
                                            ?.toDate()
                                            .toString()
                                            .slice(4, 10)}`}
                                        </span>
                                      </div>
                                    </div>
                                  </li>
                                );
                              })
                            ) : (
                              <div>
                                <Media
                                  className="img-fluid w-100"
                                  // src={start_conversion}
                                  alt="start conversion "
                                />
                              </div>
                            )}
                          </ul>
                        )}

                        <div ref={messagesEndRef} />
                      </ScrollToBottom>
                    </div>
                    <div className="">
                      {uploadFileLoading && (
                        <div>Please wait.File uploading...</div>
                      )}
                      {fileName && (
                        <>
                          <img
                            src={
                              config?.fileServer +
                              config?.imageContainer +
                              fileName
                            }
                            alt=""
                            className="inbox-uploaded-media"
                          />
                          <FontAwesomeIcon
                            onClick={() => setFileName("")}
                            icon={faClose}
                            size="2x"
                            className="mb-1"
                          />
                        </>
                      )}
                    </div>
                    <div style={{ borderTop: "1px solid gray" }}>
                      <Row className=" p-2 ">
                        <Col xl="12" className="d-flex w-100">
                          <div className="mx-1">
                            <label
                              className="btn btn-sm btn-text text-primary"
                              type="button"
                              // onClick={handleClick}
                            >
                              <FontAwesomeIcon
                                icon={faFileUpload}
                                size="2x"
                                className="mb-1"
                              />
                              <input
                                type="file"
                                ref={hiddenFileInput}
                                onChange={uploadFile}
                                style={{ display: "none" }}
                              />
                            </label>
                          </div>
                          <InputGroup className="text-box">
                            <Input
                              type="text"
                              className="form-control input-txt-bx"
                              placeholder="Type a message......"
                              value={message}
                              onChange={(event) =>
                                setMessage(event.target.value)
                              }
                              onKeyPress={(event) =>
                                event.key === "Enter"
                                  ? sendMessage(event)
                                  : null
                              }
                            />
                            <Button
                              color="primary"
                              onClick={(event) => sendMessage(event)}
                            >
                              SEND
                            </Button>
                          </InputGroup>
                        </Col>
                      </Row>
                    </div>
                  </>
                ) : (
                  <div className="vh-80 d-flex flex-column justify-content-center">
                    <div className="search-not-found text-center">
                      <div>
                        <img
                          className="img-fluid second-search"
                          src={errorImg}
                          alt=""
                        />
                        <p>Inbox is Empty</p>
                      </div>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CommonModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          fileName={imgModalFile}
        />
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    user: state.mainReducers.main.user,
    conversations: state.mainReducers.main.conversations,
    company: state.mainReducers.main.company,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(InboxPage);
