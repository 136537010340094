import {
  ChangeUserStatus,
  GetUserProfile,
  UpdateuserProfile,
} from "../../../../service/service";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import operations from "../../../../redux/Main/operations";
import noImage from "../../../../assets/images/notFound.png";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { useParams } from "react-router-dom";

const UpdateUserPage = (props) => {
  let { id } = useParams();
  const { match } = props;
  const [user, setUser] = useState(null);
  console.log("🚀 ~ file: UpdateUserPage.jsx:32 ~ UpdateUserPage ~ user", user);
  // const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeStatus, setActiveStatus] = useState("");
  console.log(
    "🚀 ~ file: UpdateUserPage.jsx:36 ~ UpdateUserPage ~ activeStatus",
    activeStatus
  );
  const [buttonLoading, setButtonLoading] = useState(false);
  const [stockPermission, setstockPermission] = useState("");
  console.log(
    "🚀 ~ file: UpdateUserPage.jsx:42 ~ UpdateUserPage ~ stockPermission",
    stockPermission
  );

  const fetchUserProfile = async () => {
    const response = await GetUserProfile(id);
    console.log("🚀 ~ file:dataaa", response.data.user);
    if (response && response.isSuccess) {
      setUser(response.data.user);
      setActiveStatus(response?.data?.user?.activeStatus);
      setstockPermission(response?.data?.user?.stockPermission);
      // if (response.data.user.type === "vendor") {
      //   const comRes = await GetCompany(response.data.user._id);
      //   if (comRes && comRes.isSuccess) {
      //     setCompany(comRes.data.company);
      //   }
      // }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleSubmit = async () => {
    if (user.activeStatus !== activeStatus) {
      setButtonLoading(true);
      const response = await ChangeUserStatus(user._id, {
        activeStatus: activeStatus,
        stockPermission: stockPermission,
      });
      if (response && response.isSuccess) {
        await fetchUserProfile();
        setButtonLoading(false);
        toast.success("User Status Updated !", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }
  };

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <div>
      <Fragment>
        <Container fluid={true}>
          <div className="page-header">
            <Row>
              <Col lg="9" sm="3">
                <Breadcrumbs title="Update User" parent="Users" />
              </Col>
              <Col lg="3" sm="3"></Col>
            </Row>
          </div>
          <div className="contentbar">
            <div className="row">
              <div className="col-lg-8 col-xl-9">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Personal Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-md">
                        <tbody>
                          <tr>
                            <td className="border">Name</td>
                            <td className="border">
                              {user.userInfo.firstName || user.userInfo.lastName
                                ? `${user.userInfo.firstName} ${user.userInfo.firstName}`
                                : "---"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Email</td>
                            <td className="border">
                              {user.email ? user.email : "---"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Phone</td>
                            <td className="border">
                              {user.userInfo.phone
                                ? user.userInfo.phone
                                : "---"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">User Type</td>
                            <td className="border text-capitalize">
                              {user.type ? user.type : "---"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Status</td>
                            <td className="border">
                              {user.activeStatus ? "Active" : "Disabled"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {/* {user.type === "vendor" && (
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Company Information
                      </h6>
                    </div>
                    <div className="card-body">
                      {company ? (
                        <div>
                          <div className="position-relative mb-5">
                            <img
                              className="img-fluid rounded"
                              src={
                                config.fileServer +
                                config.imageContainer +
                                company.coverPhoto
                                  ? config.fileServer +
                                    config.imageContainer +
                                    company.coverPhoto
                                  : noImage
                              }
                              alt=""
                            />
                          </div>
                          <div className="table-responsive">
                            <table className="table table-striped table-md">
                              <tbody>
                                <tr>
                                  <td className="border">Logo</td>
                                  <td className="border">
                                    <img
                                      width="40px"
                                      src={
                                        config.fileServer +
                                        config.thumbnailContainer +
                                        company.logo
                                          ? config.fileServer +
                                            config.thumbnailContainer +
                                            company.logo
                                          : noImage
                                      }
                                      className="img-fluid rounded-circle border"
                                      alt=""
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border">Name</td>
                                  <td className="border">{company.name}</td>
                                </tr>
                                <tr>
                                  <td className="border">Company Types</td>
                                  <td className="border">
                                    {company.companyTypes.map((c) => c + ", ")}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border">Product TYpes</td>
                                  <td className="border">
                                    {company.productTypes.map((p) => p + ", ")}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="border">Phone</td>
                                  <td className="border">{company.phone}</td>
                                </tr>
                                <tr>
                                  <td className="border">Email</td>
                                  <td className="border">{company.email}</td>
                                </tr>
                                <tr>
                                  <td className="border">Status</td>
                                  <td className="border">
                                    {company.activeStatus
                                      ? "Active"
                                      : "Disabled"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <div className="text-center">
                          <h3>This Seller Does not have company!</h3>
                        </div>
                      )}
                    </div>
                  </div>
                )} */}
              </div>
              <div className="col-lg-4 col-xl-3">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary text-center">
                      Profile Photo
                    </h6>
                  </div>
                  <div className="card-body d-flex flex-row align-items-center justify-content-center">
                    <img
                      width="60px"
                      src={
                        config.fileServer +
                        config.thumbnailContainer +
                        user.userInfo.photoURL
                          ? config.fileServer +
                            config.thumbnailContainer +
                            user.userInfo.photoURL
                          : noImage
                      }
                      alt=""
                      className="img-fluid rounded mb-1"
                    />
                  </div>
                </div>

                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Change Active Status
                    </h6>
                  </div>
                  <div className="card-body">
                    <select
                      className="form-select form-control-sm form-control mb-2"
                      onChange={(e) => setActiveStatus(e.target.value)}
                      value={activeStatus}
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Disable</option>
                    </select>
                  </div>
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary text-capitalize">
                      Stock Permission
                    </h6>
                  </div>
                  <div className="card-body">
                    <select
                      className="form-select form-control-sm form-control mb-2"
                      onChange={(e) => setstockPermission(e.target.value)}
                      value={stockPermission}
                    >
                      <option value={true}>Active</option>
                      <option value={false}>Disable</option>
                    </select>
                  </div>
                  <div className="card-footer text-right">
                    {buttonLoading ? (
                      <Button
                        color="primary btn-block"
                        disabled={buttonLoading}
                      >
                        SAVING...
                      </Button>
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        disabled={user.activeStatus === activeStatus}
                        color="primary btn-block"
                      >
                        <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fragment>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    products: state.mainReducers.main.products,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateUserPage);
