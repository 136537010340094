import {
  UploadPhoto,
  DeletePhoto,
  UpdateCategory,
} from "../../../../../service/service";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import React, { useEffect, useState } from "react";
import config from "../../../../../service/api/config";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

export default function UpdateCategor(props) {
  const {
    open,
    categories,
    handleClose,
    setCategories,
    categoryToUpdate,
    filteredCategories,
    setFilteredCategories,
  } = props;

  const [photo, setPhoto] = useState(null);
  const [isRoot, setIsRoot] = useState(false);
  const [isLeaf, setIsLeaf] = useState(false);
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [parentCategory, setParentCategory] = useState("");

  useEffect(() => {
    if (categoryToUpdate._id) {
      setPhoto(categoryToUpdate.photo);
      setIsLeaf(categoryToUpdate.isLeaf);
      setIsRoot(categoryToUpdate.isRoot);
      setParentCategory(
        categoryToUpdate.parentCategory
          ? categoryToUpdate.parentCategory._id
          : null
      );
    }
  }, [categoryToUpdate]);

  const handleUploadPhoto = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setPhoto(response.data.fileName);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handlePhotoDelete = async (id) => {
    setPhoto(null);
    setDeletedPhotos([...deletedPhotos, id]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.name.value) {
      if (!isRoot && parentCategory === "no value") {
        toast.error("Category must be root or parent category", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        setButtonLoading(true);
        const data = {
          name:
            categoryToUpdate.name !== e.target.name.value
              ? e.target.name.value
              : undefined,
          photo: categoryToUpdate.photo !== photo ? photo : undefined,
          isLeaf: categoryToUpdate.isLeaf !== isLeaf ? isLeaf : undefined,
          isRoot: categoryToUpdate.isRoot !== isRoot ? isRoot : undefined,
          parentCategory: isRoot ? undefined : parentCategory,
        };

        const response = await UpdateCategory(categoryToUpdate._id, data);

        if (response && response.isSuccess) {
          deletedPhotos.map((deletedPhoto) =>
            DeletePhoto({
              fileName: deletedPhoto,
              container: "ivcommerceoriginalcontainer",
            })
          );
          setCategories([
            response.data.category,
            ...categories.filter(
              (cat) => cat._id !== response.data.category._id
            ),
          ]);
          if (filteredCategories && filteredCategories.length > 0) {
            setFilteredCategories([
              response.data.category,
              ...filteredCategories.filter(
                (cat) => cat._id !== response.data.category._id
              ),
            ]);
          }
          handleClose();
          toast.success("Category Updated!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
        setButtonLoading(false);
      }
    } else {
      toast.error("Name & Unit fields can't be empty !!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  return (
    <Modal isOpen={open} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>Update Category</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            <label
              htmlFor="productTitle"
              className="col-sm-12 font-weight-bold small"
            >
              Name
            </label>
            <div className="col-sm-12 mb-2">
              <input
                type="text"
                className="form-control-sm form-control"
                name="name"
                placeholder="Name"
                defaultValue={categoryToUpdate && categoryToUpdate.name}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-6 mb-2">
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isRoot"
                    checked={isRoot}
                    onChange={() => {
                      setIsRoot(!isRoot);
                      setIsLeaf(false);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="isRoot">
                    Root Category
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-2">
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isLeaf"
                    checked={isLeaf}
                    onChange={() => {
                      setIsLeaf(!isLeaf);
                      setIsRoot(false);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="isLeaf">
                    Leaf Category
                  </label>
                </div>
              </div>
            </div>
          </div>
          {!isRoot && (
            <div className="form-group row">
              <label
                htmlFor="productTitle"
                className="col-sm-12 font-weight-bold small"
              >
                Parent Category
              </label>
              <div className="col-sm-12 mb-2">
                <select
                  className="form-select form-control-sm form-control mb-2"
                  aria-label="Default select example"
                  onChange={(e) => setParentCategory(e.target.value)}
                  value={parentCategory}
                >
                  <option value="no value">Open this select menu</option>
                  {categories
                    .filter(
                      (item) =>
                        !item.isLeaf && item._id !== categoryToUpdate._id
                    )
                    .map((cat) => (
                      <option value={cat._id} key={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          <div className="form-group row">
            <label
              htmlFor="productTitle"
              className="col-sm-12 font-weight-bold small"
            >
              Image
            </label>
            <div className="col-sm-12">
              {photo ? (
                <div className="w-100 text-center">
                  <div className="w-100">
                    <img
                      src={config.fileServer + config.imageContainer + photo}
                      alt={categoryToUpdate.name}
                      className="mb-2"
                      width="50%"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-icon-split btn-sm btn-danger"
                    onClick={() => handlePhotoDelete(photo)}
                  >
                    Remove Photo
                  </button>
                </div>
              ) : (
                <Dropzone
                  onChangeStatus={handleUploadPhoto}
                  accept="image/*"
                  PreviewComponent={null}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                />
              )}
            </div>
          </div>
          {buttonLoading ? (
            <Button color="primary btn-block" disabled={buttonLoading}>
              SAVING...
            </Button>
          ) : (
            <Button type="submit" color="primary btn-block">
              <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
            </Button>
          )}
        </form>
      </ModalBody>
    </Modal>
  );
}
