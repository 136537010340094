import { combineReducers } from "redux";
import types from "./types";

let dataState = {
  user: { loading: true },
  siteSettings: {},
  company: {},
  products: [],
  countries: [],
  categories: [],
  subCategories: [],
  conversations: [],
  subscription: {},
  companySubCs: [],
  vendors: [],
  unreads: 0,
  vendorPermissions: [],
  sliders: [],
};

const main = (state = dataState, action) => {
  switch (action.type) {
    case types.USER: {
      return {
        ...state,
        user: action.payload.user,
        isAuthenticated: Object.keys(action.payload.user).length !== 0,
      };
    }
    case types.COUNTRIES: {
      return {
        ...state,
        countries: action.payload.countries,
      };
    }
    case types.COMPANY: {
      return {
        ...state,
        company: action.payload.company,
      };
    }
    case types.SITE_SETTINGS: {
      return {
        ...state,
        siteSettings: action.payload.siteSettings,
      };
    }
    case types.VENDORS: {
      return {
        ...state,
        vendors: action.payload.vendors,
      };
    }
    case types.UNREADS: {
      return {
        ...state,
        unreads: action.payload.unreads,
      };
    }
    case types.SUBSCRIPTION: {
      return {
        ...state,
        subscription: action.payload.subscription,
      };
    }
    case types.PRODUCTS: {
      return {
        ...state,
        products: action.payload.products,
      };
    }
    case types.COMPANY_SUB_CS: {
      return {
        ...state,
        companySubCs: action.payload.companySubCs,
      };
    }
    case types.CATEGORIES: {
      return {
        ...state,
        categories: action.payload.categories,
      };
    }
    case types.SUB_CATEGORIES: {
      return {
        ...state,
        subCategories: action.payload.subCategories,
      };
    }
    case types.CONVERSATIONS: {
      return {
        ...state,
        conversations: action.payload.conversations,
      };
    }
    case types.VENDOR_PERMISSIONS: {
      return {
        ...state,
        vendorPermissions: action.payload.vendorPermissions,
      };
    }
    case types.SLIDERS: {
      return {
        ...state,
        sliders: action.payload.sliders,
      };
    }
    default:
      return state;
  }
};

const reducers = combineReducers({
  main,
});

export default reducers;
