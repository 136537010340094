import actions from "./actions";

const setUser = (user) => {
  return (dispatch) => {
    return dispatch(actions.setUser(user));
  };
};

const setCompany = (company) => {
  return (dispatch) => {
    return dispatch(actions.setCompany(company));
  };
};

const setCountries = (countries) => {
  return (dispatch) => {
    return dispatch(actions.setCountries(countries));
  };
};

const setSiteSettings = (siteSettings) => {
  return (dispatch) => {
    return dispatch(actions.setSiteSettings(siteSettings));
  };
};

const setVendors = (vendors) => {
  return (dispatch) => {
    return dispatch(actions.setVendors(vendors));
  };
};

const setUnreads = (unreads) => {
  return (dispatch) => {
    return dispatch(actions.setUnreads(unreads));
  };
};

const setSubscription = (subscription) => {
  return (dispatch) => {
    return dispatch(actions.setSubscription(subscription));
  };
};

const setConversations = (conversations) => {
  return (dispatch) => {
    return dispatch(actions.setConversations(conversations));
  };
};

const setProducts = (products) => {
  return (dispatch) => {
    return dispatch(actions.setProducts(products));
  };
};

const setCompanySubCs = (companySubCs) => {
  return (dispatch) => {
    return dispatch(actions.setCompanySubCs(companySubCs));
  };
};

const setCategories = (categories) => {
  return (dispatch) => {
    return dispatch(actions.setCategories(categories));
  };
};
const setSubCategories = (subCategories) => {
  return (dispatch) => {
    return dispatch(actions.setSubCategories(subCategories));
  };
};
const setVendorPermissions = (vendorPermissions) => {
  return (dispatch) => {
    return dispatch(actions.setVendorPermissions(vendorPermissions));
  };
};
const setSliders = (sliders) => {
  return (dispatch) => {
    return dispatch(actions.setSliders(sliders));
  };
};

export default {
  setUser,
  setCompany,
  setProducts,
  setCountries,
  setCategories,
  setSubCategories,
  setConversations,
  setSubscription,
  setCompanySubCs,
  setUnreads,
  setVendors,
  setSiteSettings,
  setVendorPermissions,
  setSliders,
};
