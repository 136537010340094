import {
  Col,
  Row,
  Card,
  Table,
  Button,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";
import { GetAllAttribute, DeleteAttribute } from "../../../../service/service";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Pagination } from "@material-ui/lab";
import React, { Fragment, useEffect, useState } from "react";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import UpdateAttribute from "./Components/UpdateAttribute";
import CreateAttribute from "./Components/CreateAttribute";
import ConfirmDelete from "../../Components/ConfirmDelete";
import operations from "../../../../redux/Main/operations";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { DeleteCategory } from "../../../../service/service";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import errorImg from "../../../../assets/images/search-not-found.png";
import { getComparator, stableSort } from "../../../../service/sort";

const AttributesPage = (props) => {
  const { categories, setCategories, company, rootCategories } = props;

  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("desc");

  const [orderBy, setOrderBy] = useState("createdAt");
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [attributeToUpdate, setAttributeToUpdate] = useState({});
  const [filteredVariants, setFilteredVariants] = useState(null);
  const [variants, setVariants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [parentCat, setParentCat] = useState([]);

  const handleClickOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleClose = () => {
    setOpenCreate(false);
    setOpenUpdate(false);
    setOpenDelete(false);
  };

  const confirmDelete = async (id) => {
    const response = await DeleteAttribute(id);
    if (response && response.isSuccess) {
      setVariants(
        variants.filter(
          (attribute) => attribute?._id !== response.data.variant?._id
        )
      );
      handleClose();
    }
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this attribute!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Attribute deleted.", "success");
        });
      } else {
        SweetAlert.fire("Attribute is safe!");
      }
    });
  };

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "name": {
        setOrderBy(property);
        setOrder("asc");
        break;
      }
      default:
        break;
    }
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleSearch = async (searchKey) => {
    if (searchKey.length > 0) {
      setFilteredVariants(
        variants.filter((attribute) =>
          attribute.name.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilteredVariants(null);
    }
  };

  const handleFilterVariants = async (value) => {
    if (value === "root") {
      setFilteredVariants(variants?.filter((attribute) => attribute.isRoot));
    } else {
      setFilteredVariants(null);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    const response = await GetAllAttribute(order);
    setLoading(false);

    if (response && response.isSuccess) {
      setVariants(response.data.variants);
    }
  };

  console.log("attributeToUpdate", attributeToUpdate);

  if (loading) {
    return "loading";
  }

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="Attributes" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12">
              <Button onClick={handleClickOpenCreate} color="primary btn-block">
                <FontAwesomeIcon className="mr-1" icon={faPlus} /> Create
                Attribute
              </Button>
              <CreateAttribute
                open={openCreate}
                company={company}
                variants={variants}
                handleClose={handleClose}
                setVariants={setVariants}
                categories={categories}
                setCategories={setCategories}
                setSelectedCategory={setSelectedCategory}
                selectedCategory={selectedCategory}
              />
            </Col>
          </Row>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6" sm="12" className="text-right">
                    <div className="form-inline search-form">
                      <div className="form-group w-100">
                        <input
                          type="search"
                          id="search"
                          className="form-control-plaintext"
                          placeholder="Search.."
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="3" sm="12" className="products-total">
                    <div className="select2-drpdwn-product select-options d-inline-block w-100">
                      <div className="form-group w-100">
                        <select
                          name="createdAt"
                          className="form-control btn-square"
                          onChange={(e) => handleFilterVariants(e.target.value)}
                        >
                          <option value="all">All Attributes</option>
                          <option value="root">Root Attributes</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col md="3" sm="12" className="products-total">
                    <div className="select2-drpdwn-product select-options d-inline-block w-100">
                      <div className="form-group w-100">
                        <select
                          name="createdAt"
                          className="form-control btn-square"
                          onChange={(e) => handleRequestSort(e, e.target.value)}
                        >
                          <option value="createdAt">Latest</option>
                          <option value="name">Name</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {(filteredVariants ? filteredVariants : variants)?.length >
                0 ? (
                  <div className="table-responsive">
                    <Table hover size="small">
                      <thead>
                        <tr>
                          <th
                            style={{ width: "65px" }}
                            className="text-center border"
                          >
                            #
                          </th>
                          <th className="border">Name</th>
                          <th className="border">Type</th>
                          <th className="border">Attribute Type</th>
                          <th className="text-right border">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stableSort(
                          filteredVariants ? filteredVariants : variants,
                          getComparator(order, orderBy)
                        )
                          .slice(page * 10 - 10, page * 10)
                          .map((attribute, index) => (
                            <tr key={index}>
                              {console.log("hiii", attribute)}
                              <td
                                className="text-center border"
                                style={{ width: "65px" }}
                              >
                                {index + 1}
                              </td>

                              <td className="border">{attribute.name}</td>
                              <td className="border">
                                {attribute.isRoot ? "Attribute" : ""}{" "}
                                {attribute.isLeaf ? "Value" : ""}
                              </td>

                              <td className="border">
                                {!attribute.isRoot
                                  ? attribute.parentVariant &&
                                    attribute.parentVariant.name
                                  : ""}
                              </td>

                              <td className="text-right border">
                                <div className="button-list">
                                  <button
                                    onClick={() => {
                                      setAttributeToUpdate(attribute);
                                      setOpenUpdate(true);
                                    }}
                                    className="btn btn-text p-0"
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        fontSize: 16,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                      }}
                                    ></i>
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleDelete(attribute._id);
                                    }}
                                    className="btn btn-text p-0"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        width: 35,
                                        fontSize: 16,
                                        padding: 11,
                                        color: "#e4566e",
                                      }}
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        {variants.length > 10 && (
                          <tr>
                            <td colSpan="6" align="right">
                              <Pagination
                                count={parseInt(
                                  filteredVariants
                                    ? filteredVariants.length / 10 + 1
                                    : variants.length / 10 + 1
                                )}
                                page={page}
                                onChange={handlePageChange}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="search-not-found text-center">
                    <div>
                      <img
                        className="img-fluid second-search"
                        src={errorImg}
                        alt="Error Logo"
                      />
                      <p>Sorry, We didn't find any attribute</p>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <UpdateAttribute
        open={openUpdate}
        handleClose={handleClose}
        attributeToUpdate={attributeToUpdate}
        filteredVariants={filteredVariants}
        setFilteredVariants={setFilteredVariants}
        variants={variants}
        setVariants={setVariants}
        categories={categories}
        setCategories={setCategories}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        parentCat={parentCat}
        setParentCat={setParentCat}
        setAttributeToUpdate={setAttributeToUpdate}
        fetchData={fetchData}
      />
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    categories: state.mainReducers.main.categories,
    rootCategories: state.mainReducers.main.categories,
    company: state.mainReducers.main.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesPage);
