import {
  UpdateSiteSettings,
  GetSiteReturnRefundPolicy,
} from "../../../../service/service";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import config from "../../../../service/api/config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Button, Col, Container, Row } from "reactstrap";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const ReturnPolicyPage = ({ siteSettings }) => {
  const [error, setError] = useState({});
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const fetchData = async () => {
    const response = await GetSiteReturnRefundPolicy(siteSettings._id);
    if (response && response.isSuccess) {
      setContent(
        response.data.returnAndRefundPolicy.returnAndRefundPolicy
          ? response.data.returnAndRefundPolicy.returnAndRefundPolicy
          : content
      );
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!content) {
      setError({
        content: !content ? true : false,
      });
    } else {
      setButtonLoading(true);
      const response = await UpdateSiteSettings(siteSettings._id, {
        returnAndRefundPolicy: content,
      });
      if (response && response.isSuccess) {
        fetchData();
        toast.success("Site Return And Refund Policy Updated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      setButtonLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <ComponentLoader height="80vh" />
      ) : (
        <Container fluid={true}>
          <div className="page-header">
            <Row>
              <Col lg="9" sm="3">
                <Breadcrumbs
                  title="Return And Refund Policy"
                  parent="Dashboard"
                />
              </Col>
              <Col lg="3" sm="3">
                {buttonLoading ? (
                  <Button color="primary btn-block" disabled={buttonLoading}>
                    SAVING...
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    onClick={handleSubmit}
                    color="primary btn-block"
                  >
                    <FontAwesomeIcon className="mr-1" icon={faSave} />
                    Save
                  </Button>
                )}
              </Col>
            </Row>
            <div className="card shadow mb-4 mt-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Return And Refund Policy
                </h6>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-sm-12">
                    <div className="border h-100">
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={(editor) => {}}
                        data={content}
                        config={{
                          ckfinder: {
                            uploadUrl: config.serverURL + "file/editor-file",
                          },
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(editor.getData());
                          console.log({ event, editor, data });
                        }}
                        onFilesChange={(e) => console.log(e)}
                      />
                    </div>
                    {error.content && (
                      <span className="font-weight-bold text-danger mt-2">
                        Return And Refund Policy can not be empty !!
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

export default connect(mapStateToProps)(ReturnPolicyPage);
