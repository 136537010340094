import { getApi, postApi, postFormData, putApi } from "./api/index";

//Auth
export const Login = (data) => postApi("auth/login", data);
export const LoginVendor = (data) => postApi("vendor-auth/vendor-login", data);
export const LoginAdmin = (data) => postApi("admin-auth/admin-login", data);
export const ReAuth = (data) => postApi("admin-auth/admin-reauth", data);
export const GetUserProfile = (userID) => getApi(`user/profile/${userID}`);
export const UpdateuserProfile = (data) => putApi("user/profile", data);
export const UploadUserProfilePhoto = (file) =>
  postFormData("user/profilePhoto", file);

//users
export const GetAllUsers = (data) => postApi("user", data);
export const GetUsersCount = (data) => postApi("user/users-count", data);
export const DeleteUser = (id) => postApi(`user/delete/${id}`);
export const ChangeUserStatus = (id, data) =>
  putApi(`user/active-status/${id}`, data);
export const UpgradeUser = (data) => putApi("user/upgrade", data);
export const UpdatePassword = (data) => putApi("auth/update-password", data);
export const ForgotPassword = (data) => postApi(`auth/forgot-password`, data);
export const SubmitOtp = (data) => postApi(`auth/submit-otp`, data);
export const SubmitNewPassword = (data) => postApi(`auth/reset-password`, data);

//Company
export const CreateCompany = (data) => postApi("vendor", data);
export const GetCompany = (id) => getApi(`vendor/my-company/${id}`);
export const UpdateCompany = (slug, data) => putApi(`vendor/${slug}`, data);
export const UploadCompanyPhoto = (file) =>
  postFormData(`vendor/company-photo`, file);

//Manage Company
export const GetAllVendors = (data) => postApi("vendor/vendors", data);
export const GetCompanyDetails = (slug) => getApi(`vendor/${slug}`);
export const ChangeCompanyStatus = (id, data) =>
  putApi(`vendor/active-status/${id}`, data);
export const DeleteCompany = (id) => postApi(`vendor/delete/${id}`);

//Products
export const GetAllProducts = (data) => postApi(`product/admin-products`, data);
export const GetProductsCount = (data) =>
  postApi(`product/products-count`, data);
export const AddProduct = (data) => postApi(`product/add-product`, data);
export const UploadProductPhoto = (file) =>
  postFormData(`product/product-photo`, file);
export const UploadProductDescriptionFile = (file) =>
  postFormData(`product/description-file`, file);
export const DeleteProduct = (id) => postApi(`product/delete/${id}`);
export const GetSingleProduct = (slug) =>
  getApi(`product/admin-product/${slug}`);
export const UpdateProduct = (id, data) => putApi(`product/update/${id}`, data);
export const getProductById = (id) => getApi(`product/get-product-by-id/${id}`);

//Category
export const GetAllCategory = (data) => postApi("admin-category", data);

export const CreateCategory = (data) =>
  postApi(`admin-category/create-category`, data);
export const CreateSubCategory = (data) =>
  postApi(`category/create-subcategory`, data);
export const DeleteCategory = (id) =>
  postApi(`admin-category/delete-category/${id}`);
export const DeleteSubCategory = (id) =>
  postApi(`category/delete-subcategory/${id}`);
export const UpdateCategory = (id, data) =>
  putApi(`admin-category/update-category/${id}`, data);
export const UpdateSubCategory = (slug, data) =>
  putApi(`category/update-subcategory/${slug}`, data);
export const UploadCategoryPhoto = (file) =>
  postFormData(`category/category-photo`, file);
export const UploadSubCategoryPhoto = (file) =>
  postFormData(`category/subcategory-photo`, file);

//orders
export const GetSellerOrders = (id, data) =>
  postApi(`order/seller/${id}`, data);
export const GetOrderDetails = (id) => getApi(`order/${id}`);
export const GetAllOrders = (data) => postApi(`order/all`, data);
export const DeleteOrder = (id) => postApi(`order/delete/${id}`);
export const GetOrderDetailsbyID = (id) => getApi(`order/by-id/${id}`);
export const GetOrdersCount = (data) => postApi(`order/orders-count`, data);
export const UpdateOrder = (id, data) => putApi(`order/update/${id}`, data);

//subscriptions
export const GetAllSubscriptionPackages = () => getApi("subscription/packages");
export const AddNewPackage = (data) =>
  postApi("subscription/add-package", data);
export const PackageDetails = (id) => getApi(`subscription/package/${id}`);
export const UpdatePackage = (id, data) =>
  putApi(`subscription/package/${id}`, data);
export const BuyPackage = (data) => postApi(`subscription/subscribe`, data);
export const DeletePackage = (id) =>
  postApi(`subscription/delete-package/${id}`);
export const GetSubscriptions = () => getApi(`subscription/subscriptions`);
export const UpdateSubscriptionStatus = (id, data) =>
  putApi(`subscription/update-subscription-status/${id}`, data);
export const GetCompanySubscription = (id) =>
  getApi(`subscription/vendor-subscription/${id}`);
export const GetSubscriptionDetails = (id) =>
  getApi(`subscription/subscription/${id}`);
export const ApproveSubscription = (id) =>
  putApi(`subscription/approve-subscription/${id}`);
export const DeleteSubscription = (id) =>
  postApi(`subscription/delete-subscription/${id}`);

//requirements

export const GetAllRequirements = (data) => postApi(`requirement`, data);
export const GetRequirementDetails = (id) => getApi(`requirement/${id}`);
export const ChangeRequirementStatus = (id, data) =>
  putApi(`requirement/update-status/${id}`, data);
export const DeleteRequirement = (id) => postApi(`requirement/delete/${id}`);
export const GetRequirementsBySubCs = (data) =>
  postApi(`requirement/by-subcategories`, data);
export const RespondRequirement = (data, id) =>
  putApi(`requirement/response/${id}`, data);
export const RespondedRequirements = (data) =>
  postApi(`requirement/seller-responded`, data);

//file Upload
export const DeletePhoto = (data) => postApi(`file/delete`, data);
export const UploadFile = (file) => postFormData(`file/upload-file`, file);
export const UploadPhoto = (file) => postFormData(`file/upload-photo`, file);

//Site Settings
export const GetSitePrivacyPolicy = (id) =>
  getApi(`site-settings/privacy-policy/${id}`);
export const GetSiteTermsAndConditions = (id) =>
  getApi(`site-settings/terms-and-conditions/${id}`);
export const GetSiteReturnRefundPolicy = (id) =>
  getApi(`site-settings/return-and-refund-policy/${id}`);
export const GetSiteAbout = (id) => getApi(`site-settings/about/${id}`);
export const GetSiteByShop = (id) => getApi(`site-settings/shop/${id}`);
export const UpdateSiteSettings = (id, data) =>
  putApi(`site-settings/update/${id}`, data);

//Stock Request
export const GetAllStocksRequests = (data) => postApi(`stock-request`, data);

//Site Settings
export const GetSiteSettings = (siteURL) => getApi(`site-settings/admin`);

// Events
export const getAllEvents = (data) => postApi("event/all-events", data);
export const deleteEvent = (id) => postApi(`event/delete-event/${id}`);
export const createEvent = (data) => postApi("event/create-event", data);
export const getEventsVendor = (data) => postApi("event/vendor-events", data);
export const getEventDetails = (slug) => getApi(`event/event-details/${slug}`);
export const addEventProduct = (data) =>
  postApi("event/add-event-product", data);
export const getEventProducts = (data) =>
  postApi("event/get-event-products", data);
export const updateEvent = (id, data) =>
  putApi(`event/update-event/${id}`, data);
export const updateEventProduct = (id, data) =>
  putApi(`event/update-event-product/${id}`, data);
export const deleteEventProduct = (id) =>
  postApi(`event/delete-event-product/${id}`);
export const GetEventProductDetails = (id) =>
  getApi(`event/get-event-product-details/${id}`);

//vendor permissions
export const GetAllPermissions = () => getApi(`vendor-permission`);
export const CreateVendorPermission = (data) =>
  postApi(`vendor-permission/create`, data);
export const UpdateVendorPermission = (id, data) =>
  putApi(`vendor-permission/update/${id}`, data);
export const DeleteVendorPermission = (id) =>
  postApi(`vendor-permission/delete/${id}`);

// Certifications
export const getAllCertifications = () => getApi(`certification/all`);
export const addCertification = (data) => postApi(`certification/create`, data);
export const getCertificationDetails = (slug) =>
  getApi(`certification/details/${slug}`);
export const updateCertification = (id, data) =>
  putApi(`certification/update/${id}`, data);
export const deleteCertification = (id) =>
  postApi(`certification/delete/${id}`);

// Vendor request
export const AddNewVendorRequest = (data) =>
  postApi("vendor-request/new-request", data);
export const GetRequestDetails = (id) =>
  getApi(`vendor-request/get-request/${id}`);
export const RejectVendorRequest = (id) =>
  putApi(`vendor-request/reject-request/${id}`);
export const ApproveVendorRequest = (id) =>
  putApi(`vendor-request/approve-request/${id}`);
export const VendorRequest = (data) =>
  postApi("vendor-request/get-request-list", data);

// Location
export const UpdateLocation = (id, data) =>
  putApi(`location/update/${id}`, data);
export const CreateAddress = (data) => postApi("address/", data);
export const AddLocation = (data) => postApi(`location/add`, data);
export const GetAllLocation = (data) => postApi(`location/all`, data);
export const DeleteLocation = (id) => postApi(`location/delete/${id}`);
export const AddManyLocation = (data) => postApi(`location/add-many`, data);
export const UpdateAddress = (id, data) => putApi(`address/update/${id}`, data);

// Variant Attributes
export const GetAllAttribute = (data) => getApi(`attribute?sortBy=${data}`);
// export const GetAllAttribute = () => getApi("attribute");
export const CreateAttribute = (data) =>
  postApi(`attribute/create-attribute`, data);
export const DeleteAttribute = (id) =>
  postApi(`attribute/delete-attribute/${id}`);
export const UpdateAttribute = (id, data) =>
  putApi(`attribute/update-attribute/${id}`, data);

// Message
export const GetAllMessage = () => getApi("contact-us/all-messages");
export const GetMessageDetails = (id) => getApi(`contact-us/message/${id}`);

//Brand
export const CreateBrandApi = (data) => postApi("brand/create", data);
export const GetAllBrandsApi = () => getApi("brand/all");
export const UpdateBrandApi = (id, data) => putApi(`brand/update/${id}`, data);
export const DeleteBrandApi = (id) => postApi(`brand/delete/${id}`);

//Blog
export const AddBlog = (data) => postApi("blog/create", data);
export const GetAllBlogs = () => getApi("blog/all");
export const UpdateBlog = (id, data) => putApi(`blog/update/${id}`, data);
export const DeleteBlog = (id) => postApi(`blog/delete/${id}`);
export const GetBlogDetails = (slug) => getApi(`blog/${slug}`);

// Service
export const AddService = (data) => postApi("services/create", data);
export const GetAllServices = () => getApi("services/all");
export const DeleteService = (id) => postApi(`services/delete/${id}`);
export const UpdateServices = (id, data) =>
  putApi(`services/update/${id}`, data);
export const GetDetailsService = (id) => getApi(`services/details/${id}`);

// Promotion
export const CreateNewPromotion = (data) => postApi("promotion/create", data);
export const GetAllPromotions = () => getApi("promotion/all");
export const DeletePromotion = (id) => postApi(`promotion/delete/${id}`);
export const UpdatePromotion = (id, data) =>
  putApi(`promotion/update/${id}`, data);

// Coupon

export const CreateNewCoupon = (data) => postApi("coupon/create", data);
export const GetAllCoupon = () => getApi("coupon/all");
export const DeleteCoupon = (id) => postApi(`coupon/delete/${id}`);
export const UpdateCouponAPI = (id, data) =>
  putApi(`coupon/update/${id}`, data);
export const GetDetailsCoupon = (id) => getApi(`coupon/details/${id}`);

//Shipping
export const CreateShipping = (data) =>
  postApi("shipping/create-shipping", data);
export const GetAllShipping = () => getApi("shipping/get-all");
export const GetShippingById = (id) => getApi(`shipping/get/${id}`);
export const DeleteShipping = (id) => postApi(`shipping/delete/${id}`);
export const UpdateShippingAPI = (id, data) =>
  putApi(`shipping/update/${id}`, data);

//Requirements
export const PostRequirement = (data) => postApi("requirement/post", data);
export const UploadRequirementFile = (file) =>
  postFormData("requirement/upload-file", file);
export const GetMyRequirements = () => getApi("requirement/buyer");
export const DeleteRequirements = (id) => postApi(`requirement/delete/${id}`);

//Sliders

export const CreateSliderApi = (data) => postApi("slider/create-slider", data);
export const GetAllSliders = () => getApi("slider/get-sliders");
export const DeleteSlider = (id) => postApi(`slider/delete-slider/${id}`);
export const UpdateSliderAPI = (id, data) =>
  putApi(`slider/update-slider/${id}`, data);
