import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";

const PermissionsList = ({ permission }) => {
  return (
    <div>
      <ListGroup className="mb-2">
        <ListGroupItem className="d-flex justify-content-between align-items-center">
          Product Management
          <div className="flex-grow-1" />
          <span
            className={`badge ${
              permission.productManagement.addProduct === 1
                ? "badge-danger"
                : permission.productManagement.addProduct === 2
                ? "badge-warning"
                : permission.productManagement.addProduct === 3
                ? "badge-success"
                : "badge-info"
            } counter digits`}
          >
            add
          </span>
          <span
            className={`badge ${
              permission.productManagement.updateProduct === 1
                ? "badge-danger"
                : permission.productManagement.updateProduct === 2
                ? "badge-warning"
                : permission.productManagement.updateProduct === 3
                ? "badge-success"
                : "badge-info"
            } counter digits`}
          >
            update
          </span>
          <span
            className={`badge ${
              permission.productManagement.deleteProduct === 1
                ? "badge-danger"
                : permission.productManagement.deleteProduct === 2
                ? "badge-warning"
                : permission.productManagement.deleteProduct === 3
                ? "badge-success"
                : "badge-info"
            } counter digits`}
          >
            delete
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex justify-content-between align-items-center">
          Stock Management
          <div className="flex-grow-1" />
          <span
            className={`badge ${
              permission.stockManagement.updateStock === 1
                ? "badge-danger"
                : permission.stockManagement.updateStock === 2
                ? "badge-warning"
                : permission.stockManagement.updateStock === 3
                ? "badge-success"
                : "badge-info"
            } counter digits`}
          >
            update
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex justify-content-between align-items-center">
          Order Management
          <div className="flex-grow-1" />
          <span
            className={`badge ${
              permission.orderManagement.addOrder === 1
                ? "badge-danger"
                : permission.orderManagement.addOrder === 2
                ? "badge-warning"
                : permission.orderManagement.addOrder === 3
                ? "badge-success"
                : "badge-info"
            } counter digits`}
          >
            add
          </span>
          <span
            className={`badge ${
              permission.orderManagement.updateOrder === 1
                ? "badge-danger"
                : permission.orderManagement.updateOrder === 2
                ? "badge-warning"
                : permission.orderManagement.updateOrder === 3
                ? "badge-success"
                : "badge-info"
            } counter digits`}
          >
            update
          </span>
          <span
            className={`badge ${
              permission.orderManagement.deleteOrder === 1
                ? "badge-danger"
                : permission.orderManagement.deleteOrder === 2
                ? "badge-warning"
                : permission.orderManagement.deleteOrder === 3
                ? "badge-success"
                : "badge-info"
            } counter digits`}
          >
            delete
          </span>
        </ListGroupItem>
        <ListGroupItem className="d-flex justify-content-between align-items-center">
          Event Product Management
          <div className="flex-grow-1" />
          <span
            className={`badge ${
              permission.eventManagement.addEventProduct === 1
                ? "badge-danger"
                : permission.eventManagement.addEventProduct === 2
                ? "badge-warning"
                : permission.eventManagement.addEventProduct === 3
                ? "badge-success"
                : "badge-info"
            } counter digits`}
          >
            add
          </span>
          <span
            className={`badge ${
              permission.eventManagement.updateEventProduct === 1
                ? "badge-danger"
                : permission.eventManagement.updateEventProduct === 2
                ? "badge-warning"
                : permission.eventManagement.updateEventProduct === 3
                ? "badge-success"
                : "badge-info"
            } counter digits`}
          >
            update
          </span>
          <span
            className={`badge ${
              permission.eventManagement.deleteEventProduct === 1
                ? "badge-danger"
                : permission.eventManagement.deleteEventProduct === 2
                ? "badge-warning"
                : permission.eventManagement.deleteEventProduct === 3
                ? "badge-success"
                : "badge-info"
            } counter digits`}
          >
            delete
          </span>
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default PermissionsList;
