import moment from "moment";
import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { VendorRequest } from "../../../../service/service";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { Row, Col, Card, CardBody, Container, CardHeader } from "reactstrap";

const MyRequestsPage = ({ company }) => {
  const [loading, setLoading] = useState(true);
  const [vendorRequests, setVendorRequests] = useState([]);

  const fetchData = async () => {
    const response = await VendorRequest(company._id);
    console.log(response);
    if (response && response.isSuccess) {
      setVendorRequests(response.data.vendorRequests);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="My Requests" parent="Dashboard" />
            </Col>
          </Row>
        </div>
        {loading ? (
          <ComponentLoader height="80vh" />
        ) : (
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader>
                  <h6 className="m-0 font-weight-bold text-primary">
                    Requested Products
                  </h6>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive">
                    <table className="table table-hover table-md text-center">
                      <thead>
                        <tr>
                          <th className="border">Request Name</th>
                          <th className="border">Request Type</th>
                          <th className="border">Status</th>
                          <th className="border">Created At</th>
                          <th className="border">Updated At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vendorRequests.map((vendorRequest) => (
                          <tr key={vendorRequest._id}>
                            <td className="border">
                              {vendorRequest.collectionName}
                            </td>
                            <td className="border">
                              {vendorRequest.requestType}
                            </td>
                            <td
                              className={`border badge ${
                                vendorRequest.status === "pending"
                                  ? "badge-danger"
                                  : vendorRequest.status === "reject"
                                  ? "badge-warning"
                                  : vendorRequest.status === "approve"
                                  ? "badge-success"
                                  : "badge-info"
                              } counter digits`}
                            >
                              {vendorRequest.status}
                            </td>
                            <td className="border">
                              {moment(vendorRequest.createdAt).format(
                                "D MMM YYYY"
                              )}
                            </td>
                            <td className="border">
                              {moment(vendorRequest.updatedAt).format(
                                "D MMM YYYY"
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

function mapStateToProps(state) {
  return {
    company: state.mainReducers.main.company,
  };
}

export default connect(mapStateToProps)(MyRequestsPage);
