export const VENDORMENU = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    title: "Dashboard",
    type: "link",
    icon: "desktop",
  },
  {
    title: "Products",
    icon: "bag",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/all-products`,
        title: "All Products",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/add-product`,
        title: "Add Product",
        type: "link",
        permission: "productManagement",
        permissionType: "addProduct",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/shipping`,
        title: "Shipping",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/attributes`,
        title: "Attributes",
        type: "link",
        siteFeature: "hasVariableProduct",
      },
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/stocks`,
    title: "Stock",
    icon: "shopping-cart-full",
    active: false,
    type: "link",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/requestedStocks`,
    title: "Requested Stocks",
    icon: "shopping-cart-full",
    active: false,
    type: "link",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/vendor-events`,
    title: "Vendor Events",
    type: "link",
    icon: "link",
    siteFeature: "events",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/my-requests`,
    siteFeature: "vendorAccessManagement",
    title: "my-requests",
    type: "link",
    icon: "key",
  },
  {
    title: "Requirements",
    icon: "envelope",
    type: "sub",
    active: false,
    siteFeature: "quotation",
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/browse-requirements`,
        title: "Browse",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/responded-requirements`,
        title: "Response",
        type: "link",
      },
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/message-center`,
    title: "Message Center",
    icon: "comments",
    active: false,
    type: "link",
    siteFeature: "messaging",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/orders`,
    title: "Orders",
    icon: "credit-card",
    active: false,
    type: "link",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/company-subscription`,
    title: "Subscription",
    icon: "money",
    active: false,
    type: "link",
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/dashboard/promotion`,
  //   title: "Promotion",
  //   type: "link",
  //   icon: "announcement",
  // },
  {
    title: "Settings",
    icon: "settings",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-company`,
        title: "Update Company",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-profile`,
        title: "Update Profile",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-password`,
        title: "Update Password",
        type: "link",
      },
    ],
  },
];

export const SINGLEVENDORMENU = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    title: "Dashboard",
    type: "link",
    icon: "desktop",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/categories`,
    title: "Categories",
    type: "link",
    icon: "notepad",
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/dashboard/vendors`,
  //   title: "Vendors",
  //   type: "link",
  //   icon: "shopping-cart",
  // },
  {
    title: "Products",
    icon: "bag",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/all-products`,
        title: "All Products",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/add-product`,
        title: "Add Product",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/attributes`,
        title: "Attributes",
        type: "link",
        siteFeature: "hasVariableProduct",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/shipping`,
        title: "Shipping",
        type: "link",
      },
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/stocks`,
    title: "Stock",
    icon: "shopping-cart-full",
    active: false,
    type: "link",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/orders`,
    title: "Orders",
    icon: "credit-card",
    active: false,
    type: "link",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/users`,
    title: "Users",
    type: "link",
    icon: "user",
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/dashboard/message`,
  //   title: "Contact Message",
  //   type: "link",
  //   icon: "email",
  // },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/blog`,
    title: "Blog",
    type: "link",
    icon: "book",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/brand`,
    title: "Brand",
    type: "link",
    icon: "package",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/services`,
    title: "Service",
    type: "link",
    icon: "panel",
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/dashboard/promotion`,
  //   title: "Promotion",
  //   type: "link",
  //   icon: "announcement",
  // },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/coupon`,
    title: "Coupon",
    type: "link",
    icon: "icofont-gift-box",
  },
  {
    title: "Site Settings",
    icon: "world",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-site-settings`,
        title: "Update Site",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/about-site`,
        title: "About",
        type: "link",
      },
      // {
      //   path: `${process.env.PUBLIC_URL}/dashboard/privacy-policy`,
      //   title: "Privacy Policy",
      //   type: "link",
      // },
      // {
      //   path: `${process.env.PUBLIC_URL}/dashboard/terms-and-conditions`,
      //   title: "Terms And Conditions",
      //   type: "link",
      // },
      // {
      //   path: `${process.env.PUBLIC_URL}/dashboard/return-and-refund-policy`,
      //   title: "Return And Refund Policy",
      //   type: "link",
      // },
    ],
  },
  {
    title: "Settings",
    icon: "settings",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-company`,
        title: "Update Company",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-profile`,
        title: "Update Profile",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-password`,
        title: "Update Password",
        type: "link",
      },
    ],
  },
];

export const MULTIVENDORMENU = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    title: "Dashboard",
    type: "link",
    icon: "desktop",
  },
  {
    title: "Products",
    icon: "bag",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/all-products`,
        title: "All Products",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/add-product`,
        title: "Add Product",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/shipping`,
        title: "Shipping",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/attributes`,
        title: "Attributes",
        type: "link",
        siteFeature: "hasVariableProduct",
      },
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/categories`,
    title: "Categories",
    type: "link",
    icon: "notepad",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/stocks`,
    title: "Stock",
    icon: "shopping-cart-full",
    active: false,
    type: "link",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/requestedStocks`,
    title: "Requested Stocks",
    icon: "shopping-cart-full",
    active: false,
    type: "link",
  },
  {
    title: "Events",
    icon: "link",
    type: "sub",
    active: false,
    siteFeature: "events",
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/all-events`,
        title: "All Events",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/add-event`,
        title: "Add Event",
        type: "link",
      },
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/all-certifications`,
    title: "Certifications",
    type: "link",
    icon: "link",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/vendors`,
    title: "Vendors",
    type: "link",
    icon: "shopping-cart",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/orders`,
    title: "Orders",
    icon: "credit-card",
    active: false,
    type: "link",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/users`,
    title: "Users",
    type: "link",
    icon: "user",
  },

  {
    path: `${process.env.PUBLIC_URL}/dashboard/message-center`,
    title: "Message Center",
    icon: "comments",
    active: false,
    type: "link",
    siteFeature: "messaging",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/blog`,
    title: "Blog",
    type: "link",
    icon: "book",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/requirements`,
    title: "Requirements",
    type: "link",
    icon: "envelope",
    siteFeature: "quotation",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/promotion`,
    title: "Promotion",
    type: "link",
    icon: "announcement",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/coupon`,
    title: "Coupon",
    type: "link",
    icon: "icofont-gift-box",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/slider`,
    title: "Slider",
    type: "link",
    icon: "icofont-gift-box",
  },
  {
    title: "Site Settings",
    icon: "world",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-site-settings`,
        title: "Update Site",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/about-site`,
        title: "About",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/privacy-policy`,
        title: "Privacy Policy",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/terms-and-conditions`,
        title: "Terms And Conditions",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/return-and-refund-policy`,
        title: "Return And Refund Policy",
        type: "link",
      },
    ],
  },
  {
    title: "Packages",
    icon: "money",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/subscription-packages`,
        title: "All Packages",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/add-package`,
        title: "Add Package",
        type: "link",
      },
    ],
  },
  {
    title: "Subscriptions",
    icon: "ticket",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/subscriptions`,
        title: "All Subscriptions",
        type: "link",
      },
    ],
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/vendor-permissions`,
    title: "Vendor Permissions",
    type: "link",
    icon: "key",
    siteFeature: "vendorAccessManagement",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/vendor-requests`,
    siteFeature: "vendorAccessManagement",
    title: "Vendor Requests",
    type: "link",
    icon: "key",
  },
  {
    title: "Settings",
    icon: "settings",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-company`,
        title: "Update Company",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-profile`,
        title: "Update Profile",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-password`,
        title: "Update Password",
        type: "link",
      },
    ],
  },
];

export const ADMINMENU = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    title: "Dashboard",
    type: "link",
    icon: "desktop",
  },

  {
    path: `${process.env.PUBLIC_URL}/dashboard/sub-categories`,
    title: "Sub-Categories",
    type: "link",
    icon: "menu-alt",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/users`,
    title: "Users",
    type: "link",
    icon: "user",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/companies`,
    title: "Companies",
    type: "link",
    icon: "shopping-cart",
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/requirements`,
    title: "Requirements",
    type: "link",
    icon: "envelope",
  },
  {
    title: "Packages",
    icon: "money",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/subscription-packages`,
        title: "All Packages",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/add-subscription-package`,
        title: "Add Package",
        type: "link",
      },
    ],
  },
  {
    title: "Subscriptions",
    icon: "ticket",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/subscriptions`,
        title: "All Subscriptions",
        type: "link",
      },
    ],
  },
  {
    title: "Settings",
    icon: "settings",
    type: "sub",
    active: false,
    children: [
      {
        path: `${process.env.PUBLIC_URL}/dashboard/manage-admins`,
        title: "Manage Admin",
        type: "link",
      },
      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-profile`,
        title: "Update Profile",
        type: "link",
      },

      {
        path: `${process.env.PUBLIC_URL}/dashboard/update-password`,
        title: "Update Password",
        type: "link",
      },
    ],
  },
];
