import axios from "axios";

const setShopHeader = (shopID) => {
  if (shopID) {
    axios.defaults.headers.common["shop"] = shopID;
  } else {
    axios.defaults.headers.common["shop"] = "";
  }
};

export default setShopHeader;
