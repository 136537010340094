import React from "react";
import { Row, Col, Container, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
const Breadcrumbs = (props) => {
  return (
    <Container fluid={true}>
      <Row>
        <Col lg="12">
          <h3>
            {props.title}
            <small>
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
                    <i className="fa fa-home"></i>
                  </Link>
                </BreadcrumbItem>
                <BreadcrumbItem>{props.parent}</BreadcrumbItem>
                <BreadcrumbItem active>{props.title}</BreadcrumbItem>
              </Breadcrumb>
            </small>
          </h3>
        </Col>
      </Row>
    </Container>
  );
};

export default Breadcrumbs;
