import React, { useEffect } from "react";
import { Card, CardBody, Table } from "reactstrap";
import { GetSellerOrders } from "../../../../../service/service";
import ComponentLoader from "../../../Components/Loader/ComponentLoader";
import errorImg from "../../../../../assets/images/search-not-found.png";
import { useNavigate } from "react-router-dom";

const LatestOrders = ({ company }) => {
  const [orders, setOrders] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);

    const response = await GetSellerOrders(company._id, { limit: 5 });
    if (response && response.isSuccess) {
      setOrders(response.data.orders);
    } else {
      setOrders([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleGoToDetails = (id) => {
    console.log("🚀 ~ file: LatestOrders.jsx:28 ~ handleGoToDetails ~ id", id);
    navigate(`/dashboard/update-order/${id}`);
  };

  return (
    <Card>
      {loading ? (
        <ComponentLoader height="20vh" />
      ) : (
        <CardBody>
          <h5>Latest Orders</h5>
          {orders.length > 0 ? (
            <div className="table-responsive">
              <Table hover size="small">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Products</th>
                    <th>Buyer Name</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody style={{ cursor: "pointer" }} className="">
                  {orders.map((order) => (
                    <tr
                      key={order.orderID}
                      onClick={() => handleGoToDetails(order?.orderID)}
                    >
                      <td>{order.orderID}</td>
                      <td>{order.products.length}</td>

                      <td>
                        {order.buyer &&
                          `${order.buyer.userInfo.firstName} ${order.buyer.userInfo.lastName}`}
                      </td>

                      <td>
                        {order.amount.toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td className="text-capitalize">{order.status}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="search-not-found text-center">
              <div>
                <img
                  className="img-fluid second-search"
                  src={errorImg}
                  alt="Error Logo"
                />
                <p>Sorry, We didn't find any products</p>
              </div>
            </div>
          )}
        </CardBody>
      )}
    </Card>
  );
};

export default LatestOrders;
