import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import operations from "../../../../redux/Main/operations";
import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Badge,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { imageExists } from "../../../../service/imageExists";
import noImage from "../../../../assets/images/notFound.png";
import errorImg from "../../../../assets/images/search-not-found.png";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import {
  AddNewVendorRequest,
  DeleteProduct,
  GetAllProducts,
} from "../../../../service/service";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

const AllProductsPage = (props) => {
  const { company, vendors, categories, siteSettings, user } = props;
  const [page, setPage] = useState(1);
  const [vendor, setVendor] = useState(null);
  const [limit, setLimit] = React.useState(20);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [order, setOrder] = React.useState("desc");
  const [productsCount, setProductsCount] = useState(0);
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [productCsv, setProductCsv] = useState([]);
  const getData = async () => {
    setLoading(true);

    const data = {
      limit: limit,
      sortBy: {
        field: orderBy,
        order: order,
      },
      category: category ? category : null,
      searchKey: searchKey ? searchKey : null,
      vendor: company.isVendor ? company._id : vendor,
    };
    console.log("🚀 ~ file: AllProductsPage.jsx:57 ~ getData ~ company", data);

    // console.log(data);

    const response = await GetAllProducts(data);
    console.log({ response });

    if (response && response.isSuccess) {
      setProducts(response.data.products);
      setProductsCount(response.data.productsCount);
    } else {
      setProducts([]);
      setProductsCount(0);
    }
    setLoading(false);
  };

  const loadMore = async () => {
    setButtonLoading(true);
    const data = {
      skip: products.length,
      limit: limit,
      sortBy: {
        field: orderBy,
        order: order,
      },
      category: category ? category : null,
      searchKey: searchKey ? searchKey : null,
      vendor: company.isVendor ? company._id : vendor,
    };

    const response = await GetAllProducts(data);
    // console.log("products", response);
    if (response && response.isSuccess) {
      setProducts([...products, ...response.data.products]);
      setProductsCount(response.data.productsCount);
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    getData();
  }, [order, orderBy, category, limit, vendor]);

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder(-1);
        break;
      }
      case "name": {
        setOrderBy(property);
        setOrder(1);
        break;
      }
      case "priceAsc": {
        setOrderBy("price");
        setOrder(1);
        break;
      }
      case "priceDesc": {
        setOrderBy("price");
        setOrder(-1);
        break;
      }
      default:
        break;
    }
  };

  const handleSelectCategory = (cat) => {
    if (cat === null) {
      setCategory(null);
    } else {
      console.log(cat);
      setCategory(cat);
    }
  };

  const confirmDelete = async (id) => {
    if (
      !company.isVendor ||
      (company.permissionType &&
        company.permissionType.productManagement.deleteProduct === 3)
    ) {
      const response = await DeleteProduct(id);
      if (response && response.isSuccess) {
        console.log(response.data);
        setProducts(
          products.filter(
            (product) => product._id !== response.data.product._id
          )
        );
      }
    } else if (
      company.permissionType &&
      company.permissionType.productManagement.deleteProduct === 2
    ) {
      const response = await AddNewVendorRequest({
        vendor: company._id,
        requestType: "deleteProduct",
        collectionName: "Product",
        docID: id,
        requestData: products.find((item) => item._id === id),
      });

      console.log(response);

      if (response && response.isSuccess) {
        toast.success("Product Delete Requested! Wait for Admin Approval", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        props.history.push("/dashboard/all-products");
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this product!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      console.log(result);
      if (result.value) {
        confirmDelete(id);
      } else {
        SweetAlert.fire("Product is safe!");
      }
    });
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    getData();
    // if (e.key === "Enter") {
    // } else {
    //   console.log(e.target.value);
    //   setSearchKey(e.target.value);
    // }
  };

  useEffect(() => {
    if (products) {
      let arrayObj = products;

      arrayObj = arrayObj.map((item) => {
        return {
          ProductId: item._id,
          name: item.name,
          price: item.price,
          name: item.name,
          Product: JSON.stringify(
            products.map((val) => {
              return { name: val.name };
            })
          ),
        };
      });

      setProductCsv(arrayObj);
    }
  }, [products]);

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="10" xs="12">
              <Breadcrumbs title="All Products" parent="Dashboard" />
            </Col>
            <Col lg="2" xs="12">
              {(!company.isVendor ||
                (company.permissionType &&
                  company.permissionType.productManagement.addProduct !==
                    1)) && (
                <Link
                  to="/dashboard/add-product"
                  className="btn btn-primary btn-block"
                >
                  <i className="fa fa-plus"></i>
                  <span className=" ms-1">Add Product</span>
                </Link>
              )}
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col lg="4" md="2" sm="12" className="text-right">
                    <form
                      className="form-inline search-form"
                      onSubmit={handleSearch}
                    >
                      <div className="form-group w-100">
                        <input
                          type="text"
                          id="search"
                          name="search"
                          className="form-control-plaintext rounded-0"
                          placeholder="Search.."
                          onChange={(e) => setSearchKey(e.target.value)}
                          value={searchKey}
                        />
                      </div>
                    </form>
                  </Col>

                  <Col lg="8" md="10" sm="12" className="text-right">
                    <div className="d-flex">
                      <div className="w-100">
                        {siteSettings.siteType === "multiVendor" &&
                          !company.isVendor && (
                            <div className="form-inline">
                              <div className="form-group w-100">
                                <select
                                  name="select"
                                  className="form-control btn-square border"
                                  onChange={(e) =>
                                    setVendor(
                                      e.target.value === "all"
                                        ? null
                                        : e.target.value
                                    )
                                  }
                                >
                                  <option value="all">All Vendors</option>
                                  <option value={company._id}>
                                    {company.name} - Own Products
                                  </option>
                                  {vendors.map((vendor) => (
                                    <option value={vendor._id} key={vendor._id}>
                                      {vendor.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="form-inline w-50">
                        <div className="form-group w-100">
                          <select
                            name="select"
                            className="form-control btn-square border"
                            onChange={(e) =>
                              setCategory(
                                e.target.value === "all" ? null : e.target.value
                              )
                            }
                          >
                            <option value="">All Categories</option>
                            {categories
                              .filter((cat) => cat.isRoot)
                              .map((cat) => (
                                <option value={cat._id} key={cat._id}>
                                  {cat.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-inline w-50">
                        <div className="form-group ">
                          <select
                            name="select"
                            className="form-control btn-square border"
                            onChange={(e) =>
                              handleRequestSort(e, e.target.value)
                            }
                          >
                            <option value="createdAt">Latest</option>
                            <option value="name">Name</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-inline w-50">
                        <CSVLink
                          filename="product.csv"
                          data={productCsv}
                          enclosingCharacter={`'`}
                          style={{ textDecoration: "none" }}
                        >
                          <button
                            // onClick={exportCsvHandler}
                            type="button"
                            className="border-0 py-2 px-3 rounded"
                          >
                            Export As CSV
                          </button>
                        </CSVLink>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? (
                <ComponentLoader height="80vh" />
              ) : (
                <CardBody>
                  {products.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-hover table-md">
                        <thead>
                          <tr>
                            <th className="border">Photo</th>
                            <th className="border w-50">Name</th>
                            {!company.isVendor && (
                              <th className="border">Vendor</th>
                            )}
                            <th className="border">Categories</th>
                            <th className="border">Price</th>
                            <th className="border">Status</th>
                            <th className="border">Tags</th>
                            <th className="border">Date</th>
                            <th className="border">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {products.map((product) => (
                            <tr key={product._id}>
                              <td className="border">
                                <img
                                  src={
                                    product.photos[0] &&
                                    imageExists(
                                      config.fileServer +
                                        config.thumbnailContainer +
                                        product.photos[0].url
                                    )
                                      ? config.fileServer +
                                        config.thumbnailContainer +
                                        product.photos[0].url
                                      : noImage
                                  }
                                  width="60"
                                  className="img-fluid"
                                  alt={product.name}
                                />
                              </td>
                              <td className="border">{product.name}</td>
                              {!company.isVendor && (
                                <td className="border">
                                  {product.vendor && product.vendor.name}
                                </td>
                              )}
                              <td className="border">
                                {product.categories &&
                                  product.categories.map((cat, index) => (
                                    <Badge
                                      key={index}
                                      color={
                                        cat.isRoot
                                          ? "primary"
                                          : cat.isLeaf
                                          ? "info"
                                          : "light"
                                      }
                                      className="m-1"
                                    >
                                      <span>{cat.name}</span>
                                    </Badge>
                                  ))}
                              </td>
                              <td className="border">
                                <b>৳</b>{" "}
                                {product.price.toLocaleString("en-IN", {
                                  maximumFractionDigits: 2,
                                })}
                              </td>
                              <td className="border">
                                {product.activeStatus ? "✅" : "🚫"}
                              </td>
                              <td className="border">
                                {product.tags &&
                                  product.tags.slice(0, 3).map((tag, index) => (
                                    <Badge color="light" key={index}>
                                      {tag}
                                    </Badge>
                                  ))}
                              </td>
                              <td className="border">
                                <p>
                                  {moment(product.createdAt).format(
                                    "D MMM YYYY"
                                  )}
                                </p>
                              </td>
                              <td className="border">
                                <div className="button-list">
                                  <Link
                                    to={`/dashboard/update-product/${product.slug}`}
                                  >
                                    <button
                                      href="#"
                                      className="btn btn-text p-0"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          width: 35,
                                          fontSize: 16,
                                          padding: 11,
                                          color: "rgb(40, 167, 69)",
                                        }}
                                      ></i>
                                    </button>
                                  </Link>

                                  {(!company.isVendor ||
                                    (company.permissionType &&
                                      company.permissionType.productManagement
                                        .deleteProduct !== 1)) && (
                                    <button
                                      href="#"
                                      onClick={(e) => handleDelete(product._id)}
                                      className="btn btn-text p-0"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        style={{
                                          width: 35,
                                          fontSize: 16,
                                          padding: 11,
                                          color: "#e4566e",
                                        }}
                                      ></i>
                                    </button>
                                  )}
                                </div>
                              </td>
                            </tr>
                          ))}
                          {products.length < productsCount && (
                            <tr>
                              <td colSpan="7" align="center">
                                <button
                                  href="#"
                                  onClick={loadMore}
                                  className="btn btn-primary btn-sm"
                                >
                                  {buttonLoading ? "LOADING" : "Load More"}
                                </button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="search-not-found text-center">
                      <div>
                        <img
                          className="img-fluid second-search"
                          src={errorImg}
                          alt=""
                        />
                        <p>Sorry, We didn't find any products</p>
                      </div>
                    </div>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    vendors: state.mainReducers.main.vendors,
    categories: state.mainReducers.main.categories,
    siteSettings: state.mainReducers.main.siteSettings,
    user: state.mainReducers.main.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AllProductsPage);
