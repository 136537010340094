import moment from "moment";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { VendorRequest } from "../../../../service/service";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import errorImg from "../../../../assets/images/search-not-found.png";

const VendorRequestPage = () => {
  const [loading, setLoading] = useState(true);
  const [vendorRequests, setVendorRequests] = useState([]);

  const fetchData = async () => {
    const response = await VendorRequest();
    console.log(response);
    if (response && response.isSuccess) {
      setVendorRequests(response.data.vendorRequests);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="All Requests" parent="Dashboard" />
            </Col>
            {/* <Col lg="3" xs="12">
              <Link
                to="/dashboard/add-event"
                className="btn btn-primary btn-block"
              >
                <i className="fa fa-plus mr-1"></i>
                <span className="text">Add Requests</span>
              </Link>
            </Col> */}
          </Row>
        </div>
        {loading ? (
          <ComponentLoader height="80vh" />
        ) : (
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {vendorRequests.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-hover table-md text-center">
                        <thead>
                          <tr>
                            <th className="border">Vendor</th>
                            <th className="border">Request Type</th>
                            <th className="border">Created At</th>
                            <th className="border">Updated At</th>
                            <th className="border">Status</th>
                            <th className="border">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {vendorRequests.map((vendorRequest) => (
                            <tr key={vendorRequest._id}>
                              <td className="border">
                                {vendorRequest.vendor.name}
                              </td>
                              <td className="border">
                                {vendorRequest.requestType}
                              </td>
                              <td className="border">
                                {moment(vendorRequest.createdAt).format(
                                  "D MMM YYYY"
                                )}
                              </td>
                              <td className="border">
                                {moment(vendorRequest.updatedAt).format(
                                  "D MMM YYYY"
                                )}
                              </td>
                              <td className="border text-uppercase">
                                <span
                                  className={`badge ${vendorRequest.status === "pending"
                                      ? "badge-warning"
                                      : vendorRequest.status === "rejected"
                                        ? "badge-danger"
                                        : vendorRequest.status === "approved"
                                          ? "badge-success"
                                          : "badge-info"
                                    } counter digits`}
                                >
                                  {vendorRequest.status}
                                </span>
                              </td>
                              <td className="border">
                                <div className="button-list">
                                  <Link
                                    to={`/dashboard/vendor-request-details/${vendorRequest._id}`}
                                  >
                                    <button
                                      href="#"
                                      className="btn btn-dark p-0"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          width: 35,
                                          fontSize: 16,
                                          padding: 11,
                                          color: "rgb(40, 167, 69)",
                                        }}
                                      ></i>
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="search-not-found text-center">
                      <div>
                        <img
                          className="img-fluid second-search"
                          src={errorImg}
                          alt=""
                        />
                        <p>Sorry, We didn't find any products</p>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </>
  );
};

export default VendorRequestPage;
