import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { UpdateShippingAPI } from "../../../../../service/service";

const UpdateShipping = ({
  toggleEdit,
  isOpenEdit,
  setSelectedShippingInfo,
  selectedShippingInfo,
  shippingInfo,
  setShippingInfo,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [error, setError] = useState({});

  const handleOnChange = (ev) => {
    setSelectedShippingInfo({
      ...selectedShippingInfo,
      [ev.target.name]: ev.target.value,
    });
  };

  const handleUpdate = () => {
    if (selectedShippingInfo) {
      const data = shippingInfo.filter(
        (val) => val._id !== selectedShippingInfo._id
      );
      setShippingInfo([...data, selectedShippingInfo]);
    }
  };

  const hanldeSubmitCreateShipping = async (e) => {
    e.preventDefault();

    if (
      !selectedShippingInfo.title ||
      !selectedShippingInfo.title ||
      !selectedShippingInfo.shippingType ||
      !selectedShippingInfo.shippingAmountInsideDhaka ||
      !selectedShippingInfo.shippingAmountOutsideDhaka ||
      !selectedShippingInfo.shippingInsideDhakaFrom ||
      !selectedShippingInfo.shippingInsideDhakaTo ||
      !selectedShippingInfo.shippingOutsideDhakaTo ||
      !selectedShippingInfo.shippingOutsideDhakaFrom
    ) {
      console.log("Error");
    }

    try {
      const shipping = await UpdateShippingAPI(
        selectedShippingInfo._id,
        selectedShippingInfo
      );
      if (shipping.isSuccess) {
        handleUpdate();
        toggleEdit();
        toast.success("Shipping Updated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error(shipping.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
      setErrorMessage((errorMessage) => (errorMessage = error));
    }
  };

  return (
    <Modal isOpen={isOpenEdit}>
      <ModalHeader toggle={toggleEdit}>Update Shipping</ModalHeader>
      <ModalBody>
        <Form onSubmit={hanldeSubmitCreateShipping}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label> Title</Label>

                <Input
                  type="text"
                  name="title"
                  defaultValue={selectedShippingInfo.title}
                  value={selectedShippingInfo.title}
                  onChange={handleOnChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label>Shipping Type</Label>
                <FormGroup>
                  <select
                    className="form-control btn-square border"
                    name="shippingType"
                    onChange={handleOnChange}
                  >
                    <option
                      value="regular"
                      selected={selectedShippingInfo.shippingType === "regular"}
                    >
                      Regular
                    </option>
                    <option
                      value="weight"
                      selected={selectedShippingInfo.shippingType === "weight"}
                    >
                      Weight
                    </option>
                  </select>
                </FormGroup>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Amount Inside Dhaka</Label>
                <Input
                  type="number"
                  name="shippingAmountInsideDhaka"
                  defaultValue={selectedShippingInfo.shippingAmountInsideDhaka}
                  value={selectedShippingInfo.shippingAmountInsideDhaka}
                  onChange={handleOnChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label> Outside Dhaka</Label>
                <Input
                  type="number"
                  name="shippingAmountOutsideDhaka"
                  defaultValue={selectedShippingInfo.shippingAmountOutsideDhaka}
                  value={selectedShippingInfo.shippingAmountOutsideDhaka}
                  onChange={handleOnChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <FormGroup>
                <Label>Shipping Inside Dhaka</Label>
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  type="number"
                  name="shippingInsideDhakaFrom"
                  defaultValue={selectedShippingInfo.shippingInsideDhakaFrom}
                  value={selectedShippingInfo.shippingInsideDhakaFrom}
                  onChange={handleOnChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Input placeholder="To" disabled type="text" />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  type="number"
                  name="shippingInsideDhakaTo"
                  defaultValue={selectedShippingInfo.shippingInsideDhakaTo}
                  value={selectedShippingInfo.shippingInsideDhakaTo}
                  onChange={handleOnChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <FormGroup>
                <Label>Shipping Ouside Dhaka</Label>
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  type="number"
                  name="shippingOutsideDhakaFrom"
                  defaultValue={selectedShippingInfo.shippingOutsideDhakaFrom}
                  value={selectedShippingInfo.shippingOutsideDhakaFrom}
                  onChange={handleOnChange}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Input placeholder="To" disabled type="text" />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  type="number"
                  name="shippingOutsideDhakaTo"
                  defaultValue={selectedShippingInfo.shippingOutsideDhakaTo}
                  value={selectedShippingInfo.shippingOutsideDhakaTo}
                  onChange={handleOnChange}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Button type="submit" color="primary btn-block">
            <FontAwesomeIcon className="mr-1" icon={faSave} /> Update
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default UpdateShipping;
