import {
  UpgradeUser,
  DeleteCompany,
  GetCompanyDetails,
  ChangeCompanyStatus,
  getAllCertifications,
} from "../../../../service/service";
import { Badge } from "reactstrap";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import noImage from "../../../../assets/images/notFound.png";
import React, { Fragment, useEffect, useState } from "react";
import { imageExists } from "../../../../service/imageExists";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { Button, ButtonGroup, Col, Container, Row } from "reactstrap";
import { faSave, faTrashAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";

const CompanyDetailsPage = (props) => {
  const { match, siteSettings, vendorPermissions } = props;
  const { slug } = useParams();
  const history = useNavigate();

  const [company, setCompany] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ableToSell, setAbleToSell] = useState(null);
  const [activeStatus, setActiveStatus] = useState(null);
  const [certifications, setCertifications] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [permissionType, setPermissionType] = useState(null);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [certificateLoading, setCertificateLoading] = useState(false);

  const fetchUserProfile = async () => {
    const response = await GetCompanyDetails(slug);
    console.log(response);
    if (response && response.isSuccess) {
      setCompany(response.data.company);
      setActiveStatus(response.data.company.activeStatus);
      setAbleToSell(response.data.company.ableToSell);
      setPermissionType(
        response.data.company.permissionType
          ? response.data.company.permissionType._id
          : null
      );

      setSelectedCertifications(
        response.data.company.certification
          ? response.data.company.certification
          : []
      );
      setLoading(false);
    }

    const response01 = await getAllCertifications();
    if (response01 && response01.isSuccess) {
      setCertifications(response01.data.certification);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleSelectCertificatos = async (ct) => {
    if (ct !== "-1") {
      const newCertification = certifications.find(
        (certification) => certification._id === ct
      );
      setSelectedCertifications([...selectedCertifications, newCertification]);
    }
  };

  const handleChangeStatus = async (type) => {
    if (type === "certificate") {
      setCertificateLoading(true);
    } else if (type === "permission") {
      setButtonLoading(true);
    }

    const response = await ChangeCompanyStatus(company._id, {
      ableToSell: ableToSell,
      activeStatus: activeStatus,
      permissionType: permissionType,
      certification: selectedCertifications,
    });

    if (response && response.isSuccess) {
      await fetchUserProfile();
      toast.success("Company Info Updated!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    if (type === "certificate") {
      setCertificateLoading(false);
    } else if (type === "permission") {
      setButtonLoading(false);
    }
  };

  const handleAprove = async () => {
    setButtonLoading(true);

    const response = await ChangeCompanyStatus(company._id, {
      activeStatus: "active",
      ableToSell: "true",
    });
    if (response && response.isSuccess) {
      const res = await UpgradeUser({ type: "seller", id: company.seller._id });
      if (res && res.isSuccess) {
        await fetchUserProfile();
        toast.success("Vendor Aproved!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }
    setButtonLoading(false);
  };

  const handleReject = async () => {
    setButtonLoading(true);

    const response = await DeleteCompany(company._id);
    if (response && response.isSuccess) {
      await fetchUserProfile();

      toast.success("Vendor Rejected!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    setButtonLoading(false);
  };

  const confirmDelete = async (id) => {
    await DeleteCompany(id);
  };

  const handleDelete = async () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this vendor!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(company._id).then(() => {
          SweetAlert.fire("Deleted!", "Vendor deleted.", "success");
          history("/dashboard/vendors");
        });
      } else {
        SweetAlert.fire("Vendor is safe!");
      }
    });
  };

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <div>
      <Fragment>
        <Container fluid={true}>
          <div className="page-header">
            <Row>
              <Col lg="9" sm="3">
                <Breadcrumbs title="Company Details" parent="Companies" />
              </Col>
              <Col lg="3" sm="3">
                {buttonLoading ? (
                  <Button color="primary btn-block" disabled={buttonLoading}>
                    Deleting
                  </Button>
                ) : (
                  <Button onClick={handleDelete} color="danger btn-block">
                    <FontAwesomeIcon className="mr-1" icon={faTrashAlt} />{" "}
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div className="contentbar">
            <div className="row">
              <div className="col-lg-8 col-xl-9">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Company Information
                    </h6>
                  </div>
                  <div className="card-body">
                    {company.coverPhoto && (
                      <div className="position-relative mb-1">
                        <img
                          className="img-fluid rounded"
                          src={
                            company.coverPhoto &&
                            imageExists(
                              config.fileServer +
                                config.thumbnailContainer +
                                company.coverPhoto
                            )
                              ? config.fileServer +
                                config.thumbnailContainer +
                                company.coverPhoto
                              : noImage
                          }
                          alt=""
                        />
                      </div>
                    )}
                    <div className="table-responsive">
                      <table className="table table-striped table-sm">
                        <tbody>
                          <tr>
                            <td className="border">Name</td>
                            <td className="border">{company.name}</td>
                          </tr>
                          <tr>
                            <td className="border">Company Types</td>
                            <td className="border">
                              {company.companyTypes.map((c, i) => (
                                <li key={i}>{c}</li>
                              ))}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Product Types</td>
                            <td className="border">
                              {company.productTypes.map((c, i) => (
                                <li key={i}>{c}</li>
                              ))}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Status</td>
                            <td className="border text-capitalize">
                              {company.activeStatus}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Contact Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-sm">
                        <tbody>
                          <tr>
                            <td className="border">Address</td>
                            <td className="border">
                              {company.address
                                ? company.address.streetAddress
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">City</td>
                            <td className="border">
                              {company.address
                                ? company.address.district
                                  ? company.address.district.name
                                  : ""
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Country</td>
                            <td className="border">
                              {company.address
                                ? company.address.country
                                  ? company.address.country.name
                                  : ""
                                : ""}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Phone</td>
                            <td className="border">{company.phone}</td>
                          </tr>
                          <tr>
                            <td className="border">Email</td>
                            <td className="border">{company.email}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Owner Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-sm">
                        <tbody>
                          <tr>
                            <td className="border">Name</td>
                            <td className="border">
                              {company.user
                                ? company.user.userInfo.firstName ||
                                  company.user.userInfo.lastName
                                  ? `${company.user.userInfo.firstName} ${company.user.userInfo.lastName}`
                                  : "Owner Name Not Given"
                                : "No Owner Information Is Given"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Email</td>
                            <td className="border">
                              {company.user
                                ? company.user.email
                                  ? company.user.email
                                  : "Email Not Given"
                                : "No Owner Information Is Given"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Phone</td>
                            <td className="border">
                              {company.user
                                ? company.user.userInfo.phone
                                  ? company.user.userInfo.phone
                                  : "Phone Number Not Given"
                                : "No Owner Information Is Given"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-center">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Company Logo
                    </h6>
                  </div>
                  <div className="card-body d-flex flex-row align-items-center justify-content-center">
                    {company.logo && (
                      <img
                        src={
                          company.logo &&
                          imageExists(
                            config.fileServer +
                              config.thumbnailContainer +
                              company.logo
                          )
                            ? config.fileServer +
                              config.thumbnailContainer +
                              company.logo
                            : noImage
                        }
                        alt=""
                        className="img-fluid rounded mb-1"
                      />
                    )}
                  </div>
                </div>

                {activeStatus !== "pending" ? (
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Change Status
                      </h6>
                    </div>
                    <div className="card-body">
                      <select
                        className="form-select form-control-sm form-control mb-3"
                        onChange={(e) => setActiveStatus(e.target.value)}
                        value={activeStatus}
                      >
                        <option value="active">Active</option>
                        <option value="disabled">Disable</option>
                      </select>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck"
                            checked={ableToSell}
                            onChange={() => setAbleToSell(!ableToSell)}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck"
                          >
                            Sell Permission
                          </label>
                        </div>
                      </div>
                      {siteSettings.features.vendorAccessManagement && (
                        <>
                          <label>Permission</label>
                          <select
                            className="form-select form-control-sm form-control mb-3"
                            onChange={(e) => setPermissionType(e.target.value)}
                            value={permissionType}
                          >
                            <option>Open this menu</option>
                            {vendorPermissions.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.title}
                              </option>
                            ))}
                          </select>
                        </>
                      )}
                    </div>

                    <div className="card-footer text-right">
                      {buttonLoading ? (
                        <Button
                          color="primary btn-block"
                          disabled={buttonLoading}
                        >
                          SAVING...
                        </Button>
                      ) : (
                        <Button
                          onClick={() => handleChangeStatus("permission")}
                          color="primary btn-block"
                        >
                          <FontAwesomeIcon className="mr-1" icon={faSave} />{" "}
                          Save
                        </Button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Company Aproval
                      </h6>
                    </div>
                    <div className="card-body">
                      <h6 className="m-0 font-weight-normal text-primary">
                        Aprove This Company ?
                      </h6>
                    </div>

                    <div className="card-footer text-center p-4">
                      {buttonLoading ? (
                        <Button
                          color="primary btn-block"
                          disabled={buttonLoading}
                        >
                          SAVING...
                        </Button>
                      ) : (
                        <ButtonGroup className="w-100">
                          <Button
                            onClick={handleReject}
                            size="sm"
                            color="danger"
                          >
                            Reject
                          </Button>
                          <Button
                            size="sm"
                            onClick={handleAprove}
                            color="primary"
                          >
                            Aprove
                          </Button>
                        </ButtonGroup>
                      )}
                    </div>
                  </div>
                )}
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-center">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Vendor Certification
                    </h6>
                  </div>
                  <div className="card-body d-flex flex-column align-items-center justify-content-center">
                    <label className="font-weight-bold">
                      Select Certification
                    </label>
                    {selectedCertifications &&
                      selectedCertifications.map((certification) => (
                        <Badge
                          className="mb-1 w-100"
                          key={certification._id}
                          style={{ background: `${certification.color}` }}
                        >
                          <span className="flex-grow-1">
                            {certification.title}
                          </span>
                          <button
                            type="button"
                            className="btn btn-icon-split btn-xs"
                            onClick={() =>
                              setSelectedCertifications(
                                selectedCertifications.filter(
                                  (selectedCertification) =>
                                    selectedCertification._id !==
                                    certification._id
                                )
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} size="xs" />
                          </button>
                        </Badge>
                      ))}
                    {certifications && (
                      <>
                        <select
                          value="-1"
                          className="form-select form-control-sm form-control mb-2"
                          onChange={(e) =>
                            handleSelectCertificatos(e.target.value)
                          }
                        >
                          <option value="-1">Open this menu</option>
                          {certifications
                            .filter(
                              (certification) =>
                                !selectedCertifications.find(
                                  (selectedCertification) =>
                                    selectedCertification._id ===
                                    certification._id
                                )
                            )
                            .map((certification) => (
                              <option
                                value={certification._id}
                                key={certification._id}
                              >
                                {certification.title}
                              </option>
                            ))}
                        </select>
                        {/* <span className="text-danger font-weight-bold">
                          {error.field && "Both fields can not be empty"}
                        </span> */}
                      </>
                    )}
                  </div>
                  <div className="card-footer text-right">
                    {certificateLoading ? (
                      <Button
                        color="primary btn-block"
                        disabled={certificateLoading}
                      >
                        SAVING...
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleChangeStatus("certificate")}
                        color="primary btn-block"
                      >
                        <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fragment>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    products: state.mainReducers.main.products,
    siteSettings: state.mainReducers.main.siteSettings,
    vendorPermissions: state.mainReducers.main.vendorPermissions,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsPage);
