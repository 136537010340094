import React from "react";
import { Col, Row, Card, Table, CardBody } from "reactstrap";
import CouponRow from "./CouponRow";

const CouponTable = ({ data, setCallApi }) => {
  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <Table hover size="small">
              <thead>
                <tr>
                  <th className="text-center border">Coupon Code</th>
                  <th className="border">Discount Type</th>
                  <th className="border">Discount Amount</th>
                  <th className="border">Limit</th>
                  <th className="border">End Date</th>
                  <th className="text-right border">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => (
                  <CouponRow data={d} key={d._id} setCallApi={setCallApi} />
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CouponTable;
