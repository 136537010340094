import { connect } from "react-redux";
import operations from "../../redux/Main/operations";
import { Navigate, Route, Routes } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import AllProductsPage from "./Pages/ProductsPage/AllProductsPage";
import AddProductPage from "./Pages/AddProductPage/AddProductPage";
import AttributesPage from "./Pages/AttributesPage/AttributesPage";
import { bindActionCreators } from "redux";
import {
  GetAllCategory,
  GetAllPermissions,
  GetAllVendors,
  GetCompany,
  GetCompanySubscription,
} from "../../service/service";
import Header from "./Components/Header/Header";
import { ToastContainer } from "react-toastify";
import Sidebar from "./Components/Sidebar/Sidebar";
import { firestore } from "../../service/firebase";
import { onSnapshot, collection } from "firebase/firestore";
import InboxPage from "./Pages/InboxPage/InboxPage";
import UsersPage from "./Pages/UsersPage/UsersPage";
import OrdersPage from "./Pages/OrdersPage/OrdersPage";
import { collectIdsAndDocs } from "../../service/utils";
import AddPackage from "./Pages/AddPackage/AddPackage";
import StocksPage from "./Pages/StocksPage/StocksPage";
import PageLoader from "./Components/Loader/PageLoader";
import VendorsPage from "./Pages/VendorsPage/VendorsPage";
import AddEventPage from "./Pages/AddEventPage/AddEventPage";
import AllEventsPage from "./Pages/EventsPage/AllEventsPage";
import AboutSitePage from "./Pages/AboutSitePage/AboutSitePage";
import BuyPackagePage from "./Pages/BuyPackagePage/BuyPackagePage";
import CategoriesPage from "./Pages/CategoriesPage/CategoriesPage";
import UpdateUserPage from "./Pages/UpdateUserPage/UpdateUserPage";
import MyRequestsPage from "./Pages/MyRequestsPage/MyRequestsPage";
import RequirementPage from "./Pages/RequirementPage/RequirementPage";
import UpdateEventPage from "./Pages/UpdateEventPage/UpdateEventPage";
import UpdateOrderPage from "./Pages/UpdateOrderPage/UpdateOrderPage";
import ReturnPolicyPage from "./Pages/ReturnPolicyPage/ReturnPolicyPage";
import RequirementsPage from "./Pages/RequirementsPage/RequirementsPage";
import vendorEventsPage from "./Pages/VendorEventsPage/vendorEventsPage";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage/PrivacyPolicyPage";
import UpdateUserPassword from "./Pages/UpdatePassword/UpdateUserPassword";
import UpdateProfilePage from "./Pages/UpdateProfilePage/UpdateProfilePage";
import SubscriptionsPage from "./Pages/SubscriptionsPage/SubscriptionsPage";
import VendorRequestPage from "./Pages/VendorRequestPage/VendorRequestPage";
import UpdatePackagePage from "./Pages/UpdatePackagePage/UpdatePackagePage";
import UpdateProductPage from "./Pages/UpdateProductPage/UpdateProductPage";
import UpdateCompanyPage from "./Pages/UpdateCompanyPage/UpdateCompanyPage";
import SubCategoriesPage from "./Pages/SubCategoriesPage/SubCategoriesPage";
import VendorEventDetails from "./Pages/VendorEventDetails/VendorEventDetails";
import UpdateSiteSettings from "./Pages/UpdateSiteSettings/UpdateSiteSettings";
import CompanyDetailsPage from "./Pages/CompanyDetailsPage/CompanyDetailsPage";
import CompanySubscription from "./Pages/CompanySubscription/CompanySubscription";
import RequestedStocksPage from "./Pages/RequestedStocksPages/RequestedStocksPage";
import AllCertificationsPage from "./Pages/CertificationPage/AllCertificationsPage";
import RespondRequirements from "./Pages/RespondedRequirements/RespondRequirements";
import VendorPermissionsPage from "./Pages/VendorPermissionsPage/VendorPermissionsPage";
import UpdateSubscriptionPage from "./Pages/UpdateSubscriptionPage/UpdateSubscriptionPage";
import AdminSubscriptionsPage from "./Pages/AdminSubscriptionsPage/AdminSubscriptionsPage";
import RequirementDetailsPage from "./Pages/RequirementDetailsPage/RequirementDetailsPage";
import BrowseRequirementsPage from "./Pages/BrowseRequirementsPage/BrowseRequirementsPage";
import TermsAndConditionsPage from "./Pages/TermsAndConditionsPage/TermsAndConditions";
import VendorRequestDetailsPage from "./Pages/VendorRequestDetailsPage/VendorRequestDetailsPage";
import ShowAllMessage from "./Pages/Message/ShowAllMessage";
import MessageDetails from "./Pages/Message/MessageDetails";
import BrandPage from "./Pages/BrandPage/BrandPage";
import BlogPage from "./Pages/BlogPage/BlogPage";
import CreateBlog from "./Pages/BlogPage/Components/CreateBlog";
import UpdateBlog from "./Pages/BlogPage/Components/UpdateBlog";
import Service from "./Pages/ServicePage/Service";
import CreateService from "./Pages/ServicePage/components/CreateService";
import UpdateService from "./Pages/ServicePage/components/UpdateService";
import PromotionPage from "./Pages/PromotionPage/PromotionPage";
import Coupon from "./Pages/Coupon/Coupon";
import AddCoupon from "./Pages/AddCoupon/AddCoupon";
import UpdateCoupon from "./Pages/UpdateCoupon/UpdateCoupon";
import Shipping from "./Pages/ShippingPage/Shipping";
import DashboardPage from "./Pages/DashboardPage/DashboardPage";
import UpdateSiteSettingsOne from "./Pages/UpdateSiteSettings/UpdateSiteSettingsOne";
import VendorsPageOne from "./Pages/VendorsPage/VendorsPageOne";
import VendorEventsPage from "./Pages/VendorEventsPage/vendorEventsPage";
import NotFoundPage from "../NotFound";
import Slider from "./Pages/Slider/Slider";
import CreateSlider from "./Pages/Slider/Components/CreateSlider";

function DashBoard(props) {
  const [loading, setLoading] = useState(true);
  const [vendorInfo, setVendorInfo] = useState("");

  const {
    user,
    setCompany,
    setCategories,
    setSubCategories,
    setUser,
    setConversations,
    history,
    setSubscription,
    setVendors,
    setUnreads,
    setVendorPermissions,
    siteSettings,
  } = props;

  
    console.log("🚀 ~ file: Dashboard.jsx:105 ~ DashBoard ~ user:", user)


  console.log("companyResponse?.data?.company", vendorInfo);
  useEffect(() => {
    const fetchData = async () => {
      if (user.type === "admin" || user.type === "vendor") {
        if (siteSettings) {
          const companyResponse = await GetCompany(user.id);
          console.log(
            "🚀 ~ file: Dashboard.jsx:106 ~ fetchData ~ companyResponse",
            companyResponse
          );
          if (companyResponse && companyResponse.isSuccess) {
            setVendorInfo(companyResponse?.data?.company);
            setCompany(companyResponse?.data?.company);
          }

          if (siteSettings.siteType === "multiVendor") {
            const vendorsResponse = await GetAllVendors();
            console.log(
              "🚀 ~ file: Dashboard.jsx:112 ~ fetchData ~ vendorsResponse",
              vendorsResponse
            );

            if (vendorsResponse && vendorsResponse.isSuccess) {
              setVendors(vendorsResponse?.data?.vendors);
            }
            const vendorPermissionsResponse = await GetAllPermissions();
            // console.log("🚀 ~ file: Dashboard.jsx:117 ~ fetchData ~ vendorPermissionsResponse", vendorPermissionsResponse)

            if (
              vendorPermissionsResponse &&
              vendorPermissionsResponse.isSuccess
            ) {
              setVendorPermissions(
                vendorPermissionsResponse?.data?.vendorPermissions
              );
            }
          }
          const categoryResponse = await GetAllCategory({});

          if (categoryResponse && categoryResponse.isSuccess) {
            setCategories(categoryResponse.data.categories);
            setSubCategories(categoryResponse.data.subCategories);
          }
          if (user.type === "vendor") {
            console.log(
              "🚀 ~ file: Dashboard.jsx:135 ~ fetchData ~ user",
              user
            );

            const subsRes = await GetCompanySubscription(
              companyResponse?.data?.company?._id
            );
            console.log(
              "🚀 ~ file: Dashboard.jsx:140 ~ fetchData ~ subsRes",
              subsRes
            );
            if (subsRes && subsRes.isSuccess) {
              setSubscription(subsRes.data.subscription);
              if (subsRes.data.subscription.status !== "active") {
                history.push("/dashboard/buy-package");
              }
            } else {
              setSubscription(null);
              history.push("/dashboard/buy-package");
            }
          }

          //Modular Firebase Implementation
          onSnapshot(collection(firestore, "conversations"), (snapshot) => {
            const data = snapshot.docs.map(collectIdsAndDocs);
            console.log(data);
            if (data.length > 0) {
              const sorted = data.sort((a, b) =>
                a.updatedAt < b.updatedAt ? 1 : -1
              );
              setConversations(sorted);
              var unrd = [];
              unrd = sorted.filter((item) => !item.sellerSeen);
              setUnreads(unrd.length);
            }
          });
        }
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  if (!props.user.loading && (!props.user || !props.user.id)) {
    return <Navigate to="/" />;
  }

  return (
    <Fragment>
      {loading ? (
        <PageLoader />
      ) : (
        <div className="page-wrapper">
          <Header
            user={user}
            setUser={setUser}
            history={history}
            siteSettings={siteSettings}
          />
          <div className="page-body-wrapper">
            <Sidebar />
            <div className="page-body">
              <Routes>
                <Route path="/buy-package" exact element={<BuyPackagePage />} />
                <Route path="/" exact element={<DashboardPage />} />
                <Route exact path="/add-product" element={<AddProductPage />} />
                <Route
                  exact
                  path="/update-product/:slug"
                  element={<UpdateProductPage />}
                />
                <Route
                  exact
                  path="/all-products"
                  element={<AllProductsPage />}
                />
                <Route exact path="/shipping" element={<Shipping />} />
                <Route exact path="/message" element={<ShowAllMessage />} />
                <Route exact path="message/:id" element={<MessageDetails />} />
                <Route exact path="/brand" element={<BrandPage />} />
                <Route exact path="/services" element={<Service />} />
                <Route
                  exact
                  path="/create-service"
                  element={<CreateService />}
                />
                <Route
                  exact
                  path="/update-service/:id"
                  element={<UpdateService />}
                />

                <Route exact element={<AddEventPage />} path="/add-event" />
                <Route exact path="/all-events" element={<AllEventsPage />} />

                <Route
                  exact
                  path="/update-event/:slug"
                  element={<UpdateEventPage />}
                />
                <Route exact path="/message-center" element={<InboxPage />} />
                <Route exact path="/orders" element={<OrdersPage />} />
                <Route
                  exact
                  path="/update-order/:id"
                  element={<UpdateOrderPage />}
                />
                <Route
                  exact
                  path="/update-company"
                  element={<UpdateCompanyPage />}
                />
                <Route exact path="/categories" element={<CategoriesPage />} />
                <Route exact path="/users" element={<UsersPage />} />
                <Route
                  exact
                  path="/sub-categories"
                  element={<SubCategoriesPage />}
                />
                <Route
                  exact
                  path="/update-user/:id"
                  element={<UpdateUserPage />}
                />
                <Route exact path="/vendors" element={<VendorsPage />} />
                <Route
                  exact
                  path="/vendor-events"
                  element={<VendorEventsPage />}
                />
                <Route
                  exact
                  path="/event-details/:slug"
                  element={<VendorEventDetails />}
                />
                <Route
                  exact
                  path="/all-certifications"
                  element={<AllCertificationsPage />}
                />
                <Route
                  exact
                  path="/update-user/:id"
                  element={<UpdateUserPage />}
                />
                <Route
                  exact
                  path="/company-details/:slug"
                  element={<CompanyDetailsPage />}
                />
                <Route
                  exact
                  path="/update-profile"
                  element={<UpdateProfilePage />}
                />
                <Route
                  exact
                  path="/update-password"
                  element={<UpdateUserPassword />}
                />
                <Route
                  exact
                  path="/subscription-packages/"
                  element={<AdminSubscriptionsPage />}
                />
                <Route exact path="/add-package/" element={<AddPackage />} />
                <Route
                  exact
                  path="/update-package/:id"
                  element={<UpdatePackagePage />}
                />
                <Route
                  exact
                  path="/subscriptions/"
                  element={<SubscriptionsPage />}
                />
                <Route
                  exact
                  path="/company-subscription/"
                  element={<CompanySubscription />}
                />
                <Route
                  exact
                  path="/update-subscription/:id"
                  element={<UpdateSubscriptionPage />}
                />
                <Route
                  exact
                  path="/requirements"
                  element={<RequirementsPage />}
                />
                <Route
                  exact
                  path="/requirement-details/:id"
                  element={<RequirementDetailsPage />}
                />
                <Route
                  exact
                  path="/browse-requirements/"
                  element={<BrowseRequirementsPage />}
                />
                <Route
                  exact
                  path="/browse-requirements/requirement/:id"
                  element={<RequirementPage />}
                />

                <Route
                  exact
                  path="/responded-requirements"
                  element={<RespondRequirements />}
                />

                <Route exact path="/stocks" element={<StocksPage />} />

                <Route
                  exact
                  path="/requestedStocks"
                  element={<RequestedStocksPage />}
                />
                <Route
                  exact
                  path="/requestedStocks"
                  element={<RequestedStocksPage />}
                />

                <Route
                  exact
                  path="update-site-settings"
                  element={<UpdateSiteSettings />}
                />
                <Route
                  exact
                  path="/vendor-permissions"
                  element={<VendorPermissionsPage />}
                />

                <Route
                  exact
                  path="/vendor-requests"
                  element={<VendorRequestPage />}
                />

                <Route
                  exact
                  path="/vendor-request-details/:id"
                  element={<VendorRequestDetailsPage />}
                />

                <Route exact path="/my-requests" element={<MyRequestsPage />} />

                <Route exact path="/about-site" element={<AboutSitePage />} />
                <Route
                  exact
                  path="/privacy-policy"
                  element={<PrivacyPolicyPage />}
                />
                <Route
                  exact
                  path="/terms-and-conditions"
                  element={<TermsAndConditionsPage />}
                />
                <Route
                  exact
                  path="/return-and-refund-policy"
                  element={<ReturnPolicyPage />}
                />

                <Route exact path="/promotion" element={<PromotionPage />} />
                <Route exact path="/coupon" element={<Coupon />} />
                <Route exact path="/slider" element={<Slider />} />
                <Route exact path="/create-slider" element={<CreateSlider />} />
                <Route exact path="/create-coupon" element={<AddCoupon />} />
                <Route
                  exact
                  path="/update-coupon/:id"
                  element={<UpdateCoupon />}
                />

                <Route exact path="/blog" element={<BlogPage />} />
                <Route exact path="/add-blog" element={<CreateBlog />} />
                <Route
                  exact
                  path="/update-blog/:slug"
                  element={<UpdateBlog />}
                />

                {/* <Route element={<NotFoundPage />} path="*" /> */}
                {!siteSettings.features.hasVariableProduct ? (
                  ""
                ) : (
                  <>
                    <Route
                      exact
                      path="/attributes"
                      element={<AttributesPage />}
                    />
                  </>
                )}
              </Routes>
            </div>
          </div>
          <ToastContainer />
        </div>
      )}
    </Fragment>
  );
}

function mapStateToProps(state, props) {
  return {
    user: state.mainReducers.main.user,
    company: state.mainReducers.main.company,
    vendors: state.mainReducers.main.vendors,
    siteSettings: state.mainReducers.main.siteSettings,
    auth: state.mainReducers.main.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
