import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import SweetAlert from "sweetalert2";
import { DeleteShipping } from "../../../../../service/service";
import ComponentLoader from "../../../Components/Loader/ComponentLoader";
import UpdateShipping from "./UpdateShipping";

const ShowShippingInfo = ({ shippingInfo, setShippingInfo }) => {
    const [isOpenEdit, setIsOpenEdit] = useState(false);
    const toggleEdit = () => setIsOpenEdit(!isOpenEdit);
    const [selectedShippingInfo, setSelectedShippingInfo] = useState({});

    const DeleteShippingData = async (id) => {
        const response = await DeleteShipping(id);
        if (response && response.isSuccess) {
            toast.error("Shipping deleted!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
        }
    };
    const handleDeleteData = (id) => {
        DeleteShippingData(id);
        setShippingInfo(shippingInfo.filter((data) => data._id !== id));
    };

    return (
        <Row>
            <Col sm="12">
                <Card>
                    {/* {loading ? (
                        <ComponentLoader height="80vh" />
                    ) : ( */}
                    <CardBody>
                        {shippingInfo.length > 0 ? (
                            <div className="table-responsive">
                                <table className="table table-md table-striped">
                                    <thead>
                                        <tr>
                                            <th className="border">Title</th>
                                            <th className="border">Type</th>
                                            <th className="border">Amount Inside Dhaka</th>
                                            <th className="border">Amount Outside Dhaka</th>
                                            <th className="border">Shipping (Dhaka)</th>
                                            <th className="border">Shipping (Outside)</th>
                                            <th className="border">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shippingInfo.map((shipping) => (
                                            <tr key={shipping._id}>
                                                <td className="border text-center">
                                                    {shipping.title}
                                                </td>
                                                <td className="text-capitalize border">
                                                    {shipping.shippingType}
                                                </td>
                                                <td className="border text-center">
                                                    {shipping.shippingAmountInsideDhaka}
                                                </td>
                                                <td className="border text-center">
                                                    {shipping.shippingAmountOutsideDhaka}
                                                </td>
                                                <td className="border text-center">
                                                    {shipping.shippingInsideDhakaFrom} to{" "}
                                                    {shipping.shippingInsideDhakaTo}
                                                </td>
                                                <td className="border text-center">
                                                    {shipping.shippingOutsideDhakaFrom} to{" "}
                                                    {shipping.shippingOutsideDhakaTo}
                                                </td>

                                                <td className="border text-center">
                                                    <div className="button-list">
                                                        <button
                                                            onClick={(e) => {
                                                                setSelectedShippingInfo(shipping);
                                                                toggleEdit();
                                                            }}
                                                            className="btn btn-text p-0"
                                                        >
                                                            <i
                                                                className="fa fa-pencil"
                                                                style={{
                                                                    width: 35,
                                                                    fontSize: 16,
                                                                    padding: 11,
                                                                    color: "rgb(40, 167, 69)",
                                                                }}
                                                            ></i>
                                                        </button>

                                                        {shipping && (
                                                            <button
                                                                onClick={(e) => {
                                                                    handleDeleteData(shipping._id);
                                                                }}
                                                                className="btn btn-text p-0"
                                                            >
                                                                <i
                                                                    className="fa fa-trash"
                                                                    style={{
                                                                        width: 35,
                                                                        fontSize: 16,
                                                                        padding: 11,
                                                                        color: "#e4566e",
                                                                    }}
                                                                ></i>
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                        {isOpenEdit && (
                                            <UpdateShipping
                                                isOpenEdit={isOpenEdit}
                                                toggleEdit={toggleEdit}
                                                selectedShippingInfo={selectedShippingInfo}
                                                setSelectedShippingInfo={setSelectedShippingInfo}
                                                shippingInfo={shippingInfo}
                                                setShippingInfo={setShippingInfo}
                                            />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            // <div className="search-not-found text-center">
                            //     <div>
                            //         <img
                            //             className="img-fluid second-search"
                            //             src={errorImg}
                            //             alt="Error Image"
                            //         />
                            //         <p>Sorry, We didn't find any products</p>
                            //     </div>
                            // </div>
                            <></>
                        )}
                    </CardBody>
                    {/* )} */}
                </Card>
            </Col>
        </Row>
    );
};

export default ShowShippingInfo;
