import { connect } from "react-redux";
import { operations } from "./redux/Main";
import { bindActionCreators } from "redux";
import { ToastContainer } from "react-toastify";
import "react-dropzone-uploader/dist/styles.css";
import React, { useState, useEffect } from "react";
import Dashboard from "./Pages/Dashboard/Dashboard";
import { GetAllLocation, GetCompany } from "../src/service/service";
import SellerLogin from "./Pages/LoginPage/SellerLogin";
import VendorLogin from "./Pages/LoginPage/VendorLogin";
import ForgetPassword from "./Pages/LoginPage/ForgetPassword";
import PageLoader from "./Pages/Dashboard/Components/Loader/PageLoader";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NotFoundPage from "./Pages/NotFound";

function App({ siteSettings, setCountries, user }) {
  console.log("🚀 ~ file: App.js:16 ~ App ~ user", user);
  const [loading, setLoading] = useState(true);
  console.log(window.location.origin);
  useEffect(() => {
    if (siteSettings._id) {
      const fetchData = async () => {
        // if (siteSettings.shop.globalSeller || !siteSettings.shop.isVendor) {
        //   const response = await GetAllLocation({ areaType: "country" });
        //   if (response && response.isSuccess) {
        //     setCountries(response.data.location);
        //   }
        // }

        setLoading(false);
      };
      fetchData();
    }
  }, [siteSettings]);

  return (
    <div>
      {loading ? (
        <PageLoader />
      ) : (
        <Router>
          <Routes>
            {" "}
            <Route path="/dashboard/*" element={<Dashboard />} />
            <Route path="/" element={<SellerLogin />} />
            <Route path="/vendor-login" element={<VendorLogin />} />
            <Route element={<ForgetPassword />} path="/forget-password" />
            {/* <Route path="*" element={<NotFoundPage />} /> */}
          </Routes>
          <ToastContainer />
        </Router>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    countries: state.mainReducers.main.countries,
    siteSettings: state.mainReducers.main.siteSettings,
    user: state.mainReducers.main.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
