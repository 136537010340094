import React, { useEffect } from "react";
import { Badge, Card, CardBody, CardHeader, Table } from "reactstrap";
import { GetAllProducts } from "../../../../../service/service";
import ComponentLoader from "../../../Components/Loader/ComponentLoader";
import noImage from "../../../../../assets/images/notFound.png";
import errorImg from "../../../../../assets/images/search-not-found.png";
import { imageExists } from "../../../../../service/imageExists";
import config from "../../../../../service/api/config";

const LatestOrders = ({ company }) => {
  const [products, setProducts] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const getData = async () => {
    setLoading(true);

    const response = await GetAllProducts({ limit: 5, vendor: company._id });
    if (response && response.isSuccess) {
      setProducts(response.data.products);
    } else {
      setProducts([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card>
      <CardHeader>
        <h5>Latest Products</h5>
      </CardHeader>
      {loading ? (
        <ComponentLoader height="20vh" />
      ) : (
        <CardBody>
          {products.length > 0 ? (
            <div className="table-responsive text-left user-status">
              <Table borderless>
                <thead>
                  <tr>
                    <th scope="col">Images</th>
                    <th scope="col">Name</th>
                    <th scope="col">Categories</th>
                    <th scope="col">Price</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {products.map((product) => (
                    <tr>
                      <th className="bd-t-none" scope="row">
                        <img
                          src={
                            product.photos[0] &&
                            imageExists(
                              config.fileServer +
                                config.thumbnailContainer +
                                product.photos[0].url
                            )
                              ? config.fileServer +
                                config.thumbnailContainer +
                                product.photos[0].url
                              : noImage
                          }
                          width="60"
                          className="img-fluid"
                          alt="Product Item"
                        />
                      </th>
                      <td>{product.name}</td>
                      <td>
                        {product.categories &&
                          product.categories.map((cat, index) => (
                            <Badge
                              key={index}
                              color={
                                cat.isRoot
                                  ? "primary"
                                  : cat.isLeaf
                                  ? "info"
                                  : "light"
                              }
                              className="m-1"
                            >
                              <span>{cat.name}</span>
                            </Badge>
                          ))}
                      </td>
                      <td>
                        <b>৳</b>{" "}
                        {product.price.toLocaleString("en-IN", {
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td>{product.activeStatus ? "✅" : "🚫"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <div className="search-not-found text-center">
              <div>
                <img
                  className="img-fluid second-search"
                  src={errorImg}
                  alt="Error Logo"
                />
                <p>Sorry, We didn't find any products</p>
              </div>
            </div>
          )}
        </CardBody>
      )}
    </Card>
  );
};

export default LatestOrders;
