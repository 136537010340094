import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import RequirementCard from "./Components/RequirementCard";
import React, { Fragment, useEffect, useState } from "react";
import errorImg from "../../../../assets/images/search-not-found.png";
import { GetAllRequirements } from "../../../../service/service";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const BrowseRequirementsPage = (props) => {
  const { companySubCs, company, siteSettings } = props;
  const [order, setOrder] = React.useState("desc");
  const [loading, setLoading] = React.useState(true);
  const [requirements, setRequirements] = useState([]);
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [filteredrequirements, setFilteredRequirements] = useState(null);

  const fetchData = async () => {
    const response = await GetAllRequirements({
      company: siteSettings.vendor._id,
      status: "active",
    });
    console.log("response", response);
    if (response && response.isSuccess) {
      setRequirements(response.data.requirements);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "name": {
        setOrderBy(property);
        setOrder("asc");
        break;
      }
      default:
        break;
    }
  };

  const handleSearch = async (searchKey) => {
    if (searchKey.length > 0) {
      setFilteredRequirements(
        requirements.filter((rqs) =>
          rqs.productName.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilteredRequirements(null);
    }
  };

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="Browse Requirements" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12"></Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="10" sm="12" className="text-right">
                    <form className="form-inline search-form">
                      <div className="form-group w-100">
                        <input
                          type="search"
                          id="search"
                          className="form-control-plaintext"
                          placeholder="Search.."
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                  </Col>
                  <Col md="2" sm="12" className="products-total">
                    <div className="select2-drpdwn-product select-options d-inline-block">
                      <div className="form-group w-100">
                        <select
                          className="form-control btn-square"
                          name="select"
                          onChange={(e) => handleRequestSort(e, e.target.value)}
                        >
                          <option value="createdAt" selected>
                            Latest
                          </option>
                          <option value="name">Name</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {(filteredrequirements ? filteredrequirements : requirements)
                  .length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-md">
                      <tbody>
                        {(filteredrequirements
                          ? filteredrequirements
                          : requirements
                        ).map((item, i) => (
                          <RequirementCard
                            key={i}
                            item={item}
                            company={company}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="search-not-found text-center">
                    <div>
                      <img
                        className="img-fluid second-search"
                        src={errorImg}
                        alt=""
                      />
                      <p>Sorry, We didn't find any requirement</p>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    companySubCs: state.mainReducers.main.companySubCs,
    company: state.mainReducers.main.company,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrowseRequirementsPage);
