import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import config from "../../../../../service/api/config";
import noImage from "../../../../../assets/images/notFound.png";

const RequirementCard = (props) => {
  const { item, company } = props;
  return (
    <tr>
      <td className="border">
        <img
          width="50px"
          alt="Description"
          src={
            config.fileServer + config.thumbnailContainer + item.file
              ? config.fileServer + config.thumbnailContainer + item.file
              : noImage
          }
          className="img-fluid border border-color-1 rounded mx-auto d-block"
        />
      </td>
      <td className="border">
        <div>
          <p className="font-size-16 font-weight-bold m-0 p-0">
            {item.productName}
          </p>
          <p className="font-size-20 m-0 p-0">
            <b>Posted on:</b> {item.createdAt.toString().slice(0, 10)}{" "}
            {item.status === "closed" &&
              " | " + item.closedAt.toString().slice(0, 10)}
          </p>
          <p className="font-size-15 m-0 p-0">
            <b>Similar to:</b> {item.productType && item.productType.name}
          </p>
          <p className="font-size-15 m-0 p-0 text-justify">
            {item.note
              ? item.note.substring(0, 200) +
                (item.note.length > 200 ? "..." : "")
              : "No Note Given"}
          </p>
        </div>
      </td>

      <td className="w-25 border">
        <div>
          <span className="d-block font-size-12">
            Response:{" "}
            {item.response.length >= 1
              ? "You have already responded"
              : "You haven't responded yet"}{" "}
            {item.response.find((obj) => obj === company._id) && (
              <Badge pill color="success">
                <i className="icofont icofont-ui-check"></i>
              </Badge>
            )}
          </span>

          <Link
            className="btn btn-primary btn-xs"
            to={`requirement/${item._id}`}
          >
            Details
          </Link>
        </div>
      </td>
    </tr>
  );
};

export default RequirementCard;
