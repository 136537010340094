import { connect } from "react-redux";
import { Link } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import errorImg from "../../../../assets/images/search-not-found.png";
import {
  Card,
  Col,
  Row,
  Table,
  CardBody,
  CardHeader,
  Container,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { bindActionCreators } from "redux";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import noImage from "../../../../assets/images/notFound.png";
import { GetAllUsers, DeleteUser } from "../../../../service/service";
import { getComparator, stableSort } from "../../../../service/sort";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const UsersPage = (props) => {
  const { siteSettings } = props;
  console.log(
    "🚀 ~ file: UsersPage.jsx:25 ~ UsersPage ~ siteSettings",
    siteSettings
  );
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState([]);
  const [type, setType] = useState(null);
  const [limit, setLimit] = React.useState(20);
  const [loading, setLoading] = useState(true);
  const [searchKey, setSearchKey] = useState("");
  const [usersCount, setUsersCount] = useState(0);
  const [order, setOrder] = React.useState("desc");
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [openCreate, setOpenCreate] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [buttonLoading, setButtonLoading] = useState(false);

  const getData = async () => {
    setLoading(true);

    const data = {
      limit: limit,
      type: type ? type : null,
      searchKey: searchKey ? searchKey : null,
    };

    const response = await GetAllUsers(data);
    if (response && response.isSuccess) {
      setUsers(response.data.users);
      setUsersCount(response.data.usersCount);
    } else {
      setUsers([]);
      setUsersCount(0);
    }
    setLoading(false);
  };

  const loadMore = async () => {
    setButtonLoading(true);
    const data = {
      skip: users.length,
      limit: limit,

      type: type ? type : null,
      searchKey: searchKey ? searchKey : null,
    };

    const response = await GetAllUsers(data);

    if (response && response.isSuccess) {
      setUsers([...users, ...response.data.users]);
      setUsersCount(response.data.usersCount);
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    getData();
  }, [order, type, limit]);

  const getUsers = async () => {
    const response = await GetAllUsers();
    if (response && response.isSuccess) {
      setUsers(response.data.users);
    }
    setLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const confirmDelete = async (id) => {
    const response = await DeleteUser(id);
    if (response && response.isSuccess) {
      setUsers(users.filter((u) => u._id !== response.data.user._id));
    }
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "User deleted.", "success");
        });
      } else {
        SweetAlert.fire("User is safe!");
      }
    });
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    getData();
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="Users" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12"></Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="10" sm="12" className="text-right">
                    <form
                      className="form-inline search-form"
                      onSubmit={handleSearch}
                    >
                      <div className="form-group w-100">
                        <input
                          type="text"
                          id="search"
                          name="search"
                          className="form-control-plaintext rounded-0"
                          placeholder="Search.."
                          onChange={(e) => setSearchKey(e.target.value)}
                          value={searchKey}
                        />
                      </div>
                    </form>
                  </Col>
                  <Col md="2" sm="12" className="text-right mb-2">
                    <div className="form-inline">
                      <div className="form-group w-100">
                        <select
                          name="select"
                          className="form-control btn-square border"
                          onChange={(e) => setType(e.target.value)}
                        >
                          <option value="">All User</option>
                          <option value="vendor">Vendor</option>
                          <option value="user">User</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? (
                <ComponentLoader height="80vh" />
              ) : (
                <CardBody>
                  {(filteredUsers ? filteredUsers : users).length > 0 ? (
                    <div className="table-responsive">
                      <Table hover size="small">
                        <thead>
                          <tr>
                            <th className="border">#</th>
                            <th className="border">Name</th>
                            <th className="border">Email</th>
                            <th className="border">Phone</th>
                            <th className="border">Type</th>
                            <th className="border">Status</th>
                            {/* <th className="border text-capitalize">
                              stock Permission
                            </th> */}
                            <th className="border text-right">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {stableSort(
                            filteredUsers ? filteredUsers : users,
                            getComparator(order, orderBy)
                          )
                            .slice(page * 10 - 10, page * 10)
                            .map((user) => (
                              <tr key={user?._id}>
                                <td className="border">
                                  <img
                                    src={
                                      config?.fileServer +
                                      config?.thumbnailContainer +
                                      user?.userInfo?.photoURL
                                        ? config?.fileServer +
                                          config?.thumbnailContainer +
                                          user?.userInfo?.photoURL
                                        : noImage
                                    }
                                    className="img-fluid"
                                    alt=""
                                    width="40px"
                                  />
                                </td>
                                <td className="border">
                                  {user?.userInfo?.firstName
                                    ? `${user?.userInfo?.firstName} ${user?.userInfo?.lastName}`
                                    : "---"}
                                </td>
                                <td className="border">
                                  {user?.email ? user?.email : "---"}
                                </td>
                                <td className="border">
                                  {user?.userInfo?.phone
                                    ? user?.userInfo?.phone
                                    : "---"}
                                </td>
                                <td className="border text-capitalize">
                                  {user.type ? user.type : "---"}
                                </td>
                                <td className="border">
                                  {user.activeStatus ? "Active" : "Disabled"}
                                </td>
                                {/* <td
                                  className="border
 
                                "
                                >
                                  {user?.stockPermission
                                    ? "Active"
                                    : "Disabled"}
                                </td> */}
                                <td className="border text-right">
                                  <div className="button-list">
                                    <Link
                                      to={`/dashboard/update-user/${user._id}`}
                                    >
                                      <button className="btn btn-text p-0">
                                        <i
                                          className="fa fa-pencil"
                                          style={{
                                            width: 35,
                                            fontSize: 16,
                                            padding: 11,
                                            color: "rgb(40, 167, 69)",
                                          }}
                                        ></i>
                                      </button>
                                    </Link>

                                    {user.type !== "admin" && (
                                      <button
                                        onClick={(e) => handleDelete(user._id)}
                                        className="btn btn-text p-0"
                                      >
                                        <i
                                          className="fa fa-trash"
                                          style={{
                                            width: 35,
                                            fontSize: 16,
                                            padding: 11,
                                            color: "#e4566e",
                                          }}
                                        ></i>
                                      </button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          {users.length < usersCount && (
                            <tr>
                              <td colSpan="7" align="center">
                                <button
                                  onClick={loadMore}
                                  className="btn btn-primary btn-sm"
                                >
                                  {buttonLoading ? "LOADING" : "Load More"}
                                </button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  ) : (
                    <div className="search-not-found text-center">
                      <div>
                        <img
                          className="img-fluid second-search"
                          src={errorImg}
                          alt="Error Logo"
                        />
                        <p>Sorry, We didn't find any category</p>
                      </div>
                    </div>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    users: state.mainReducers.main.users,
    users: state.mainReducers.main.users,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersPage);
