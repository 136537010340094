import React from "react";
import moment from "moment";
import { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { operations } from "../../../../redux/Main";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Col, Container, ListGroup, ListGroupItem, Row } from "reactstrap";

const CompanySubscription = (props) => {
  const { subscription } = props;
  return (
    <div>
      <Fragment>
        <Container fluid={true}>
          <div className="page-header">
            <Row>
              <Col lg="9" sm="3">
                <Breadcrumbs title="Subscription Details" parent="Dashboard" />
              </Col>
            </Row>
          </div>
          <div className="contentbar">
            <div className="row">
              <div className="col-lg-8">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Current Subscription Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-sm">
                        <tbody>
                          <tr>
                            <td>Package Name</td>
                            <td>{subscription.package.name}</td>
                          </tr>
                          <tr>
                            <td>Description</td>
                            <td>{subscription.package.description}</td>
                          </tr>
                          <tr>
                            <td>Price</td>
                            <td>{subscription.price}</td>
                          </tr>

                          <tr>
                            <td>Duration</td>
                            <td>{subscription.package.duration}</td>
                          </tr>

                          <tr>
                            <td>Starting Date</td>
                            <td>
                              {moment(subscription.createdAt).format(
                                "D MMM YYYY"
                              )}
                            </td>
                          </tr>

                          <tr>
                            <td>Expire Date</td>
                            <td>
                              {moment(subscription.expireDate).format(
                                "D MMM YYYY"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Current Benifits
                    </h6>
                  </div>
                  <div className="card-body">
                    <ListGroup className="list-group-flush">
                      {subscription.package.facilities &&
                        subscription.package.facilities.map((fac) => (
                          <ListGroupItem>{fac}</ListGroupItem>
                        ))}
                    </ListGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Subscription History
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-sm">
                        <tbody>
                          <tr>
                            <td>Package</td>
                            <td>Starting Date </td>
                            <td>Expire Date </td>
                            <td>Price </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Fragment>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    user: state.mainReducers.main.user,
    subscription: state.mainReducers.main.subscription,
    auth: state.mainReducers.main.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySubscription);
