import React, { useState } from "react";
import {
  Row,
  Card,
  Input,
  Label,
  CardBody,
  FormGroup,
  CardHeader,
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import config from "../../../../../service/api/config";
import noImage from "../../../../../assets/images/notFound.png";
import { imageExists } from "../../../../../service/imageExists";
import { UploadPhoto, DeletePhoto } from "../../../../../service/service";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const ImageObject = ({
  item,
  homeSections,
  uploadedFiles,
  deletedPhotos,
  setHomeSections,
  setDeletedPhotos,
}) => {
  const handleUploadPhoto = async ({ meta, file }, status, allFiles) => {
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          if (item.fieldType === "array") {
            setHomeSections({
              ...homeSections,
              [item.id]: [
                ...uploadedFiles,
                {
                  photoURL: response.data.fileName,
                  [item.dataFields[0]]: "",
                  [item.dataFields[1]]: "",
                  [item.dataFields[2]]: "",
                  [item.dataFields[3]]: "",
                },
              ],
            });
          } else {
            setHomeSections({
              ...homeSections,
              [item.id]: {
                photoURL: response.data.fileName,
              },
            });
          }
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleDeletePhoto = async (photoURL) => {
    setDeletedPhotos([...deletedPhotos, photoURL]);
    setHomeSections({
      ...homeSections,
      [item.id]: uploadedFiles.filter((f) => f.photoURL !== photoURL),
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(uploadedFiles);
    const [recordedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, recordedItem);

    setHomeSections({
      ...homeSections,
      [item.id]: items,
    });
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <h5>{item.name}</h5>
        </CardHeader>
        <CardBody>
          <Row>
            {uploadedFiles && (
              <>
                {item.fieldType === "array" ? (
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="image">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {uploadedFiles.map((image, index) => (
                            <Draggable
                              key={image.photoURL}
                              draggableId={image.photoURL}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  className="border border-color-1 p-2 m-2 bg-gray-1"
                                >
                                  <div className="row">
                                    <div className="col-12 col-md-2 mb-2 mb-md-0">
                                      <img
                                        src={
                                          config.fileServer +
                                            config.imageContainer +
                                            image &&
                                          imageExists(
                                            config.fileServer +
                                              config.imageContainer +
                                              image.photoURL
                                          )
                                            ? config.fileServer +
                                              config.imageContainer +
                                              image.photoURL
                                            : noImage
                                        }
                                        alt=""
                                        className="img-fluid rounded mb-1"
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-block btn-sm"
                                        onClick={() =>
                                          handleDeletePhoto(image.photoURL)
                                        }
                                      >
                                        Delete
                                      </button>
                                    </div>
                                    <div className="col-12 col-md-10">
                                      <div className="d-block">
                                        {item.dataFields &&
                                          item.dataFields.map((fld, ind) => (
                                            <FormGroup className="row">
                                              <div className="col-2">
                                                <Label className="font-weight-bold small text-capitalize">
                                                  {fld}
                                                </Label>
                                              </div>
                                              <div className="col-10">
                                                <Input
                                                  className="form-control small text-capitalize"
                                                  type="text"
                                                  defaultValue={image[fld]}
                                                  placeholder={fld}
                                                  name="text"
                                                  onChange={(e) => {
                                                    let arr = uploadedFiles;
                                                    arr[index] = {
                                                      ...arr[index],
                                                      [fld]: e.target.value,
                                                    };
                                                    setHomeSections({
                                                      ...homeSections,
                                                      [item.id]: arr,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </FormGroup>
                                          ))}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  // uploadedFiles.map((image, index) => (
                  //   <div className="col-lg-4 col-xs-12 my-2">
                  //     <div className="border border-color-1 p-1">
                  //       <img
                  //         src={
                  //           config.fileServer + config.imageContainer + image &&
                  //           imageExists(
                  //             config.fileServer +
                  //               config.imageContainer +
                  //               image.photoURL
                  //           )
                  //             ? config.fileServer +
                  //               config.imageContainer +
                  //               image.photoURL
                  //             : noImage
                  //         }
                  //         alt=""
                  //         className="img-fluid rounded mb-1"
                  //       />
                  //       <div className="d-block">
                  //         {item.dataFields &&
                  //           item.dataFields.map((fld, ind) => (
                  //             <FormGroup>
                  //               <Label className="font-weight-bold small">
                  //                 {fld}
                  //               </Label>
                  //               <Input
                  //                 className="form-control"
                  //                 type="text"
                  //                 defaultValue={image[fld]}
                  //                 placeholder={fld}
                  //                 name="text"
                  //                 onChange={(e) => {
                  //                   let arr = uploadedFiles;
                  //                   arr[index] = {
                  //                     ...arr[index],
                  //                     [fld]: e.target.value,
                  //                   };
                  //                   setHomeSections({
                  //                     ...homeSections,
                  //                     [item.id]: arr,
                  //                   });
                  //                 }}
                  //               />
                  //             </FormGroup>
                  //           ))}
                  //         <button
                  //           type="button"
                  //           className="btn btn-danger btn-block btn-sm"
                  //           onClick={() => handleDeletePhoto(image.photoURL)}
                  //         >
                  //           Delete
                  //         </button>
                  //       </div>
                  //     </div>
                  //   </div>
                  // ))
                  <div className="mb-1 col-lg-12">
                    <img
                      src={
                        uploadedFiles &&
                        imageExists(
                          config.fileServer +
                            config.imageContainer +
                            uploadedFiles.photoURL
                        )
                          ? config.fileServer +
                            config.imageContainer +
                            uploadedFiles.photoURL
                          : noImage
                      }
                      alt="Rounded Logo"
                      className="img-fluid rounded mb-1"
                    />
                    <div className="d-block">
                      {item.dataFields &&
                        item.dataFields.map((fld, index) => (
                          <FormGroup>
                            <Label className="font-weight-bold small">
                              {fld}
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={uploadedFiles[fld]}
                              placeholder={fld}
                              name="text"
                              onChange={(e) => {
                                setHomeSections({
                                  ...homeSections,
                                  [item.id]: {
                                    ...uploadedFiles,
                                    [fld]: e.target.value,
                                  },
                                });
                              }}
                            />
                          </FormGroup>
                        ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </Row>

          <Dropzone
            // getUploadParams={getUploadParams}
            onChangeStatus={handleUploadPhoto}
            accept="image/*"
            PreviewComponent={null}
            disabled={
              uploadedFiles &&
              item.length &&
              uploadedFiles.length === item.length
            }
          />
        </CardBody>
        {/* <CardFooter>
          <div className="custom-file mb-4">
            <input
              type="file"
              className="custom-file-input"
              id="customFile"
              onChange={handleUploadPhoto}
              disabled={
                uploadedFiles &&
                item.length &&
                uploadedFiles.length === item.length
              }
            />
            <label className="custom-file-label" htmlFor="customFile">
              Upload Images
            </label>
          </div>
        </CardFooter> */}
      </Card>
    </div>
  );
};

export default ImageObject;
