import React from "react";

const NotFoundPage = () => {
  return (
    <div className="vh-100 d-flex flex-column justify-content-center">
      <div className="text-center">
        <h1 className="display-4 text-uppercase">
          404 ! <br />
          Page Not Found
        </h1>
      </div>
    </div>
  );
};

export default NotFoundPage;
