import React from "react";
import { Col, Row, Card, Table, CardBody } from "reactstrap";
import TableRow from "./TableRow";

const AllBrands = ({ data, setUpdate, setDelete }) => {
  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <Table hover size="small">
              <thead>
                <tr>
                  <th style={{ width: "65px" }} className="text-center border">
                    Icon
                  </th>
                  <th className="border">Brand Name</th>
                  <th className="border">Description</th>
                  <th className="border">Url</th>
                  <th className="text-right border">Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((d) => (
                  <TableRow
                    data={d}
                    key={d._id}
                    setUpdate={setUpdate}
                    setDelete={setDelete}
                  />
                ))}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AllBrands;
