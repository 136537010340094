import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ButtonGroup, Modal, ModalHeader } from "reactstrap";
import {
  UpdateProduct,
  GetAllProducts,
  AddNewVendorRequest,
} from "../../../../../service/service";

export default function UpdateSubCategor(props) {
  const {
    open,
    handleClose,
    setProducts,
    stockToUpdate,
    company,
    siteSettings,
  } = props;
  const [name, setName] = useState(null);
  const [type, setType] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [reserved, setReserved] = useState(0);
  const [available, setAvailable] = useState(0);
  const [newReserved, setNewReserved] = useState(0);
  const [newAvailable, setNewAvailable] = useState(0);

  useEffect(() => {
    if (stockToUpdate) {
      setName(stockToUpdate.name);
      setType(stockToUpdate?.stock?.type);
      setAvailable(
        stockToUpdate?.stock?.available ? stockToUpdate?.stock?.available : 0
      );
      setReserved(
        stockToUpdate?.stock?.reserved ? stockToUpdate?.stock?.reserved : 0
      );
    }
  }, [stockToUpdate]);

  const handleSubmit = async (e) => {
    setButtonLoading(true);
    const data = {
      stockType: type,
      reserved: reserved,
      available: available,
      company: company._id,
    };

    if (
      !siteSettings.features.vendorAccessManagement ||
      !company.isVendor ||
      (company.permissionType &&
        company.permissionType.stockManagement.updateStock === 3)
    ) {
      const response = await UpdateProduct(stockToUpdate._id, data);

      console.log(response);
      if (response && response.isSuccess) {
        console.log(response.data);
        const productsResponse = await GetAllProducts(company._id);
        if (productsResponse && productsResponse.isSuccess) {
          setProducts(productsResponse.data.products);

          setButtonLoading(false);
          toast.success("Stock Updated!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          handleClose();
        }
      }
    } else if (
      company.permissionType &&
      company.permissionType.stockManagement.updateStock === 2
    ) {
      const response = await AddNewVendorRequest({
        vendor: company._id,
        requestType: "updateStock",
        requestData: data,
        collectionName: "Stock",
        docID: stockToUpdate._id,
      });

      console.log(response);

      if (response && response.isSuccess) {
        toast.success("Stock Update Requested! Wait for Admin Approval", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        handleClose();
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }
  };

  const changeAvailable = async (action, value) => {
    if (action === "add") {
      setAvailable(available + parseInt(value));
    } else if (action === "red") {
      setAvailable(available - parseInt(value));
    }
  };

  const changeReserved = async (action, value) => {
    if (action === "add") {
      setReserved(reserved + parseInt(value));
    } else if (action === "red") {
      setReserved(reserved - parseInt(value));
    }
  };

  return (
    <Modal isOpen={open} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>Update Stock</ModalHeader>
      <div className="container mt-4 mb-4">
        <h5 className="mb-1 font-weight-bold text-primary">{name}</h5>
        <h6 className="mb-1  text-dark">Available: {available}</h6>
        <h6 className="mb-1  text-dark">Reserved: {reserved}</h6>
        <h6 className="mb-1  text-dark">
          Sold: {stockToUpdate && stockToUpdate?.stock?.sold}
        </h6>
        <div className="mt-4">
          <div className="form-group row">
            <label className="col-sm-12 font-weight-bold small">
              Stock Type
            </label>
            <div className="col-sm-12">
              <select
                className="form-select form-control-sm form-control mb-2"
                onChange={(e) => setType(e.target.value)}
                value={type}
              >
                <option selected>Open this select menu</option>
                <option value="unlimited">Unlimited</option>
                <option value="limited">Limited</option>
              </select>
            </div>
          </div>
          {type === "limited" && (
            <>
              <div className="form-group row">
                <label
                  for="productTitle"
                  className="col-sm-12 font-weight-bold small"
                >
                  Update Main Stock
                </label>
                <div className="col-sm-12 d-flex">
                  <input
                    type="text"
                    className="form-control-sm form-control"
                    name="name"
                    placeholder="Name"
                    required
                    value={newAvailable}
                    onChange={(e) => setNewAvailable(e.target.value)}
                    data-msg="Please enter a valid email address."
                    data-error-className="u-has-error"
                    data-success-className="u-has-success"
                  />
                  <ButtonGroup>
                    <Button
                      size="xs"
                      color="primary"
                      onClick={() => changeAvailable("add", newAvailable)}
                    >
                      Add
                    </Button>
                    <Button
                      size="xs"
                      color="danger"
                      onClick={() => changeAvailable("red", newAvailable)}
                    >
                      Reduct
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
              <div className="form-group row">
                <label
                  for="productTitle"
                  className="col-sm-12 font-weight-bold small"
                >
                  Update Reserved Stock
                </label>
                <div className="col-sm-12 d-flex">
                  <input
                    type="text"
                    className="form-control-sm form-control"
                    name="available"
                    placeholder="Unit"
                    required
                    value={newReserved}
                    onChange={(e) => setNewReserved(e.target.value)}
                    data-msg="Please enter a valid email address."
                    data-error-className="u-has-error"
                    data-success-className="u-has-success"
                  />
                  <ButtonGroup>
                    <Button
                      size="xs"
                      color="primary"
                      onClick={() => changeReserved("add", newReserved)}
                    >
                      Add
                    </Button>
                    <Button
                      size="xs"
                      color="danger"
                      onClick={() => changeReserved("red", newReserved)}
                    >
                      Reduct
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </>
          )}

          {buttonLoading ? (
            <Button color="primary btn-block" disabled={buttonLoading}>
              SAVING...
            </Button>
          ) : (
            <Button onClick={handleSubmit} color="primary btn-block">
              <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
