import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Media } from "reactstrap";
import { bindActionCreators } from "redux";
import { operations } from "../../../../redux/Main";
import { imageExists } from "../../../../service/imageExists";
import noImage from "../../../../assets/images/notFound.png";
import { timeConvert } from "../../../../service/timeConvert";
import config from "../../../../service/api/config";

const Notification = (props) => {
  const { conversations, unreads } = props;

  return (
    <li className="onhover-dropdown">
      <Link to={`/dashboard/message-center`} className="txt-dark">
        <i className="icon-comments middle"></i>
        <span className="badge badge-pill badge-primary notification">
          {unreads ? unreads : ""}
        </span>
      </Link>
      <ul className="notification-dropdown onhover-show-div">
        <li>
          Messages{" "}
          <span className="badge badge-pill badge-secondary text-white text-uppercase pull-right">
            {unreads} New
          </span>
        </li>
        {conversations.map((item, index) => (
          <Link
            key={index}
            to={`/dashboard/message-center?conversation=${item.id}`}
            className="txt-dark"
            style={{ textDecoration: "none" }}
          >
            <li className={!item.sellerSeen ? "bg-active" : ""}>
              <div className="media">
                <Media
                  src={
                    config.fileServer +
                      config.thumbnailContainer +
                      item.buyerPhoto &&
                    imageExists(
                      config.fileServer +
                        config.thumbnailContainer +
                        item.buyerPhoto
                    )
                      ? config.fileServer +
                        config.thumbnailContainer +
                        item.buyerPhoto
                      : noImage
                  }
                  className="rounded-circle width-80 p-0 pr-2 img-fluid"
                  alt=""
                />
                <div className="media-body">
                  <h6 className="mt-0">{item.buyerName}</h6>
                  <p className="mb-0">{item.lastMessage}</p>
                  <span>
                    <i className="icofont icofont-clock-time p-r-5"></i>
                    {timeConvert(
                      `${item.updatedAt
                        ?.toDate()
                        .getHours()
                        .toString()}:${item.updatedAt
                        ?.toDate()
                        .getMinutes()
                        .toString()}`
                    )}
                    , {`${item.updatedAt?.toDate().toString().slice(4, 10)}`}
                  </span>
                </div>
              </div>
            </li>
          </Link>
        ))}
      </ul>
    </li>
  );
};

function mapStateToProps(state, props) {
  return {
    conversations: state.mainReducers.main.conversations,
    unreads: state.mainReducers.main.unreads,
    user: state.mainReducers.main.user,
    auth: state.mainReducers.main.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
