import {
  GetCompany,
  DeletePhoto,
  UploadPhoto,
  UpdateCompany,
  UpdateAddress,
  CreateAddress,
  GetAllCategory,
  GetAllLocation,
  GetCompanyDetails,
} from "../../../../service/service";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import Dropzone from "react-dropzone-uploader";
import config from "../../../../service/api/config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import React, { Fragment, useEffect, useState } from "react";
import { imageExists } from "../../../../service/imageExists";
import noImage from "../../../../assets/images/notFound.png";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { NOE } from "../../../../service/commondata";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const UpdateCompanyPage = (props) => {
  const { company, setCompany, user, siteSettings } = props;
  const [loading, setLoading] = useState(true);
  const [productTypes, setProductTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [zip, setZip] = useState(null);
  const [logo, setLogo] = useState(null);
  const [city, setCity] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [content, setContent] = useState("");
  const [website, setWebsite] = useState(null);
  const [country, setCountry] = useState(null);
  const [address, setAddress] = useState(null);
  const [district, setDistrict] = useState(null);
  const [division, setDivision] = useState(null);
  const [districts, setDistricts] = useState(null);
  const [divisions, setDivisions] = useState(null);
  const [coverPhoto, setCoverPhoto] = useState(null);
  const [companyTypes, setCompanyTypes] = useState([]);
  const [deletedLogos, setDeletedLogos] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [deletedCovers, setDeletedCovers] = useState([]);
  const [globalSeller, setGlobalSeller] = useState(false);
  const [deletedPosters, setDeletedPosters] = useState([]);
  const [streetAddress, setStreetAddress] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [totalEmployees, setTotalEmployees] = useState(null);
  const [yearEstablished, setYearEstablished] = useState(null);
  const [shortDescription, setShortDescription] = useState(null);
  const [countries, setCountries] = useState([]);
  console.log(
    "🚀 ~ file: UpdateCompanyPage.jsx:63 ~ UpdateCompanyPage ~ countries",
    countries
  );
  // const onEditorChange = (value) => {
  //   setContent(value);
  //   console.log(content);
  // };

  // const onFilesChange = (files) => {
  //   setFiles(files);
  // };
  const fetchCountry = async () => {
    const data = {
      areaType: "country",
    };
    const response = await GetAllLocation(data);
    if (response && response.isSuccess) {
      setCountries(response.data.location);
    }
    setLoading(false);
  };
  const fetchDivision = async () => {
    const data = {
      areaType: "division",
      parentArea: country?._id,
    };
    const response = await GetAllLocation(data);
    if (response && response.isSuccess) {
      setDivisions(response.data.location);
    }
    setLoading(false);
  };

  const fetchDistrict = async () => {
    const data = {
      areaType: "district",
      parentArea: division,
    };
    const response = await GetAllLocation(data);
    if (response && response.isSuccess) {
      setDistricts(response.data.location);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!country && !siteSettings.shop?.globalSeller) {
      setCountry(siteSettings.shop?.country);
    }
    if (country) {
      fetchDivision();
    }
    if (countries) {
      fetchCountry();
    }
    if (division) {
      fetchDistrict();
    }
  }, [country, division]);

  const handleUploadPosters = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        if (response && response.isSuccess) {
          setUploadedFiles((uploadedFiles) => [
            ...uploadedFiles,
            {
              url: response.data.fileName,
              id: response.data.fileName,
              text: "",
              link: "",
            },
          ]);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleUploadLogo = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setLogo(response.data.fileName);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleUploadCover = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setCoverPhoto(response.data.fileName);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleDeletePoster = async (id) => {
    setDeletedPosters([...deletedPosters, id]);
    setUploadedFiles(uploadedFiles.filter((f) => f.id !== id));
  };

  const handleDeleteLogo = async (id) => {
    setDeletedLogos([...deletedLogos, id]);
    setLogo(null);
  };

  const handleDeleteCover = async (id) => {
    setDeletedCovers([...deletedCovers, id]);
    setCoverPhoto(null);
  };

  const fetchData = async () => {
    const response = await GetCompanyDetails(company.slug);

    if (response && response.isSuccess) {
      // setUpdatedCompany(company);

      // setCity(company.city);
      setName(response.data.company.name);
      setEmail(response.data.company.email);
      setPhone(response.data.company.phone);
      setWebsite(response.data.company.website);
      setAddress(response.data.company.address);
      setContent(
        response.data.company.description
          ? response.data.company.description
          : ""
      );
      setGlobalSeller(response.data.company?.globalSeller);
      setLogo(response.data.company.logo ? response.data.company.logo : null);
      setYearEstablished(response.data.company.yearEstablished);
      setShortDescription(response.data.company.shortDescription);
      setUploadedFiles(
        response.data.company.photos ? response.data.company.photos : []
      );
      setCoverPhoto(
        response.data.company.coverPhoto
          ? response.data?.company.coverPhoto
          : null
      );
      setCountry(
        response.data.company.address
          ? response.data.company.address?.country
          : null
      );
      setDistrict(
        response.data.company.address
          ? response.data.company.address.district
          : null
      );
      setDivision(
        response.data.company.address
          ? response.data.company.address.division
          : null
      );
      setCompanyTypes(
        response.data.company.companyTypes
          ? response.data.company.companyTypes
          : []
      );
      setProductTypes(
        response.data.company.productTypes
          ? response.data.company.productTypes
          : []
      );
      setTotalEmployees(
        response.data.company.totalEmployees
          ? response.data.company.totalEmployees
          : null
      );
      setStreetAddress(
        response.data.company.address
          ? response.data.company.address.streetAddress
          : null
      );

      console.log(company.address);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await GetAllCategory();
      if (response && response.isSuccess) {
        setCategories(response.data.categories);
        setSubCategories(response.data.subCategories);
      }
    };

    fetchCategories();
    fetchData();
  }, [company]);

  const handleSetCompanyTypes = (e) => {
    if (e.target.checked === true) {
      setCompanyTypes([...companyTypes, e.target.value]);
    } else {
      setCompanyTypes(companyTypes.filter((item) => item !== e.target.value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // setButtonLoading(true);

    let data = {
      description: content !== company.description ? content : null,
      photos: uploadedFiles,
      name: name !== company.name ? name : null,
      logo: logo,
      coverPhoto: coverPhoto,
      zip: zip !== company.zip ? zip : null,
      email: email !== company.email ? email : null,
      phone: phone !== company.phone ? phone : null,
      website: website !== company.website ? website : null,
      yearEstablished:
        yearEstablished !== company.yearEstablished ? yearEstablished : null,
      shortDescription:
        shortDescription !== company.shortDescription ? shortDescription : null,
      country: country !== company?.country ? country : null,
      city: city !== company.city ? city : null,
      companyTypes: companyTypes !== company.companyTypes ? companyTypes : null,
      productTypes: productTypes !== company.productTypes ? productTypes : null,
      globalSeller:
        globalSeller !== company?.globalSeller ? globalSeller : null,
      totalEmployees:
        totalEmployees !== company.totalEmployees ? totalEmployees : null,
    };

    if (address) {
      const addressData = {
        country: country !== company.address?.country ? country : null,
        district: district !== company.address.district?._id ? district : null,
        division: division !== company.address.division?._id ? division : null,
        streetAddress:
          streetAddress !== company.address?.streetAddress
            ? streetAddress
            : null,
      };
      await UpdateAddress(company.address?._id, addressData);
    } else {
      const addressData = {
        country: country,
        district: district,
        division: division,
        streetAddress: streetAddress,
      };
      const response = await CreateAddress(addressData);
      if (response && response.isSuccess) {
        setAddress(response.data.address);
        data.address = response.data.address?._id;
      }
    }
    console.log("company.slug", company.slug);
    const response = await UpdateCompany(company.slug, data);
    if (response && response.isSuccess) {
      deletedLogos.map((deletedLogo) =>
        DeletePhoto({
          fileName: deletedLogo,
          container: "ivcommerceoriginalcontainer",
        })
      );
      deletedCovers.map((deletedCover) =>
        DeletePhoto({
          fileName: deletedCover,
          container: "ivcommerceoriginalcontainer",
        })
      );
      deletedPosters.map((deletedPoster) =>
        DeletePhoto({
          fileName: deletedPoster,
          container: "ivcommerceoriginalcontainer",
        })
      );
      const companyResponse = await GetCompany(user.id);
      if (companyResponse && companyResponse.isSuccess) {
        setCompany(companyResponse.data.company);
        toast.success("Company Updated !", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      setButtonLoading(false);
    }
  };

  return (
    <div>
      <Fragment>
        <Container fluid={true}>
          <div className="page-header">
            <Row>
              <Col lg="9" sm="3">
                <Breadcrumbs title="Company Details" parent="Settings" />
              </Col>
              <Col lg="3" sm="3">
                {buttonLoading ? (
                  <Button color="primary btn-block" disabled={buttonLoading}>
                    SAVING...
                  </Button>
                ) : (
                  <Button onClick={handleSubmit} color="primary btn-block">
                    <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                  </Button>
                )}
              </Col>
            </Row>
          </div>

          {loading ? (
            <ComponentLoader height="80vh" />
          ) : (
            <div className="contentbar">
              <div className="row">
                <div className="col-lg-8 col-xl-9">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Basic Informations
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="form-group row">
                        <label
                          for="productTitle"
                          className="col-sm-12 font-weight-bold small"
                        >
                          Company Name
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control-sm form-control"
                            name="name"
                            value={name}
                            placeholder="Name"
                            onChange={(e) => setName(e.target.value)}
                            required
                            data-msg="Please enter a valid email address."
                            data-error-className="u-has-error"
                            data-success-className="u-has-success"
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          for="productTitle"
                          className="col-sm-12 font-weight-bold small"
                        >
                          Short Description
                        </label>
                        <div className="col-sm-12">
                          <textarea
                            value={shortDescription}
                            onChange={(e) =>
                              setShortDescription(e.target.value)
                            }
                            className="form-control-sm form-control"
                            rows="4"
                            name="shortDescription"
                            placeholder="Short Description about company"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-12 font-weight-bold small">
                          Long Description
                        </label>
                        <div className="col-sm-12">
                          <div className="border h-100">
                            <CKEditor
                              editor={ClassicEditor}
                              onInit={(editor) => {}}
                              data={content}
                              config={{
                                ckfinder: {
                                  uploadUrl:
                                    config.serverURL + "file/editor-file",
                                },
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setContent(editor.getData());
                                console.log({ event, editor, data });
                              }}
                              onFilesChange={(e) => console.log(e)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Business Informations
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="form-group row">
                        <div className="col-sm-6">
                          <label
                            for="productTitle"
                            className="col-sm-12 font-weight-bold small"
                          >
                            Business Type
                          </label>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheck1"
                              type="checkbox"
                              value="Manufacturer"
                              checked={
                                companyTypes &&
                                companyTypes.find((t) => t === "Manufacturer")
                              }
                              onChange={handleSetCompanyTypes}
                            />
                            <label
                              className="custom-control-label"
                              for="customCheck1"
                            >
                              Manufacturer
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheck2"
                              type="checkbox"
                              value="Exporter"
                              checked={
                                companyTypes &&
                                companyTypes.find((t) => t === "Exporter")
                              }
                              onChange={handleSetCompanyTypes}
                            />
                            <label
                              className="custom-control-label"
                              for="customCheck2"
                            >
                              Exporter
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheck3"
                              type="checkbox"
                              value="Wholesaler"
                              checked={
                                companyTypes &&
                                companyTypes.find((t) => t === "Wholesaler")
                              }
                              onChange={handleSetCompanyTypes}
                            />
                            <label
                              className="custom-control-label"
                              for="customCheck3"
                            >
                              Wholesaler
                            </label>
                          </div>
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="customCheck4"
                              type="checkbox"
                              value="Retailer"
                              checked={
                                companyTypes &&
                                companyTypes.find((t) => t === "Retailer")
                              }
                              onChange={handleSetCompanyTypes}
                            />
                            <label
                              className="custom-control-label"
                              for="customCheck4"
                            >
                              Retailer
                            </label>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <label
                            for="productTitle"
                            className="col-sm-12 font-weight-bold small"
                          >
                            Product Types
                          </label>
                          <div className="product-tags mb-2">
                            {productTypes &&
                              productTypes.map((type) => (
                                <Badge color="light" className="mb-1">
                                  {type}{" "}
                                  <button
                                    type="button"
                                    className="btn btn-icon-split btn-xs"
                                    onClick={() =>
                                      setProductTypes(
                                        productTypes.filter((p) => p !== type)
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon={faTimes} size="xs" />
                                  </button>
                                </Badge>
                              ))}
                          </div>
                          <select
                            className="form-select form-control-sm form-control mb-2"
                            onChange={(e) =>
                              setProductTypes([...productTypes, e.target.value])
                            }
                          >
                            <option selected>Select New Types</option>
                            {categories
                              .filter((item) => item.isRoot)
                              .map(
                                (category) =>
                                  productTypes &&
                                  !productTypes.find(
                                    (p) => p === category.name
                                  ) && (
                                    <option value={category.name}>
                                      {category.name}
                                    </option>
                                  )
                              )}
                          </select>

                          {siteSettings.features.b2b && (
                            <>
                              <label
                                for="productTitle"
                                className="col-sm-12 font-weight-bold mt-2"
                              >
                                Business Area
                              </label>

                              <div className="row">
                                <div className="col-6">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      id="globalRadio"
                                      name="globalSeller"
                                      value={true}
                                      checked={globalSeller}
                                      onClick={(e) => setGlobalSeller(true)}
                                    />
                                    <label
                                      className="custom-control-label form-label collapsed"
                                      for="globalRadio"
                                    >
                                      Global
                                    </label>
                                  </div>
                                </div>
                                <div className="col-6">
                                  <div className="custom-control custom-radio">
                                    <input
                                      type="radio"
                                      className="custom-control-input"
                                      id="localRadio"
                                      name="globalSeller"
                                      value={false}
                                      checked={!globalSeller}
                                      onClick={(e) => setGlobalSeller(false)}
                                    />
                                    <label
                                      className="custom-control-label form-label collapsed"
                                      for="localRadio"
                                    >
                                      Local
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Company Posters
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {uploadedFiles.map((image) => (
                          <div className="col-wd-3 col-lg-4 mb-1">
                            <img
                              src={
                                image &&
                                imageExists(
                                  config.fileServer +
                                    config.imageContainer +
                                    image.url
                                )
                                  ? config.fileServer +
                                    config.imageContainer +
                                    image.url
                                  : noImage
                              }
                              alt="Product"
                              className="mb-2 border img-fluid"
                            />

                            <div className="d-flex">
                              <div>
                                <input
                                  type="text"
                                  className="form-control-sm form-control"
                                  name="text"
                                  defaultValue={image.text}
                                  placeholder="Desctiption"
                                  onChange={(e) => {
                                    let arr = uploadedFiles;
                                    arr[image.id].text = e.target.value;
                                    setUploadedFiles(arr);
                                  }}
                                />
                                <input
                                  type="text"
                                  className="form-control-sm form-control"
                                  name="link"
                                  defaultValue={image.link}
                                  placeholder="Link"
                                  onChange={(e) => {
                                    let arr = uploadedFiles;
                                    arr[image.id].link = e.target.value;
                                    setUploadedFiles(arr);
                                  }}
                                />
                              </div>
                              <button
                                type="button"
                                className="btn btn-icon-split btn-xs"
                                onClick={() => handleDeletePoster(image.id)}
                              >
                                <FontAwesomeIcon icon={faTimes} size="xs" />
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="col-wd-3 col-lg-4 mb-1">
                          <Dropzone
                            // getUploadParams={getUploadParams}
                            onChangeStatus={handleUploadPosters}
                            accept="image/*"
                            PreviewComponent={null}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-3">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Company Logo
                      </h6>
                    </div>
                    <div className="card-body">
                      {logo ? (
                        <div className="w-100 text-center">
                          <div className="w-100">
                            <img
                              src={
                                config.fileServer + config.imageContainer + logo
                              }
                              alt=""
                              className="mb-2 img-fluid"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-icon-split btn-sm btn-danger"
                            onClick={() => handleDeleteLogo(logo)}
                          >
                            Remove Photo
                          </button>
                        </div>
                      ) : (
                        <Dropzone
                          onChangeStatus={handleUploadLogo}
                          accept="image/*"
                          PreviewComponent={null}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                        />
                      )}
                    </div>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Company Cover
                      </h6>
                    </div>
                    <div className="card-body">
                      {coverPhoto ? (
                        <div className="w-100 text-center">
                          <div className="w-100">
                            <img
                              src={
                                config.fileServer +
                                config.imageContainer +
                                coverPhoto
                              }
                              alt=""
                              className="mb-2 img-fluid"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-icon-split btn-sm btn-danger"
                            onClick={() => handleDeleteCover(coverPhoto)}
                          >
                            Remove Photo
                          </button>
                        </div>
                      ) : (
                        <Dropzone
                          onChangeStatus={handleUploadCover}
                          accept="image/*"
                          PreviewComponent={null}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                        />
                      )}
                    </div>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Company Information
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="form-group row">
                        <label className="col-sm-12 font-weight-bold small">
                          Number of Employees
                        </label>
                        <div className="col-sm-12">
                          <select
                            className="form-select form-control-sm form-control mb-2"
                            onChange={(e) => setTotalEmployees(e.target.value)}
                            value={totalEmployees}
                          >
                            <option selected>Open this select menu</option>
                            {NOE.map((n) => (
                              <option value={n}>{n}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          for="productTitle"
                          className="col-sm-12 font-weight-bold small"
                        >
                          Year of Establishment
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control-sm form-control"
                            name="yearEstablished"
                            placeholder="Year of Establishment"
                            value={yearEstablished}
                            onChange={(e) => setYearEstablished(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Contact Information
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="form-group row">
                        <label className="col-sm-12 font-weight-bold small">
                          Country
                        </label>
                        <div className="col-sm-12">
                          {siteSettings.shop?.globalSeller ||
                          !company.isVendor ? (
                            <select
                              value={country && country?._id}
                              disabled={
                                !siteSettings.shop?.globalSeller &&
                                company.isVendor
                              }
                              onChange={(e) =>
                                setCountry(
                                  countries.find(
                                    (item) => item?._id === e.target.value
                                  )
                                )
                              }
                              className="form-select form-control-sm form-control mb-2"
                            >
                              {countries.map((div) => (
                                <option value={div._id} key={div._id}>
                                  {div.name}
                                </option>
                              ))}
                            </select>
                          ) : (
                            <input
                              disabled
                              type="text"
                              name="country"
                              value={country && country?.name}
                              placeholder="Country"
                              className="form-control-sm form-control"
                            />
                          )}
                        </div>
                      </div>
                      {country && divisions && (
                        <div className="form-group row">
                          <label
                            htmlFor="productTitle"
                            className="col-sm-12 font-weight-bold small"
                          >
                            Division
                          </label>
                          <div className="col-sm-12">
                            <select
                              required
                              value={division && division._id}
                              disabled={!divisions ? true : false}
                              aria-label="Default select example"
                              onChange={(e) => setDivision(e.target.value)}
                              className="form-select form-control-sm form-control mb-2"
                            >
                              <option>Open to select</option>
                              {divisions.map((div) => (
                                <option value={div._id} key={div._id}>
                                  {div.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}

                      {division && districts && (
                        <div className="form-group row">
                          <label
                            htmlFor="productTitle"
                            className="col-sm-12 font-weight-bold small"
                          >
                            City/District
                          </label>
                          <div className="col-sm-12">
                            <select
                              required
                              value={district && district?._id}
                              disabled={!division ? true : false}
                              aria-label="Default select example"
                              onChange={(e) => setDistrict(e.target.value)}
                              className="form-select form-control-sm form-control mb-2"
                            >
                              <option>Open to select</option>
                              {districts.map((dis) => (
                                <option value={dis._id} key={dis._id}>
                                  {dis.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          for="productTitle"
                          className="col-sm-12 font-weight-bold small"
                        >
                          Street Address
                        </label>
                        <div className="col-sm-12">
                          <textarea
                            rows="4"
                            name="address"
                            value={streetAddress}
                            className="form-control-sm form-control"
                            placeholder="Company Street Address"
                            onChange={(e) => setStreetAddress(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <div className="form-group row">
                        <label
                          for="productTitle"
                          className="col-sm-12 font-weight-bold small"
                        >
                          Zip
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            value={zip}
                            name="city"
                            required
                            placeholder="Zip"
                            data-error-className="u-has-error"
                            data-success-className="u-has-success"
                            className="form-control-sm form-control"
                            onChange={(e) => setZip(e.target.value)}
                            data-msg="Please enter a valid email address."
                          />
                        </div>
                      </div> */}
                      <div className="form-group row">
                        <label
                          for="productTitle"
                          className="col-sm-12 font-weight-bold small"
                        >
                          Company Email
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control-sm form-control"
                            name="city"
                            value={email}
                            placeholder="Email"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          for="productTitle"
                          className="col-sm-12 font-weight-bold small"
                        >
                          Company Website
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control-sm form-control"
                            name="city"
                            value={website}
                            placeholder="Website"
                            onChange={(e) => setWebsite(e.target.value)}
                            required
                            data-msg="Please enter a valid email address."
                            data-error-className="u-has-error"
                            data-success-className="u-has-success"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          for="productTitle"
                          className="col-sm-12 font-weight-bold small"
                        >
                          Phone
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            className="form-control-sm form-control"
                            name="city"
                            value={phone}
                            placeholder="Phone"
                            onChange={(e) => setPhone(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Container>
      </Fragment>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    user: state.mainReducers.main.user,
    company: state.mainReducers.main.company,
    products: state.mainReducers.main.products,
    // countries: state.mainReducers.main.countries,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCompanyPage);
