import {
  Card,
  Media,
  CardBody,
  ListGroup,
  CardFooter,
  CardHeader,
  ListGroupItem,
} from "reactstrap";
import React from "react";
import { connect } from "react-redux";
import { countryObjects } from "../../../../../service/commondata";

const CountryObject = ({
  item,
  list,
  setHomeSections,
  homeSections,
  states,
}) => {
  const handleSetField = async (con) => {
    if (item.fieldType === "array") {
      setHomeSections({
        ...homeSections,
        [item.id]: [
          ...list,
          {
            name: states.countries.find((x) => x.code === con).name,
            _id: states.countries.find((x) => x.code === con)._id,
            code: con,
          },
        ],
      });
    }
  };

  return (
    <div>
      <Card>
        <CardHeader>
          <h5>{item.name}</h5>
        </CardHeader>
        <CardBody>
          {list && (
            <>
              {item.fieldType === "array" ? (
                <ListGroup>
                  {list.map((listItem, index) => (
                    <ListGroupItem className="d-flex justify-content-between align-items-center">
                      <Media
                        body
                        src={`https://www.countryflags.io/${listItem.code}/flat/64.png`}
                        alt=""
                      />
                      <span className="w-100">{listItem.name}</span>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() =>
                          setHomeSections({
                            ...homeSections,
                            [item.id]: list.filter(
                              (f) => f.code !== listItem.code
                            ),
                          })
                        }
                      >
                        x
                      </button>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              ) : (
                <div className="mb-1 col-lg-12"></div>
              )}
            </>
          )}
        </CardBody>
        <CardFooter>
          {(!list || list.length < item.length) && (
            <select
              className="form-select form-control-sm form-control mb-2"
              onChange={(e) => handleSetField(e.target.value)}
              value={null}
            >
              <option selected value={null}>
                Open this select menu
              </option>
              {states.countries
                .filter(
                  list
                    ? (it) => !list.find((l) => l.code === it.code)
                    : (it) => it
                )
                .map((country) => (
                  <option value={country.code}>{country.name}</option>
                ))}
            </select>
          )}
        </CardFooter>
      </Card>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    states: state.mainReducers.main,
  };
}

export default connect(mapStateToProps)(CountryObject);
