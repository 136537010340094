import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { bindActionCreators } from "redux";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import noImage from "../../../../assets/images/notFound.png";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import errorImg from "../../../../assets/images/search-not-found.png";
import { getComparator, stableSort } from "../../../../service/sort";
import {
  DeleteOrder,
  DeleteRequirements,
  GetAllRequirements,
} from "../../../../service/service";

const RequirementsPage = (props) => {
  const [page, setPage] = useState(1);
  const { company } = props;
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState("desc");
  const [requirements, setRequirements] = useState([]);
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [filteredRequirements, setFilteredRequirements] = useState(null);

  const fetchData = async () => {
    const response = await GetAllRequirements({ company: company._id });
    if (response && response.isSuccess) {
      setRequirements(response.data.requirements);
      requirements.map((requirement) => setFile(requirement.file));
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const confirmDelete = async (id) => {
    const response = await DeleteRequirements(id);
    console.log(
      "🚀 ~ file: RequirementsPage.jsx:54 ~ confirmDelete ~ response",
      response
    );
    if (response && response.status) {
      setRequirements(
        requirements.filter((val) => val._id !== response.data.requirement._id)
      );
    }
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this order!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Requirement deleted.", "success");
        });
      } else {
        SweetAlert.fire("Requirement is safe!");
      }
    });
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleSearch = async (searchKey) => {
    if (searchKey.length > 0) {
      setFilteredRequirements(
        requirements.filter(
          (requirement) =>
            requirement.productName
              .toLowerCase()
              .includes(searchKey.toLowerCase()) ||
            (requirement.productType &&
              requirement.productType.name
                .toLowerCase()
                .includes(searchKey.toLowerCase()))
        )
      );
    } else {
      setFilteredRequirements(null);
    }
  };

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "amountAsc": {
        setOrderBy("amount");
        setOrder("asc");
        break;
      }
      case "amountDesc": {
        setOrderBy("amount");
        setOrder("desc");
        break;
      }
      default:
        break;
    }
  };

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col sm="12">
              <Breadcrumbs title="Requirements" parent="Dashboard" />
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="10" sm="12">
                    <form className="form-inline search-form">
                      <div className="form-group w-100">
                        <input
                          type="search"
                          id="search"
                          className="form-control-plaintext"
                          placeholder="Search.."
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                  </Col>
                  <Col md="2" sm="12">
                    <div className="form-group w-100">
                      <select
                        className="form-control btn-square"
                        name="select"
                        onChange={(e) => handleRequestSort(e, e.target.value)}
                      >
                        <option value="createdAt" selected>
                          Latest
                        </option>
                        <option value="amountAsc">Price: low to high</option>
                        <option value="amountDesc">Price: high to low</option>
                      </select>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {(filteredRequirements ? filteredRequirements : requirements)
                  .length > 0 ? (
                  <div className="table-responsive">
                    <Table hover size="small">
                      <thead>
                        <tr>
                          <th className="border">Product Image</th>
                          <th className="border">Product</th>
                          <th className="border">Similar</th>
                          <th className="border">Qty</th>
                          <th className="border">Status</th>
                          <th className="border">Date</th>
                          <th className="border">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stableSort(
                          filteredRequirements
                            ? filteredRequirements
                            : requirements,
                          getComparator(order, orderBy)
                        )
                          .slice(page * 10 - 10, page * 10)
                          .map((requirement) => (
                            <tr key={requirement._id}>
                              <td className="border">
                                <img
                                  width="40px"
                                  alt="Description"
                                  src={
                                    requirement.file !== null
                                      ? config.fileServer +
                                        config.thumbnailContainer +
                                        requirement.file
                                      : noImage
                                  }
                                  className="img-fluid border border-color-1 rounded mx-auto d-block"
                                />
                              </td>
                              <td className="border">
                                {requirement.productName}
                              </td>
                              <td className="border">
                                {requirement.productType &&
                                  requirement.productType.name}
                              </td>
                              <td className="border">{requirement.quantity}</td>
                              <td className="border">
                                <span
                                  className={`p-1 m-0 text-uppercase font-weight-bold badge ${
                                    requirement.status === "pending" &&
                                    "badge-warning"
                                  } ${
                                    requirement.status === "active" &&
                                    "badge-success"
                                  } ${
                                    requirement.status === "disabled" &&
                                    "badge-dark"
                                  } ${
                                    requirement.status === "closed" &&
                                    "badge-danger"
                                  }`}
                                >
                                  {requirement.status}
                                </span>
                              </td>
                              <td className="border">
                                {requirement.createdAt.toString().slice(0, 10)}
                              </td>

                              <td className="border">
                                <div className="button-list">
                                  <Link
                                    to={`/dashboard/requirement-details/${requirement._id}`}
                                  >
                                    <button
                                      href="#"
                                      className="btn btn-text p-0"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          width: 35,
                                          fontSize: 16,
                                          padding: 11,
                                          color: "rgb(40, 167, 69)",
                                        }}
                                      ></i>
                                    </button>
                                  </Link>
                                  <button
                                    onClick={(e) =>
                                      handleDelete(requirement._id)
                                    }
                                    className="btn btn-text p-0"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        width: 35,
                                        fontSize: 16,
                                        padding: 11,
                                        color: "#e4566e",
                                      }}
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        {requirements.length > 10 && (
                          <tr>
                            <td colSpan="6" align="right">
                              <Pagination
                                count={parseInt(
                                  filteredRequirements
                                    ? (
                                        filteredRequirements.length / 10
                                      ).toFixed(0)
                                    : (requirements.length / 10).toFixed(0)
                                )}
                                page={page}
                                onChange={handlePageChange}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="search-not-found text-center">
                    <div>
                      <img
                        className="img-fluid second-search"
                        src={errorImg}
                        alt=""
                      />
                      <p>Sorry, We didn't find any products</p>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RequirementsPage);
