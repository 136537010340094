import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { operations } from "../../../../redux/Main";

function VendorsPageOne() {
  return (
    <div>
      <h1>vendores</h1>
    </div>
  );
}

function mapStateToProps(state, props) {
  return {
    categories: state.mainReducers.main.categories,
    company: state.mainReducers.main.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorsPageOne);
