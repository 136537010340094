import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import operations from "../../../../redux/Main/operations";
import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Container } from "reactstrap";
import moment from "moment";
import SweetAlert from "sweetalert2";
import Breadcrumbs from "../../Components/Breadcrumbs";
import errorImg from "../../../../assets/images/search-not-found.png";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { DeleteBlog, GetAllBlogs } from "../../../../service/service";

const BlogPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  // console.log(props.siteSettings);
  const siteSettings = props.siteSettings;

  const getData = async () => {
    setLoading(true);

    const response = await GetAllBlogs();
    if (response && response.isSuccess) {
      setBlogs(response.data.blogs);
    } else {
      setBlogs([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(blogs);

  const confirmDelete = async (id) => {
    const response = await DeleteBlog(id);
    if (response && response.isSuccess) {
      setBlogs(blogs.filter((blog) => blog._id !== response.data.blog._id));
    }
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Blog!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      console.log(result);
      if (result.value) {
        confirmDelete(id);
      } else {
        SweetAlert.fire("Blog is safe!");
      }
    });
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="Blogs" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12">
              <Link
                to="/dashboard/add-blog"
                className="btn btn-primary btn-block"
              >
                <i className="fa fa-plus mr-1"></i>
                <span className="text">Add Blog</span>
              </Link>
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              {loading ? (
                <ComponentLoader height="80vh" />
              ) : (
                <CardBody>
                  {blogs.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-hover table-md">
                        <thead>
                          <tr>
                            <th className="border">Title</th>
                            <th className="border">URL</th>
                            <th className="border">Date</th>
                            <th className="border">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {blogs.map((blog) => (
                            <tr key={blog._id}>
                              <td className="border">{blog.title}</td>
                              <td
                                className="border"
                                style={{ cursor: "pointer" }}
                              >{`${
                                siteSettings.buyerAppURL
                                  ? siteSettings.buyerAppURL
                                  : `/`
                              }/blog-details/${blog.slug}`}</td>

                              <td className="border">
                                <p>
                                  {moment(blog.createdAt).format("D MMM YYYY")}
                                </p>
                              </td>
                              <td className="border">
                                <div className="button-list">
                                  <Link
                                    to={`/dashboard/update-blog/${blog.slug}`}
                                  >
                                    <button
                                      href="#"
                                      className="btn btn-text p-0"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          width: 35,
                                          fontSize: 16,
                                          padding: 11,
                                          color: "rgb(40, 167, 69)",
                                        }}
                                      ></i>
                                    </button>
                                  </Link>

                                  <button
                                    href="#"
                                    onClick={(e) => handleDelete(blog._id)}
                                    className="btn btn-text p-0"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        width: 35,
                                        fontSize: 16,
                                        padding: 11,
                                        color: "#e4566e",
                                      }}
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="search-not-found text-center">
                      <div>
                        <img
                          className="img-fluid second-search"
                          src={errorImg}
                          alt=""
                        />
                        <p>Sorry, We didn't find any Blog</p>
                      </div>
                    </div>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    vendors: state.mainReducers.main.vendors,
    categories: state.mainReducers.main.categories,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPage);
