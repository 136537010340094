import {
  Col,
  Row,
  Card,
  Table,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
import { Pagination } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import config from "../../../../service/api/config";
import { stableSort } from "../../../../service/sort";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { getComparator } from "../../../../service/sort";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import { GetAllStocksRequests } from "../../../../service/service";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import errorImg from "../../../../assets/images/search-not-found.png";

const RequestedStocksPage = ({ company }) => {
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [stockRequests, setStockRequests] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState(null);

  const fetchStockRequests = async () => {
    const response = await GetAllStocksRequests({ vendor: company._id });
    if (response && response.isSuccess) {
      setStockRequests(response.data.stockRequests);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchStockRequests();
  }, [company]);

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "name": {
        setOrderBy(property);
        setOrder("asc");
        break;
      }
      default:
        break;
    }
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleSearch = async (searchKey) => {
    if (searchKey.length > 0) {
      setFilteredProduct(
        stockRequests.filter((stockRequest) =>
          stockRequest.product.name
            .toLowerCase()
            .includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilteredProduct(null);
    }
  };

  // const handleSort = () => {
  //   const sortBy = stockRequests.sort((stock1, stock2) => {
  //     if (stock1.product.name > stock2.product.name) return 1;
  //     if (stock2.product.name > stock1.product.name) return -1;

  //     if (stock1.createdAt > stock2.createdAt) return 1;
  //     if (stock2.createdAt > stock1.createdAt) return -1;
  //   });
  //   return sortBy;
  // };

  // handleSort();

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="requested stocks" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12"></Col>
          </Row>
        </div>
      </Container>

      <Row>
        <Col sm="12">
          <Card>
            <CardHeader>
              <Row>
                <Col md="10" sm="12" className="text-right">
                  <form className="form-inline search-form">
                    <div className="form-group w-100">
                      <input
                        id="search"
                        type="search"
                        placeholder="Search.."
                        className="form-control-plaintext"
                        onChange={(event) => {
                          handleSearch(event.target.value);
                        }}
                      />
                    </div>
                  </form>
                </Col>
                <Col md="2" sm="12" className="text-right">
                  <div className="form-inline">
                    <div className="form-group w-100">
                      <select
                        name="select"
                        className="form-control btn-square border"
                        onChange={(e) => handleRequestSort(e, e.target.value)}
                      >
                        <option value="createdAt">Latest</option>
                        <option value="name">Name</option>
                      </select>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
              {(filteredProduct ? filteredProduct : stockRequests).length >
              0 ? (
                <div className="table-responsive">
                  <Table hover size="small">
                    <thead>
                      <tr>
                        <th className="border">Photo</th>
                        <th className="border">Product Name</th>
                        <th className="border">Qty</th>
                        <th className="border">Created By</th>
                        <th className="border">Created At</th>
                      </tr>
                    </thead>

                    <tbody>
                      {stableSort(
                        filteredProduct ? filteredProduct : stockRequests,
                        getComparator(order, orderBy)
                      )
                        .slice(page * 5 - 5, page * 5)
                        .map((stockRequest) => {
                          const { _id, user, product, quantity, createdAt } =
                            stockRequest;

                          const photo = product.photos[0];
                          const url = photo.url;
                          const date = createdAt.toString().slice(0, 10);
                          const imgSrc =
                            photo &&
                            imageExists(
                              config.fileServer +
                                config.thumbnailContainer +
                                url
                            )
                              ? config.fileServer +
                                config.thumbnailContainer +
                                url
                              : noImage;
                          const productName =
                            product.name.substring(0, 50) + "...";

                          return (
                            <tr key={_id}>
                              <td className="border">
                                <img
                                  width="40px"
                                  className="img-fluid"
                                  alt="Product Logo"
                                  src={imgSrc}
                                />
                              </td>
                              <td className="border">{productName}</td>
                              <td className="border">{quantity}</td>
                              <td className="border">
                                {user &&
                                  `${user.userInfo.firstName} ${user.userInfo.lastName}`}
                              </td>
                              <td className="border">
                                {moment(createdAt).format("D MMM YYYY")}
                              </td>
                            </tr>
                          );
                        })}
                      {stockRequests.length > 5 && (
                        <tr>
                          <td colSpan="6" align="right">
                            <Pagination
                              count={
                                filteredProduct
                                  ? parseInt(
                                      (filteredProduct.length / 5 + 1).toFixed(
                                        0
                                      )
                                    )
                                  : parseInt(
                                      (stockRequests.length / 5 + 1).toFixed(0)
                                    )
                              }
                              page={page}
                              onChange={handlePageChange}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="search-not-found text-center">
                  <div>
                    <img
                      className="img-fluid second-search"
                      src={errorImg}
                      alt=""
                    />
                    <p>Sorry, We didn't find any stock requests</p>
                  </div>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = (state) => {
  return { company: state.mainReducers.main.company };
};

export default connect(mapStateToProps)(RequestedStocksPage);
