import React, { useState, useEffect } from "react";
import { getProductById } from "../../../../service/service";
import { Col, Card, CardBody, CardHeader } from "reactstrap";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const StockComponent = ({ vendorRequest }) => {
  const [stock, setStock] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const response = await getProductById(vendorRequest.docID);
    if (response && response.isSuccess) {
      setStock(response.data.product.stock);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const details = (value) => {
    switch (value) {
      case "updateStock":
        return (
          <>
            {loading ? (
              <ComponentLoader height="80vh" />
            ) : (
              <div className="table-responsive">
                <table className="table table-hover table-md text-center">
                  <thead>
                    <tr className="font-01 weight">
                      <th className="border">Field Name</th>
                      <th className="border">Original Value</th>
                      <th className="border">New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="border font-01 weight">Available Stock</th>
                      <td className="border">{stock.available}</td>
                      <td className="border">
                        {vendorRequest.requestData.available
                          ? vendorRequest.requestData.available
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Deserved Stock</th>
                      <td className="border">{stock.reserved}</td>
                      <td className="border">
                        {vendorRequest.requestData.reserved
                          ? vendorRequest.requestData.reserved
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Stock Type</th>
                      <td className="border">{stock.stockType}</td>
                      <td className="border">
                        {vendorRequest.requestData.stockType
                          ? vendorRequest.requestData.stockType
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Col sm="12">
        <Card>
          <CardHeader>
            <h6 className="m-0 font-weight-bold text-primary">
              {`Requested Data Details - ${vendorRequest.requestType}`}
            </h6>
          </CardHeader>
          <CardBody>{details(vendorRequest.requestType)}</CardBody>
        </Card>
      </Col>
    </>
  );
};

export default StockComponent;
