import CountUp from "react-countup";
import { Card, CardBody, Col, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import {
  GetUsersCount,
  GetProductsCount,
} from "../../../../../service/service";
import { connect } from "react-redux";

const UsersCountCard = (props) => {
  const [usersCount, setUsersCount] = useState(0);
  const [newProducts, setNewProducts] = useState(0);
  const [vendorsCount, setVendorsCount] = useState(0);
  const [newUsersCount, setNewUsersCount] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [newVendorsCount, setNewVendorsCount] = useState(0);

  const { siteSettings } = props;

  const calculateDate = (additionalDays) => {
    var date = new Date();

    Date.prototype.addDays = function (days) {
      date.setDate(date.getDate() + days);
      return date;
    };

    return date.addDays(additionalDays);
  };

  const getData = async () => {
    const totalUser = await GetUsersCount({ type: "user" });
    const newUser = await GetUsersCount({
      type: "user",
      dateRange: {
        min: calculateDate(-30),
        max: new Date(),
      },
    });

    if (newUser && newUser.isSuccess) {
      setNewUsersCount(newUser.data.usersCount);
    }
    if (totalUser && totalUser.isSuccess) {
      setUsersCount(totalUser.data.usersCount);
    }

    const totalVendor = await GetUsersCount({ type: "vendor" });
    const newVendors = await GetUsersCount({
      type: "vendor",
      dateRange: {
        min: calculateDate(-30),
        max: new Date(),
      },
    });

    const totalProducts = await GetProductsCount();
    const newProduct = await GetProductsCount({
      dateRange: {
        min: calculateDate(-30),
        max: new Date(),
      },
    });

    console.log("newProduct", newProduct);

    if (newVendors && newVendors.isSuccess) {
      setNewVendorsCount(newVendors.data.usersCount);
    }
    if (totalVendor && totalVendor.isSuccess) {
      setVendorsCount(totalVendor.data.usersCount);
    }

    if (totalProducts && totalProducts.isSuccess) {
      setTotalProducts(totalProducts.data.productsCount);
    }

    if (newProduct && newProduct.isSuccess) {
      setNewProducts(newProduct.data.productsCount);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Row>
        {
          !siteSettings.features.portfolioOnly && (
            <Col sm="12" md="6" lg="6">
              <Card className="border">
                <CardBody>
                  <div className="media">
                    <div className="align-self-center mr-3 text-left">
                      <h6 className="mb-1">New Users</h6>
                      <h4 className="mb-0">Last 30 Days</h4>
                    </div>
                    <div className="media-body align-self-center">
                      {newUsersCount ? (
                        <i className="fa fa-long-arrow-up fa-3x font-info"></i>
                      ) : (
                        <i className="fa fa-long-arrow-down fa-3x font-danger"></i>
                      )}
                    </div>

                    <div className="media-body">
                      <h5 className="mt-2">
                        <span className="counter font">
                          <CountUp end={newUsersCount} />
                        </span>
                      </h5>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )
        }

        {!siteSettings.features.portfolioOnly &&
        (<Col sm="12" md="6" lg="6">
          <Card className="border">
            <CardBody>
              <div className="media">
                <div className="align-self-center mr-3 text-left">
                  <h6 className="mb-1">Total Users</h6>
                  <h4 className="mb-0">Overall</h4>
                </div>
                <div className="media-body align-self-center">
                  {usersCount ? (
                    <i className="fa fa-long-arrow-up fa-3x font-info"></i>
                  ) : (
                    <i className="fa fa-long-arrow-down fa-3x font-danger"></i>
                  )}
                </div>
                <div className="media-body">
                  <h5 className="mt-2">
                    <span className="counter font">
                      <CountUp end={usersCount} />
                    </span>
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>)}

        {!siteSettings.features.portfolioOnly &&
        (<Col sm="12" md="6" lg="6">
          <Card className="border">
            <CardBody>
              <div className="media">
                <div className="align-self-center mr-3 text-left">
                  <h6 className="mb-1">New Vendors</h6>
                  <h4 className="mb-0">Last 30 Days</h4>
                </div>
                <div className="media-body align-self-center">
                  {newVendorsCount ? (
                    <i className="fa fa-long-arrow-up fa-3x font-info"></i>
                  ) : (
                    <i className="fa fa-long-arrow-down fa-3x font-danger"></i>
                  )}
                </div>
                <div className="media-body">
                  <h5 className="mt-2">
                    <span className="counter font">
                      <CountUp end={newVendorsCount} />
                    </span>
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>)}

        {!siteSettings.features.portfolioOnly && 
        (<Col sm="12" md="6" lg="6">
          <Card className="border">
            <CardBody>
              <div className="media">
                <div className="align-self-center mr-3 text-left">
                  <h6 className="mb-1">Total Vendors</h6>
                  <h4 className="mb-0">Overall</h4>
                </div>
                <div className="media-body align-self-center">
                  {vendorsCount ? (
                    <i className="fa fa-long-arrow-up fa-3x font-info"></i>
                  ) : (
                    <i className="fa fa-long-arrow-down fa-3x font-danger"></i>
                  )}
                </div>
                <div className="media-body">
                  <h5 className="mt-2">
                    <span className="counter font">
                      <CountUp end={vendorsCount} />
                    </span>
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>)}

        <Col sm="12" md="6" lg="6">
          <Card className="border">
            <CardBody>
              <div className="media">
                <div className="align-self-center mr-3 text-left">
                  <h6 className="mb-1">New Products</h6>
                  <h4 className="mb-0">Last 30 Days</h4>
                </div>
                <div className="media-body align-self-center">
                  {newProducts ? (
                    <i className="fa fa-long-arrow-up fa-3x font-info"></i>
                  ) : (
                    <i className="fa fa-long-arrow-down fa-3x font-danger"></i>
                  )}
                </div>
                <div className="media-body">
                  <h5 className="mt-2">
                    <span className="counter font">
                      <CountUp end={newProducts} />
                    </span>
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col sm="12" md="6" lg="6">
          <Card className="border">
            <CardBody>
              <div className="media">
                <div className="align-self-center mr-3 text-left">
                  <h6 className="mb-1">Total Products</h6>
                  <h4 className="mb-0">Overall</h4>
                </div>
                <div className="media-body align-self-center">
                  {totalProducts ? (
                    <i className="fa fa-long-arrow-up fa-3x font-info"></i>
                  ) : (
                    <i className="fa fa-long-arrow-down fa-3x font-danger"></i>
                  )}
                </div>
                <div className="media-body">
                  <h5 className="mt-2">
                    <span className="counter font">
                      <CountUp end={totalProducts} />
                    </span>
                  </h5>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

export default connect(mapStateToProps)(UsersCountCard);
