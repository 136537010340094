import {
  AddProduct,
  DeletePhoto,
  UploadPhoto,
  GetAllAttribute,
  AddNewVendorRequest,
  GetAllShipping,
} from "../../../../service/service";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import React, { useEffect, useState } from "react";
import config from "../../../../service/api/config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Container,
  Row,
  Col,
  Button,
  Badge,
  Table,
  Card,
  Collapse,
} from "reactstrap";
import { currencies, productUnits } from "../../../../service/commondata";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useNavigate } from "react-router-dom";

const AddProductPage = (props) => {
  let navigate = useNavigate();
  const { products, vendors, company, siteSettings, categories } = props;
    const [sku, setSku] = useState("");
  const [tags, setTags] = useState([]);
  const [name, setName] = useState("");
  const [error, setError] = useState({});
  const [price, setPrice] = useState(null);
  const [vendor, setVendor] = useState("");
  const [unit, setUnit] = useState("Units");
  const [content, setContent] = useState("");
  const [forSale, setForSale] = useState(true);
  const [available, setAvailable] = useState("");
  const [category, setCategory] = useState(null);
  const [activeStatus, setActiveStatus] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadedARThumbnail, setUploadedARThumbnail] = useState({});
  const [uploadedARImage, setUploadedARImage] = useState({});
  const [arContents, setARContents] = useState([]);
  const [currencyUnit, setCurrencyUnit] = useState("BDT");
  const [specifications, setSpecifications] = useState([]);
  const [stockType, setStockType] = useState("unlimited");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [shortDescription, setShortDescription] = useState("");
  const [minOrderQuantity, setMinOrderQuantity] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [productVariants, setProductVariants] = useState([]);
  const [rootAttributes, setRootAttributes] = useState([]);
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [leafAttributes, setLeafAttributes] = useState([]);
  const [collapsedIndex, setCollapsedIndex] = useState(-1);
  const [video, setVideo] = useState("");
  const [shippings, setShippings] = useState([]);
  const [regularShipping, setRegularShipping] = useState("");
  const [expressShipping, setExpressShipping] = useState("");
  const [offeredPrice, setOfferedPrice] = useState(0);

  const handleUploadPhoto = async ({ meta, file }, status, allFiles) => {
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        if (response && response.isSuccess) {
          setUploadedFiles((uploadedFiles) => [
            ...uploadedFiles,
            {
              url: response.data.fileName,
              id: response.data.fileName,
              name: "",
            },
          ]);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handlePhotoDelete = async (id) => {
    DeletePhoto({
      fileName: id,
      container: "ivcommerceoriginalcontainer",
    });
    setUploadedFiles(uploadedFiles.filter((f) => f.id !== id));
  };

  const handleUploadARThumbnail = async ({ meta, file }, status, allFiles) => {
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        if (response && response.isSuccess) {
          setUploadedARThumbnail({
            url: response.data.fileName,
            id: response.data.fileName,
          });
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleUploadARImage = async ({ meta, file }, status, allFiles) => {
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        if (response && response.isSuccess) {
          setUploadedARImage({
            url: response.data.fileName,
            id: response.data.fileName,
          });
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleAddAR = (event) => {
    event.preventDefault();
    if (uploadedARImage.url && uploadedARThumbnail.url) {
      setARContents([
        ...arContents,
        {
          image_url: uploadedARImage.url,
          image_id: uploadedARImage.id,
          thumbnail_url: uploadedARThumbnail.url,
          thumbnail_id: uploadedARThumbnail.id,
        },
      ]);
      setUploadedARImage({});
      setUploadedARThumbnail({});
    }
  };

  const handleARContentDelete = async (item) => {
    DeletePhoto({
      fileName: item.image_id,
      container: "ivcommerceoriginalcontainer",
    });
    DeletePhoto({
      fileName: item.thumbnail_id,
      container: "ivcommerceoriginalcontainer",
    });
    setARContents(arContents.filter((f) => f.image_id !== item.image_id));
  };

  const handleAddTags = (event) => {
    event.preventDefault();
    setTags([...tags, event.target.tag.value]);
    event.target.tag.value = "";
  };

  const handleAddSpecs = (event) => {
    event.preventDefault();
    if (event.target.specTitle.value && event.target.specValue.value) {
      setSpecifications([
        ...specifications,
        {
          title: event.target.specTitle.value,
          value: event.target.specValue.value,
        },
      ]);
      event.target.specTitle.value = "";
      event.target.specValue.value = "";
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (
      !name ||
      !category ||
      !price ||
      !shortDescription ||
      !regularShipping ||
      !expressShipping ||
      (stockType === "limited" && !available)
    ) {
      setError({
        name: !name ? true : false,
        price: !price ? true : false,
        category: !category ? true : false,
        available: !available ? true : false,
        shortDescription: !shortDescription ? true : false,
        regularShipping: !regularShipping ? true : false,
        expressShipping: !expressShipping ? true : false,
      });
    } else {
      setButtonLoading(true);
      const data = {
        vendor: siteSettings?.vendor,
        sku: sku,
        name: name,
        shortDescription: shortDescription,
        description: content,
        vendor: siteSettings.isMultiVendor
          ? vendor
            ? vendor
            : company._id
          : company._id,
        company: props.company.isVendor
          ? props.company.parentCompany
          : props.company._id,
        unit: unit,
        tags: tags,
        price: price,
        photos: uploadedFiles,
        arContents: arContents,
        productVariants: productVariants,
        selectedAttributes: selectedAttributes,
        categories: selectedCategories,
        currencyUnit: siteSettings.features.multiCurrency
          ? currencyUnit
          : undefined,
        minOrderQuantity:
          siteSettings.features.b2b && company.isB2B ? minOrderQuantity : 1,
        category: category,
        specifications: specifications,
        activeStatus: activeStatus,
        forSale: forSale,
        available: available,
        stockType: stockType,
        video: video,
        regularShipping: regularShipping,
        expressShipping: expressShipping,
        offeredPrice: parseInt(offeredPrice),
      };

      if (
        !siteSettings.features.vendorAccessManagement ||
        !company.isVendor ||
        (company.permissionType &&
          company.permissionType.productManagement.addProduct === 3)
      ) {
        const response = await AddProduct(data);
        if (response && response.isSuccess) {
          props.setProducts([response.data.product, ...products]);
          toast.success("Product Saved!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          navigate("/dashboard/all-products");
        } else {
          toast.error("Please provide all required info", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      } else if (
        company.permissionType &&
        company.permissionType.productManagement.addProduct === 2
      ) {
        const response = await AddNewVendorRequest({
          vendor: company._id,
          requestType: "addProduct",
          requestData: data,
          collectionName: "Product",
        });
        if (response && response.isSuccess) {
          toast.success("Product Requested! Wait for Admin Approval", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          navigate("/dashboard/all-products");
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }
      setButtonLoading(false);
    }
  };

  const handleSelectCategory = async (ct) => {
    if (ct !== "-1") {
      const cat = categories.find((item) => {
        return item._id === ct;
      });
      setSelectedCategories([...selectedCategories, cat]);
      if (cat.isLeaf) {
        setCategory(cat);
      } else {
        setCategory(cat);
      }
    }
  };

  const handleUploadVariantPhoto = async ({ meta, file }, status, allFiles) => {
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        if (response && response.isSuccess) {
          let data = {
            url: response.data.fileName,
            id: response.data.fileName,
          };
          productVariants[collapsedIndex].photo = data;
          setProductVariants([...productVariants]);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleVariantPhotoDelete = async (id) => {
    DeletePhoto({
      fileName: id,
      container: "ivcommerceoriginalcontainer",
    });
    productVariants[collapsedIndex].photo = null;
    setProductVariants([...productVariants]);
  };

  const setAttributeValue = (e, index) => {
    let variants = productVariants;
    let attr = leafAttributes.find((attr) => attr._id === e.target.value);
    if (!attr) {
      variants[index].attributes = productVariants[index].attributes.filter(
        (val) => val.parentVariant._id !== e.target.value
      );
    } else {
      let attrInd = productVariants[index].attributes.findIndex(
        (val) => val.parentVariant._id === attr.parentVariant._id
      );
      if (attrInd >= 0) {
        variants[index].attributes[attrInd] = attr;
      } else {
        variants[index].attributes.push(attr);
      }
    }
    setProductVariants([...variants]);
  };

  const handleGetAttribute = async () => {
    const response = await GetAllAttribute();
    if (response && response.isSuccess) {
      setRootAttributes(response.data.variants.filter((attr) => attr.isRoot));
      setLeafAttributes(response.data.variants.filter((attr) => attr.isLeaf));
    }
  };

  const handleSetSelectedAttr = (e) => {
    let selectedAttr = selectedAttributes;
    if (selectedAttributes.find((attr) => attr._id === e.target.value)) {
      selectedAttr = selectedAttributes.filter(
        (attr) => attr._id !== e.target.value
      );
      productVariants.map((variant) => {
        variant.attributes = variant.attributes.filter(
          (attr) => attr.parentVariant._id !== e.target.value
        );
      });
      setProductVariants([...productVariants]);
    } else {
      let item = rootAttributes.find((attr) => attr._id === e.target.value);
      item.values = leafAttributes.filter(
        (attr) => attr.parentVariant._id === item._id
      );
      selectedAttr.push(item);
    }
    if (!selectedAttr.length) {
      setProductVariants([]);
    }
    setSelectedAttributes([...selectedAttr]);
  };

  const handleAddProductVariants = () => {
    const data = {
      attributes: [],
      photo: null,
      isRegularPrice: true,
      price: 0,
    };
    setCollapsedIndex(productVariants.length);
    setProductVariants([...productVariants, data]);
  };

  const handleVariantDelete = (index) => {
    setProductVariants(productVariants.filter((variant, ind) => ind !== index));
  };

  const handleVariantPrice = (index) => {
    productVariants[index].isRegularPrice =
      !productVariants[index].isRegularPrice;
    setProductVariants([...productVariants]);
  };

  const handleSetCustomPrice = (e, index) => {
    productVariants[index].price = e.target.value;
    setProductVariants([...productVariants]);
  };

  const getAllShipping = async () => {
    const allShipping = await GetAllShipping();
    console.log(
      "🚀 ~ file: AddProductPage.jsx:440 ~ getAllShipping ~ allShipping",
      allShipping
    );

    if (allShipping.isSuccess) {
      setShippings(allShipping.data.allShipping);
    }
    console.log(allShipping);
  };

  useEffect(() => {
    handleGetAttribute();
    getAllShipping();
  }, []);

  return (
    <div>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" sm="3">
              <Breadcrumbs title="Add Products" parent="Dashboard" />
            </Col>
            <Col lg="3" sm="3">
              {buttonLoading ? (
                <Button color="primary btn-block" disabled={buttonLoading}>
                  SAVING...
                </Button>
              ) : (
                <Button onClick={handleFormSubmit} color="primary btn-block">
                  <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                </Button>
              )}
            </Col>
          </Row>
        </div>
        <div className="contentbar">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Product Details
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-group row mb-1">
                    <label
                      htmlFor="productTitle"
                      className="col-sm-12 font-weight-bold small "
                    >
                      Title
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-12 mb-2">
                      <input
                        type="text"
                        className="form-control-sm form-control"
                        name="name"
                        placeholder="Name"
                        onChange={(e) => setName(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-2">
                    <label
                      htmlFor="productTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      SKU (Stock Keeping Unit)
                    </label>
                    <div className="col-sm-12 mb-2">
                      <input
                        name="sku"
                        type="text"
                        value={sku}
                        placeholder="Stock Keeping Unit"
                        onChange={(e) => setSku(e.target.value)}
                        className="form-control-sm form-control"
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-1">
                    <label
                      htmlFor="productTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Short Description (Please don't use any media file here
                      like: image,video etc)
                    </label>

                    {/* <div className="col-sm-12">
                      <textarea
                        className="form-control-sm form-control"
                        value={shortDescription}
                        onChange={(e) => setShortDescription(e.target.value)}
                        rows="4"
                        name="shortDescription"
                        placeholder="Short Description About Product"
                      />
                    </div> */}
                    <div className="col-sm-12 mb-2">
                      <div className="border h-100">
                        <CKEditor
                          editor={ClassicEditor}
                          data={shortDescription}
                          config={{
                            ckfinder: {
                              uploadUrl: config.serverURL + "file/editor-file",
                            },
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setShortDescription(editor.getData());
                            console.log({ event, editor, data });
                          }}
                          onFilesChange={(e) => console.log(e)}
                        />
                      </div>
                    </div>
                    <span className="text-danger">
                      {error.shortDescription &&
                        "short Description is required"}
                    </span>
                  </div>
                  <div className="form-group row">
                    <label className="col-sm-12 font-weight-bold small mb-1">
                      Full Description
                    </label>
                    <div className="col-sm-12">
                      <div className="border h-100">
                        <CKEditor
                          editor={ClassicEditor}
                          data={content}
                          config={{
                            ckfinder: {
                              uploadUrl: config.serverURL + "file/editor-file",
                            },
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setContent(editor.getData());
                            console.log({ event, editor, data });
                          }}
                          onFilesChange={(e) => console.log(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Product Pricing
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-group row mb-2">
                    <label
                      htmlFor="productTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Price
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-12">
                      <div className="d-flex">
                        <input
                          required
                          name="price"
                          type="number"
                          value={price}
                          placeholder="500"
                          data-error-className="u-has-error"
                          data-success-className="u-has-success"
                          onChange={(e) => setPrice(e.target.value)}
                          data-msg="Please enter a valid email address."
                          className="form-control-sm form-control font-20 flex-grow-1"
                        />
                        <select
                          className="form-select form-control-sm form-control ms-1 w-25"
                          onChange={(e) => setUnit(e.target.value)}
                          value={unit}
                        >
                          <option value="-1">Select Unit</option>
                          {productUnits.map((unit) => (
                            <option value={unit} key={unit}>
                              {unit}
                            </option>
                          ))}
                        </select>

                        {siteSettings.features.b2b && (
                          <select
                            className="form-select form-control-sm form-control ml-1 w-25"
                            onChange={(e) => setCurrencyUnit(e.target.value)}
                            value={currencyUnit}
                          >
                            <option value="-1">None</option>
                            {currencies.map((currency) => (
                              <option value={currency.code} key={currency.code}>
                                {currency.name} ({currency.code})
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                      <span className="text-danger">
                        {error.price && "Price is required"}
                      </span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="productTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Offer Price
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-12">
                      <div className="d-flex">
                        <input
                          required
                          name="price"
                          type="number"
                          value={offeredPrice}
                          placeholder="500"
                          data-error-className="u-has-error"
                          data-success-className="u-has-success"
                          onChange={(e) => setOfferedPrice(e.target.value)}
                          data-msg="Please enter a valid email address."
                          className="form-control-sm form-control font-20 flex-grow-1"
                        />

                        {siteSettings.features.b2b && (
                          <select
                            className="form-select form-control-sm form-control ml-1 w-25"
                            onChange={(e) => setCurrencyUnit(e.target.value)}
                            value={currencyUnit}
                          >
                            <option value="-1">None</option>
                            {currencies.map((currency) => (
                              <option value={currency.code} key={currency.code}>
                                {currency.name} ({currency.code})
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                      <span className="text-danger">
                        {error.price && "Price is required"}
                      </span>
                    </div>
                  </div>

                  {siteSettings.features.b2b && company.isB2B && (
                    <div className="form-group row">
                      <label
                        htmlFor="productTitle"
                        className="col-sm-12 font-weight-bold small"
                      >
                        MOQ
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="number"
                          name="minOrder"
                          placeholder="100/500"
                          onChange={(e) => setMinOrderQuantity(e.target.value)}
                          className="form-control-sm form-control font-20"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Product Images
                  </h6>
                  <ul>
                    <small>
                      <li>Please select all the images of same dimension.</li>
                      <li>Advised to keep your image size less than 250kb.</li>
                    </small>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="row">
                    {uploadedFiles.map((image, index) => (
                      <div className="col-wd-3 col-lg-4 mb-1" key={index}>
                        <img
                          src={
                            image &&
                            imageExists(
                              config.fileServer +
                                config.imageContainer +
                                image.url
                            )
                              ? config.fileServer +
                                config.imageContainer +
                                image.url
                              : noImage
                          }
                          alt="Product"
                          className="mb-2 border img-fluid"
                        />
                        <div className="d-flex">
                          <input
                            type="text"
                            className="form-control-sm form-control"
                            name="image"
                            defaultValue={image.name}
                            placeholder="Image Title"
                            onChange={(e) => {
                              let arr = uploadedFiles;
                              for (var i in arr) {
                                if (arr[i].id === image.id) {
                                  arr[i].name = e.target.value;
                                  break;
                                }
                              }
                              setUploadedFiles(arr);
                            }}
                          />{" "}
                          <button
                            type="button"
                            className="btn btn-icon-split btn-sm"
                            onClick={() => handlePhotoDelete(image.id)}
                          >
                            <FontAwesomeIcon icon={faTimes} size="xs" />
                          </button>
                        </div>
                      </div>
                    ))}
                    <div className="col-wd-3 col-lg-4 mb-1">
                      <Dropzone
                        onChangeStatus={handleUploadPhoto}
                        accept="image/*"
                        PreviewComponent={null}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    {" "}
                    Shipping
                  </h6>
                </div>
                <div className="card-body">
                  {shippings.length > 0 && (
                    <>
                      <label className="col-sm-12 font-weight-bold">
                        Regular Shipping
                      </label>

                      <select
                        className="form-control btn-square border"
                        onChange={(e) => setRegularShipping(e.target.value)}
                      >
                        <option value="">Select One</option>
                        {shippings.map((ship) => (
                          <option key={ship._id} value={ship._id}>
                            {ship.title}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">
                        {error.regularShipping &&
                          "Regular Shipping is required"}
                      </span>

                      <label className="col-sm-12 font-weight-bold mt-3">
                        Express Shipping
                      </label>

                      <select
                        className="form-control btn-square border"
                        onChange={(e) => setExpressShipping(e.target.value)}
                      >
                        <option value="">Select One</option>
                        {shippings.map((ship) => (
                          <option key={ship._id} value={ship._id}>
                            {ship.title}
                          </option>
                        ))}
                      </select>
                      <span className="text-danger">
                        {error.expressShipping &&
                          "Express Shipping is required"}
                      </span>
                    </>
                  )}
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Product Video Url
                  </h6>
                  <ul>
                    <small>
                      <li>Please add Youtube url here</li>
                    </small>
                  </ul>
                </div>
                <div className="col-sm-12 px-4">
                  <div className="form-group row mt-2 mb-4">
                    <label
                      htmlFor="productTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      URL
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        className="form-control-sm form-control"
                        name="url"
                        placeholder="Name"
                        onChange={(e) => setVideo(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {siteSettings.features?.augmentedReality && (
                <Card>
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Product Virtual Experience
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <Table className="table table-responsive-sm">
                        <thead>
                          <tr style={{ textAlign: "center" }}>
                            <th>Background Image</th>
                            <th>Thumbnail</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan="3">
                              {arContents && (
                                <DragDropContext>
                                  <Droppable droppableId="image">
                                    {(provided) => (
                                      <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                      >
                                        {arContents.map((item, index) => (
                                          <Draggable
                                            key={item.image_url}
                                            draggableId={item.image_url}
                                            index={index}
                                          >
                                            {(provided) => (
                                              <div
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                className="border border-color-1 p-2 m-2 bg-gray-1"
                                              >
                                                <td>
                                                  <div>
                                                    <img
                                                      src={
                                                        config.fileServer +
                                                          config.imageContainer +
                                                          item &&
                                                        imageExists(
                                                          config.fileServer +
                                                            config.imageContainer +
                                                            item.image_url
                                                        )
                                                          ? config.fileServer +
                                                            config.imageContainer +
                                                            item.image_url
                                                          : noImage
                                                      }
                                                      alt=""
                                                      className="img-fluid rounded mb-1"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <div>
                                                    <img
                                                      src={
                                                        config.fileServer +
                                                          config.imageContainer +
                                                          item &&
                                                        imageExists(
                                                          config.fileServer +
                                                            config.imageContainer +
                                                            item.thumbnail_url
                                                        )
                                                          ? config.fileServer +
                                                            config.imageContainer +
                                                            item.thumbnail_url
                                                          : noImage
                                                      }
                                                      alt=""
                                                      className="img-fluid rounded mb-1"
                                                    />
                                                  </div>
                                                </td>
                                                <td>
                                                  <button
                                                    type="button"
                                                    className="btn btn-danger btn-block btn-sm"
                                                    onClick={() =>
                                                      handleARContentDelete(
                                                        item
                                                      )
                                                    }
                                                  >
                                                    Delete
                                                  </button>
                                                </td>
                                              </div>
                                            )}
                                          </Draggable>
                                        ))}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="card-footer">
                    <div className="add-product-tags">
                      <form onSubmit={handleAddAR}>
                        <div className="row input-group input-group-lg">
                          <div className="col-md-3 col-lg-5 mb-1">
                            {!uploadedARImage.url ? (
                              <Dropzone
                                onChangeStatus={handleUploadARImage}
                                accept="image/*"
                                PreviewComponent={null}
                              />
                            ) : (
                              <img
                                src={
                                  config.fileServer +
                                    config.imageContainer +
                                    uploadedARImage &&
                                  imageExists(
                                    config.fileServer +
                                      config.imageContainer +
                                      uploadedARImage.url
                                  )
                                    ? config.fileServer +
                                      config.imageContainer +
                                      uploadedARImage.url
                                    : noImage
                                }
                                alt=""
                                className="img-fluid rounded mb-1"
                              />
                            )}
                          </div>
                          <div className="col-md-3 col-lg-5 mb-1">
                            {!uploadedARThumbnail.url ? (
                              <Dropzone
                                onChangeStatus={handleUploadARThumbnail}
                                accept="image/*"
                                PreviewComponent={null}
                              />
                            ) : (
                              <img
                                src={
                                  config.fileServer +
                                    config.imageContainer +
                                    uploadedARThumbnail &&
                                  imageExists(
                                    config.fileServer +
                                      config.imageContainer +
                                      uploadedARThumbnail.url
                                  )
                                    ? config.fileServer +
                                      config.imageContainer +
                                      uploadedARThumbnail.url
                                    : noImage
                                }
                                alt=""
                                className="img-fluid rounded mb-1"
                              />
                            )}
                          </div>
                          <div className="col-md-3 col-lg-2 input-group-append">
                            <button className="input-group-text" type="submit">
                              Add
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </Card>
              )}

              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Specifications
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table className="table table-responsive-sm">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Title</th>
                          <th>Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        {specifications.map((spec, index) => (
                          <tr key={spec._id}>
                            <td>
                              <button
                                type="button"
                                className="btn btn-icon-split btn-xs"
                                onClick={() =>
                                  setSpecifications(
                                    specifications.filter(
                                      (s) => s.title !== spec.title
                                    )
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faTimes} size="xs" />
                              </button>
                            </td>
                            <td>{spec.title}</td>
                            <td>{spec.value}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className="card-footer">
                  <div className="add-product-tags">
                    <form onSubmit={handleAddSpecs}>
                      <div className="input-group input-group-sm">
                        <input
                          type="text"
                          className="form-control-sm form-control"
                          placeholder="title"
                          name="specTitle"
                        />
                        <input
                          type="text"
                          className="form-control-sm form-control"
                          placeholder="value"
                          name="specValue"
                        />
                        <div className="input-group-append">
                          <button className="input-group-text" type="submit">
                            Add
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {!siteSettings.features.hasVariableProduct ? (
                ""
              ) : (
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Product Variants
                    </h6>
                  </div>
                  <div className="card-body">
                    <table
                      className="w-100"
                      style={{
                        borderCollapse: "separate",
                        borderSpacing: "0 0.5em",
                      }}
                    >
                      <tr>
                        <span className="text-danger">
                          {error.productVariants &&
                            "Product Variants is required"}
                        </span>
                        <label
                          htmlFor="productTitle"
                          className="col-sm-12 font-weight-bold small "
                        >
                          Select the attribute types for your variants.
                        </label>

                        {rootAttributes.map((attr, item) => (
                          <div
                            className="custom-control custom-checkbox pt-2"
                            style={{ paddingLeft: "40px", display: "flex" }}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`custom-checkbox-${attr.name}`}
                              checked={selectedAttributes.find(
                                (val) => val._id === attr._id
                              )}
                              value={attr._id}
                              onChange={handleSetSelectedAttr}
                            />
                            <label
                              className="custom-control-label ps-2"
                              htmlFor={`custom-checkbox-${attr.name}`}
                            >
                              {attr.name}
                            </label>
                          </div>
                        ))}
                      </tr>

                      {productVariants.map((variant, index) => (
                        <tr className="bg-light border col-md-12">
                          <div className="text-dark row px-3">
                            {selectedAttributes.map((attr, ind) => (
                              <td className="d-flex align-items-center mt-3 col-md-4">
                                <b>{attr.name}</b>
                                <select
                                  className="form-select form-control-sm form-control ms-2"
                                  onChange={(e) => setAttributeValue(e, index)}
                                >
                                  <option value={attr._id}>None</option>
                                  {attr.values.map((val) => (
                                    <option
                                      value={val._id}
                                      selected={
                                        variant.attributes[ind]
                                          ? val._id ===
                                            variant.attributes[ind]._id
                                          : false
                                      }
                                    >
                                      {val.name}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            ))}
                            <td>
                              <button
                                className="btn btn-text p-0 btn-lg"
                                onClick={(e) => setCollapsedIndex(index)}
                                type="button"
                              >
                                <i
                                  className="fa fa-pencil"
                                  style={{
                                    width: 35,
                                    fontSize: 16,
                                    padding: 11,
                                    color: "rgb(40, 167, 69)",
                                  }}
                                ></i>
                              </button>
                              <button
                                className="btn btn-text p-0 btn-lg"
                                onClick={(e) => handleVariantDelete(index)}
                                type="button"
                              >
                                <i
                                  className="fa fa-trash"
                                  style={{
                                    width: 35,
                                    fontSize: 16,
                                    padding: 11,
                                    color: "#e4566e",
                                  }}
                                ></i>
                              </button>
                            </td>
                          </div>

                          <Collapse isOpen={collapsedIndex === index}>
                            <div
                              className="row my-3"
                              style={{ paddingLeft: "30px" }}
                            >
                              <div className="col-md-6 custom-control custom-checkbox text-dark d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  className="custom-control-input me-2"
                                  id={`custom-checkbox-regular-price`}
                                  checked={variant.isRegularPrice}
                                  onChange={(e) =>
                                    handleVariantPrice(collapsedIndex)
                                  }
                                />
                                <label
                                  className="col-sm-12 custom-control-label"
                                  htmlFor={`custom-checkbox-regular-price`}
                                >
                                  Regular Price
                                </label>
                              </div>
                              <div className="col-md-6 custom-control custom-checkbox text-dark d-flex align-items-center">
                                <input
                                  type="checkbox"
                                  className="custom-control-input me-2"
                                  id={`custom-checkbox-custom-price`}
                                  checked={!variant.isRegularPrice}
                                  onChange={(e) =>
                                    handleVariantPrice(collapsedIndex)
                                  }
                                />
                                <label
                                  className="col-sm-12 custom-control-label"
                                  htmlFor={`custom-checkbox-custom-price`}
                                >
                                  Custom Price
                                </label>
                              </div>
                            </div>
                            <Collapse isOpen={!variant.isRegularPrice}>
                              <div className="px-3 text-dark">
                                <label>Custom Price</label>
                                <input
                                  name="custom-price"
                                  id="custom-price"
                                  type="number"
                                  placeholder="500"
                                  className="form-control"
                                  min={0}
                                  required={!variant.isRegularPrice}
                                  onChange={(e) =>
                                    handleSetCustomPrice(e, collapsedIndex)
                                  }
                                />
                              </div>
                            </Collapse>
                            <div className="form-group row text-dark mt-3 px-3">
                              <label
                                htmlFor="variantImage"
                                className="col-sm-12"
                              >
                                Add Variant Image
                              </label>
                              <div className="col-sm-12">
                                <div className="col-wd-8 col-lg-8 mb-1">
                                  {variant.photo ? (
                                    <div className="d-flex">
                                      <div>
                                        <img
                                          src={
                                            variant.photo &&
                                            imageExists(
                                              config.fileServer +
                                                config.imageContainer +
                                                variant.photo.url
                                            )
                                              ? config.fileServer +
                                                config.imageContainer +
                                                variant.photo.url
                                              : noImage
                                          }
                                          alt="Product"
                                          className="mb-2 border img-fluid"
                                        />
                                      </div>
                                      <button
                                        type="button"
                                        className="btn "
                                        onClick={() =>
                                          handleVariantPhotoDelete(
                                            variant.photo.id
                                          )
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faTimes}
                                          size="xs"
                                        />
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="col-wd-8 col-lg-8 mb-1">
                                      <Dropzone
                                        onChangeStatus={
                                          handleUploadVariantPhoto
                                        }
                                        accept="image/*"
                                        PreviewComponent={null}
                                      />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Collapse>
                        </tr>
                      ))}

                      <Collapse
                        isOpen={selectedAttributes.length}
                        className="row mt-3"
                      >
                        <tr className="col-md-12">
                          <td className="float-right">
                            <button
                              className="btn btn-primary"
                              onClick={handleAddProductVariants}
                            >
                              Add New Variant
                            </button>
                          </td>
                        </tr>
                      </Collapse>
                    </table>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-4 col-xl-3">
              {company?.isMultiVendor && (
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Vendor
                    </h6>
                  </div>
                  <div className="card-body">
                    <select
                      className="form-select form-control-sm form-control mb-2"
                      onChange={(e) => setVendor(e.target.value)}
                      value={vendor}
                    >
                      <option value={company._id}>
                        {company.name} - Own Product
                      </option>
                      {vendors.map((vendor) => (
                        <option value={vendor._id} key={vendor._id}>
                          {vendor.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Status</h6>
                </div>
                <div className="card-body">
                  <div className="form-group">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="active"
                        checked={activeStatus}
                        onChange={() => setActiveStatus(!activeStatus)}
                      />
                      <label className="custom-control-label" htmlFor="active">
                        Active Product
                      </label>
                    </div>
                  </div>
                  {!siteSettings.features.portfolioOnly && (
                    <div className="form-group">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="forSale"
                          checked={forSale}
                          onChange={() => setForSale(!forSale)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="forSale"
                        >
                          For Sale
                        </label>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Categories
                  </h6>
                </div>
                <div className="card-body">
                  {selectedCategories.map((cat, index) => (
                    <Badge
                      key={index}
                      color={
                        cat.isRoot ? "primary" : cat.isLeaf ? "info" : "light"
                      }
                      className="mb-1 w-100"
                    >
                      <span className="flex-grow-1">{cat.name}</span>

                      <button
                        type="button"
                        disabled={index !== selectedCategories.length - 1}
                        className="btn btn-icon-split btn-xs"
                        onClick={() =>
                          setSelectedCategories(
                            selectedCategories.filter((t) => t._id !== cat._id)
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faTimes} size="xs" />
                      </button>
                    </Badge>
                  ))}

                  {selectedCategories.length === 0 && (
                    <>
                      <label className="col-sm-12 font-weight-bold small">
                        Root Category
                      </label>
                      <select
                        className="form-select form-control-sm form-control mb-2"
                        onChange={(e) => handleSelectCategory(e.target.value)}
                        value="-1"
                      >
                        <option value="-1">Open this select menu</option>
                        {categories
                          .filter((it) => it.isRoot)
                          .map((category) => (
                            <option value={category._id} key={category._id}>
                              {category.name}
                            </option>
                          ))}
                      </select>
                    </>
                  )}

                  {selectedCategories.length > 0 &&
                    !selectedCategories[selectedCategories.length - 1]
                      .isLeaf && (
                      <>
                        <label className="col-sm-12 font-weight-bold small">
                          Sub Category
                        </label>
                        <select
                          className="form-select form-control-sm form-control mb-2"
                          onChange={(e) => handleSelectCategory(e.target.value)}
                          value="-1"
                        >
                          <option value="-1">Open this select menu</option>
                          {categories
                            .filter(
                              (it) =>
                                it?.parentCategory &&
                                it?.parentCategory?._id ===
                                  selectedCategories[
                                    selectedCategories?.length - 1
                                  ]?._id
                            )
                            .map((category) => (
                              <option value={category?._id} key={category?._id}>
                                {category?.name}
                              </option>
                            ))}
                        </select>
                      </>
                    )}
                  <span className="text-danger">
                    {error.category && "Category is required"}
                  </span>
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Stock</h6>
                </div>
                <div className="card-body">
                  <label className="col-sm-12 font-weight-bold small">
                    Stock Type
                  </label>
                  <select
                    className="form-select form-control-sm form-control mb-2"
                    onChange={(e) => setStockType(e.target.value)}
                    value={stockType}
                  >
                    <option>Open this select menu</option>
                    <option value="limited">Limited</option>
                    <option value="unlimited">Unlimited</option>
                  </select>

                  {stockType === "limited" && (
                    <div className="form-group row">
                      <label
                        htmlFor="productTitle"
                        className="col-sm-12 font-weight-bold small"
                      >
                        Available on Stock
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="number"
                          name="available"
                          placeholder="500"
                          value={available}
                          className="form-control-sm form-control font-20"
                          onChange={(e) => setAvailable(e.target.value)}
                        />
                      </div>
                      <span className="text-danger">
                        {error.available && "Insert Stock"}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">Tags</h6>
                </div>
                <div className="card-body">
                  <div className="product-tags">
                    {tags.map((tag, index) => (
                      <Badge color="light" className="mb-1" key={index}>
                        {tag}
                        <button
                          type="button"
                          className="btn btn-icon-split btn-xs"
                          onClick={() => setTags(tags.filter((t) => t !== tag))}
                        >
                          <FontAwesomeIcon icon={faTimes} size="xs" />
                        </button>
                      </Badge>
                    ))}
                  </div>
                </div>
                <div className="card-footer">
                  <div className="add-product-tags">
                    <form onSubmit={handleAddTags}>
                      <div className="input-group input-group-sm">
                        <input
                          type="text"
                          className="form-control-sm form-control"
                          placeholder="Add Tags"
                          name="tag"
                        />
                        <div className="input-group-append">
                          <button className="input-group-text" type="submit">
                            Add
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    products: state.mainReducers.main.products,
    categories: state.mainReducers.main.categories,
    subCategories: state.mainReducers.main.subCategories,
    siteSettings: state.mainReducers.main.siteSettings,
    vendors: state.mainReducers.main.vendors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProductPage);
