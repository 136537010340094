import {
  DeletePhoto,
  createEvent,
  UploadPhoto,
  GetAllCategory,
} from "../../../../service/service";
import { Badge } from "reactstrap";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import InputRange from "react-input-range";
import Dropzone from "react-dropzone-uploader";
import React, { useState, useEffect } from "react";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Row, Col, Button, Container } from "reactstrap";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";

const AddEventPage = ({ history, vendors }) => {
  const initialslider = {
    value: 10,
    nagative: -10,
    foramtedLabel: 10,
    disabled: 10,
    foramtedLabelKg: 5,
    draggable: {
      min: 10,
      max: 20,
    },
  };
  const [title, setTitle] = useState("");
  const [error, setError] = useState({});
  const [status, setStatus] = useState("");
  const [banners, setBanners] = useState([]);
  const [expiresAt, setExpiresAt] = useState("");
  const [startedAt, setStartedAt] = useState("");
  const [categories, setCategories] = useState([]);
  const [values, setValues] = useState(initialslider);
  const [btnLoading, setBtnLoading] = useState(false);
  const [participation, setParticipation] = useState("");
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const handleUploadBanner = async ({ meta, file }, status, allFiles) => {
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        if (response && response.isSuccess) {
          setBanners((banners) => [
            ...banners,
            {
              name: "",
              id: response.data.fileName,
              url: response.data.fileName,
            },
          ]);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handlePhotoDelete = async (id) => {
    await DeletePhoto({
      fileName: id,
      container: "ivcommerceoriginalcontainer",
    });
    setBanners(banners.filter((f) => f.id !== id));
  };

  const fetchData = async () => {
    const response = await GetAllCategory();
    if (response && response.isSuccess) {
      setCategories(response.data.categories);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectCategory = async (ct) => {
    if (ct !== "-1") {
      const cat = categories.find((item) => item._id === ct);
      setSelectedCategories([...selectedCategories, cat]);
    }
  };

  const handleSelectVendors = async (ct) => {
    if (ct !== "-1") {
      const cat = vendors.find((item) => item._id === ct);
      setSelectedVendors([...selectedVendors, cat]);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!title || !status || !startedAt || !expiresAt || !participation) {
      setError({
        title: !title ? true : false,
        status: !status ? true : false,
        startedAt: !startedAt ? true : false,
        expiresAt: !expiresAt ? true : false,
        participation: !participation ? true : false,
      });
    } else {
      setBtnLoading(true);
      const data = {
        title: title,
        status: status,
        banners: banners,
        startedAt: startedAt,
        expiresAt: expiresAt,
        participation: participation,
        discountRange: values.draggable,
        selectedCategories:
          participation === "private"
            ? selectedCategories.length > 0
              ? selectedCategories
              : null
            : null,
        selectedVendors:
          participation === "private"
            ? selectedVendors.length > 0
              ? selectedVendors
              : null
            : null,
      };

      if (participation === "private") {
        if (data.selectedCategories === null && data.selectedVendors === null) {
          setBtnLoading(false);
          setError({
            field:
              data.selectedCategories === null && data.selectedVendors === null
                ? true
                : false,
          });
        } else if (data.selectedCategories || data.selectedVendors) {
          const response = await createEvent(data);
          setBtnLoading(false);
          if (response && response.isSuccess) {
            setBtnLoading(false);
            toast.success("Event CREATED !!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            history.push("/dashboard/all-events");
          }
        }
      } else if (participation === "public") {
        const response = await createEvent(data);
        setBtnLoading(false);
        if (response && response.isSuccess) {
          setBtnLoading(false);
          toast.success("Event CREATED !!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          history.push("/dashboard/all-events");
        }
      }
    }
  };

  return (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" sm="3">
              <Breadcrumbs title="Add Events" parent="Dashboard" />
            </Col>
            <Col lg="3" sm="3">
              {btnLoading ? (
                <Button color="primary btn-block" disabled={btnLoading}>
                  SAVING...
                </Button>
              ) : (
                <Button onClick={handleFormSubmit} color="primary btn-block">
                  <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Container>
      <div className="contentbar">
        <div className="row">
          <div className="col-lg-8 col-xl-9">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Event Details
                </h6>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <label
                    htmlFor="eventTitle"
                    className="col-sm-12 font-weight-bold small"
                  >
                    Event Title
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="name"
                      value={title}
                      placeholder="Enter Event Title"
                      className="form-control-sm form-control"
                      onChange={(e) => setTitle(e.target.value)}
                    />
                    <span className="text-danger font-weight-bold">
                      {error.title && "Event title is required"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Event Banner
                </h6>
              </div>
              <div className="card-body">
                <div className="row">
                  {banners.map((image, index) => (
                    <div className="col-wd-3 col-lg-3 mb-1" key={index}>
                      <div className="d-flex">
                        <img
                          src={
                            image &&
                            imageExists(
                              config.fileServer +
                                config.imageContainer +
                                image.url
                            )
                              ? config.fileServer +
                                config.imageContainer +
                                image.url
                              : noImage
                          }
                          alt="Product"
                          height="200px"
                          className="mb-2 border"
                        />
                      </div>
                      <div className="d-flex">
                        <input
                          type="text"
                          className="form-control-sm form-control"
                          name="image"
                          defaultValue={image.link}
                          placeholder="Image Link"
                          onChange={(e) => {
                            let arr = banners;
                            for (var i in arr) {
                              if (arr[i].id === image.id) {
                                arr[i].link = e.target.value;
                                break;
                              }
                            }
                            setBanners(arr);
                          }}
                        />{" "}
                        <button
                          type="button"
                          className="btn btn-icon-split btn-sm"
                          onClick={() => handlePhotoDelete(image.id)}
                        >
                          <FontAwesomeIcon icon={faTimes} size="xs" />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-wd-3 col-lg-4 mb-1">
                  <Dropzone
                    accept="image/*"
                    PreviewComponent={null}
                    onChangeStatus={handleUploadBanner}
                  />
                </div>
              </div>
            </div>
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Discount Range
                </h6>
              </div>
              <div className="card-body">
                <div className="theme-form form-label-align-right range-slider">
                  <div className="row mb-0">
                    <div className="col-md-12">
                      <InputRange
                        draggableTrack
                        minValue={0}
                        maxValue={100}
                        value={values.draggable}
                        onChange={(value) =>
                          setValues({ ...values, draggable: value })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-3">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Participation Status
                </h6>
              </div>
              <div className="card-body">
                <label className="font-weight-bold small">
                  Select Participation Status
                </label>
                <select
                  onChange={(e) => setParticipation(e.target.value)}
                  className="form-select form-control-sm form-control mb-2"
                >
                  <option>Open to select status</option>
                  <option value="public">Public</option>
                  <option value="private">Private</option>
                </select>
                <span className="text-danger font-weight-bold">
                  {error.participation &&
                    "Event participation status is required"}
                </span>
              </div>
            </div>
            {participation && participation === "private" && (
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Selected Categories
                  </h6>
                </div>
                <div className="card-body">
                  <label className="font-weight-bold small">
                    Select Event Categories
                  </label>
                  {selectedCategories &&
                    selectedCategories.map((cat, index) => (
                      <Badge
                        key={index}
                        color={"primary"}
                        className="mb-1 w-100"
                      >
                        <span className="flex-grow-1">{cat.name}</span>
                        <button
                          type="button"
                          className="btn btn-icon-split btn-xs"
                          onClick={() =>
                            setSelectedCategories(
                              selectedCategories.filter(
                                (t) => t._id !== cat._id
                              )
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} size="xs" />
                        </button>
                      </Badge>
                    ))}
                  {categories && (
                    <>
                      <select
                        value="-1"
                        className="form-select form-control-sm form-control mb-2"
                        onChange={(e) => handleSelectCategory(e.target.value)}
                      >
                        <option value="-1">Open this select menu</option>
                        {categories
                          .filter(
                            (item) =>
                              !selectedCategories.find(
                                (c) => c._id === item._id
                              )
                          )
                          .map((category) => (
                            <option value={category._id} key={category._id}>
                              {category.name}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger font-weight-bold">
                        {error.field && "Both fields can not be empty"}
                      </span>
                    </>
                  )}
                </div>
              </div>
            )}
            {participation && participation === "private" && (
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Selected Vendors
                  </h6>
                </div>
                <div className="card-body">
                  <label className="font-weight-bold small">
                    Select Event Vendors
                  </label>
                  {selectedVendors &&
                    selectedVendors.map((cat, index) => (
                      <Badge
                        key={index}
                        color={"primary"}
                        className="mb-1 w-100"
                      >
                        <span className="flex-grow-1">{cat.name}</span>
                        <button
                          type="button"
                          className="btn btn-icon-split btn-xs"
                          onClick={() =>
                            setSelectedVendors(
                              selectedVendors.filter((t) => t._id !== cat._id)
                            )
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} size="xs" />
                        </button>
                      </Badge>
                    ))}
                  {vendors && (
                    <>
                      <select
                        value="-1"
                        className="form-select form-control-sm form-control mb-2"
                        onChange={(e) => handleSelectVendors(e.target.value)}
                      >
                        <option value="-1">Open this select menu</option>
                        {vendors
                          .filter(
                            (item) =>
                              !selectedVendors.find((c) => c._id === item._id)
                          )
                          .map((vendor) => (
                            <option value={vendor._id} key={vendor._id}>
                              {vendor.name}
                            </option>
                          ))}
                      </select>
                      <span className="text-danger font-weight-bold">
                        {error.field && "Both fields can not be empty"}
                      </span>
                    </>
                  )}
                </div>
              </div>
            )}
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Other Info
                </h6>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <label
                    htmlFor="eventTitle"
                    className="col-sm-12 font-weight-bold small"
                  >
                    Event Start Time
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="date"
                      name="name"
                      value={startedAt}
                      placeholder="Enter Event Starting Time"
                      className="form-control-sm form-control"
                      onChange={(e) => setStartedAt(e.target.value)}
                    />
                    <span className="text-danger font-weight-bold">
                      {error.startedAt && "Event start date is required"}
                    </span>
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="eventTitle"
                    className="col-sm-12 font-weight-bold small"
                  >
                    Event End Time
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="date"
                      name="name"
                      value={expiresAt}
                      placeholder="Enter Event Ending time"
                      className="form-control-sm form-control"
                      onChange={(e) => setExpiresAt(e.target.value)}
                    />
                    <span className="text-danger font-weight-bold">
                      {error.expiresAt && "Event expire date is required"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Event Status
                </h6>
              </div>
              <div className="card-body">
                <label className="font-weight-bold small">
                  Select Event Status
                </label>
                <select
                  onChange={(e) => setStatus(e.target.value)}
                  className="form-select form-control-sm form-control mb-2"
                >
                  <option>Open to select status</option>
                  <option value="active">Active</option>
                  <option value="expired">Expired</option>
                  <option value="scheduled">Scheduled</option>
                </select>
                <span className="text-danger font-weight-bold">
                  {error.status && "Event status is required"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    vendors: state.mainReducers.main.vendors,
  };
}

export default connect(mapStateToProps)(AddEventPage);
