import React, { useState } from "react";

import SweetAlert from "sweetalert2";
import { DeletePromotion } from "../../../../../service/service";
import UpdatePromotionModal from "./UpdatePromotionModal";

const TableRow = ({ data, setUpdate, setDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const d = data;

  const confirmDelete = async (id) => {
    const response = await DeletePromotion(id);
    if (response && response.isSuccess) {
      setDelete(true);
    }
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Promotion!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Promotion deleted.", "success");
        });
      } else {
        SweetAlert.fire("Promotion is safe!");
      }
    });
  };

  const handleUpdateBrand = (e) => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <tr>
        <td className="border">
          <img
            width="250"
            alt="promotion img"
            src={d.promotionImage.url}
            className="img-fluid"
          />
        </td>
        <td className="border">{d.title}</td>

        <td className="border">{d.url}</td>
        <td className="border">{d.IsActive ? "✅" : "🚫"}</td>

        <td className="text-right border">
          <div className="button-list">
            <button onClick={handleUpdateBrand} className="btn btn-text p-0">
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                }}
              ></i>
            </button>
            <button
              onClick={() => {
                handleDelete(d._id);
              }}
              className="btn btn-text p-0"
            >
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#e4566e",
                }}
              ></i>
            </button>
          </div>
        </td>
      </tr>
      <UpdatePromotionModal
        data={data}
        open={isOpen}
        toggle={handleUpdateBrand}
        setUpdate={setUpdate}
      />
    </>
  );
};

export default TableRow;
