import { toast } from "react-toastify";
import { Col, Modal, ModalHeader, Row } from "reactstrap";
import React, { useState, useEffect } from "react";
import config from "../../../../service/api/config";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";

import { DialogContent, Step, StepLabel, Stepper } from "@material-ui/core";
import {
  addEventProduct,
  AddNewVendorRequest,
  GetAllProducts,
  getEventProducts,
} from "../../../../service/service";
import ComponentLoader from "../Loader/ComponentLoader";

const ProductModal = ({
  open,
  event,
  categories,
  handleClose,
  discountRange,
  selectedCategories,
  company,
  siteSettings,
  fetchDataUpdated,
}) => {
  console.log(siteSettings);

  const steps = ["Select Category & Product", "Add Product"];

  const [error, setError] = useState({});
  const [discount, setDiscount] = useState("");
  const [product, setProduct] = useState(null);
  const [category, setCategory] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [stockLimit, setStockLimit] = useState("");
  const [eventProduct, setEventProduct] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [productsCount, setProductsCount] = useState(0);

  const getData = async () => {
    setLoading(true);
    const data = {
      limit: 20,
      category: category ? category : null,
      searchKey: searchKey ? searchKey : null,
      vendor: company.isVendor ? company._id : null,
    };

    const productResponse = await GetAllProducts(data);

    console.log(productResponse);
    if (productResponse && productResponse.isSuccess) {
      setProducts(productResponse.data.products);
      setProductsCount(productResponse.data.productsCount);
    } else {
      setProducts([]);
      setProductsCount(0);
    }
    setLoading(false);
  };

  const fetchData = async () => {
    const response = await getEventProducts({
      event: event._id,
    });
    if (response && response.isSuccess) {
      setEventProduct(response.data.products);
    }
  };

  const loadMore = async () => {
    setButtonLoading(true);
    const data = {
      skip: products.length,
      limit: 20,

      category: category ? category : null,
      searchKey: searchKey ? searchKey : null,
      vendor: company.isVendor ? company._id : null,
    };

    const productResponse = await GetAllProducts(data);
    // console.log("products", products);
    if (productResponse && productResponse.status) {
      setProducts([...products, ...productResponse.data.products]);
      setProductsCount(productResponse.data.productsCount);
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    fetchData();
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [category]);

  const handleSelectCategory = async (ct) => {
    const cat = categories.find((item) => item._id === ct);
    setCategory(cat);
  };

  const handleSelectProduct = async (ct) => {
    const cat = products.find((item) => item._id === ct);
    setProduct(cat);
  };

  const handleNext = () => {
    if (product) {
      setActiveStep(activeStep + 1);
    } else {
      setError({
        product: !product ? true : false,
      });
    }
  };

  const handleBack = () => {
    setError({});
    setProduct(null);
    setCategory(null);
    setActiveStep(activeStep - 1);
  };

  const compareDiscount = () => {
    if (
      parseInt(discount) >= parseInt(discountRange.min) &&
      parseInt(discount) <= parseInt(discountRange.max)
    ) {
      return discount;
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!discount || !stockLimit) {
      setError({
        stockLimit: !stockLimit ? true : false,
        discount: !compareDiscount() ? true : false,
      });
    } else {
      const data = {
        event: event._id,
        product: product._id,
        discount: parseInt(discount),
        stockLimit: parseInt(stockLimit),
      };

      if (
        !siteSettings.features.vendorAccessManagement ||
        !company.isVendor ||
        (company.permissionType &&
          company.permissionType.eventManagement.addEventProduct === 3)
      ) {
        const response = await addEventProduct(data);
        console.log(
          "🚀 ~ file: ProductModal.jsx:164 ~ handleSubmit ~ response",
          response
        );

        if (!response?.isSuccess) {
          toast.error(response?.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });

          return;
        }
        if (response && response.isSuccess) {
          toast.success("Product Added In The Event !!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          handleClose();
          fetchDataUpdated();
        }
      } else if (
        company.permissionType &&
        company.permissionType.eventManagement.addEventProduct === 2
      ) {
        const response = await AddNewVendorRequest({
          vendor: company._id,
          requestType: "addEventProduct",
          requestData: data,
          collectionName: "EventProduct",
        });

        console.log(response);

        if (response && response.isSuccess) {
          toast.success("Product Requested! Wait for Admin Approval", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }

      handleClose();
      setActiveStep(0);
    }
  };

  const compare = (property) => {
    let sortOrder = 1;
    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      const result =
        a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    getData();
    // if (e.key === "Enter") {
    // } else {
    //   console.log(e.target.value);
    //   setSearchKey(e.target.value);
    // }
  };

  return (
    <>
      <Modal isOpen={open} toggle={handleClose} centered size="lg">
        <ModalHeader toggle={handleClose}>ADD EVENT PRODUCT</ModalHeader>
        <DialogContent className="p-1">
          {activeStep !== steps.length && (
            <Stepper className="text-primary" activeStep={activeStep}>
              {steps.map((label) => (
                <Step className="text-primary" key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          )}
          <div className="container">
            <div>
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {activeStep === 0 && (
                      <>
                        <Row>
                          <Col lg="4" md="2" sm="12" className="text-right">
                            <form
                              className="form-inline search-form"
                              onSubmit={handleSearch}
                            >
                              <div className="form-group w-100">
                                <input
                                  type="text"
                                  id="search"
                                  name="search"
                                  className="form-control-plaintext rounded-0"
                                  placeholder="Search.."
                                  onChange={(e) => setSearchKey(e.target.value)}
                                  value={searchKey}
                                />
                              </div>
                            </form>
                          </Col>

                          <Col lg="8" md="10" sm="12" className="text-right">
                            <div className="d-flex">
                              <div className="w-100"></div>
                              <div className="form-inline">
                                {selectedCategories ? (
                                  <div>
                                    <select
                                      className="form-select form-control-sm form-control mb-2"
                                      onChange={(e) =>
                                        handleSelectCategory(e.target.value)
                                      }
                                    >
                                      <option value="-1">
                                        Open this select menu
                                      </option>
                                      {selectedCategories.map((cat) => (
                                        <option value={cat._id} key={cat._id}>
                                          {cat.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                ) : (
                                  <>
                                    <select
                                      value="-1"
                                      className="form-select form-control-sm form-control mb-2"
                                      onChange={(e) =>
                                        handleSelectCategory(e.target.value)
                                      }
                                    >
                                      <option value="-1">
                                        Open this select menu
                                      </option>
                                      {categories.map((cat) => (
                                        <option value={cat._id} key={cat._id}>
                                          {cat.name}
                                        </option>
                                      ))}
                                    </select>
                                  </>
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <span className="text-danger font-weight-bold">
                          {error.category && `Must select a category !!`}
                        </span>
                        {loading ? (
                          <div className="w-100 text-center">
                            <ComponentLoader height="30vh" />
                          </div>
                        ) : (
                          <div className="table-responsive text-capitalize table-wrapper-scroll-y my-custom-scrollbar border p-0">
                            <table className="table table-md text-center">
                              <thead>
                                <tr>
                                  <th className="border w-10">Image</th>
                                  <th className="border w-10">Product Name</th>
                                  <th className="border w-10">Vendor Name</th>
                                  <th className="border">Price</th>
                                  <th className="border">Select Product</th>
                                </tr>
                              </thead>
                              <tbody>
                                {products
                                  .filter(
                                    (product) =>
                                      !eventProduct.find(
                                        (envProduct) =>
                                          envProduct.product._id === product._id
                                      )
                                  )
                                  .map((product) => {
                                    return (
                                      <tr key={product._id}>
                                        <td className="border">
                                          <img
                                            alt="event cover"
                                            width="40px"
                                            src={
                                              product.photos &&
                                              product.photos[0] &&
                                              product.photos[0].url &&
                                              imageExists(
                                                config.fileServer +
                                                  config.thumbnailContainer +
                                                  product.photos[0].url
                                              )
                                                ? config.fileServer +
                                                  config.thumbnailContainer +
                                                  product.photos[0].url
                                                : noImage
                                            }
                                          />
                                        </td>
                                        <td className="border">
                                          {product.name}
                                        </td>
                                        <td className="border">
                                          {product.vendor
                                            ? product.vendor.name
                                            : " "}
                                        </td>
                                        <td className="border">
                                          {product.price}
                                        </td>
                                        <td className="border">
                                          <label
                                            className="d-block"
                                            htmlFor="edo-ani"
                                          >
                                            <input
                                              id="edo-ani"
                                              type="radio"
                                              name="rdo-ani"
                                              value={product._id}
                                              className="radio_animated"
                                              onChange={(e) =>
                                                handleSelectProduct(
                                                  e.target.value
                                                )
                                              }
                                            />
                                          </label>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                {products.length < productsCount && (
                                  <tr>
                                    <td colSpan="7" align="center">
                                      <button
                                        href="#"
                                        onClick={loadMore}
                                        className="btn btn-primary btn-sm"
                                      >
                                        {buttonLoading
                                          ? "LOADING"
                                          : "Load More"}
                                      </button>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        )}
                        <span className="text-danger font-weight-bold">
                          {error.product && `Must select a product !!`}
                        </span>
                      </>
                    )}
                    {activeStep === 1 && product && (
                      <>
                        {product && (
                          <>
                            <div className="d-flex justify-content-between">
                              <div>
                                <div className="mb-2">
                                  <span className="lead font-01 pl-2">
                                    {category && category.name}
                                  </span>
                                  <span>/</span>
                                  <span className="lead font-01 pl-2">
                                    {product && product.name}
                                  </span>
                                </div>
                                <div className="mb-2 mt-2">
                                  <p className="lead font-01 pl-2">
                                    Available Stock:{" "}
                                    {product &&
                                    product.stock &&
                                    product.stock.available
                                      ? product.stock.available
                                      : "---"}
                                  </p>
                                  <p className="lead font-01 pl-2">
                                    Product Price:{" "}
                                    {product.price && product.price}
                                  </p>
                                  <p className="lead font-01 pl-2">
                                    Discount Amount:
                                    {((discount / 100) * product.price).toFixed(
                                      2
                                    )}
                                  </p>
                                  <p className="lead font-01 pl-2">
                                    Price After Discount:
                                    {(
                                      product.price -
                                      (discount / 100) * product.price
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <img
                                  alt="event cover"
                                  width="200px"
                                  height="200px"
                                  src={
                                    product.photos &&
                                    product.photos[0] &&
                                    product.photos[0].url &&
                                    imageExists(
                                      config.fileServer +
                                        config.thumbnailContainer +
                                        product.photos[0].url
                                    )
                                      ? config.fileServer +
                                        config.thumbnailContainer +
                                        product.photos[0].url
                                      : noImage
                                  }
                                />
                              </div>
                            </div>

                            <div className="mb-2">
                              <div>
                                <label
                                  htmlFor="eventTitle"
                                  className="font-weight-bold small"
                                >
                                  Event Product Discount
                                </label>
                                <input
                                  type="number"
                                  name="discount"
                                  value={discount}
                                  className="form-control-sm form-control"
                                  onChange={(e) => setDiscount(e.target.value)}
                                  placeholder={`Discount must be within ${discountRange.min}-${discountRange.max}`}
                                />
                                <span className="text-danger font-weight-bold">
                                  {error.discount &&
                                    `Discount must be within ${discountRange.min}-${discountRange.max} !!`}
                                </span>
                              </div>
                              <div>
                                <label
                                  htmlFor="eventTitle"
                                  className="font-weight-bold small"
                                >
                                  Event Product Stock Limit
                                </label>
                                <input
                                  type="number"
                                  name="name"
                                  value={stockLimit}
                                  placeholder="0"
                                  className="form-control-sm form-control"
                                  onChange={(e) =>
                                    setStockLimit(e.target.value)
                                  }
                                />
                                <span className="text-danger font-weight-bold">
                                  {error.stockLimit &&
                                    `Stock limit cant not be empty !!`}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div className="mb-4">
                      <div className="d-flex justify-content-center">
                        {activeStep === 0 && (
                          <>
                            <button
                              type="button"
                              onClick={handleNext}
                              style={{ width: "40%" }}
                              className="btn btn-primary btn-sm m-2"
                            >
                              NEXT
                            </button>
                          </>
                        )}
                        {activeStep === 1 && (
                          <>
                            <button
                              type="button"
                              onClick={handleBack}
                              style={{ width: "40%" }}
                              className="btn btn-primary btn-sm m-2"
                            >
                              Back
                            </button>
                            <button
                              type="button"
                              onClick={handleSubmit}
                              style={{ width: "40%" }}
                              className="btn btn-primary btn-sm m-2"
                            >
                              Submit
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Modal>
    </>
  );
};

export default ProductModal;
