import {
  DeletePhoto,
  GetBlogDetails,
  UpdateBlog,
  UploadPhoto,
} from "../../../../../service/service";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "react-dropzone-uploader/dist/styles.css";
import React, { useEffect, useState } from "react";
import config from "../../../../../service/api/config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import operations from "../../../../../redux/Main/operations";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { Container, Row, Col, Button, FormGroup, Label } from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const UpdateBlogPage = (props) => {
  let { slug } = useParams();
  const history = useNavigate();
  const [name, setName] = useState("");
  const [error, setError] = useState({});
  const [content, setContent] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [blog, setBlog] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [photo, setPhoto] = useState("");
  const [blogImage, setBlogImage] = useState(null);
  const [shortDescription, setShortDescription] = useState("");
  const [isPhotoDeleted, setIsPhotoDeleted] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleUploadPhoto = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setPhoto(response.data.fileName);
          setBlogImage(response.data);
          setIsPhotoDeleted(true);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleDeletePhoto = async (photo) => {
    await DeletePhoto({
      fileName: photo,
      container: "ivcommerceoriginalcontainer",
    });
    setPhoto(null);
  };

  const fetchData = async () => {
    const response = await GetBlogDetails(slug);
    if (response && response.isSuccess) {
      setBlog(response.data.blog);
      setContent(response.data.blog.description);
      setName(response.data.blog.title);
      setPhoto(response.data.blog.blogImage?.fileName);
      setBlogImage(response.data.blog.blogImage);
      setShortDescription(response.data.blog.shortDescription);
      setLoading(false);
    }
    setButtonLoading(false);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!name || !content || !blogImage || !shortDescription) {
      setError({
        name: !name ? true : false,
        content: !content ? true : false,
        blogImage: !blogImage ? true : false,
      });
    } else {
      setButtonLoading(true);
      const data = {
        title: name,
        description: content,
        company: props.company.isVendor
          ? props.company.parentCompany
          : props.company._id,
        blogImage: blogImage,
        shortDescription: shortDescription,
      };

      const response = await UpdateBlog(blog._id, data);
      if (response && response.isSuccess) {
        setBlogs([response.data.blog, ...blogs]);
        toast.success("Blog Updated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        history("/dashboard/blog");
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      setButtonLoading(false);
    }
  };

  return (
    <div>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" sm="3">
              <Breadcrumbs title="Update Blog" parent="Dashboard" />
            </Col>
            <Col lg="3" sm="3">
              {buttonLoading ? (
                <Button color="primary btn-block" disabled={buttonLoading}>
                  SAVING...
                </Button>
              ) : (
                <Button onClick={handleFormSubmit} color="primary btn-block">
                  <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                </Button>
              )}
            </Col>
          </Row>
        </div>
        {loading ? (
          <></>
        ) : (
          <div className="content">
            <div className="row">
              <div className="col-lg-8 col-xl-9">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Blog Details
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <label
                        htmlFor="productTitle"
                        className="col-sm-12 font-weight-bold small"
                      >
                        Title
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          className="form-control-sm form-control"
                          name="name"
                          placeholder="Name"
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          required
                        />
                        <span className="text-danger">
                          {error.name && "Title is required"}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="productTitle"
                        className="col-sm-12 font-weight-bold small"
                      >
                        Short Description
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-12">
                        <textarea
                          className="form-control-sm form-control"
                          name="name"
                          value={shortDescription}
                          placeholder="Short Description"
                          onChange={(e) => setShortDescription(e.target.value)}
                          required
                        />
                        <span className="text-danger">
                          {error.name && "Title is required"}
                        </span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-sm-12 font-weight-bold small">
                        Description
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-12">
                        <div className="border">
                          <CKEditor
                            editor={ClassicEditor}
                            data={content}
                            config={{
                              ckfinder: {
                                uploadUrl:
                                  config.serverURL + "file/editor-file",
                              },
                              height: "500",
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              setContent(editor.getData());
                              console.log({ event, editor, data });
                            }}
                            onFilesChange={(e) => console.log(e)}
                            required
                          />
                        </div>
                        <span className="text-danger">
                          {error.content && "Description is required"}
                        </span>
                      </div>
                    </div>

                    <FormGroup>
                      <Label for="brandImage">
                        {" "}
                        Blog Cover Image (Recommended Size: 870x460)
                        <span className="text-danger">*</span>
                      </Label>
                      <div>
                        {photo ? (
                          <div className="w-100 text-center">
                            <div className="w-100">
                              <img
                                src={
                                  isPhotoDeleted
                                    ? config.fileServer +
                                      config.imageContainer +
                                      photo
                                    : blogImage.url
                                }
                                alt=""
                                className="mb-2"
                                width="50%"
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-icon-split btn-sm btn-danger"
                              onClick={() => handleDeletePhoto(photo)}
                            >
                              Remove Photo
                            </button>
                          </div>
                        ) : (
                          <Dropzone
                            onChangeStatus={handleUploadPhoto}
                            accept="image/*"
                            PreviewComponent={null}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                          />
                        )}
                      </div>
                      <span className="text-danger">
                        {error.blogImage && "Blog Cover image is required"}
                      </span>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    products: state.mainReducers.main.products,
    categories: state.mainReducers.main.categories,
    subCategories: state.mainReducers.main.subCategories,
    siteSettings: state.mainReducers.main.siteSettings,
    vendors: state.mainReducers.main.vendors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateBlogPage);
