import React, { useState } from "react";
import { dateTime } from "../../../../../service/dateTimeFormat";
import { Link } from "react-router-dom";
const TableList = ({ m }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { time, date } = dateTime(m.createdAt);
  return (
    <tr>
      <td className="border">{m.name}</td>
      <td className="border">{m.email}</td>
      <td className="border">{m.phoneNumber}</td>
      <td className="border">
        {m.message.length > 20 ? m.message.slice(0, 20) + "..." : m.message}
      </td>
      <th className="border" scope="row">
        <span className="mr-1">{date}</span> <span>{time}</span>
      </th>
      <td className="border">
        {" "}
        <Link
          to={`./${m._id}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          {isHovered ? (
            <i className="icofont icofont-envelope-open fa-2x"></i>
          ) : (
            <i className="icofont icofont-envelope fa-2x"></i>
          )}
        </Link>
      </td>
    </tr>
  );
};

export default TableList;
