import {
  getAllCertifications,
  deleteCertification,
} from "../../../../service/service";
import SweetAlert from "sweetalert2";
import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Row, Col, Container, Button } from "reactstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdateCertification from "./components/UpdateCertification";
import CreateCertification from "./components/CreateCertification";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import errorImg from "../../../../assets/images/search-not-found.png";

const AllCertificationsPage = () => {
  const [loading, setLoading] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [certification, setCertification] = useState(null);
  const [certifications, setCertifications] = useState([]);

  const fetchData = async () => {
    const response = await getAllCertifications();
    if (response && response.isSuccess) {
      setCertifications(response.data.certification);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [certifications]);

  const confirmDelete = async (id) => {
    const response = await deleteCertification(id);
    if (response && response.isSuccess) {
      setCertifications(
        certifications.filter(
          (certification) =>
            certification._id !== response.data.certification._id
        )
      );
    }
  };

  const handleDelete = (id) => {
    SweetAlert.fire({
      icon: "warning",
      reverseButtons: true,
      title: "Are you sure?",
      showCancelButton: true,
      cancelButtonText: "cancel",
      confirmButtonText: "confirm",
      text: "Once deleted, you will not be able to recover this event!",
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Event deleted.", "success");
        });
      } else {
        SweetAlert.fire("Event is safe!");
      }
    });
  };

  const handleClose = () => {
    setUpdateModal(false);
    setCreateModal(false);
  };

  return (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" sm="3">
              <Breadcrumbs title="All Certifications" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12">
              <Button
                color="primary btn-block"
                onClick={() => setCreateModal(true)}
              >
                <FontAwesomeIcon className="mr-1" icon={faPlus} /> Create
                Certification
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
      {loading ? (
        <ComponentLoader height="40vh" />
      ) : (
        <div className="row">
          <div className="col-lg-12 col-xl-12">
            <div className="card shadow mb-4">
              <div className="card-body">
                {certifications.length > 0 ? (
                  <div className="table-responsive text-capitalize">
                    <table className="table table-md text-center">
                      <thead>
                        <tr>
                          <th className="border">Title</th>
                          <th className="border">Color</th>
                          <th className="border">Description</th>
                          <th className="border">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {certifications &&
                          certifications.map((certification) => {
                            return (
                              <tr key={certification._id}>
                                <td className="border">
                                  {certification.title}
                                </td>
                                <td className="border">
                                  <p className="d-flex justify-content-center">
                                    <span
                                      className="mr-2"
                                      style={{
                                        width: "20px",
                                        height: "20px",
                                        backgroundColor: `${certification.color}`,
                                      }}
                                    ></span>
                                    <span>{certification.color}</span>
                                  </p>
                                </td>
                                <td className="border">
                                  {certification.description}
                                </td>
                                <td className="border">
                                  <div className="button-list">
                                    <button
                                      href="#"
                                      onClick={() => {
                                        setUpdateModal(true);
                                        setCertification(certification);
                                      }}
                                      className="btn btn-dark p-0"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          width: 35,
                                          fontSize: 16,
                                          padding: 11,
                                          color: "rgb(40, 167, 69)",
                                        }}
                                      ></i>
                                    </button>

                                    <button
                                      href="#"
                                      onClick={(e) =>
                                        handleDelete(certification._id)
                                      }
                                      className="btn btn-text p-0"
                                    >
                                      <i
                                        className="fa fa-trash"
                                        style={{
                                          width: 35,
                                          padding: 11,
                                          fontSize: 16,
                                          color: "#e4566e",
                                        }}
                                      ></i>
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="search-not-found text-center">
                    <div>
                      <img
                        className="img-fluid second-search"
                        src={errorImg}
                        alt=""
                      />
                      <p>Sorry, We didn't find any stock requests</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <UpdateCertification
        open={updateModal}
        handleClose={handleClose}
        certification={certification}
      />
      <CreateCertification open={createModal} handleClose={handleClose} />
    </>
  );
};

export default AllCertificationsPage;
