import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  Col,
  Row,
} from "reactstrap";
import ModalProductList from "./ModalProductList";
import AddedList from "./AddedList";
import { toast } from "react-toastify";
import { GetAllProducts } from "../../../../../../service/service";

const UpdateModalSection = ({
  open,
  toggle,
  products,
  setSectionData,
  sectionData,
  setHomeSections,
  homeSections,
  item,
  productArray,
  setProductArray,
  productIdList,
  setProductIdList,
  setAllProducts,
  allCategory,
  index,
  data,
}) => {
  const [title, setTitle] = useState(sectionData[index].title);

  console.log(data);

  const [category, setCategory] = useState(null);
  const [searchKey, setSearchKey] = useState("");
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);

    const data = {
      category: category ? category : null,
      searchKey: searchKey ? searchKey : null,
    };

    const response = await GetAllProducts(data);

    if (response && response.isSuccess) {
      setAllProducts(response.data.products);
    } else {
      setAllProducts([]);
    }
    setLoading(false);
  };

  const updateSection = (e) => {
    e.preventDefault();
    if (title.length > 0 && productArray.length > 0) {
      const data = {
        product: [...productArray],
        title: title,
        productIdList: [...productIdList],
      };

      sectionData[index] = data;

      setHomeSections({
        ...homeSections,
        [item.id]: [...sectionData],
      });
      setProductIdList([]);
      setProductArray([]);
      toggle();
    } else {
      toast.warning("Title or Product can't be empty", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getData();
  }, [category, searchKey]);

  return (
    <Modal isOpen={open} centered>
      <ModalHeader toggle={toggle}>Update Product Section</ModalHeader>
      <ModalBody style={{ overflow: "auto", height: "400px" }}>
        <FormGroup>
          <Label for="title">Title</Label>
          <Input
            type="text"
            name="title"
            id="title"
            placeholder="Give a feature name"
            onChange={(e) => setTitle(e.target.value)}
            value={title}
          />
        </FormGroup>
        {productArray.map((p) => (
          <AddedList
            product={p}
            key={p._id}
            productArray={productArray}
            setProductArray={setProductArray}
            productIdList={productIdList}
            setProductIdList={setProductIdList}
          />
        ))}
        <Row className="mb-2">
          <Col lg="4" md="2" sm="12" className="text-right">
            <form
              className=" search-form"
              // onSubmit={handleSearch}
            >
              <div className="form-group w-100">
                <input
                  type="text"
                  id="search"
                  name="search"
                  className="form-control-plaintext rounded-0"
                  placeholder="Search.."
                  onChange={(e) => setSearchKey(e.target.value)}
                />
              </div>
            </form>
          </Col>
          <Col lg="8" md="10" sm="12" className="text-right">
            <div className="">
              <div className="form-group w-100">
                <select
                  name="select"
                  className="form-control btn-square border"
                  onChange={(e) =>
                    setCategory(
                      e.target.value === "all" ? null : e.target.value
                    )
                  }
                >
                  <option value={"all"}>All Categories</option>
                  {allCategory
                    .filter((cat) => cat.isRoot)
                    .map((cat) => (
                      <option value={cat._id} key={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </Col>
        </Row>

        <Table hover size="small">
          <thead>
            <tr>
              <th style={{ width: "65px" }} className="text-center border">
                Icon
              </th>
              <th className="border">Name</th>
              <th className="border">Price</th>
              <th className="text-right border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.map((d) => (
              <ModalProductList
                product={d}
                key={d._id}
                productArray={productArray}
                setProductArray={setProductArray}
                productIdList={productIdList}
                setProductIdList={setProductIdList}
              />
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button className="btn-block" onClick={updateSection}>
          Update
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UpdateModalSection;
