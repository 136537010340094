import { Fragment } from "react";
import { connect } from "react-redux";
import { Card, CardBody } from "reactstrap";
import { bindActionCreators } from "redux";
import React, { useEffect, useState } from "react";
import config from "../../../../service/api/config";
import { operations } from "../../../../redux/Main";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Button, Col, Container, Row } from "reactstrap";
import noImage from "../../../../assets/images/notFound.png";
import { GetRequirementDetails } from "../../../../service/service";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import ResponseToRequirement from "./Components/ResponseToRequirement";
import { useParams } from "react-router";

const RequirementPage = (props) => {
  const { match, history, company, siteSettings } = props;
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [requirement, setRequirement] = useState(null);

  const handleClose = async () => {
    setOpen(false);
  };

  const handleOpen = async (id) => {
    setOpen(true);
  };

  const fetchData = async () => {
    const response = await GetRequirementDetails(id);

    if (response && response.isSuccess) {
      setRequirement(response.data.requirement);
      setStatus(response.data.requirement.status);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" sm="3">
              <Breadcrumbs
                title="Requirement Details"
                parent="Browse Requirements"
              />
            </Col>
            <Col lg="3" sm="3"></Col>
          </Row>
        </div>
        <div className="contentbar">
          <div className="row">
            <div className="col-lg-8">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold ">
                    {requirement.productName}
                  </h6>
                </div>
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-lg-9 col-xl-10">
                      <div className="">
                        <p className="font-size-14 m-0 p-0">
                          <b>Similar to:</b>{" "}
                          {requirement.productType &&
                            requirement.productType.name}
                        </p>
                        <p className="font-size-14 m-0 p-0">
                          <b>Posted on:</b>{" "}
                          {requirement.createdAt.toString().slice(0, 10)}{" "}
                          {requirement.status === "closed" &&
                            " | " +
                              requirement.closedAt.toString().slice(0, 10)}
                        </p>
                        <p className="font-size-14 m-0 p-0">
                          <b>Prefered Quantity:</b> {requirement.quantity}
                        </p>
                        <p className="font-size-14 m-0 p-0">
                          <b>Contact Number:</b> {requirement.contactNumber}
                        </p>
                        <p className="font-size-14 m-0 p-0">
                          <b>Contact Email:</b> {requirement.contactEmail}
                        </p>
                      </div>
                    </div>

                    <div className="col-lg-3 col-xl-2">
                      <img
                        width="60px"
                        alt="Description"
                        src={
                          config.fileServer +
                          config.thumbnailContainer +
                          requirement.file
                            ? config.fileServer +
                              config.thumbnailContainer +
                              requirement.file
                            : noImage
                        }
                        className="img-fluid border border-color-1 rounded mx-auto d-block"
                      />
                    </div>
                  </div>
                  <div className="border-top pt-2">
                    <p className="font-size-12 m-0 p-0 text-justify">
                      {requirement.note}
                    </p>
                  </div>
                </div>
              </div>

              <Card>
                <CardBody>
                  <h6 className="font-weight-bold mt-2 mb-2">
                    Company Details
                  </h6>
                  <div className="table-responsive">
                    <table className="table table-md">
                      <thead>
                        <tr>
                          <td className="border">Company logo</td>
                          <td className="border">Company name</td>
                          <td className="border">Company Types</td>
                          <td className="border">Company email</td>
                          <td className="border">Company phone</td>
                        </tr>
                      </thead>
                      <tbody>
                        {requirement.response.map((res) => {
                          return (
                            <tr key={res._id}>
                              <td className="border">
                                <img
                                  width="60px"
                                  alt="Description"
                                  src={
                                    res.logo
                                      ? res.logo !== null
                                        ? res.logo
                                        : noImage
                                      : "No Logo Found"
                                  }
                                  className="img-fluid border border-color-1 rounded mx-auto d-block"
                                />
                              </td>
                              <td className="border">{res.name}</td>
                              <td className="border">
                                {res.companyTypes.map((type) => (
                                  <span>{`${type}, `}</span>
                                ))}
                              </td>
                              <td className="border">{res.email}</td>
                              <td className="border">{res.phone}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-lg-4">
              {requirement.buyer ? (
                <>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Response
                      </h6>
                    </div>
                    <div className="card-body">
                      <span className="d-block mb-2">
                        Previous Response:{" "}
                        {requirement.response.find(
                          (obj) => obj._id === company._id
                        )
                          ? "You have already responsed"
                          : "You haven't responded yet"}
                      </span>
                      {/* requirement.response.find((obj) => obj === company._id) */}
                      {requirement.response.find(
                        (obj) => obj._id === company._id
                      ) ? (
                        <Button className="btn btn-dark btn-block" disabled>
                          Already Responded
                        </Button>
                      ) : (
                        <Button
                          className="btn btn-primary btn-block"
                          onClick={handleOpen}
                        >
                          Respond
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Buyer Information
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped ">
                          <tbody>
                            <tr>
                              <td>Name</td>
                              <td>
                                {requirement.buyer &&
                                  requirement.buyer.userInfo.firstName}{" "}
                                {requirement.buyer &&
                                  requirement.buyer.userInfo.lastName}
                              </td>
                            </tr>
                            <tr>
                              <td>Email</td>
                              <td>
                                {requirement.contactEmail
                                  ? requirement.contactEmail
                                  : "No Email Found"}
                              </td>
                            </tr>
                            <tr>
                              <td>Phone</td>
                              <td>{requirement.contactNumber}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <span className="d-block mb-2">Buyer Not Available</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {requirement.buyer && (
          <ResponseToRequirement
            handleClose={handleClose}
            open={open}
            requirement={requirement}
            company={company}
            buyer={requirement.buyer && requirement.buyer}
            history={history}
            siteSettings={siteSettings}
          />
        )}
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    requirement: state.mainReducers.main.requirement,
    products: state.mainReducers.main.products,
    company: state.mainReducers.main.company,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RequirementPage);
