import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Pagination } from "@material-ui/lab";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Button, ButtonGroup, Card, Col, Container, Row } from "reactstrap";
import SweetAlert from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import operations from "../../../../redux/Main/operations";
import React, { Fragment, useEffect, useState } from "react";
import errorImg from "../../../../assets/images/search-not-found.png";
import { getComparator, stableSort } from "../../../../service/sort";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { 
  GetAllSubscriptionPackages,
  DeletePackage
 } from "../../../../service/service";

const AdminSubscriptionsPage = (props) => {
  const [page, setPage] = useState(1);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [filteredPackages, setFilteredPackages] = useState(null);

  const getPackages = async () => {
    const response = await GetAllSubscriptionPackages();
    if (response && response.isSuccess) {
      console.log(response.data);
      setPackages(response.data.packages);
    }
    setLoading(false);
  };

  useEffect(() => {
    getPackages();
  }, []);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "name": {
        setOrderBy(property);
        setOrder("asc");
        break;
      }
      default:
        break;
    }
  };

  const confirmDelete = async (id) => {
    await DeletePackage(id);
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this package!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Package deleted.", "success");
          getPackages();
        });
      } else {
        SweetAlert.fire("Package is safe!");
      }
    });
  };

  const handleSearch = async (searchKey) => {
    if (searchKey.length > 0) {
      setFilteredPackages(
        packages.filter((com) =>
          com.name.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilteredPackages(null);
    }
  };

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="Packages" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12">
              <Link
                to="/dashboard/add-package"
                className="btn btn-primary btn-block"
              >
                <i className="fa fa-plus mr-1"></i>
                <span className="text">Create Package</span>
              </Link>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md="10" sm="12" className="text-right">
            <form className="form-inline search-form">
              <div className="form-group w-100">
                <input
                  type="search"
                  id="search"
                  className="form-control-plaintext"
                  placeholder="Search.."
                  onChange={(e) => {
                    handleSearch(e.target.value);
                  }}
                />
              </div>
            </form>
          </Col>
          <Col md="2" sm="12" className="">
            <div className="select2-drpdwn-product select-options d-inline-block w-100">
              <div className="form-group w-100">
                <select
                  className="form-control btn-square"
                  name="select"
                  onChange={(e) => handleRequestSort(e, e.target.value)}
                >
                  <option value="createdAt" selected>
                    Latest
                  </option>
                  <option value="name">Name</option>
                </select>
              </div>
            </div>
          </Col>
        </Row>
        {(filteredPackages ? filteredPackages : packages).length > 0 ? (
          <Row>
            {stableSort(
              filteredPackages ? filteredPackages : packages,
              getComparator(order, orderBy)
            )
              .slice(page * 10 - 10, page * 10)
              .map((pack) => (
                <Col md="6" lg="6" xl="4 set-col-6">
                  <Card className="custom-card pb-0">
                    <Link to={`/dashboard/update-package/${pack._id}`}>
                      <div className="text-center profile-details">
                        <h2>{pack.name}</h2>
                        <h6>{pack.description}</h6>
                      </div>
                    </Link>
                    <Row className="card-footer">
                      <Col className="col-4 p-2" sm="4">
                        <h6>Price</h6>
                        <p className="font-size-10 font-weight-bold">
                          <span className="text-uppercase">{pack.price}</span>
                        </p>
                      </Col>

                      <Col className="col-4 p-2" sm="4">
                        <h6>Duration</h6>
                        <p className="font-size-10 font-weight-bold">
                          <span className="text-uppercase">
                            {pack.duration}
                          </span>
                        </p>
                      </Col>

                      <Col className="col-4 p-2" sm="4">
                        <Button onClick={() => handleDelete(pack._id)} color="danger btn-block" className="p-2">
                          <FontAwesomeIcon className="mr-1" icon={faTrashAlt} />{" "}
                          Delete
                        </Button>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              ))}
          </Row>
        ) : (
          <div className="search-not-found text-center">
            <div>
              <img className="img-fluid second-search" src={errorImg} alt="" />
              <p>Sorry, We didn't find any package</p>
            </div>
          </div>
        )}
        {packages.length > 10 && (
          <Pagination
            count={
              filteredPackages
                ? (filteredPackages.length / 10).toFixed(0)
                : (packages.length / 10).toFixed(0)
            }
            page={page}
            onChange={handlePageChange}
          />
        )}
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    user: state.mainReducers.main.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminSubscriptionsPage);
