import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { UpdateCouponAPI, GetDetailsCoupon } from "../../../../service/service";
import DatePicker from "react-datepicker";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const UpdateCoupon = (props) => {
  let slug = useParams();
  const history = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [couponCode, setCouponCode] = useState("");
  const [couponDescription, setCouponDescription] = useState("");
  const [discountBy, setDiscountBy] = useState({
    type: "wholesale",
  });
  const [discountType, setDiscountType] = useState("percentage");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [minAmountToBuy, setMinAmountToBuy] = useState(0);
  const [IsCashBack, setIsCashBack] = useState(false);
  const [limit, setLimit] = useState(0);
  const [maxAmountToDiscount, setMaxAmountToDiscount] = useState(0);
  const [changeLimitOnAdd, setChangeLimitOnAdd] = useState(false);
  const [changeLimitOnPurchage, setChangeLimitOnPurchage] = useState(false);
  const [couponType, setCouponType] = useState("general");

  const fetchCouponDetails = async (id) => {
    console.log(slug.id);
    const coupon = await GetDetailsCoupon(id);

    if (coupon && coupon.isSuccess) {
      const data = coupon.data.coupon;

      setCouponCode(data.couponCode);
      setStartDate(new Date(data.startDate));
      setEndDate(new Date(data.endDate));
      setCouponDescription(data.couponDescription);
      setDiscountBy(data.discountBy);
      setDiscountAmount(data.discountAmount);
      setDiscountType(data.discountType);
      setMinAmountToBuy(data.minAmountToBuy);
      setMaxAmountToDiscount(data.maxAmountToDiscount);
      setIsCashBack(data.IsCashBack);
      setLimit(data.limit);
      setChangeLimitOnAdd(data.changeLimitOnAdd);
      setChangeLimitOnPurchage(data.changeLimitOnPurchage);
      setCouponType(data.couponType);
    }
  };

  // Validate Input
  const validate = (
    startDate,
    endDate,
    discountAmount,
    discountType,
    changeLimitOnAdd,
    changeLimitOnPurchage,
    limit
  ) => {
    let isValidate = true;
    let warningMessage = "";
    if (startDate.getTime() > endDate.getTime()) {
      warningMessage = "End date can't be Smaller than Start date!!";
      isValidate = false;
    } else if (discountAmount <= 0) {
      warningMessage = "Discount Amount Can't not be 0";
      isValidate = false;
    } else if (discountType === "percentage" && discountAmount > 100) {
      warningMessage = "Discount Amount Can't not be more than 100%";
      isValidate = false;
    } else if (limit > 0 && !changeLimitOnAdd && !changeLimitOnPurchage) {
      warningMessage = "Please select your limit reducing method";
      isValidate = false;
    }

    return { isValidate, warningMessage };
  };

  // Submit form handler function
  const handleUpdateCoupon = async (e) => {
    e.preventDefault();

    let { isValidate, warningMessage } = validate(
      startDate,
      endDate,
      discountAmount,
      discountType,
      changeLimitOnAdd,
      changeLimitOnPurchage,
      limit
    );

    if (isValidate && warningMessage.length === 0) {
      const data = {
        couponCode,
        startDate,
        endDate,
        couponDescription,
        discountBy,
        discountType,
        discountAmount,
        minAmountToBuy,
        IsCashBack,
        limit,
        maxAmountToDiscount,
        changeLimitOnAdd,
        changeLimitOnPurchage,
        couponType,
      };

      try {
        const newCoupon = await UpdateCouponAPI(slug.id, data);

        if (newCoupon && newCoupon.isSuccess) {
          toast.success("Coupon Updated!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          history("/dashboard/coupon");
        }

        if (!newCoupon.isSuccess) {
          toast.error(newCoupon.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        }
      } catch (error) {
        console.log(error);
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }

    if (!isValidate) {
      toast.warn(warningMessage, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    fetchCouponDetails(slug.id);
  }, [slug.id]);

  return (
    <Container>
      {" "}
      <div className="page-header">
        <Row>
          <Col lg="8" xs="12">
            <Breadcrumbs title="Update Coupon" parent="Dashboard" />
          </Col>
          <Col lg="4" xs="12">
            <Button color="primary btn-block" onClick={handleUpdateCoupon}>
              <FontAwesomeIcon className="mr-1" icon={faSave} />
              Update
            </Button>
          </Col>
        </Row>
      </div>
      <div className="contentbar">
        <div className="row">
          <div className="col-lg-8 col-xl-9">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Coupon Details
                </h6>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <label
                    htmlFor="ServiceTitle"
                    className="col-sm-12 font-weight-bold small"
                  >
                    Coupon Code
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control-sm form-control"
                      placeholder="Coupon Code"
                      onChange={(e) => setCouponCode(e.target.value)}
                      value={couponCode}
                      required
                    />
                    <span className="text-danger"></span>
                  </div>
                </div>

                <div className="form-group row pt-3">
                  <label className="col-sm-12 font-weight-bold small">
                    Limit
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="number"
                      className="form-control-sm form-control"
                      placeholder="Limit"
                      min={0}
                      value={limit}
                      onChange={(e) => setLimit(e.target.value)}
                    />
                  </div>
                </div>

                {limit > 0 && (
                  <div className="form-group row pt-3">
                    <label className="col-sm-12 font-weight-bold small">
                      Reduce Limit Count
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-12">
                      <select
                        name="diccountType"
                        className="form-control"
                        onChange={(e) => {
                          if (e.target.value === "onadd") {
                            setChangeLimitOnAdd(true);
                            setChangeLimitOnPurchage(false);
                          } else if (e.target.value === "onpurchase") {
                            setChangeLimitOnAdd(false);
                            setChangeLimitOnPurchage(true);
                          } else {
                            setChangeLimitOnAdd(false);
                            setChangeLimitOnPurchage(false);
                          }
                        }}
                      >
                        <option value="chooseOption">Choose a option</option>
                        <option
                          selected={changeLimitOnPurchage}
                          value="onpurchase"
                        >
                          On Purchase
                        </option>
                        <option selected={changeLimitOnAdd} value="onadd">
                          On Add
                        </option>
                      </select>
                    </div>
                  </div>
                )}

                <div className="form-group row pt-3">
                  <label className="col-sm-12 font-weight-bold small">
                    Discount Type
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-12">
                    <select
                      name="diccountType"
                      className="form-control"
                      onChange={(e) => setDiscountType(e.target.value)}
                    >
                      <option
                        selected={discountType === "percentage"}
                        value="percentage"
                      >
                        Parcentage
                      </option>
                      <option
                        selected={discountType === "amount"}
                        value="amount"
                      >
                        Amount
                      </option>
                    </select>
                  </div>
                </div>

                <div className="form-group row pt-3">
                  <label className="col-sm-12 font-weight-bold small">
                    Discount Amount
                    <span className="text-danger">*</span>
                    {discountType === "percentage"
                      ? "( Enter Percentage of Amount )"
                      : "( Enter Amount )"}
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="number"
                      className="form-control-sm form-control"
                      placeholder="Discount Amount"
                      min={0}
                      onChange={(e) => setDiscountAmount(e.target.value)}
                      value={discountAmount}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-md-6">
                    <label
                      htmlFor="ServiceTitle"
                      className="font-weight-bold small mr-2"
                    >
                      Start Date
                      <span className="text-danger">*</span>
                    </label>
                    <DatePicker
                      timeInputLabel="Time:"
                      dateFormat="dd/MM/yyyy h:mm aa"
                      showTimeInput
                      selected={startDate}
                      onChange={(date) => {
                        setStartDate(date);
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="ServiceTitle"
                      className="font-weight-bold small mr-3"
                    >
                      End Date
                      <span className="text-danger">*</span>
                    </label>
                    <DatePicker
                      timeInputLabel="Time:"
                      dateFormat="dd/MM/yyyy h:mm aa"
                      showTimeInput
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>

                <div className="form-group row pt-3">
                  <div className="col-md-6">
                    <label className=" font-weight-bold small">
                      Minimum Amount To Buy
                    </label>
                    <div className="">
                      <input
                        type="number"
                        className="form-control-sm form-control"
                        placeholder="Minimum Amount To Buy"
                        min={0}
                        value={minAmountToBuy}
                        onChange={(e) => setMinAmountToBuy(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className=" font-weight-bold small">
                      Maximum Amount of Discount
                    </label>
                    <div className="">
                      <input
                        type="number"
                        className="form-control-sm form-control"
                        placeholder="Maximum Discount"
                        min={0}
                        value={maxAmountToDiscount}
                        onChange={(e) => setMaxAmountToDiscount(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group row pt-3">
                  <label className="col-sm-12 font-weight-bold small">
                    Description
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="textarea"
                      className="form-control"
                      placeholder="Description"
                      onChange={(e) => setCouponDescription(e.target.value)}
                      value={couponDescription}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-xl-3">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Coupon Type
                </h6>
              </div>
              <div className="card-body">
                <select
                  className="form-select form-control-sm form-control mb-2"
                  onChange={(e) => setCouponType(e.target.value)}
                  value={couponType}
                >
                  <option selected={couponType === "general"} value="general">
                    General
                  </option>
                  <option selected={couponType === "onsignup"} value="onsignup">
                    On Signup
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default UpdateCoupon;
