import React, { Fragment, useEffect, useState } from "react";
import { Col, Row, Table, CardBody, Container } from "reactstrap";
import TableList from "./component/TableList";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { GetAllMessage } from "../../../../service/service";
import Breadcrumbs from "../../Components/Breadcrumbs";
import errorImg from "../../../../assets/images/search-not-found.png";

const ShowAllMessage = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await GetAllMessage();
        const data = await response.data.allMessage;
        setMessages(data);
        setIsLoading(false);
      } catch (error) {
        setIsError(true);
        setIsLoading(false);
      }
    }
    fetch();
  }, []);

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title=" Contact Message" parent="Dashboard" />
            </Col>
          </Row>
          {isLoading ? (
            <ComponentLoader height="80vh" />
          ) : (
            <Row>
              <CardBody>
                {isError ? (
                  <div className="search-not-found text-center my-auto">
                    <div>
                      <img
                        className="img-fluid second-search"
                        src={errorImg}
                        alt=""
                      />
                      <p>Sorry, We didn't find any Message</p>
                    </div>
                  </div>
                ) : (
                  <Table className="table table-hover table-md">
                    <thead>
                      <tr>
                        <th className="border">Name</th>
                        <th className="border">Email</th>
                        <th className="border">Phone</th>
                        <th className="border">Message</th>
                        <th className="border">Date</th>
                        <th className="border">Actions</th>
                      </tr>
                    </thead>

                    <tbody>
                      {messages.map((m) => (
                        <TableList m={m} key={m._id} />
                      ))}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Row>
          )}
        </div>
      </Container>
    </Fragment>
  );
};

export default ShowAllMessage;
