import { Button } from "reactstrap";
import { toast } from "react-toastify";
import Dialog from "@material-ui/core/Dialog";
import React, { useEffect, useState } from "react";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import noImage from "../../../../../assets/images/notFound.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { imageExists } from "../../../../../service/imageExists";
import { UpdateSubCategory, UploadFile } from "../../../../../service/service";

export default function UpdateSubCategor(props) {
  const {
    open,
    handleClose,
    setSubCategories,
    subCategorieyToUpdate,
    categories,
    subCategories,
  } = props;
  const [name, setName] = useState(null);
  const [cat, setCat] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [unit, setUnit] = useState(null);

  useEffect(() => {
    if (subCategorieyToUpdate) {
      setName(subCategorieyToUpdate.name);
      setCat(subCategorieyToUpdate.category._id);
      setUnit(subCategorieyToUpdate.unit);
      setPhoto(subCategorieyToUpdate.photo);
    }
  }, [subCategorieyToUpdate]);

  const handleUploadPhoto = async (e) => {
    const files = e.target.files;
    try {
      const response = await UploadFile(files[0]);
      console.log(response);
      if (response && response.isSuccess) {
        setPhoto(response.data.photoURL);
      }
    } catch (err) {
      if (err.response.status === 500) {
        console.log("There was a problem with the server");
      } else {
        console.log(err.response.data.msg);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (e.target.name.value && cat) {
      setButtonLoading(true);
      const data = {
        name: e.target.name.value,
        category: cat,
        photo: photo,
        unit: unit,
      };
      const response = await UpdateSubCategory(
        subCategorieyToUpdate.slug,
        data
      );

      console.log(response);
      if (response && response.isSuccess) {
        console.log(response.data);
        setSubCategories([
          response.data.subCategory,
          ...subCategories.filter(
            (cat) => cat._id !== response.data.subCategory._id
          ),
        ]);

        handleClose();
      }
      setButtonLoading(false);
      toast.success("Sub-Category Updated!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div className="container mt-4 mb-4">
        <h6 className="m-0 font-weight-bold text-primary">
          Update Sub-Category
        </h6>

        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            <label
              for="productTitle"
              className="col-sm-12 font-weight-bold small"
            >
              Name
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control-sm form-control"
                name="name"
                placeholder="Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
                data-msg="Please enter a valid email address."
                data-error-className="u-has-error"
                data-success-className="u-has-success"
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              for="productTitle"
              className="col-sm-12 font-weight-bold small"
            >
              Unit
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control-sm form-control"
                name="unit"
                placeholder="Unit"
                required
                value={unit}
                onChange={(e) => setUnit(e.target.value)}
                data-msg="Please enter a valid email address."
                data-error-className="u-has-error"
                data-success-className="u-has-success"
              />
            </div>
          </div>
          <div className="form-group row">
            <label
              for="productTitle"
              className="col-sm-12 font-weight-bold small"
            >
              Sub-Category Photo
            </label>
            <div className="col-sm-12">
              {photo && (
                <img
                  src={photo && imageExists(photo) ? photo : noImage}
                  alt="Rounded logo"
                  className="img-fluid rounded mb-1"
                />
              )}
              <div className="custom-file mb-2">
                <input
                  type="file"
                  className="custom-file-input"
                  id="customFile"
                  onChange={handleUploadPhoto}
                  multiple
                />
                <label className="custom-file-label" htmlFor="customFile">
                  Upload Images
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 font-weight-bold small">Category</label>
            <div className="col-sm-12">
              <select
                className="form-select form-control-sm form-control mb-2"
                onChange={(e) => setCat(e.target.value)}
              >
                <option selected>Open this select menu</option>
                {categories.map((c) => (
                  <option selected={cat === c._id} value={c._id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {buttonLoading ? (
            <Button color="primary btn-block" disabled={buttonLoading}>
              SAVING...
            </Button>
          ) : (
            <Button type="submit" color="primary btn-block">
              <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
            </Button>
          )}
        </form>
      </div>
    </Dialog>
  );
}
