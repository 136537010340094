const USER = "USER";
const COMPANY = "COMPANY";
const PRODUCTS = "PRODUCTS";
const COUNTRIES = "COUNTRIES";
const CATEGORIES = "CATEGORIES";
const SUB_CATEGORIES = "SUB_CATEGORIES";
const CONVERSATIONS = "CONVERSATIONS";
const SUBSCRIPTION = "SUBSCRIPTION";
const UNREADS = "UNREADS";
const VENDORS = "VENDORS";
const SITE_SETTINGS = "SITE_SETTINGS";
const COMPANY_SUB_CS = "COMPANY_SUB_CS";
const VENDOR_PERMISSIONS = "VENDOR_PERMISSIONS";
const SLIDERS = "SLIDERS";

export default {
  USER,
  COMPANY,
  COUNTRIES,
  SITE_SETTINGS,
  VENDORS,
  UNREADS,
  SUBSCRIPTION,
  CONVERSATIONS,
  SUB_CATEGORIES,
  CATEGORIES,
  PRODUCTS,
  VENDOR_PERMISSIONS,
  COMPANY_SUB_CS,
  SLIDERS,
};
