import UserMenu from "./UserMenu";
import { Link } from "react-router-dom";
import Notification from "./Notification";
import React, { useState, useEffect, useLayoutEffect } from "react";
import { imageExists } from "../../../../service/imageExists";
import config from "../../../../service/api/config";

const Header = (props) => {
  const [sidebar, setSidebar] = useState(true);
  const [navMenus, setNavMenus] = useState(false);
  const width = useWindowSize();

  const { history, siteSettings } = props;

  const goFull = () => {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  const openCloseSidebar = () => {
    if (sidebar) {
      setSidebar(false);
      document
        .querySelector(".page-body-wrapper")
        .classList.add("sidebar-close");
    } else {
      setSidebar(true);
      document
        .querySelector(".page-body-wrapper")
        .classList.remove("sidebar-close");
    }
  };

  const toggle = () => {
    setNavMenus(!navMenus);
  };

  function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
      function updateSize() {
        setSize(window.innerWidth);
      }
      window.addEventListener("resize", updateSize);
      updateSize();
      return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
  }

  useEffect(() => {
    if (width <= 991) {
      setSidebar(false);
      document
        .querySelector(".page-body-wrapper")
        .classList.add("sidebar-close");
    }
  }, [width]);
  console.log("sitesettings--->", siteSettings);
  return (
    <div className="page-main-header">
      <div className="main-header-left">
        <div className="logo-wrapper">
          <Link to="/dashboard">
            <img
              src={
                siteSettings.logo &&
                siteSettings.logo.nav &&
                imageExists(
                  config.fileServer +
                    config.imageContainer +
                    siteSettings.logo.nav
                )
                  ? config.fileServer +
                    config.imageContainer +
                    siteSettings.logo.nav
                  : ""
              }
              alt=""
              className="image-dark"
              style={{ padding: 13 }}
            />
          </Link>
        </div>
      </div>
      <div className="main-header-right d-flex">
        <div className="mobile-sidebar">
          <div className="media-body text-right switch-sm">
            <label className="switch">
              <input
                type="checkbox"
                id="sidebar-toggle"
                defaultChecked={sidebar}
                onClick={openCloseSidebar}
              />
              <span className="switch-state"></span>
            </label>
          </div>
        </div>
        <div className="nav-right col">
          <ul className={"nav-menus " + (navMenus ? "open" : "")}>
            <li>
              <a onClick={goFull} className="text-dark" href="#javaScript">
                <img
                  className="align-self-center pull-right mr-2"
                  src={require("../../../../assets/images/dashboard/browser.png")}
                  alt="header-browser"
                />
              </a>
            </li>
            {siteSettings.features.messaging == true && <Notification />}
            {/* <li onClick={ToogleRightSidebar}>
              <a href="#javaScript">
                <i className="icon-comments middle"></i>
              </a>
            </li> */}
            <UserMenu history={history} />
          </ul>
          <div className="d-lg-none mobile-toggle" onClick={() => toggle()}>
            <i className="icon-more"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
