import { Button } from "reactstrap";
import { toast } from "react-toastify";
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { CreateSubCategory } from "../../../../../service/service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";

export default function CreateSubCategor(props) {
  const {
    open,
    handleClose,
    setSubCategories,
    categories,
    subCategories,
  } = props;
  const [cat, setCat] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (e.target.name.value && cat) {
      setButtonLoading(true);
      const data = { name: e.target.name.value, category: cat };
      const response = await CreateSubCategory(data);
      if (response && response.isSuccess) {
        setSubCategories([response.data.subCategory, ...subCategories]);
        handleClose();
      }
      setButtonLoading(false);
      toast.success("Sub-Category Created!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <div className="container mt-4 mb-4">
        <h6 className="mb-2 font-weight-bold text-primary">
          Create Sub-Category
        </h6>

        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            <label
              for="productTitle"
              className="col-sm-12 font-weight-bold small"
            >
              Name
            </label>
            <div className="col-sm-12">
              <input
                type="text"
                className="form-control-sm form-control"
                name="name"
                placeholder="Name"
                required
                data-msg="Please enter a valid email address."
                data-error-className="u-has-error"
                data-success-className="u-has-success"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 font-weight-bold small">Category</label>
            <div className="col-sm-12">
              <select
                className="form-select form-control-sm form-control mb-2"
                onChange={(e) => setCat(e.target.value)}
              >
                <option selected>Open this select menu</option>
                {categories.map((c) => (
                  <option value={c._id}>{c.name}</option>
                ))}
              </select>
            </div>
          </div>
          {buttonLoading ? (
            <Button color="primary btn-block" disabled={buttonLoading}>
              SAVING...
            </Button>
          ) : (
            <Button type="submit" color="primary btn-block">
              <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
            </Button>
          )}
        </form>
      </div>
    </Dialog>
  );
}
