import {
  GetSubscriptionDetails,
  ApproveSubscription,
  DeleteSubscription,
  UpdateSubscriptionStatus,
} from "../../../../service/service";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import operations from "../../../../redux/Main/operations";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { Button, ButtonGroup, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "../../Components/Breadcrumbs";

const CompanyDetailsPage = (props) => {
  const { match, history } = props;
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [subscription, setSubscription] = useState({});
  const [buttonLoading, setButtonLoading] = useState(false);

  const fetchUserProfile = async () => {
    const response = await GetSubscriptionDetails(match.params.id);
    if (response && response.isSuccess) {
      setSubscription(response.data.subscription);
      console.log(
        "Subscription: ",
        response.data.subscription.createdAt.toString().slice(0, 10)
      );
      setStatus(response.data.subscription.status);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const handleChangeStatus = async () => {
    if (subscription.status !== status) {
      setButtonLoading(true);
      const response = await UpdateSubscriptionStatus(subscription._id, {
        status,
      });
      console.log(response);
      if (response && response.isSuccess) {
        toast.success("Company Status Updated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      setButtonLoading(false);
    }
  };

  const handleAprove = async () => {
    setButtonLoading(true);

    const response = await ApproveSubscription(subscription._id);
    console.log(response);
    if (response && response.isSuccess) {
      console.log(response.data);
      toast.success("Subscription Aproved!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    await fetchUserProfile();
    setButtonLoading(false);
  };

  const handleReject = async () => {
    setButtonLoading(true);

    const response = await DeleteSubscription(subscription._id);
    if (response && response.isSuccess) {
      toast.success("Subscription Rejected!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      history.push("/dashboard/subscriptions");
    }
    setButtonLoading(false);
  };

  const confirmDelete = async (id) => {
    await DeleteSubscription(id);
  };

  const handleDelete = async () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(subscription._id).then(() => {
          SweetAlert.fire("Deleted!", "Subscription deleted.", "success");
          history.push("/dashboard/subscriptions");
        });
      } else {
        SweetAlert.fire("Subscription is safe!");
      }
    });
  };

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <div>
      <Fragment>
        <Container fluid={true}>
          <div className="page-header">
            <Row>
              <Col lg="9" sm="3">
                <Breadcrumbs
                  title="Subscription Details"
                  parent="Subscriptions"
                />
              </Col>
              <Col lg="3" sm="3">
                {buttonLoading ? (
                  <Button color="primary btn-block" disabled={buttonLoading}>
                    Deleting
                  </Button>
                ) : (
                  <Button onClick={handleDelete} color="danger btn-block">
                    <FontAwesomeIcon className="mr-1" icon={faTrashAlt} />{" "}
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          {Object.keys(subscription).length >= 0 && (
            <div className="contentbar">
              <div className="row">
                <div className="col-lg-8 col-xl-9">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Company Information
                      </h6>
                    </div>
                    <div className="card-body">
                      {subscription.vendor && (
                        <div className="table-responsive">
                          <table className="table table-striped table-sm">
                            <tbody>
                              <tr>
                                <td>Name</td>
                                <td>{subscription.vendor.name}</td>
                              </tr>
                              <tr>
                                <td>Company Types</td>
                                <td>
                                  {subscription.vendor.companyTypes.map(
                                    (c) => c + ", "
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>Product Types</td>
                                <td>
                                  {subscription.vendor.productTypes.map(
                                    (p) => p + ", "
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <td>Status</td>
                                <td>{subscription.vendor.activeStatus}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Package Information
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-striped table-sm">
                          <tbody>
                            <tr>
                              <td>Package</td>
                              <td>
                                {subscription.package
                                  ? subscription.package.name
                                  : " "}
                              </td>
                            </tr>
                            <tr>
                              <td>Price</td>
                              <td>
                                {subscription.package
                                  ? subscription.package.price
                                  : ""}
                              </td>
                            </tr>
                            <tr>
                              <td>Created</td>
                              <td>
                                {subscription.createdAt.toString().slice(0, 10)}
                              </td>
                            </tr>
                            {subscription.status !== "pending" && (
                              <>
                                <tr>
                                  <td>Started</td>
                                  <td>
                                    {subscription.createdAt &&
                                      subscription.createdAt
                                        .toString()
                                        .slice(0, 10)}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Expire</td>
                                  <td>
                                    {subscription.expireDate &&
                                      subscription.expireDate
                                        .toString()
                                        .slice(0, 10)}
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-3">
                  {status !== "pending" ? (
                    <div className="card shadow mb-4">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Change Status
                        </h6>
                      </div>
                      <div className="card-body">
                        <select
                          className="form-select form-control-sm form-control mb-3"
                          onChange={(e) => setStatus(e.target.value)}
                          value={status}
                        >
                          <option selected={status === "active"} value="active">
                            Active
                          </option>
                          <option
                            selected={status === "expired"}
                            value="expired"
                          >
                            Expired
                          </option>
                        </select>
                      </div>

                      <div className="card-footer text-right">
                        {buttonLoading ? (
                          <Button
                            color="primary btn-block"
                            disabled={buttonLoading}
                          >
                            SAVING...
                          </Button>
                        ) : (
                          <Button
                            onClick={handleChangeStatus}
                            color="primary btn-block"
                          >
                            <FontAwesomeIcon className="mr-1" icon={faSave} />{" "}
                            Save
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="card shadow mb-4">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Aproval
                        </h6>
                      </div>
                      <div className="card-body">
                        <h6 className="m-0 font-weight-normal text-primary">
                          Aprove This Subscription?
                        </h6>
                      </div>

                      <div className="card-footer text-center p-0">
                        {buttonLoading ? (
                          <Button
                            color="primary btn-block"
                            disabled={buttonLoading}
                          >
                            SAVING...
                          </Button>
                        ) : (
                          <ButtonGroup className="w-100">
                            <Button
                              onClick={handleReject}
                              size="sm"
                              color="danger"
                            >
                              Reject
                            </Button>
                            <Button
                              size="sm"
                              onClick={handleAprove}
                              color="primary"
                            >
                              Aprove
                            </Button>
                          </ButtonGroup>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </Container>
      </Fragment>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    products: state.mainReducers.main.products,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsPage);
