import { toast } from "react-toastify";
import React, { useState, useEffect } from "react";
import { Modal, Button, ModalBody, ModalHeader } from "reactstrap";
import { updateCertification } from "../../../../../service/service";
import ComponentLoader from "../../../Components/Loader/ComponentLoader";

const UpdateCertification = ({ open, handleClose, certification }) => {
  const [error, setError] = useState({});
  const [title, setTitle] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(true);
  const [description, setDescription] = useState("");

  const setData = () => {
    if (certification) {
      setTitle(certification.title);
      setColor(certification.color);
      setDescription(certification.description);
    }
    setLoading(false);
  };

  useEffect(() => {
    setData();
  }, [certification]);

  const handleFormSubmit = async () => {
    if (!title || !color || !description) {
      setError({
        title: !title ? true : false,
        color: !color ? true : false,
        description: !description ? true : false,
      });
    } else {
      setError({});
      const data = {
        title: title,
        color: color,
        description: description,
      };
      const response = await updateCertification(certification._id, data);
      if (response && response.isSuccess) {
        toast.success("CERTIFICATION UPDATED !!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        handleClose();
      }
      toast.error(response.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      handleClose();
    }
  };

  return (
    <>
      <Modal isOpen={open} toggle={handleClose} centered size="lg">
        <ModalHeader toggle={handleClose}>Update Certification</ModalHeader>
        <ModalBody>
          {loading ? (
            <ComponentLoader height="40vh" />
          ) : (
            <div className="contentbar">
              <div className="row">
                <div className="col-lg-12 col-xl-12">
                  <div className="form-group row">
                    <label
                      htmlFor="eventTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Certification Title
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="name"
                        value={title}
                        placeholder="Enter Certification Title"
                        className="form-control-sm form-control"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      <span className="text-danger font-weight-bold">
                        {error.title && "Certification title is required"}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="productTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Short Description
                    </label>
                    <div className="col-sm-12">
                      <textarea
                        rows="4"
                        value={description}
                        name="shortDescription"
                        className="form-control-sm form-control"
                        placeholder="Description About Certification"
                        onChange={(e) => setDescription(e.target.value)}
                      />
                      <span className="text-danger font-weight-bold">
                        {error.description &&
                          "Certification description is required"}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="productTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Certification Badge Color
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="color"
                        value={color}
                        className="form-control"
                        style={{ height: "80px" }}
                        onChange={(e) => setColor(e.target.value)}
                      />
                      <span className="text-danger font-weight-bold">
                        {error.color && "Certification color is required"}
                      </span>
                    </div>
                  </div>
                  <Button
                    onClick={handleFormSubmit}
                    className="btn btn-dark w-100"
                  >
                    Update Certification
                  </Button>
                </div>
              </div>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default UpdateCertification;
