import React, { useState, useEffect } from "react";
import { Button, Col, Row } from "reactstrap";
import ModalProductSection from "./ModalProductSection";
import UpdateModalSection from "./UpdateModalSection";
import {
  GetAllProducts,
  GetAllCategory,
} from "../../../../../../service/service";
import config from "../../../../../../service/api/config";

const ProductSection = ({ item, homeSections, list, setHomeSections }) => {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [sectionData, setSectionData] = useState(list);
  const [productArray, setProductArray] = useState([]);
  const [productIdList, setProductIdList] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [ind, seInd] = useState(0);
  const [rendered, setIsRendered] = useState(false);

  const handleAddButton = async (e) => {
    e.preventDefault();

    setOpen(!open);

    const response = await GetAllProducts({});
    if (response.isSuccess) {
      setAllProducts(response.data.products);
    }
  };

  const handleUpdateButton = async (index) => {
    setIsRendered(!rendered);
    setOpenUpdate(!openUpdate);
    console.log(index);
    seInd(index);
    const response = await GetAllProducts({});
    if (response.isSuccess) {
      setAllProducts(response.data.products);
    }
    const idList = list[index].product.map((p) => p._id);
    setProductArray(list[index].product);
    setProductIdList(idList);
  };

  const toggleModal = () => {
    setProductArray([]);
    setProductIdList([]);
    setOpen(!open);
  };
  const toggleUpdateModal = () => {
    setProductArray([]);
    setProductIdList([]);
    setIsRendered(!rendered);
    setOpenUpdate(!openUpdate);
  };

  const removeSection = (index) => {
    const newSectionData = sectionData.filter((d, ind) => ind !== index);
    setSectionData(newSectionData);
    setHomeSections({
      ...homeSections,
      [item.id]: newSectionData,
    });
  };

  useEffect(() => {
    const getCategory = async () => {
      const catResponse = await GetAllCategory();
      if (catResponse.isSuccess) {
        setAllCategory(catResponse.data.categories);
      }
    };

    getCategory();
  }, []);

  return (
    <div className="card shadow mb-4">
      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
        <h5>{item.name}</h5>
      </div>

      <div className="card-body py-3 d-flex">
        <div className="row">
          {sectionData.length > 0 &&
            sectionData.map((d, index) => (
              <div key={index} className="col-12">
                <div className="card w-100">
                  <div className="card-header">
                    <h6>{d.title}</h6>
                  </div>
                  <div className="card-body d-flex">
                    <Row>
                      {d.product.map((p) => (
                        <Col
                          key={p._id}
                          style={{ width: "100px", marginRight: "5px" }}
                        >
                          <img
                            src={
                              config.fileServer +
                              config.thumbnailContainer +
                              p.photos
                            }
                            alt={p.name}
                            className="mr-1 mb-1"
                          />
                          <p style={{ fontSize: ".5rem", width: "100px" }}>
                            {p.name} <br /> <b>৳</b>
                            <span className="font-weight-bold">{p.price}</span>
                          </p>
                        </Col>
                      ))}
                    </Row>
                  </div>
                  <div className="card-footer d-flex justify-content-between">
                    <Button
                      className="row"
                      onClick={(e) => {
                        e.preventDefault();
                        handleUpdateButton(index);
                      }}
                    >
                      {" "}
                      Edit
                    </Button>
                    {rendered && (
                      <UpdateModalSection
                        open={openUpdate}
                        toggle={toggleUpdateModal}
                        products={allProducts}
                        sectionData={sectionData}
                        setSectionData={setSectionData}
                        setHomeSections={setHomeSections}
                        homeSections={homeSections}
                        item={item}
                        productArray={productArray}
                        setProductArray={setProductArray}
                        productIdList={productIdList}
                        setProductIdList={setProductIdList}
                        data={d}
                        index={ind}
                        setAllProducts={setAllProducts}
                        allCategory={allCategory}
                      />
                    )}

                    <button
                      className="btn btn-text p-0"
                      onClick={(e) => {
                        e.preventDefault();
                        removeSection(index);
                      }}
                    >
                      <i
                        className="fa fa-trash"
                        style={{
                          width: 35,
                          fontSize: 16,
                          padding: 11,
                          color: "#e4566e",
                        }}
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="card-footer">
        <Button className="btn-block" onClick={handleAddButton}>
          Add
        </Button>
        <ModalProductSection
          open={open}
          toggle={toggleModal}
          products={allProducts}
          sectionData={sectionData}
          setSectionData={setSectionData}
          setHomeSections={setHomeSections}
          homeSections={homeSections}
          item={item}
          productArray={productArray}
          setProductArray={setProductArray}
          productIdList={productIdList}
          setProductIdList={setProductIdList}
          setAllProducts={setAllProducts}
          allCategory={allCategory}
        />
      </div>
    </div>
  );
};

export default ProductSection;
