import { toast } from "react-toastify";
import React, { useState } from "react";
import { Modal, ModalHeader } from "reactstrap";
import { DialogContent } from "@material-ui/core";
import {
  updateEventProduct,
  AddNewVendorRequest,
} from "../../../../service/service";

const ProductUpdateModel = ({
  open,
  company,
  product,
  handleClose,
  siteSettings,
  discountRange,
}) => {
  console.log("siteSettings", siteSettings);
  const [error, setError] = useState(false);
  const [discount, setDiscount] = useState(product.discount);
  const [stockLimit, setStockLimit] = useState(product.stockLimit);
  const [activeStatus, setActiveStatus] = useState(product.activeStatus);

  const compareDiscount = () => {
    if (
      parseInt(discount) >= parseInt(discountRange.min) &&
      parseInt(discount) <= parseInt(discountRange.max)
    ) {
      return discount;
    }
    return null;
  };

  const handleSubmit = async () => {
    if (!discount || !stockLimit || !activeStatus) {
      setError({
        stockLimit: !stockLimit ? true : false,
        activeStatus: !activeStatus ? true : false,
        discount: !compareDiscount() ? true : false,
      });
    } else {
      if (
        !siteSettings.features.vendorAccessManagement ||
        !company.isVendor ||
        (company.permissionType &&
          company.permissionType.eventManagement.updateEventProduct === 3)
      ) {
        const data = {
          discount: discount,
          stockLimit: stockLimit,
          activeStatus: activeStatus,
        };
        const response = await updateEventProduct(product._id, data);
        if (response && response.isSuccess) {
          toast.success("Event Product Updated!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      } else if (
        company.permissionType &&
        company.permissionType.eventManagement.updateEventProduct === 2
      ) {
        const data = {
          discount: discount,
          stockLimit: stockLimit,
          activeStatus: activeStatus,
        };
        const response = await AddNewVendorRequest({
          requestData: data,
          docID: product._id,
          vendor: company._id,
          collectionName: "EventProduct",
          requestType: "updateEventProduct",
        });
        if (response && response.isSuccess) {
          toast.success("Product Update Requested! Wait for Admin Approval", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }
      setError({});
      handleClose();
    }
  };

  return (
    <>
      <Modal isOpen={open} toggle={handleClose} centered size="lg">
        <ModalHeader toggle={handleClose}>UPDATE EVENT PRODUCT</ModalHeader>
        <DialogContent className="p-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 mb-4">
                <div>
                  <label
                    htmlFor="eventTitle"
                    className="font-weight-bold small"
                  >
                    Event Product Discount
                  </label>
                  <input
                    type="number"
                    name="discount"
                    value={discount}
                    className="form-control-sm form-control"
                    onChange={(e) => setDiscount(e.target.value)}
                    placeholder={`Discount must be within ${discountRange.min}-${discountRange.max}`}
                  />
                  <span className="text-danger font-weight-bold">
                    {error.discount &&
                      `Discount must be within ${discountRange.min}-${discountRange.max} !!`}
                  </span>
                </div>
                <div>
                  <label
                    htmlFor="eventTitle"
                    className="font-weight-bold small"
                  >
                    Event Product Stock Limit
                  </label>
                  <input
                    name="name"
                    type="number"
                    placeholder="0"
                    value={stockLimit}
                    className="form-control-sm form-control"
                    onChange={(e) => setStockLimit(e.target.value)}
                  />
                  <span className="text-danger font-weight-bold">
                    {error.stockLimit && `Stock limit can not be empty !!`}
                  </span>
                </div>
                <div>
                  <label
                    htmlFor="eventTitle"
                    className="font-weight-bold small"
                  >
                    Event Product Status
                  </label>
                  <select
                    value={activeStatus}
                    className="form-select form-control-sm form-control mb-2"
                    onChange={(e) => setActiveStatus(e.target.value)}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </select>
                  <span className="text-danger font-weight-bold">
                    {error.activeStatus &&
                      `Product active status can not be empty !!`}
                  </span>
                </div>
                <button
                  onClick={handleSubmit}
                  className="btn btn-primary w-100 mt-2"
                >
                  Update Product
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Modal>
    </>
  );
};

export default ProductUpdateModel;
