import types from "./types";

const setUser = (user) => ({
  type: types.USER,
  status: "success",
  payload: {
    user: user,
  },
});

const setCountries = (countries) => ({
  type: types.COUNTRIES,
  status: "success",
  payload: {
    countries: countries,
  },
});

const setCompany = (company) => ({
  type: types.COMPANY,
  status: "success",
  payload: {
    company: company,
  },
});

const setSiteSettings = (siteSettings) => ({
  type: types.SITE_SETTINGS,
  status: "success",
  payload: {
    siteSettings: siteSettings,
  },
});

const setVendors = (vendors) => ({
  type: types.VENDORS,
  status: "success",
  payload: {
    vendors: vendors,
  },
});

const setUnreads = (unreads) => ({
  type: types.UNREADS,
  status: "success",
  payload: {
    unreads: unreads,
  },
});

const setSubscription = (subscription) => ({
  type: types.SUBSCRIPTION,
  status: "success",
  payload: {
    subscription: subscription,
  },
});

const setProducts = (products) => ({
  type: types.PRODUCTS,
  status: "success",
  payload: {
    products: products,
  },
});

const setCompanySubCs = (companySubCs) => ({
  type: types.COMPANY_SUB_CS,
  status: "success",
  payload: {
    companySubCs: companySubCs,
  },
});

const setCategories = (categories) => ({
  type: types.CATEGORIES,
  status: "success",
  payload: {
    categories: categories,
  },
});

const setConversations = (conversations) => ({
  type: types.CONVERSATIONS,
  status: "success",
  payload: {
    conversations: conversations,
  },
});

const setSubCategories = (subCategories) => ({
  type: types.SUB_CATEGORIES,
  status: "success",
  payload: {
    subCategories: subCategories,
  },
});

const setVendorPermissions = (vendorPermissions) => ({
  type: types.VENDOR_PERMISSIONS,
  success: "success",
  payload: {
    vendorPermissions: vendorPermissions,
  },
});
const setSliders = (sliders) => ({
  type: types.SLIDERS,
  success: "success",
  payload: {
    sliders: sliders,
  },
});

export default {
  setUser,
  setCountries,
  setCompany,
  setProducts,
  setCategories,
  setSubCategories,
  setConversations,
  setUnreads,
  setSubscription,
  setCompanySubCs,
  setVendors,
  setSiteSettings,
  setVendorPermissions,
  setSliders,
};
