import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { operations } from "../../../../redux/Main";
const UserMenu = (props) => {
  const { setUser, history, setProducts } = props;

  const logOut = async () => {
    setUser({});
    setProducts({});
    localStorage.removeItem("auth_token");
    history.push("/");
  };

  return (
    <li className="onhover-dropdown">
      <div className="media  align-items-center d-flex align-items-center">
        <img
          className="align-self-center pull-right mr-2"
          src={require("../../../../assets/images/dashboard/user.png")}
          alt="header-user"
        />
        <div className="media-body">
          <h6 className="m-0 txt-dark f-16">
            My Account
            <i className="fa fa-angle-down pull-right ml-2"></i>
          </h6>
        </div>
      </div>
      <ul className="profile-dropdown onhover-show-div p-20">
        <li>
          <Link to={`/dashboard/update-profile`}>
            <i className="icon-user"></i>
            Edit Profile
          </Link>
        </li>
        {/* <li>
          <Link to={`${process.env.PUBLIC_URL}/applications/email-app`}>
            <i className="icon-email"></i>
            Inbox
          </Link>
        </li>
        <li>
          <Link to={`${process.env.PUBLIC_URL}/applications/todo-app`}>
            <i className="icon-check-box"></i>
            Task
          </Link>
        </li> */}
        <li onClick={logOut}>
          <a href="#javaScript">
            <i className="icon-power-off"></i>
            Logout
          </a>
        </li>
      </ul>
    </li>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    user: state.mainReducers.main.user,
    auth: state.mainReducers.main.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
