import {
  DeleteRequirement,
  GetRequirementDetails,
  ChangeRequirementStatus,
} from "../../../../service/service";
import { toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { Button, ButtonGroup, Col, Container, Row } from "reactstrap";
import noImage from "../../../../assets/images/notFound.png";
import { useParams } from "react-router";

const RequirementDetailsPage = (props) => {
  const { match, history } = props;
  const { id } = useParams();
  console.log(
    "🚀 ~ file: RequirementDetailsPage.jsx:24 ~ RequirementDetailsPage ~ id",
    id
  );
  console.log(
    "🚀 ~ file: RequirementDetailsPage.jsx:22 ~ RequirementDetailsPage ~ match",
    match
  );
  const [file, setFile] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [requirement, setRequirement] = useState(null);
  const [buyer, setBuyer] = useState(null);

  const fetchData = async () => {
    const response = await GetRequirementDetails(id);
    if (response && response.isSuccess) {
      setRequirement(response.data.requirement);
      setFile(response.data.requirement.file);
      setStatus(response.data.requirement.status);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeStatus = async () => {
    if (requirement.status !== status) {
      setButtonLoading(true);
      const response = await ChangeRequirementStatus(requirement._id, {
        status,
      });
      console.log(status);
      console.log(response);
      if (response && response.isSuccess) {
        await fetchData();
        toast.success("Requirement Status Updated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      setButtonLoading(false);
    }
  };

  const handleAprove = async () => {
    setButtonLoading(true);

    const response = await ChangeRequirementStatus(requirement._id, {
      status: "active",
    });
    console.log(response);
    if (response && response.isSuccess) {
      await fetchData();
      toast.success("Requirement Aproved!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    setButtonLoading(false);
  };

  const handleReject = async () => {
    setButtonLoading(true);

    const response = await ChangeRequirementStatus(requirement._id, {
      status: "disable",
    });
    if (response && response.isSuccess) {
      await fetchData();

      toast.success("Company Rejected!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    setButtonLoading(false);
  };

  const confirmDelete = async (id) => {
    await DeleteRequirement(id);
  };

  const handleDelete = async () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this category!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(requirement._id).then(() => {
          SweetAlert.fire("Deleted!", "Requirement deleted.", "success");
          history.push("/dashboard/requirements");
        });
      } else {
        SweetAlert.fire("Requirement is safe!");
      }
    });
  };

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <div>
      <Fragment>
        <Container fluid={true}>
          <div className="page-header">
            <Row>
              <Col lg="9" sm="3">
                <Breadcrumbs title="Requirement Details" parent="Companies" />
              </Col>
              <Col lg="3" sm="3">
                {buttonLoading ? (
                  <Button color="primary btn-block" disabled={buttonLoading}>
                    Loading
                  </Button>
                ) : (
                  <Button onClick={handleDelete} color="danger btn-block">
                    <FontAwesomeIcon className="mr-1" icon={faTrashAlt} />{" "}
                    Delete
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div className="contentbar">
            <div className="row">
              <div className="col-lg-8 col-xl-9">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Requirement Details
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-md">
                        <tbody>
                          <tr>
                            <td className="border">Product Name</td>
                            <td className="border">
                              {requirement.productName}
                            </td>
                          </tr>

                          <tr>
                            <td className="border">
                              Similar Product Subcategory
                            </td>
                            <td className="border">
                              {requirement.produtType
                                ? requirement.produtType.name
                                : "---"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Details</td>
                            <td className="border">{requirement.note}</td>
                          </tr>
                          <tr>
                            <td className="border">Contact Number</td>
                            <td className="border">
                              {requirement.contactNumber}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Contact Email</td>
                            <td className="border">
                              {requirement.contactEmail
                                ? requirement.contactEmail
                                : "---"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Posted on</td>
                            <td className="border">
                              {requirement.createdAt.toString().slice(0, 10)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Buyer Information
                    </h6>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-striped table-md">
                        <tbody>
                          <tr>
                            <td className="border">Name</td>
                            <td className="border">
                              {requirement.buyer.userInfo.firstName}{" "}
                              {requirement.buyer.userInfo.lastName}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Email</td>
                            <td className="border">
                              {requirement.buyer.email
                                ? requirement.buyer.email
                                : "---"}
                            </td>
                          </tr>
                          <tr>
                            <td className="border">Phone</td>
                            <td className="border">
                              {requirement.buyer.phone
                                ? requirement.buyer.phone
                                : "---"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-3">
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Attachment
                    </h6>
                  </div>
                  <div className="card-body">
                    <img
                      width="60px"
                      alt="Description"
                      src={
                        config.fileServer + config.imageContainer + file !==
                        null
                          ? config.fileServer + config.imageContainer + file
                          : noImage
                      }
                      className="img-fluid border border-color-1 rounded mx-auto d-block"
                    />
                  </div>
                </div>

                {status !== "pending" ? (
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Change Status
                      </h6>
                    </div>
                    <div className="card-body">
                      <select
                        className="form-select form-control-sm form-control mb-3"
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}
                      >
                        <option value="active">Active</option>
                        <option value="closed">Close</option>
                        <option value="disabled">Diasble</option>
                      </select>
                    </div>

                    <div className="card-footer text-right">
                      {buttonLoading ? (
                        <Button
                          color="primary btn-block"
                          disabled={buttonLoading}
                        >
                          SAVING...
                        </Button>
                      ) : (
                        <Button
                          onClick={handleChangeStatus}
                          color="primary btn-block"
                        >
                          <FontAwesomeIcon className="mr-1" icon={faSave} />{" "}
                          Save
                        </Button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Requirement Approval
                      </h6>
                    </div>
                    <div className="card-body">
                      <h6 className="m-0 font-weight-normal text-primary text-center">
                        Aprove This Requirement ?
                      </h6>
                    </div>

                    <div className="card-footer text-center p-4">
                      {buttonLoading ? (
                        <Button
                          color="primary btn-block"
                          disabled={buttonLoading}
                        >
                          SAVING...
                        </Button>
                      ) : (
                        <ButtonGroup className="w-100">
                          <Button
                            onClick={handleReject}
                            size="sm"
                            color="danger"
                          >
                            Reject
                          </Button>
                          <Button
                            size="sm"
                            onClick={handleAprove}
                            color="primary"
                          >
                            Aprove
                          </Button>
                        </ButtonGroup>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Container>
      </Fragment>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    requirement: state.mainReducers.main.requirement,
    products: state.mainReducers.main.products,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequirementDetailsPage);
