import React, { useState, useEffect } from "react";
import { Col, Card, CardBody, CardHeader } from "reactstrap";
import { GetOrderDetailsbyID } from "../../../../service/service";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const OrderComponent = ({ vendorRequest }) => {
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    const response = await GetOrderDetailsbyID(vendorRequest.docID);
    console.log(response);
    if (response && response.isSuccess) {
      setOrder(response.data.order);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (
      vendorRequest.requestType === "updateOrder" ||
      vendorRequest.requestType === "deleteOrder"
    )
      fetchData();
    else setLoading(false);
  }, []);

  const details = (value) => {
    switch (value) {
      case "addOrder":
        return (
          <div className="table-responsive">
            <table className="table table-hover table-md text-center">
              <thead>
                <tr className="font-01 weight">
                  <th className="border">Field Name</th>
                  <th className="border">New Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th className="border font-01 weight">Product's Name</th>
                  <td className="border">
                    {vendorRequest.requestData.products &&
                      vendorRequest.requestData.products.map((product) => (
                        <li key={product._id}>{product.name}</li>
                      ))}
                  </td>
                </tr>
                <tr>
                  <th className="border font-01 weight">Amount</th>
                  <td className="border">
                    {vendorRequest.requestData.amount &&
                      vendorRequest.requestData.amount}
                  </td>
                </tr>
                <tr>
                  <th className="border font-01 weight">Paid Amount</th>
                  <td className="border">
                    {vendorRequest.requestData.paidAmount &&
                      vendorRequest.requestData.paidAmount}
                  </td>
                </tr>
                <tr>
                  <th className="border font-01 weight">Payment Type</th>
                  <td className="border">
                    {vendorRequest.requestData.paymentType &&
                      vendorRequest.requestData.paymentType}
                  </td>
                </tr>
                <tr>
                  <th className="border font-01 weight">Payment Status</th>
                  <td className="border">
                    {vendorRequest.requestData.status &&
                      vendorRequest.requestData.status}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      case "updateOrder":
        return (
          <>
            {loading ? (
              <ComponentLoader height="40vh" />
            ) : (
              <div className="table-responsive">
                <table className="table table-hover table-md text-center">
                  <thead>
                    <tr className="font-01 weight">
                      <th className="border">Field Name</th>
                      <th className="border">Original Value</th>
                      <th className="border">New Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th className="border font-01 weight">Product's Name</th>
                      <td className="border">
                        {order.products &&
                          order.products.map((product) => (
                            <li key={product._id}>{product.name}</li>
                          ))}
                      </td>
                      <td className="border">
                        {vendorRequest.requestData.products &&
                          vendorRequest.requestData.products.map((product) => (
                            <li key={product._id}>{product.name}</li>
                          ))}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Amount</th>
                      <td className="border">{order.amount}</td>
                      <td className="border">
                        {vendorRequest.requestData.amount &&
                          vendorRequest.requestData.amount}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Paid Amount</th>
                      <td className="border">{order.paidAmount}</td>
                      <td className="border">
                        {vendorRequest.requestData.paidAmount &&
                          vendorRequest.requestData.paidAmount}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Payment Type</th>
                      <td className="border">{order.paymentType}</td>
                      <td className="border">
                        {vendorRequest.requestData.paymentType &&
                          vendorRequest.requestData.paymentType}
                      </td>
                    </tr>
                    <tr>
                      <th className="border font-01 weight">Payment Status</th>
                      <td className="border">{order.status}</td>
                      <td className="border">
                        {vendorRequest.requestData.status &&
                          vendorRequest.requestData.status}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </>
        );
      case "deleteOrder":
        return (
          <>
            {loading ? (
              <ComponentLoader height="40vh" />
            ) : (
              <>
                {vendorRequest.requestType === "deleteOrder" &&
                vendorRequest.status === "approved" ? null : (
                  <div className="table-responsive">
                    <table className="table table-hover table-md text-center">
                      <thead>
                        <tr className="font-01 weight">
                          <th className="border">Field Name</th>
                          <th className="border">Original Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th className="border font-01 weight">
                            Product's Name
                          </th>
                          <td className="border">
                            {order &&
                              order.products &&
                              order.products.map((product) => (
                                <li key={product._id}>{product.name}</li>
                              ))}
                          </td>
                        </tr>
                        <tr>
                          <th className="border font-01 weight">Amount</th>
                          <td className="border">{order && order.amount}</td>
                        </tr>
                        <tr>
                          <th className="border font-01 weight">Paid Amount</th>
                          <td className="border">
                            {order && order.paidAmount}
                          </td>
                        </tr>
                        <tr>
                          <th className="border font-01 weight">
                            Payment Type
                          </th>
                          <td className="border">
                            {order && order.paymentType}
                          </td>
                        </tr>
                        <tr>
                          <th className="border font-01 weight">
                            Payment Status
                          </th>
                          <td className="border">{order && order.status}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </>
            )}
          </>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Col sm="12">
        <Card>
          <CardHeader>
            <h6 className="m-0 font-weight-bold text-primary">
              {`Requested Data Details - ${vendorRequest.requestType}`}
            </h6>
          </CardHeader>
          <CardBody>{details(vendorRequest.requestType)}</CardBody>
        </Card>
      </Col>
    </>
  );
};

export default OrderComponent;
