import React from "react";
import { dateTime } from "../../../../../service/dateTimeFormat";
import SweetAlert from "sweetalert2";
import { DeleteCoupon } from "../../../../../service/service";
import { Link } from "react-router-dom";

const CouponRow = ({ data, setCallApi }) => {
  const { time, date } = dateTime(data.endDate);

  const confirmDelete = async (id) => {
    const response = await DeleteCoupon(id);
    if (response && response.isSuccess) {
      setCallApi(true);
    }
    console.log(response);
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Coupon!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Coupon deleted.", "success");
        });
      } else {
        SweetAlert.fire("Coupon is safe!");
      }
    });
  };

  return (
    <>
      <tr>
        <td className="border font-weight-bold">{data.couponCode}</td>
        <td className="border">{data.discountType}</td>
        <td className="border font-weight-bold">
          {data.discountAmount}
          {data.discountType === "percentage" && "%"}
        </td>
        <td className={`border ${data.limit > 0 && `font-weight-bold`} `}>
          {data.limit}
        </td>
        <td className="border">
          {date} {time}
        </td>
        <td className="border text-right">
          <div className="button-list">
            <Link to={`/dashboard/update-coupon/${data._id}`}>
              <button className="btn btn-text p-0">
                <i
                  className="fa fa-pencil"
                  style={{
                    width: 35,
                    fontSize: 16,
                    padding: 11,
                    color: "rgb(40, 167, 69)",
                  }}
                ></i>
              </button>
            </Link>
            <button
              className="btn btn-text p-0"
              onClick={() => {
                handleDelete(data._id);
              }}
            >
              <i
                className="fa fa-trash"
                style={{
                  width: 35,
                  fontSize: 16,
                  padding: 11,
                  color: "#e4566e",
                }}
              ></i>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};

export default CouponRow;
