import {
  ADMINMENU,
  MULTIVENDORMENU,
  SINGLEVENDORMENU,
  VENDORMENU,
} from "./menu";
import UserPanel from "./UserPanel";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import React, { useState, useEffect } from "react";
import config from "../../../../service/api/config";
import operations from "../../../../redux/Main/operations";

const Sidebar = (props) => {
  const [mainmenu, setMainMenu] = useState(null);
  const { user, company, subscription, siteSettings } = props;
  console.log(
    "🚀 ~ file: Sidebar.jsx:18 ~ Sidebar ~ siteSettings",
    siteSettings
  );
  console.log("🚀 ~ file: Sidebar.jsx:18 ~ Sidebar ~ company", company);
  console.log("🚀 ~ file: Sidebar.jsx:18 ~ Sidebar ~ user", user);

  const [MENUITEMS, setMENUITEMS] = useState(null);

  console.log(MENUITEMS);

  useEffect(() => {
    setMENUITEMS(
      user.type === "vendor"
        ? VENDORMENU
        : user.type === "admin"
        ? siteSettings.siteType === "multiVendor"
          ? MULTIVENDORMENU
          : SINGLEVENDORMENU
        : ADMINMENU
    );
    setMainMenu(
      user.type === "vendor"
        ? VENDORMENU
        : user.type === "admin"
        ? siteSettings.siteType === "multiVendor"
          ? MULTIVENDORMENU
          : SINGLEVENDORMENU
        : ADMINMENU
    );
  }, [user]);

  useEffect(() => {
    if (MENUITEMS) {
      var currentUrl = window.location.pathname;

      mainmenu.filter((items) => {
        if (!items.children) {
          if (items.path === currentUrl) setNavActive(items);
          return false;
        }
        items.children.filter((subItems) => {
          if (subItems.path === currentUrl) setNavActive(subItems);
          if (!subItems.children) return false;
          subItems.children.filter((subSubItems) => {
            if (subSubItems.path === currentUrl) {
              setNavActive(subSubItems);
              return true;
            } else {
              return false;
            }
          });
          return subItems;
        });
        return items;
      });
    }
    // eslint-disable-next-line
  }, [MENUITEMS]);

  const setNavActive = (item) => {
    MENUITEMS.filter((menuItem) => {
      if (menuItem !== item) menuItem.active = false;
      if (menuItem.children && menuItem.children.includes(item))
        menuItem.active = true;
      if (menuItem.children) {
        // eslint-disable-next-line
        menuItem.children.filter((submenuItems) => {
          if (submenuItems.children && submenuItems.children.includes(item)) {
            menuItem.active = true;
          }
          if (submenuItems !== item) {
            submenuItems.active = false;
          }
        });
      }
      return menuItem;
    });
    item.active = !item.active;
    setMainMenu({ mainmenu: MENUITEMS });
  };

  return (
    <div className="page-sidebar custom-scrollbar page-sidebar-open">
      <UserPanel
        name={company.name}
        profilePhoto={
          config.fileServer + config.thumbnailContainer + company.logo
        }
      />
      {user.type === "vendor" &&
      (!subscription || subscription.status !== "active") ? (
        <div className="sidebar-widget text-center">
          <div className="sidebar-widget-top">
            <h6 className="mb-2 fs-14">Need Help</h6>
            <i className="icon-bell"></i>
          </div>
          <div className="sidebar-widget-bottom p-20 m-20">
            <p>
              +1 234 567 899 <br /> bmart <br />{" "}
              <a href="#javaScript">Visit FAQ</a>
            </p>
          </div>
        </div>
      ) : (
        <ul className="sidebar-menu">
          {MENUITEMS &&
            MENUITEMS.map((menuItem, i) => {
              console.log(
                "🚀 ~ file: Sidebar.jsx:124 ~ Sidebar ~ siteSettings",
                siteSettings
              );
              if (
                (!menuItem.siteFeature ||
                  (menuItem.siteFeature &&
                    siteSettings.features[menuItem.siteFeature])) &&
                (!company.isVendor ||
                  !siteSettings.features.vendorAccessManagement ||
                  !menuItem.permission ||
                  (company.permissionType &&
                    company.permissionType[menuItem.permission][
                      menuItem.permissionType
                    ] !== 1)) &&
                (!siteSettings.features.portfolioOnly
                  ? menuItem
                  : menuItem.title !== "Stock" &&
                    menuItem.title !== "Orders" &&
                    menuItem.title !== "Users")
              )
                return (
                  <li className={`${menuItem.active ? "active" : ""}`} key={i}>
                    {menuItem.sidebartitle ? (
                      <div className="sidebar-title">
                        {menuItem.sidebartitle}
                      </div>
                    ) : (
                      ""
                    )}
                    {menuItem.type === "sub" ? (
                      <a
                        className="sidebar-header "
                        href="#javaScript"
                        onClick={() => setNavActive(menuItem)}
                      >
                        <i
                          className={`${
                            menuItem.icon.includes("icofont")
                              ? `icofont ${menuItem.icon}`
                              : `icon-${menuItem.icon}`
                          }`}
                        ></i>
                        <span>{menuItem.title}</span>
                        <i className="fa fa-angle-right pull-right"></i>
                      </a>
                    ) : (
                      ""
                    )}
                    {menuItem.type === "link" && (
                      <Link
                        to={`${process.env.PUBLIC_URL}${menuItem.path}`}
                        className={`sidebar-header ${
                          menuItem.active ? "Active" : ""
                        }`}
                        onClick={() => setNavActive(menuItem)}
                      >
                        <i
                          className={`${
                            menuItem.icon.includes("icofont")
                              ? `icofont ${menuItem.icon}`
                              : `icon-${menuItem.icon}`
                          }`}
                        ></i>
                        <span>{menuItem.title}</span>
                        {menuItem.children ? (
                          <i className="fa fa-angle-right pull-right"></i>
                        ) : (
                          ""
                        )}
                      </Link>
                    )}
                    {menuItem.children ? (
                      <ul
                        className={`sidebar-submenu ${
                          menuItem.active ? "menu-open" : ""
                        }`}
                        style={
                          menuItem.active
                            ? {
                                opacity: 1,
                                transition: "opacity 500ms ease-in",
                              }
                            : {}
                        }
                      >
                        {menuItem.children.map(
                          (childrenItem, index) =>
                            (!childrenItem.siteFeature ||
                              (childrenItem.siteFeature &&
                                siteSettings.features[
                                  childrenItem.siteFeature
                                ])) &&
                            (!company.isVendor ||
                              !siteSettings.features.vendorAccessManagement ||
                              !childrenItem.permission ||
                              (company.permissionType &&
                                company.permissionType[childrenItem.permission][
                                  childrenItem.permissionType
                                ] !== 1)) && (
                              <li
                                key={index}
                                className={
                                  childrenItem.children
                                    ? childrenItem.active
                                      ? "active"
                                      : ""
                                    : ""
                                }
                              >
                                {childrenItem.type === "sub" ? (
                                  <a
                                    href="#javaScript"
                                    onClick={() => setNavActive(childrenItem)}
                                  >
                                    <i className="fa fa-angle-right"></i>
                                    {childrenItem.title}
                                    <i className="fa fa-angle-down pull-right"></i>
                                  </a>
                                ) : (
                                  ""
                                )}

                                {childrenItem.type === "link" ? (
                                  <Link
                                    to={`${process.env.PUBLIC_URL}${childrenItem.path}`}
                                    className={
                                      childrenItem.active ? "active" : ""
                                    }
                                    onClick={() => setNavActive(childrenItem)}
                                  >
                                    <i className="fa fa-angle-right"></i>
                                    {childrenItem.title}{" "}
                                  </Link>
                                ) : (
                                  ""
                                )}
                                {childrenItem.children ? (
                                  <ul
                                    className={`sidebar-submenu ${
                                      childrenItem.active ? "menu-open" : ""
                                    }`}
                                  >
                                    {childrenItem.children.map(
                                      (childrenSubItem, key) => (
                                        <li
                                          className={
                                            childrenSubItem.active
                                              ? "active"
                                              : ""
                                          }
                                          key={key}
                                        >
                                          {childrenSubItem.type === "link" ? (
                                            <Link
                                              to={`${process.env.PUBLIC_URL}${childrenSubItem.path}`}
                                              className={
                                                childrenSubItem.active
                                                  ? "active"
                                                  : ""
                                              }
                                            >
                                              <i className="fa fa-angle-right"></i>
                                              {childrenSubItem.title}
                                            </Link>
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  ""
                                )}
                              </li>
                            )
                        )}
                      </ul>
                    ) : (
                      ""
                    )}
                  </li>
                );
            })}
        </ul>
      )}
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    subscription: state.mainReducers.main.subscription,
    user: state.mainReducers.main.user,
    siteSettings: state.mainReducers.main.siteSettings,
    auth: state.mainReducers.main.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
