import {
  ReAuth,
  DeletePhoto,
  UploadPhoto,
  GetUserProfile,
  UpdatePassword,
  UpdateuserProfile,
} from "../../../../service/service";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dropzone from "react-dropzone-uploader";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Button, Col, Container, Row } from "reactstrap";
import operations from "../../../../redux/Main/operations";
import setAuthToken from "../../../../service/setAuthToken";
import React, { Fragment, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const UpdateProfilePage = (props) => {
  const { user, company } = props;
  const [phn, setPhn] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [profilePhoto, setProfilePhoto] = useState(null);
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [passwordButton, setPasswordButton] = useState(false);

  const getUser = async () => {
    try {
      const response = await GetUserProfile(user.id);
      console.log(
        "🚀 ~ file: UpdateProfilePage.jsx:40 ~ getUser ~ response",
        response
      );
      if (response && response.data) {
        if (response.status === 200) {
          setUserInfo(response.data.user);
          setEmail(response.data.user.email);
          setPhn(response.data.user?.phone);
          setLastName(response.data.user.userInfo.lastName);
          setFirstName(response.data.user.userInfo.firstName);
          setProfilePhoto(response.data.user.userInfo.photoURL);
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user.id) getUser();
    else setUserInfo(null);
  }, [user]);

  const handleUploadPhoto = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setProfilePhoto(response.data.fileName);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleDeletePhoto = async (id) => {
    setDeletedPhotos([...deletedPhotos, id]);
    setProfilePhoto(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.firstName.value) {
      setButtonLoading(true);
      const data = {
        firstName: e.target.firstName.value ? e.target.firstName.value : null,
        lastName: e.target.lastName.value ? e.target.lastName.value : null,
        phone:
          userInfo.phone !== e.target.phone.value ? e.target.phone.value : null,
        photoURL: profilePhoto,
        email:
          userInfo.email !== e.target.email.value ? e.target.email.value : null,
        type: user.type,
      };
      const response = await UpdateuserProfile(data);
      if (response && response.isSuccess) {
        deletedPhotos.map((deletedPhoto) =>
          DeletePhoto({
            fileName: deletedPhoto,
            container: "ivcommerceoriginalcontainer",
          })
        );
        getUser();
        const authRes = await ReAuth({
          token: localStorage.getItem("auth_token"),
          companyID: company._id,
          type: user.type,
        });
        console.log(authRes);

        if (authRes && authRes.isSuccess) {
          const newUser = jwtDecode(authRes.data.token);

          if (newUser.email) {
            localStorage.setItem("auth_token", authRes.data.token);
            setAuthToken(authRes.data.token);
            props.setUser(newUser);
          }
        }
        toast.success("Profile Updated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      setButtonLoading(false);
    }
  };

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <div>
      <form onSubmit={handleSubmit} className="login">
        <Fragment>
          <Container fluid={true}>
            <div className="page-header">
              <Row>
                <Col lg="9" sm="3">
                  <Breadcrumbs title="Update Profile" parent="Dashboard" />
                </Col>
                <Col lg="3" sm="3">
                  {buttonLoading ? (
                    <Button color="primary btn-block" disabled={buttonLoading}>
                      SAVING...
                    </Button>
                  ) : (
                    <Button type="submit" color="primary btn-block">
                      <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                    </Button>
                  )}
                </Col>
              </Row>
            </div>

            <div className="contentbar">
              <div className="row">
                <div className="col-lg-8 col-xl-9">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Basic Informations
                      </h6>
                    </div>
                    <div className="card-body">
                      <div className="form-group row">
                        <label
                          htmlFor="productTitle"
                          className="col-sm-12 font-weight-bold small mb-1"
                        >
                          First Name
                        </label>
                        <div className="col-sm-12 mb-2">
                          <input
                            required
                            type="text"
                            name="firstName"
                            placeholder="First Name"
                            value={firstName ? firstName : "---"}
                            data-error-className="u-has-error"
                            data-success-className="u-has-success"
                            className="form-control-sm form-control"
                            data-msg="Please enter a valid email address."
                            onChange={(event) => {
                              setFirstName(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="productTitle"
                          className="col-sm-12 font-weight-bold small mb-1"
                        >
                          Last Name
                        </label>
                        <div className="col-sm-12 mb-2">
                          <input
                            type="text"
                            name="lastName"
                            placeholder="Name"
                            value={lastName ? lastName : "---"}
                            data-error-className="u-has-error"
                            data-success-className="u-has-success"
                            className="form-control-sm form-control"
                            data-msg="Please enter a valid email address."
                            onChange={(event) => {
                              setLastName(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="productTitle"
                          className="col-sm-12 font-weight-bold small mb-1"
                        >
                          Email
                        </label>
                        <div className="col-sm-12 mb-2">
                          <input
                            type="text"
                            name="email"
                            placeholder="Email"
                            defaultValue={email}
                            data-error-className="u-has-error"
                            data-success-className="u-has-success"
                            className="form-control-sm form-control"
                            data-msg="Please enter a valid email address."
                            onChange={(event) => {
                              setEmail(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="productTitle"
                          className="col-sm-12 font-weight-bold small mb-1"
                        >
                          Phone
                        </label>
                        <div className="col-sm-12 mb-2">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Phone"
                            defaultValue={phn}
                            data-error-className="u-has-error"
                            data-success-className="u-has-success"
                            className="form-control-sm form-control"
                            data-msg="Please enter a valid email address."
                            onChange={(event) => {
                              setPhn(event.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-xl-3">
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Profile Photo
                      </h6>
                    </div>
                    <div className="card-body">
                      {profilePhoto ? (
                        <div className="w-100 text-center">
                          <div className="w-100">
                            <img
                              src={
                                config.fileServer +
                                config.imageContainer +
                                profilePhoto
                              }
                              alt=""
                              className="mb-2 img-fluid"
                            />
                          </div>
                          <button
                            type="button"
                            className="btn btn-icon-split btn-sm btn-danger"
                            onClick={() => handleDeletePhoto(profilePhoto)}
                          >
                            Remove Photo
                          </button>
                        </div>
                      ) : (
                        <Dropzone
                          onChangeStatus={handleUploadPhoto}
                          accept="image/*"
                          PreviewComponent={null}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Fragment>
      </form>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    user: state.mainReducers.main.user,
    company: state.mainReducers.main.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfilePage);
