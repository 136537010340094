import {
  AddNewVendorRequest,
  DeleteOrder,
  GetAllOrders,
  GetSellerOrders,
} from "../../../../service/service";
import moment from "moment";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import React, { Fragment, useEffect, useState } from "react";
import { getComparator, stableSort } from "../../../../service/sort";
import errorImg from "../../../../assets/images/search-not-found.png";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

const OrdersPage = (props) => {
  const { company, vendors, siteSettings } = props;
  const [page, setPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [buyer, setBuyer] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [limit, setLimit] = React.useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [order, setOrder] = React.useState("desc");
  const [ordersCount, setOrdersCount] = useState(0);
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [filteredOrders, setFilteredOrders] = useState(null);
  const [csvData, setCsvData] = useState([]);

  // console.log("vendors", vendors);

  const getVendorData = async () => {
    setLoading(true);

    const data = {
      limit: limit,
      sortBy: {
        field: orderBy,
        order: order,
      },
      buyer: buyer ? buyer._id : null,
      orderID: searchKey ? searchKey : null,
    };

    const response = await GetSellerOrders(company?._id, data);
    if (response && response.isSuccess) {
      setOrders(response.data.orders);
      setOrdersCount(response.data.ordersCount);
    } else {
      setOrders([]);
      setOrdersCount(0);
    }
    setLoading(false);
  };

  const getSellerData = async () => {
    setLoading(true);

    const data = {
      limit: limit,
      sortBy: {
        field: orderBy,
        order: order,
      },
      buyer: buyer ? buyer._id : null,
      seller: vendor ? vendor : null,
      orderID: searchKey ? searchKey : null,
    };

    const response = await GetAllOrders(data);
    if (response && response.isSuccess) {
      setOrders(response.data.orders);
      setOrdersCount(response.data.ordersCount);
    } else {
      setOrders([]);
      setOrdersCount(0);
    }
    setLoading(false);
  };

  const loadMoreSeller = async () => {
    setButtonLoading(true);
    const data = {
      skip: orders.length,
      limit: limit,
      sortBy: {
        field: orderBy,
        order: order,
      },
      buyer: buyer ? buyer._id : null,
      seller: vendor ? vendor : null,
      orderID: searchKey ? searchKey : null,
    };

    const response = await GetAllOrders(data);

    if (response && response.isSuccess) {
      setOrders([...orders, ...response.data.orders]);
      setOrdersCount(response.data.ordersCount);
    }
    setButtonLoading(false);
  };

  const loadMoreVendor = async () => {
    setButtonLoading(true);
    const data = {
      skip: orders.length,
      limit: limit,
      sortBy: {
        field: orderBy,
        order: order,
      },
      buyer: buyer ? buyer._id : null,
      orderID: searchKey ? searchKey : null,
    };

    const response = await GetSellerOrders(company?._id, data);
    console.log(
      "🚀 ~ file: OrdersPage.jsx:126 ~ loadMoreVendor ~ response",
      response
    );

    if (response && response.isSuccess) {
      setOrders([...orders, ...response.data.orders]);
      setOrdersCount(response.data.ordersCount);
    }
    setButtonLoading(false);
  };

  const fetchData = async () => {
    const response = await GetSellerOrders(company._id);
    if (response && response.isSuccess) {
      setOrders(response.data.orders);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (company?._id && company?.isVendor) {
      getVendorData();
    } else if (company?._id && !company?.isVendor) {
      getSellerData();
    }
  }, [company, vendor, buyer, orderBy, order]);

  const confirmDelete = async (id) => {
    if (
      !siteSettings?.features?.vendorAccessManagement ||
      !company?.isVendor ||
      (company?.permissionType &&
        company?.permissionType?.orderManagement?.deleteOrder === 3)
    ) {
      const response = await DeleteOrder(id);
      if (response && response.isSuccess) {
        setOrders(
          orders.filter((order) => order?._id !== response?.data?.order?._id)
        );
        toast.success("Order Deleted", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } else if (
      company.permissionType &&
      company.permissionType.orderManagement.deleteOrder === 2
    ) {
      const response = await AddNewVendorRequest({
        vendor: company?._id,
        requestType: "deleteOrder",
        collectionName: "Order",
        docID: id,
        requestData: orders.find((item) => item._id === id),
      });

      console.log(response);

      if (response && response.isSuccess) {
        toast.success("Order Delete Requested! Wait for Admin Approval", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this order!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id);
      } else {
        SweetAlert.fire("Order is safe!");
      }
    });
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (company?._id && company?.isVendor) {
      getVendorData();
    } else if (company?._id && !company?.isVendor) {
      getSellerData();
    }
  };

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "amountAsc": {
        setOrderBy("amount");
        setOrder("asc");
        break;
      }
      case "amountDesc": {
        setOrderBy("amount");
        setOrder("desc");
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (orders) {
      let arrayObj = orders;
      console.log(
        "🚀 ~ file: OrdersPage.jsx:255 ~ useEffect ~ arrayObj",
        arrayObj
      );

      arrayObj = arrayObj.map((item) => {
        return {
          OrderId: item.orderID,

          OrderStatus: item?.status,
          OrderDate: item?.activities[0]?.date,
          CurrencyUnit: item?.currencyUnit,
          BuyerEmail: item?.buyer?.email,
          BuyerPhone: item?.buyer?.phone,
          PaidAmount: item?.paidAmount,
          PaymentType: item?.paymentType,
          Seller_Name: item?.seller?.name,
          Seller_Phone: item?.seller?.phone,
          Amount: item?.amount,
          OfferAmount: item?.offerAmount,
          TotalAmount: item?.totalAmount,
          Product: JSON.stringify(
            item?.products.map((val) => {
              return { name: val?.name };
            })
          ),
        };
      });

      setCsvData(arrayObj);

      // console.log("arrayObj", arrayObj);
    }
  }, [orders]);

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col sm="12">
              <Breadcrumbs title="Orders" parent="Dashboard" />
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="4" lg="4" sm="12">
                    <form
                      onSubmit={handleSearch}
                      className="form-inline search-form"
                    >
                      <div className="form-group w-100">
                        <input
                          type="text"
                          id="search"
                          name="search"
                          value={searchKey}
                          className="form-control-plaintext rounded-0"
                          placeholder="Search.."
                          onChange={(e) => setSearchKey(e.target.value)}
                        />
                      </div>
                    </form>
                  </Col>

                  <Col md="4" lg="4" sm="12">
                    <div className="d-flex">
                      <div className="form-inline">
                        <div className="form-group w-100">
                          {siteSettings?.siteType === "multiVendor" &&
                            !company?.isVendor && (
                              <div className="form-inline">
                                <div className="form-group w-100">
                                  <select
                                    name="select"
                                    className="form-control btn-square border"
                                    onChange={(e) =>
                                      setVendor(
                                        e.target.value === "all"
                                          ? null
                                          : e.target.value
                                      )
                                    }
                                  >
                                    <option value="all">All Vendors</option>
                                    <option value={company?._id}>
                                      {company?.name} - Own Products
                                    </option>
                                    {vendors.map((vendor) => (
                                      <option
                                        value={vendor._id}
                                        key={vendor._id}
                                      >
                                        {vendor.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="form-inline">
                        <div className="form-group w-100">
                          <select
                            name="select"
                            className="form-control btn-square border"
                            onChange={(e) =>
                              handleRequestSort(e, e.target.value)
                            }
                          >
                            <option value="createdAt">Latest</option>
                            <option value="amountAsc">low to high</option>
                            <option value="amountDesc">high to low</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col md="4" lg="4" sm="12" className="text-end">
                    <CSVLink
                      filename="order.csv"
                      data={csvData}
                      enclosingCharacter={`'`}
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        // onClick={exportCsvHandler}
                        type="button"
                        className="border-0 py-2 px-3 rounded"
                      >
                        Export As CSV
                      </button>
                    </CSVLink>
                  </Col>
                </Row>
              </CardHeader>
              {loading ? (
                <ComponentLoader height="80vh" />
              ) : (
                <CardBody>
                  {(filteredOrders ? filteredOrders : orders).length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-md table-striped">
                        <thead>
                          <tr>
                            <th className="border">ID</th>
                            <th className="border">Products</th>
                            <th className="border">Product List</th>
                            <th className="border">Buyer Name</th>
                            {!company?.isVendor && (
                              <th className="border">Seller Name</th>
                            )}
                            <th className="border">Amount</th>
                            <th className="border">Status</th>
                            <th className="border">Date</th>
                            <th className="border">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {stableSort(
                            filteredOrders ? filteredOrders : orders,
                            getComparator(order, orderBy)
                          )
                            .slice(page * 10 - 10, page * 10)
                            .map((order) => (
                              <tr key={order?.orderID}>
                                <td className="border">{order?.orderID}</td>
                                <td className="border">
                                  {order?.products?.length}
                                </td>
                                <td className="border">
                                  {order?.products.map((product) => {
                                    return (
                                      <li key={product?._id}>
                                        {`${product?.name}`}
                                      </li>
                                    );
                                  })}
                                </td>
                                <td className="border">
                                  {order?.buyer &&
                                    `${order?.buyer?.userInfo?.firstName} ${order?.buyer?.userInfo?.lastName}`}
                                </td>
                                {!company?.isVendor && (
                                  <th className="border">
                                    {order.seller?.name}
                                  </th>
                                )}
                                <td className="border">
                                  {order.amount.toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                  })}
                                </td>
                                <td className="border text-capitalize">
                                  {order.status}
                                </td>
                                <td className="border">
                                  {moment(order.createdAt).format("D MMM YYYY")}
                                </td>

                                <td className="border">
                                  <div className="button-list">
                                    <Link
                                      to={`/dashboard/update-order/${order?.orderID}`}
                                    >
                                      <button className="btn btn-text p-0">
                                        <i
                                          className="fa fa-pencil"
                                          style={{
                                            width: 35,
                                            fontSize: 16,
                                            padding: 11,
                                            color: "rgb(40, 167, 69)",
                                          }}
                                        ></i>
                                      </button>
                                    </Link>
                                    {(!siteSettings?.features
                                      ?.vendorAccessManagement ||
                                      !company?.isVendor ||
                                      (company?.permissionType &&
                                        company.permissionType?.orderManagement
                                          .deleteOrder !== 1)) && (
                                      <button
                                        onClick={(e) =>
                                          handleDelete(order?._id)
                                        }
                                        className="btn btn-text p-0"
                                      >
                                        <i
                                          className="fa fa-trash"
                                          style={{
                                            width: 35,
                                            fontSize: 16,
                                            padding: 11,
                                            color: "#e4566e",
                                          }}
                                        ></i>
                                      </button>
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          {orders.length < ordersCount && (
                            <tr>
                              <td colSpan="7" align="center">
                                <button
                                  onClick={
                                    company?.isVendor
                                      ? loadMoreVendor
                                      : loadMoreSeller
                                  }
                                  className="btn btn-primary btn-sm"
                                >
                                  {buttonLoading ? "LOADING" : "Load More"}
                                </button>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div className="search-not-found text-center">
                      <div>
                        <img
                          className="img-fluid second-search"
                          src={errorImg}
                          alt="Error Image"
                        />
                        <p>Sorry, We didn't find any products</p>
                      </div>
                    </div>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    orders: state.mainReducers.main.orders,
    company: state.mainReducers.main.company,
    vendors: state.mainReducers.main.vendors,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(OrdersPage);
