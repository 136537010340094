import React, { useEffect, useState } from "react";
import { Col, Row, Button, Container } from "reactstrap";
import Breadcrumbs from "../../Components/Breadcrumbs";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import errorImg from "../../../../assets/images/search-not-found.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { GetAllCoupon } from "../../../../service/service";
import CouponTable from "./Components/CouponTable";

const Coupon = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [callApi, setCallApi] = useState(true);

  const fetchCoupon = async () => {
    try {
      const response = await GetAllCoupon();
      if (response && response.isSuccess) {
        setLoading(false);
        setData(response.data.allCoupons);
        setCallApi(false);
        console.log(response);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCoupon();
  }, [callApi]);

  return (
    <Container>
      <div className="page-header">
        <Row>
          <Col lg="8" xs="12">
            <Breadcrumbs title="Coupon" parent="Dashboard" />
          </Col>
          <Col lg="4" xs="12">
            <Link
              className="btn btn-primary btn-block"
              to="/dashboard/create-coupon"
            >
              <FontAwesomeIcon className="mr-1" icon={faPlus} />
              Create Coupon
            </Link>
          </Col>
        </Row>
      </div>
      {loading ? (
        <ComponentLoader height="80vh" />
      ) : error ? (
        <div className="search-not-found text-center my-auto">
          <div>
            <img className="img-fluid second-search" src={errorImg} alt="" />
            <p>Sorry, We didn't find any Coupons</p>
          </div>
        </div>
      ) : (
        <CouponTable data={data} setCallApi={setCallApi} />
      )}
    </Container>
  );
};

export default Coupon;
