import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import Dropzone from "react-dropzone-uploader";
import config from "../../../../../service/api/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { DeletePhoto, UploadPhoto } from "../../../../../service/service";
import { CreateNewPromotion } from "../../../../../service/service";

const CreatePromotion = ({ toggle, open, setDataSubmit }) => {
  const [title, setTitle] = useState("");
  const [photo, setPhoto] = useState(null);
  const [promotionImage, setPromotionImage] = useState({});
  const [url, setUrl] = useState("");
  const [IsActive, SetIsActive] = useState(false);

  const handleUploadPhoto = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setPhoto(response.data.fileName);
          setPromotionImage(response.data);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleDeletePhoto = async (photo) => {
    await DeletePhoto({
      fileName: photo,
      container: "ivcommerceoriginalcontainer",
    });
    setPhoto(null);
    console.log("Deleted");
  };

  const handleSubmission = async (e) => {
    e.preventDefault();

    if (title && promotionImage && IsActive) {
      console.log({ title, promotionImage, IsActive, url });

      const data = {
        title: title,
        url: url,
        promotionImage: promotionImage,
        IsActive: IsActive,
      };

      console.log(data);

      const response = await CreateNewPromotion(data);

      if (response && response.isSuccess) {
        toggle();
        toast.success("Promotion Created!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setDataSubmit(true);
        setPhoto(null);
      }
    } else {
      toast.warning("Please provide all detals ", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  return (
    <Modal isOpen={open} centered>
      <ModalHeader toggle={toggle}>Create Promotion</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmission}>
          <FormGroup>
            <Label for="promotionTitle">Promotion Title</Label>

            <Input
              id="promotionTitle"
              name="promotionTitle"
              placeholder="Enter Title"
              type="text"
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormGroup>

          <FormGroup>
            <Label for="promotionUrl">URL</Label>

            <Input
              id="promotionUrl"
              name="promotionUrl"
              placeholder="Enter Url"
              type="text"
              onChange={(e) => setUrl(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="" className="mr-4">
              Show
            </Label>
            <Input
              id="isActive"
              name="isActive"
              type="checkbox"
              onChange={() => SetIsActive(!IsActive)}
            />
          </FormGroup>

          <FormGroup>
            <Label for="promotionImage">
              Image (Please upload wider image for better view)
            </Label>
            <div>
              {photo ? (
                <div className="w-100 text-center">
                  <div className="w-100">
                    <img
                      src={config.fileServer + config.imageContainer + photo}
                      alt=""
                      className="mb-2"
                      width="50%"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-icon-split btn-sm btn-danger"
                    onClick={() => handleDeletePhoto(photo)}
                  >
                    Remove Photo
                  </button>
                </div>
              ) : (
                <Dropzone
                  onChangeStatus={handleUploadPhoto}
                  accept="image/*"
                  PreviewComponent={null}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                />
              )}
            </div>
          </FormGroup>

          <Button type="submit" color="primary btn-block">
            <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
          </Button>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CreatePromotion;
