import React from "react";
import { imageExists } from "../../../../service/imageExists";
import noImage from "../../../../assets/images/notFound.png";

const UserPanel = (props) => {
  const { profilePhoto, name } = props;
  return (
    <div className="sidebar-user text-center">
      <div>
        <img
          className="img-50 rounded-circle"
          src={imageExists(profilePhoto) ? profilePhoto : noImage}
          alt="not found"
        />
      </div>
      <h6 className="mt-3 f-12">{name}</h6>
      <a href="#javaScript" style={{ textDecoration: "none" }}>
        <i className="fa fa-circle text-success"></i> Online
      </a>
    </div>
  );
};

export default UserPanel;
