const urlArray = [
  "http://localhost:4101",
  "https://ivmv.azurewebsites.net",
  "https://ivseller.azurewebsites.net",
  "http://localhost:3210",
  "http://localhost:4100",
  "https://ivseller.azurewebsites.net",
  "https://earot.xyz",
  "https://www.seller.earot.xyz",
  "https://www.earot.xyz",
  "https://seller.earot.xyz",
];

var flag = false;

urlArray.some((url) => {
  flag = url.includes(window.location.origin);
  if (flag) return flag;
});
let productionUrl = "";

// if (flag) {
//   productionUrl = "https://estoreapiprod.azurewebsites.net/";
// } else {
//   productionUrl = "https://bmartdevapi.azurewebsites.net/";
// }
const config = {
  // local server
  // serverURL: "http://localhost:5000/",
  // Live  server
  serverURL: "https://ivcommerceapiprod.azurewebsites.net/",

  // developement server

  //production url: https://ivcommerceapiprod.azurewebsites.net/
  //dev url: https://ivcommerceapidev.azurewebsites.net/
  // serverURL:
  //   process.env.NODE_ENV === "production"
  //     ? "https://ivcommerceapiprod.azurewebsites.net/"
  //     : "https://ivcommerceapidev.azurewebsites.net/",

  //production server
  // serverURL: productionUrl,

  //file server
  fileServer:
    process.env.NODE_ENV === "production"
      ? "https://ivestore.blob.core.windows.net/"
      : "https://ivestore.blob.core.windows.net/",

  //original container
  imageContainer:
    process.env.NODE_ENV === "production"
      ? "ivcommerceoriginalcontainer/"
      : "ivcommerceoriginalcontainer/",

  //thumbnail container
  thumbnailContainer:
    process.env.NODE_ENV === "production"
      ? "ivcommercethumbnailcontainer/"
      : "ivcommercethumbnailcontainer/",

  //file container
  fileContainer:
    process.env.NODE_ENV === "production"
      ? "ivcommercefiles/"
      : "ivcommercefiles/",
};

export default config;
