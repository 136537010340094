import React, { Fragment, useEffect, useState } from "react";
import {
  DeletePhoto,
  UploadPhoto,
  GetSiteByShop,
  UpdateSiteSettings,
  UpdateSiteTheme,
} from "../../../../service/service";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import config from "../../../../service/api/config";
import operations from "../../../../redux/Main/operations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import {
  Col,
  Row,
  Button,
  Input,
  Label,
  FormGroup,
  Container,
} from "reactstrap";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone-uploader";
import ImageObject from "./Components/ImageObject";
import ReferenceID from "./Components/ReferenceID";
import CountryObject from "./Components/CountryObject";
import Breadcrumbs from "../../Components/Breadcrumbs";
import ProductSection from "./Components/PoductSection/ProductSection";
import { Select } from "@material-ui/core";

const UpdateSiteSettingsPage = (props) => {
  const { company, siteSetting } = props;

  const [loading, setLoading] = useState(true);
  const [siteLogo, setSiteLogo] = useState({
    header: null,
    nav: null,
  });
  const [themeColor, setThemeColor] = useState(null);
  const [deletedNavs, setDeletedNavs] = useState([]);
  const [homeSections, setHomeSections] = useState({});
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [siteSettings, setSiteSettings] = useState(null);
  const [deletedHeaders, setDeletedHeaders] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [textScale, setTextScale] = useState(0);
  const [titleFont, setTitleFont] = useState("");
  const [titleSize, setTitleSize] = useState("");
  const [subTitleFont, setSubTitleFont] = useState("");
  const [subTitleFontsize, setSubTitleFontSize] = useState("");
  console.log(
    "🚀 ~ file: UpdateSiteSettings.jsx:58 ~ UpdateSiteSettingsPage ~ subTitleFontsize",
    subTitleFontsize
  );
  const [bodyOneFont, setBodyOneFont] = useState("");
  const [bodyOneFontsize, setBodyOneFontsize] = useState("");
  const [bodyTwoFont, setBodyTwoFont] = useState("");
  const [bodyTwoFontsize, setBodyTwoFontsize] = useState("");
  const [radius, setRadius] = useState("");
  console.log(
    "🚀 ~ file: UpdateSiteSettings.jsx:59 ~ UpdateSiteSettingsPage ~ radius",
    radius
  );
  const [radius2, setRadius2] = useState("");
  const [scale, setScale] = useState("");

  const fontSize = [
    0, 1, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 36, 40,
  ];
  const fonts = ["Oswald", "popins", "sans-sarif"];
  const [themeId, setThemeId] = useState(siteSetting.theme._id);

  const getSiteSettings = async () => {
    try {
      const response = await GetSiteByShop(company?._id);
      console.log(
        "🚀 ~ file: UpdateSiteSettings.jsx:73 ~ getSiteSettings ~ response",
        response
      );
      if (response && response.isSuccess) {
        setSiteSettings(response.data.siteSettings);
        setSiteLogo(response.data.siteSettings.logo);
        setThemeColor(
          response.data.siteSettings.color
            ? response.data.siteSettings.color
            : response.data.siteSettings.theme.color
        );

        // setHomeSections(
        //   response.data.siteSettings.homeSections
        //     ? response.data.siteSettings.homeSections
        //     : {}
        // );
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUploadHeaderLogo = async ({ meta, file }, status, allFiles) => {
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setSiteLogo({ ...siteLogo, header: response.data.fileName });
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleUploadNavLogo = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setSiteLogo({ ...siteLogo, nav: response.data.fileName });
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleDeleteHeaderLogo = async (header) => {
    setSiteLogo({ ...siteLogo, header: null });
    setDeletedHeaders([...deletedHeaders, header]);
  };

  const handleDeleteNavLogo = async (nav) => {
    setDeletedNavs([...deletedNavs, nav]);
    setSiteLogo({ ...siteLogo, nav: null });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (e.target.title.value) {
      setButtonLoading(true);
      const data = {
        title: e.target.title.value,
        themeId: themeId,
        // titleFontSize: titleSize,
        // subTitleFontFamily: subTitleFont,
        // subTitleFontSize: subTitleFontsize,
        // bodyOneFontFamily: bodyOneFont,
        // bodOneFontSize: bodyOneFontsize,
        // bodyTwoFontFamily: bodyTwoFont,
        // bodyTwoFontSize: bodyTwoFontsize,

        // textScale: scale,
        // primary: e.target.primaryColor.value,
        // secondary: e.target.secondaryColor.value,
        // error: e.target.errorColor.value,
        // success: e.target.successColor.value,
        // info: e.target.infoColor.value,
        // warning: e.target.warningColor.value,
        // dark: e.target.darkColor.value,
        // light: e.target.lightColor.value,

        // homeSections: homeSections,
        theme: {
          fonts: {
            title: {
              fontFamily: e?.target?.fontFamily?.value
                ? e?.target?.fontFamily?.value
                : null,
              fontSize: e?.target?.fontSize?.value
                ? e?.target?.fontSize?.value
                : null,
            },
            subtitle: {
              fontFamily: e?.target?.subFontFamily?.value
                ? e?.target?.subFontFamily?.value
                : null,
              fontSize: e?.target?.subFontSize?.value
                ? e?.target?.subFontSize?.value
                : null,
            },
            body1: {
              fontFamily: e?.target?.bodyOneFontFamily?.value
                ? e?.target?.bodyOneFontFamily?.value
                : null,
              fontSize: e?.target?.bodyOneFontSize?.value
                ? e?.target?.bodyOneFontSize?.value
                : null,
            },
            body2: {
              fontFamily: e?.target?.bodyTwoFontFamily?.value
                ? e?.target?.bodyTwoFontFamily?.value
                : null,
              fontSize: e?.target?.bodyTwoFontSize?.value
                ? e?.target?.bodyTwoFontSize?.value
                : null,
            },
          },
          sizes: {
            radius: e?.target.radius?.value ? e?.target?.radius?.value : null,
            radius2: e?.target.radius2?.value
              ? e?.target?.radius2?.value
              : null,
            textScale: e?.target?.textScale?.value
              ? e?.target?.textScale?.value
              : null,
          },
          colors: {
            primary: e?.target?.primaryColor?.value
              ? e?.target?.primaryColor?.value
              : null,
            secondary: e?.target?.secondaryColor?.value
              ? e?.target?.secondaryColor?.value
              : null,
          },
        },
        socialLinks: {
          facebook: e.target.facebook.value ? e.target.facebook.value : null,
          twitter: e.target.twitter.value ? e.target.twitter.value : null,
          instagram: e.target.instagram.value ? e.target.instagram.value : null,
          youtube: e.target.youtube.value ? e.target.youtube.value : null,
          linkedin: e.target.linkedin.value ? e.target.linkedin.value : null,
        },
        fonts: {
          title: {
            fontFamily: e.target.titleFont.value,
            fontSize: e.target.titleSize.value,
          },
          subtitle: {
            fontFamily: e.target.subTitleFont.value,
            fontSize: e.target.titleSize.value,
          },
          body1: {
            fontFamily: e.target.bodyOneFont.value,
            fontSize: e.target.bodyOneFontSize.value,
          },
          body2: {
            fontFamily: e.target.bodyTwoTitleFont.value,
            fontSize: e.target.bodyTwoFontSize.value,
          },
        },
        sizes: {
          radius: radius,
          radius2: radius2,
          scale: scale,
        },
        colors: {
          primary: e.target.primaryColor.value,
          secondary: e.target.secondaryColor.value,
          error: e.target.errorColor.value,
          success: e.target.successColor.value,
          info: e.target.infoColor.value,
          warning: e.target.warningColor.value,
          dark: e.target.darkColor.value,
          light: e.target.lightColor.value,
        },
        logo: siteLogo,
      };

      try {
        const response = await UpdateSiteSettings(siteSetting?._id, data);

        if (response && response.isSuccess) {
          deletedPhotos.map((deletedPhoto) =>
            DeletePhoto({
              fileName: deletedPhoto,
              container: "ivcommerceoriginalcontainer",
            })
          );
          deletedNavs.map((deletedNav) =>
            DeletePhoto({
              fileName: deletedNav,
              container: "ivcommerceoriginalcontainer",
            })
          );
          deletedHeaders.map((deletedHeader) =>
            DeletePhoto({
              fileName: deletedHeader,
              container: "ivcommerceoriginalcontainer",
            })
          );
          // getSiteSettings();
          toast.success("Site Updated Updated!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          console.log(response);
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      } catch (error) {
        console.log(error);
      }
      setButtonLoading(false);
    }
  };

  useEffect(() => {
    if (company?._id) getSiteSettings();
    else setSiteSettings(null);
  }, [company]);
  useEffect(() => {
    if (siteSetting) {
      const { sizes } = siteSetting.theme;
      setRadius(sizes?.radius);
      setRadius2(sizes?.radius2);
    }
  }, [siteSetting]);

  return (
    <div>
      <form onSubmit={handleSubmit} className="login">
        <Fragment>
          <Container fluid={true}>
            <div className="page-header">
              <Row>
                <Col lg="9" sm="3">
                  <Breadcrumbs
                    title="Update Site Settings"
                    parent="Dashboard"
                  />
                </Col>
                <Col lg="3" sm="3">
                  {buttonLoading ? (
                    <Button color="primary btn-block" disabled={buttonLoading}>
                      SAVING...
                    </Button>
                  ) : (
                    <Button type="submit" color="primary btn-block">
                      <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                    </Button>
                  )}
                </Col>
              </Row>
            </div>

            <div className="contentbar">
              <div className="row">
                <div
                  className={
                    siteSettings?.theme?.homeSections
                      ? `col-lg-8 col-xl-9`
                      : `col-lg-12 col-xl-12`
                  }
                >
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Basic Informations
                      </h6>
                    </div>
                    <div className="card-body">
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label className="font-weight-bold small">
                              Site Title
                            </Label>
                            <Input
                              className="form-control"
                              type="text"
                              name="title"
                              placeholder="Site Title"
                              defaultValue={siteSetting?.title}
                              required
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Site URL
                        </Label>
                        {siteSetting?.siteURL &&
                          siteSetting?.siteURL.map((url) => (
                            <Input
                              className="form-control"
                              type="text"
                              defaultValue={url}
                              disabled
                            />
                          ))}
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Primary Color
                        </Label>
                        <Input
                          className="form-control"
                          style={{ height: "80px" }}
                          type="color"
                          name="primaryColor"
                          defaultValue={siteSetting?.theme?.colors?.primary}
                          onChange={(e) => setThemeColor(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Secondary Color
                        </Label>
                        <Input
                          className="form-control"
                          style={{ height: "80px" }}
                          type="color"
                          name="secondaryColor"
                          defaultValue={siteSetting?.theme?.colors?.secondary}
                          onChange={(e) => setThemeColor(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Error Color
                        </Label>
                        <Input
                          className="form-control"
                          style={{ height: "80px" }}
                          type="color"
                          name="errorColor"
                          defaultValue={siteSetting.theme.colors.error}
                          onChange={(e) => setThemeColor(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Warning Color
                        </Label>
                        <Input
                          className="form-control"
                          style={{ height: "80px" }}
                          type="color"
                          name="warningColor"
                          defaultValue={siteSetting.theme.colors.warning}
                          onChange={(e) => setThemeColor(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Info Color
                        </Label>
                        <Input
                          className="form-control"
                          style={{ height: "80px" }}
                          type="color"
                          name="infoColor"
                          defaultValue={siteSetting.theme.colors.info}
                          onChange={(e) => setThemeColor(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Success Color
                        </Label>
                        <Input
                          className="form-control"
                          style={{ height: "80px" }}
                          type="color"
                          name="successColor"
                          defaultValue={siteSetting.theme.colors.success}
                          onChange={(e) => setThemeColor(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Dark Color
                        </Label>
                        <Input
                          className="form-control"
                          style={{ height: "80px" }}
                          type="color"
                          name="darkColor"
                          defaultValue={siteSetting.theme.colors.dark}
                          onChange={(e) => setThemeColor(e.target.value)}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Light Color
                        </Label>
                        <Input
                          className="form-control"
                          style={{ height: "80px" }}
                          type="color"
                          name="lightColor"
                          defaultValue={siteSetting.theme.colors.light}
                          onChange={(e) => setThemeColor(e.target.value)}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Themes & fonts
                      </h6>
                    </div>
                    <div className="card-body">
                      <FormGroup>
                        <div className="d-flex">
                          <span className="w-100">
                            <Label
                              className="font-weight-bold small capitalized"
                              for="titleFont"
                            >
                              Title Font
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2"
                              name="titleFont"
                              onChange={(e) => setTitleFont(e.target.value)}
                              defaultValue={
                                siteSetting?.theme?.fonts?.title?.fontFamily
                              }
                            >
                              <option>Open this select menu</option>
                              {fonts.map((ft) => {
                                return (
                                  <option
                                    defaultValue={
                                      siteSetting?.theme?.fonts?.title
                                        ?.fontFamily
                                    }
                                  >
                                    {ft}
                                  </option>
                                );
                              })}
                            </select>
                          </span>
                          {/* <Input
                            className="form-control w-25"
                            type="text"
                            name="fontFamily"
                            placeholder="Font Family "
                            defaultValue={
                              siteSetting?.theme?.fonts?.title?.fontFamily &&
                              siteSetting?.theme?.fonts?.title?.fontFamily
                            }
                          /> */}

                          {/* <Input
                            className="form-control w-25 ms-5"
                            type="number"
                            name="fontSize"
                            placeholder="Font Size "
                            defaultValue={
                              siteSetting?.theme?.fonts?.title?.fontSize &&
                              siteSetting?.theme?.fonts?.title?.fontSize
                            }
                          /> */}
                          <span className="w-100">
                            <Label
                              className="font-weight-bold small capitalized"
                              for="titleSize"
                            >
                              Title Size
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2 ms-2"
                              onChange={(e) => setTitleSize(e.target.value)}
                              name="titleSize"
                              defaultValue={
                                siteSetting?.theme?.fonts?.title?.fontSize
                              }
                            >
                              <option>Open this select menu</option>
                              {fontSize.map((fz) => {
                                return (
                                  <option
                                    defaultValue={
                                      siteSetting?.theme?.fonts?.title?.fontSize
                                    }
                                  >
                                    {fz}
                                  </option>
                                );
                              })}
                            </select>
                          </span>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="d-flex">
                          <span className="w-100">
                            <Label
                              className="font-weight-bold small capitalized"
                              for="subTitleFont"
                            >
                              Subtitle Font
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2"
                              onChange={(e) => setSubTitleFont(e.target.value)}
                              name="subTitleFont"
                              defaultValue={
                                siteSetting?.theme?.fonts?.title?.fontFamily
                              }
                            >
                              <option value="-1">Open this select menu</option>
                              {fonts.map((ft) => {
                                return <option>{ft}</option>;
                              })}
                            </select>
                          </span>
                          {/* <Input
                            className="form-control w-25"
                            type="text"
                            name="fontFamily"
                            placeholder="Font Family "
                            defaultValue={
                              siteSetting?.theme.fonts.title.fontFamily &&
                              siteSetting?.theme.fonts.title.fontFamily
                            }
                          /> */}

                          {/* <Input
                            className="form-control w-25 ms-5"
                            type="number"
                            name="fontSize"
                            placeholder="Font Size "
                            defaultValue={
                              siteSetting?.theme.fonts.title.fontSize &&
                              siteSetting?.theme.fonts.title.fontSize
                            }
                          /> */}
                          <span className="w-100">
                            <Label
                              className="font-weight-bold small capitalized"
                              for="subTitleSize"
                            >
                              Subtitle Size
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2 ms-2"
                              name="subTitleSize"
                              onChange={(e) =>
                                setSubTitleFontSize(e.target.value)
                              }
                              defaultValue={
                                siteSetting?.theme?.fonts?.subtitle?.fontSize
                              }
                            >
                              <option>Open this select menu</option>
                              {fontSize.map((fz) => {
                                return <option>{fz}</option>;
                              })}
                            </select>
                          </span>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="d-flex">
                          <span className="w-100">
                            <Label
                              className="font-weight-bold small capitalized"
                              for="bodyOneFont"
                            >
                              Body One Font
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2"
                              name="bodyOneFont"
                              onChange={(e) => setBodyOneFont(e.target.value)}
                              defaultValue={
                                siteSetting?.theme?.fonts?.body1?.fontFamily
                              }
                            >
                              <option value="-1">Open this select menu</option>
                              {fonts.map((ft) => {
                                return <option>{ft}</option>;
                              })}
                            </select>
                          </span>
                          {/* <Input
                            className="form-control w-25"
                            type="text"
                            name="fontFamily"
                            placeholder="Font Family "
                            defaultValue={
                              siteSetting?.theme.fonts.title.fontFamily &&
                              siteSetting?.theme.fonts.title.fontFamily
                            }
                          /> */}

                          {/* <Input
                            className="form-control w-25 ms-5"
                            type="number"
                            name="fontSize"
                            placeholder="Font Size "
                            defaultValue={
                              siteSetting?.theme.fonts.title.fontSize &&
                              siteSetting?.theme.fonts.title.fontSize
                            }
                          /> */}
                          <span className="w-100">
                            <Label
                              className="font-weight-bold small capitalized"
                              for="bodyOneFontSize"
                            >
                              Body One fontSize
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2 ms-2"
                              name="bodyOneFontSize"
                              onChange={(e) =>
                                setBodyOneFontsize(e.target.value)
                              }
                              defaultValue={
                                siteSetting?.theme?.fonts?.body1?.fontSize
                              }
                            >
                              <option>Open this select menu</option>
                              {fontSize.map((fz) => {
                                return <option>{fz}</option>;
                              })}
                            </select>
                          </span>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="d-flex">
                          <span className="w-100">
                            <Label
                              className="font-weight-bold small capitalized"
                              for="bodyTwoTitleFont"
                            >
                              Body Two Font
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2"
                              name="bodyTwoTitleFont"
                              onChange={(e) => setBodyTwoFont(e.target.value)}
                              defaultValue={
                                siteSetting?.theme?.fonts?.body2?.fontFamily
                              }
                            >
                              <option value="-1">Open this select menu</option>
                              {fonts.map((ft) => {
                                return <option>{ft}</option>;
                              })}
                            </select>
                          </span>
                          {/* <Input
                            className="form-control w-25"
                            type="text"
                            name="fontFamily"
                            placeholder="Font Family "
                            defaultValue={
                              siteSetting?.theme.fonts.title.fontFamily &&
                              siteSetting?.theme.fonts.title.fontFamily
                            }
                          /> */}

                          {/* <Input
                            className="form-control w-25 ms-5"
                            type="number"
                            name="fontSize"
                            placeholder="Font Size "
                            defaultValue={
                              siteSetting?.theme.fonts.title.fontSize &&
                              siteSetting?.theme.fonts.title.fontSize
                            }
                          /> */}
                          <span className="w-100">
                            <Label
                              className="font-weight-bold small capitalized"
                              for="bodyTwoFontSize"
                            >
                              Body Two Size
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2 ms-2"
                              name="bodyTwoFontSize"
                              onChange={(e) =>
                                setBodyTwoFontsize(e.target.value)
                              }
                              defaultValue={
                                siteSetting?.theme?.fonts?.body2?.fontSize
                              }
                            >
                              <option>Open this select menu</option>
                              {fontSize.map((fz) => {
                                return <option>{fz}</option>;
                              })}
                            </select>
                          </span>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="d-flex">
                          <span className="w-100">
                            <Label
                              className="font-weight-bold small capitalized"
                              for="radius"
                            >
                              Radius
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2"
                              name="radius"
                              onChange={(e) => setRadius(e.target.value)}
                              value={radius}
                            >
                              {fontSize.map((ft) => {
                                return <option value={ft}>{ft}</option>;
                              })}
                            </select>
                          </span>
                          {/* <Input
                            className="form-control w-25"
                            type="text"
                            name="fontFamily"
                            placeholder="Font Family "
                            defaultValue={
                              siteSetting?.theme.fonts.title.fontFamily &&
                              siteSetting?.theme.fonts.title.fontFamily
                            }
                          /> */}

                          {/* <Input
                            className="form-control w-25 ms-5"
                            type="number"
                            name="fontSize"
                            placeholder="Font Size "
                            defaultValue={
                              siteSetting?.theme.fonts.title.fontSize &&
                              siteSetting?.theme.fonts.title.fontSize
                            }
                          /> */}
                          <span className="w-100">
                            <Label
                              className="font-weight-bold small capitalized"
                              for="textScale"
                            >
                              Scale
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2 ms-2"
                              name="textScale"
                              onChange={(e) => setTextScale(e.target.value)}
                              defaultValue={
                                siteSetting?.theme?.sizes?.textScale
                              }
                            >
                              <option>Open this select menu</option>
                              {fontSize.map((fz) => {
                                return <option>{fz}</option>;
                              })}
                            </select>
                          </span>
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <div className="d-flex">
                          <span className="w-100">
                            <Label className="font-weight-bold small capitalized">
                              Radius2
                            </Label>
                            <select
                              className="form-select w-50 form-control-sm form-control mb-2"
                              onChange={(e) => setRadius2(e.target.value)}
                              value={radius2}
                              name="radius2"
                            >
                              {/* <option value="-1">Open this select menu</option> */}
                              {fontSize.map((ft) => {
                                return <option value={ft}>{ft}</option>;
                              })}
                            </select>
                          </span>
                        </div>
                      </FormGroup>
                      {/* <FormGroup>
                        <Label className="font-weight-bold small">
                          Sub Title
                        </Label>
                        <div className="d-flex">
                          <Input
                            className="form-control w-25"
                            type="text"
                            name="subFontFamily"
                            placeholder="Sub Font Family "
                            defaultValue={
                              siteSetting?.theme?.fonts?.subtitle?.fontFamily &&
                              siteSetting?.theme?.fonts?.subtitle?.fontFamily
                            }
                          />
                          <Input
                            className="form-control w-25 ms-5"
                            type="number"
                            name="subFontSize"
                            placeholder="Subtitle Font Size "
                            defaultValue={
                              siteSetting?.theme?.fonts?.subtitle?.fontSize &&
                              siteSetting?.theme?.fonts?.subtitle?.fontSize
                            }
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Body One
                        </Label>
                        <div className="d-flex">
                          <Input
                            className="form-control w-25"
                            type="text"
                            name="bodyOneFontFamily"
                            placeholder="BodyOne Font Family "
                            defaultValue={
                              siteSetting?.theme?.fonts?.body1?.fontFamily &&
                              siteSetting?.theme?.fonts?.body1?.fontFamily
                            }
                          />
                          <Input
                            className="form-control w-25 ms-5"
                            type="number"
                            name="bodyOneFontSize"
                            placeholder="BodyOne Font Size "
                            defaultValue={
                              siteSetting?.theme?.fonts?.body1?.fontSize &&
                              siteSetting?.theme?.fonts?.body1?.fontSize
                            }
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Body Two
                        </Label>
                        <div className="d-flex">
                          <Input
                            className="form-control w-25"
                            type="text"
                            name="bodyTwoFontFamily"
                            placeholder="BodyTwo Font Family "
                            defaultValue={
                              siteSetting?.theme?.fonts?.body2?.fontFamily &&
                              siteSetting?.theme?.fonts?.body2?.fontFamily
                            }
                          />
                          <Input
                            className="form-control w-25 ms-5"
                            type="number"
                            name="bodyTwoFontSize"
                            placeholder="BodyTwo Font Size "
                            defaultValue={
                              siteSetting?.theme?.fonts?.body2?.fontSize &&
                              siteSetting?.theme?.fonts?.body2?.fontSize
                            }
                          />
                        </div>
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">Sizes</Label>
                        <div className="d-flex">
                          <Input
                            className="form-control w-25"
                            type="number"
                            name="radius"
                            placeholder="Radius "
                            defaultValue={
                              siteSetting?.theme?.sizes?.radius &&
                              siteSetting?.theme?.sizes?.radius
                            }
                          />
                          <Input
                            className="form-control w-25 ms-5"
                            type="number"
                            name="textScale"
                            placeholder="Text Scale "
                            defaultValue={
                              siteSetting?.theme.sizes.textScale &&
                              siteSetting?.theme.sizes.textScale
                            }
                          />
                        </div>
                      </FormGroup> */}
                    </div>
                  </div>
                  <div className="card shadow mb-4">
                    <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 className="m-0 font-weight-bold text-primary">
                        Social Links
                      </h6>
                    </div>
                    <div className="card-body">
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Facebook
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="facebook"
                          placeholder="Facebook Link"
                          defaultValue={
                            siteSetting?.socialLinks &&
                            siteSetting?.socialLinks?.facebook
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Twitter
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="twitter"
                          placeholder="Twitter Link"
                          defaultValue={
                            siteSetting?.socialLinks &&
                            siteSetting?.socialLinks?.twitter
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Instagram
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="instagram"
                          placeholder="Instagram Link"
                          defaultValue={
                            siteSetting?.socialLinks &&
                            siteSetting?.socialLinks?.instagram
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          Youtube
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="youtube"
                          placeholder="Youtube Link"
                          defaultValue={
                            siteSetting?.socialLinks &&
                            siteSetting?.socialLinks?.youtube
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label className="font-weight-bold small">
                          LinkedIn
                        </Label>
                        <Input
                          className="form-control"
                          type="text"
                          name="linkedin"
                          placeholder="LinkedIn Link"
                          defaultValue={
                            siteSetting?.socialLinks &&
                            siteSetting?.socialLinks?.linkedin
                          }
                        />
                      </FormGroup>
                    </div>
                  </div>
                  {siteSettings?.theme?.homeSections.map(
                    (item, index) =>
                      item.dataType === "imageObject" && (
                        <ImageObject
                          item={item}
                          uploadedFiles={
                            homeSections[item.id]
                              ? homeSections[item.id]
                              : item.fieldType === "array"
                              ? []
                              : {}
                          }
                          homeSections={homeSections}
                          deletedPhotos={deletedPhotos}
                          setHomeSections={setHomeSections}
                          setDeletedPhotos={setDeletedPhotos}
                        />
                      )
                  )}
                  {siteSettings?.theme?.homeSections.map(
                    (item, index) =>
                      item.dataType === "refID" && (
                        <ReferenceID
                          item={item}
                          list={
                            homeSections[item.id]
                              ? homeSections[item.id]
                              : item.fieldType === "array"
                              ? []
                              : {}
                          }
                          setHomeSections={setHomeSections}
                          homeSections={homeSections}
                        />
                      )
                  )}
                  {siteSettings?.theme?.homeSections.map(
                    (item, index) =>
                      item.dataType === "countryObject" && (
                        <CountryObject
                          item={item}
                          list={
                            homeSections[item.id]
                              ? homeSections[item.id]
                              : item.fieldType === "array"
                              ? []
                              : {}
                          }
                          setHomeSections={setHomeSections}
                          homeSections={homeSections}
                        />
                      )
                  )}
                  {siteSettings?.theme?.homeSections.map(
                    (item, index) =>
                      item.dataType === "products" && (
                        <ProductSection
                          item={item}
                          list={
                            homeSections[item.id]
                              ? homeSections[item.id]
                              : item.fieldType === "array"
                              ? []
                              : {}
                          }
                          setHomeSections={setHomeSections}
                          homeSections={homeSections}
                        />
                      )
                  )}
                </div>
                {siteSettings?.theme?.homeSections && (
                  <div className="col-lg-4 col-xl-3">
                    <div className="card shadow mb-4">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Header Logo
                        </h6>
                      </div>
                      <div className="card-body">
                        {siteLogo && siteLogo?.header ? (
                          <div className="w-100 text-center">
                            <div className="w-100">
                              <img
                                src={
                                  config?.fileServer +
                                  config?.imageContainer +
                                  siteLogo?.header
                                }
                                alt=""
                                className="mb-2 img-fluid"
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-icon-split btn-sm btn-danger"
                              onClick={() =>
                                handleDeleteHeaderLogo(siteLogo.header)
                              }
                            >
                              Remove Photo
                            </button>
                          </div>
                        ) : (
                          <Dropzone
                            onChangeStatus={handleUploadHeaderLogo}
                            accept="image/*"
                            PreviewComponent={null}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                          />
                        )}
                      </div>
                    </div>
                    <div className="card shadow mb-4">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold text-primary">
                          Navbar Logo
                        </h6>
                      </div>
                      <div className="card-body">
                        {siteLogo && siteLogo.nav ? (
                          <div className="w-100 text-center">
                            <div className="w-100">
                              <img
                                src={
                                  config.fileServer +
                                  config.imageContainer +
                                  siteLogo.nav
                                }
                                alt=""
                                className="mb-2 img-fluid"
                              />
                            </div>
                            <button
                              type="button"
                              className="btn btn-icon-split btn-sm btn-danger"
                              onClick={() => handleDeleteNavLogo(siteLogo.nav)}
                            >
                              Remove Photo
                            </button>
                          </div>
                        ) : (
                          <Dropzone
                            onChangeStatus={handleUploadNavLogo}
                            accept="image/*"
                            PreviewComponent={null}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Container>
        </Fragment>
      </form>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    siteSetting: state.mainReducers.main.siteSettings,
    vendors: state.mainReducers.main.vendors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateSiteSettingsPage);
