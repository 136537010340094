import React, { useState } from "react";
import { Container } from "reactstrap";
import PageHeader from "./PageHeader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import config from "../../../../../service/api/config";
import Dropzone from "react-dropzone-uploader";
import { DeletePhoto, UploadPhoto } from "../../../../../service/service";
import { AddService } from "../../../../../service/service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const CreateService = () => {
  const [photo, setPhoto] = useState(null);
  const [serviceName, setServiceName] = useState("");
  const [serviceImg, setServiceImg] = useState({});
  const [description, setDescription] = useState("");
  const navigate = useNavigate();

  const handleUploadPhoto = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setPhoto(response.data.fileName);
          setServiceImg(response.data);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleDeletePhoto = async (photo) => {
    await DeletePhoto({
      fileName: photo,
      container: "ivcommerceoriginalcontainer",
    });
    setPhoto(null);
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();

    if (serviceName && description && serviceImg) {
      const data = {
        serviceName: serviceName,
        description: description,
        serviceImg: serviceImg,
      };
      const response = await AddService(data);

      console.log(response);

      if (response && response.isSuccess) {
        toast.success("Service Created!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });

        setPhoto(null);
        setServiceName("");
        setServiceImg({});
        setDescription("");
        navigate("/dashboard/services");
      } else {
        toast.warning("Please provide all details ", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <Container fluid={true}>
      <PageHeader
        title="Add Service"
        buttonText="Save"
        handleSubmitForm={handleSubmitForm}
      />
      <div className="contentbar">
        <div className="row">
          <div className="col-lg-8 col-xl-9">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  Service Details
                </h6>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <label
                    htmlFor="ServiceTitle"
                    className="col-sm-12 font-weight-bold small"
                  >
                    Name
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      className="form-control-sm form-control"
                      name="name"
                      placeholder="Service Name"
                      onChange={(e) => setServiceName(e.target.value)}
                      required
                    />
                    <span className="text-danger"></span>
                  </div>
                </div>

                <label
                  htmlFor="ServiceImg"
                  className="col-sm-12 font-weight-bold small"
                >
                  Image Image (Please upload wider image (500x300) and max 400kb
                  for best performance)
                </label>
                <div>
                  {photo ? (
                    <div className="w-100 text-center">
                      <div className="w-100">
                        <img
                          src={
                            config.fileServer + config.imageContainer + photo
                          }
                          alt=""
                          className="mb-2"
                          width="50%"
                        />
                      </div>
                      <button
                        type="button"
                        className="btn btn-icon-split btn-sm btn-danger"
                        onClick={() => handleDeletePhoto(photo)}
                      >
                        Remove Photo
                      </button>
                    </div>
                  ) : (
                    <Dropzone
                      onChangeStatus={handleUploadPhoto}
                      accept="image/*"
                      PreviewComponent={null}
                      maxFiles={1}
                      multiple={false}
                      canCancel={false}
                    />
                  )}
                </div>

                <div className="form-group row pt-3">
                  <label className="col-sm-12 font-weight-bold small">
                    Description
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-sm-12">
                    <div className="border">
                      <CKEditor
                        editor={ClassicEditor}
                        config={{
                          ckfinder: {
                            uploadUrl: config.serverURL + "file/editor-file",
                          },
                          height: "500",
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setDescription(data);
                        }}
                        onFilesChange={(e) => console.log(e)}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CreateService;
