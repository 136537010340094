import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CountCard01 from "./Components/CountCard01";
import operations from "../../../../redux/Main/operations";
import React, { Fragment, useEffect, useState } from "react";
import { Container, Col, Row, Card, CardHeader } from "reactstrap";
import {
  GetOrdersCount,
  GetProductsCount,
  GetSiteSettings,
  GetUsersCount,
} from "../../../../service/service";
import config from "../../../../service/api/config";
import LatestOrders from "./Components/LatestOrders";
import UsersCountCard from "./Components/UsersCountCard";
import LatestProducts from "./Components/LatestProducts";

const DashboardPage = (props) => {
  const { company, conversations, user, siteSettings } = props;
  console.log("🚀 ~ file: DashboardPage.jsx:20 ~ DashboardPage ~ company", company)
  const primary = localStorage.getItem("primary_color");
  const secondary = localStorage.getItem("secondary_color");
  const portfolioOnly = siteSettings.features.portfolioOnly;

  const [usersCount, setUsersCount] = useState(0);
  const [ordersCount, setOrdersCount] = useState(0);
  const [productsCount, setProductsCount] = useState(0);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);

  const getProductsCountData = async (data) => {
    const productsCountResponse = await GetProductsCount(data);
    console.log(productsCountResponse);
    if (productsCountResponse && productsCountResponse.isSuccess) {
      setProductsCount(productsCountResponse.data.productsCount);
    }
  };

  const getUsersCountData = async (data) => {
    const response = await GetUsersCount(data);
    console.log(response);
    if (response && response.isSuccess) {
      setUsersCount(response.data.usersCount);
    }
  };

  const getOrdersCountData = async (data) => {
    const response = await GetOrdersCount({
      seller: company._id,
      status: "delivered",
    });
    console.log(response);
    if (response && response.isSuccess) {
      setOrdersCount(response.data.ordersCount);
    }

    const pendingRes = await GetOrdersCount({
      seller: company._id,
      status: "pending",
    });
    if (pendingRes && pendingRes.isSuccess) {
      setPendingOrdersCount(pendingRes.data.ordersCount);
    }
  };

  useEffect(() => {
    getOrdersCountData();
    getProductsCountData({ vendor: company._id });
    if (!company.isVendor) {
    } else {
      getUsersCountData({ type: "user" });
    }
  }, []);

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <h3>DASHBOARD</h3>
        </div>
        <Row>
          <Col sm="12" lg="6" xl="6 xl-50 set-col-6" md="12">
            <Card className="custom-card general-user-card">
              <CardHeader>
                <img
                  src={
                    config.fileServer +
                    config.imageContainer +
                    company.coverPhoto
                  }
                  className="img-fluid"
                  alt=""
                />
              </CardHeader>
              <div className="card-profile">
                <img
                  src={config.fileServer + config.imageContainer + company.logo}
                  className="rounded-circle"
                  alt=""
                />
              </div>
              <div className="text-center profile-details">
                <h4>{company.name}</h4>
                <h6>{user.name}</h6>
              </div>
            </Card>
          </Col>

          {!portfolioOnly && (
            <Col xl="6" lg="6" md="12" sm="12">
              <LatestOrders company={company} />
            </Col>
          )}

          <Col sm="6" xl="3" lg="6">
            <CountCard01
              bgColor="bg-primary"
              icon="icon-package"
              countNumber={productsCount}
              title="Products"
            />
          </Col>

          {!portfolioOnly && (
            <>
              {siteSettings.features.messaging == true && (
                <Col sm="6" xl="3" lg="6">
                  <CountCard01
                    bgColor="bg-secondary"
                    icon="icon-comments"
                    countNumber={conversations.length}
                    title="Messages"
                  />
                </Col>
              )}

              <Col sm="6" xl="3" lg="6">
                <CountCard01
                  bgColor="bg-info"
                  icon="icofont icofont-gift"
                  countNumber={ordersCount}
                  title="Total Sell"
                />
              </Col>

              <Col sm="6" xl="3" lg="6">
                <CountCard01
                  bgColor="bg-danger"
                  icon="icofont icofont-cart-alt"
                  countNumber={pendingOrdersCount}
                  title="Pending Orders"
                />
              </Col>
            </>
          )}

          {!company.isVendor && (
            <Col xl="12 xl-100 set-col-12">
              <UsersCountCard />
            </Col>
          )}
        </Row>

        <Row>
          <Col xl="12" md="12" sm="12">
            <LatestProducts company={company} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state.mainReducers.main.company,
    vendors: state.mainReducers.main.vendors,
    conversations: state.mainReducers.main.conversations,
    user: state.mainReducers.main.user,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
