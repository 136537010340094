import { connect } from "react-redux";
import { Pagination } from "@material-ui/lab";
import React, { useState, useEffect } from "react";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import { Col, Row, Card, CardBody, Container } from "reactstrap";
import { RespondedRequirements } from "../../../../service/service";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const RespondRequirements = ({ company }) => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [requirements, setRequirements] = useState([]);
  const [filteredProduct, setFilteredProduct] = useState(null);

  const companyID = { company: company._id };

  const fetchData = async () => {
    const response = await RespondedRequirements(companyID);
    if (response && response.isSuccess) {
      setRequirements(response.data.requirements);
      // console.log("response", response.data.requirements);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="responded requirements" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12"></Col>
          </Row>
        </div>
      </Container>
      <Card>
        <CardBody>
          <div className="table-responsive">
            <table className="table table-md">
              <thead>
                <tr>
                  <th className="border">Photo</th>
                  <th className="border">Product Name</th>
                  <th className="border">Email</th>
                  <th className="border">Number</th>
                  <th className="border">Qty</th>
                  <th className="border">Note</th>
                  <th className="border">Requested At</th>
                  <th className="border">Responded At</th>
                </tr>
              </thead>
              <tbody>
                {requirements
                  .slice(page * 5 - 5, page * 5)
                  .map((requirement) => {
                    const imgSrc =
                      config.fileServer +
                        config.thumbnailContainer +
                        requirement.file &&
                      imageExists(
                        config.fileServer +
                          config.thumbnailContainer +
                          requirement.file
                      )
                        ? config.fileServer +
                          config.thumbnailContainer +
                          requirement.file
                        : noImage;

                    const requestedAt = requirement.createdAt
                      .toString()
                      .slice(0, 10);
                    const respondedAt = requirement.updatedAt
                      .toString()
                      .slice(0, 10);

                    const note = requirement.note
                      ? requirement.note.slice(0, 20)
                      : "No Note Given";

                    return (
                      <tr key={requirement._id}>
                        <td className="border">
                          <img
                            width="34px"
                            src={imgSrc}
                            className="img-fluid"
                            alt=""
                          />
                        </td>
                        <td className="border">{requirement.productName}</td>
                        <td className="border">{requirement.contactEmail}</td>
                        <td className="border">{requirement.contactNumber}</td>
                        <td className="border">{requirement.quantity}</td>
                        <td className="border">{`${note}...`}</td>
                        <td className="border">{requestedAt}</td>
                        <td className="border">{respondedAt}</td>
                      </tr>
                    );
                  })}
                {requirements.length > 5 && (
                  <tr>
                    <td colSpan="6" align="right">
                      <Pagination
                        page={page}
                        onChange={handlePageChange}
                        count={
                          filteredProduct
                            ? parseInt(
                                (filteredProduct.length / 5 + 1).toFixed(0)
                              )
                            : parseInt((requirements.length / 5 + 1).toFixed(0))
                        }
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.mainReducers.main.company,
  };
};

export default connect(mapStateToProps)(RespondRequirements);
