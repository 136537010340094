import {
  DeletePhoto,
  updateEvent,
  UploadPhoto,
  GetAllCategory,
  GetAllProducts,
  getEventDetails,
  getEventProducts,
  deleteEventProduct,
} from "../../../../service/service";
import { Badge } from "reactstrap";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import InputRange from "react-input-range";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import React, { useState, useEffect } from "react";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Row, Col, Button, Container } from "reactstrap";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import ProductModal from "../../Components/Modal/ProductModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTimes } from "@fortawesome/free-solid-svg-icons";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import ProductUpdateModel from "../../Components/Modal/ProductUpdateModel";

const UpdateEventPage = ({ history, vendors, company, siteSettings }) => {
  console.log(
    "🚀 ~ file: UpdateEventPage.jsx:31 ~ UpdateEventPage ~ vendors",
    vendors
  );
  const initialslider = {
    value: 10,
    nagative: -10,
    foramtedLabel: 10,
    disabled: 10,
    foramtedLabelKg: 5,
    draggable: {
      min: 10,
      max: 20,
    },
  };
  const { slug } = useParams();
  const [title, setTitle] = useState("");
  const [event, setEvent] = useState({});
  const [error, setError] = useState({});
  const [status, setStatus] = useState("");
  const [modal, setModal] = useState(false);
  const [banners, setBanners] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [expiresAt, setExpiresAt] = useState("");
  const [startedAt, setStartedAt] = useState("");
  const [categories, setCategories] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [values, setValues] = useState(initialslider);
  const [eventProduct, setEventProduct] = useState([]);
  const [updateModal, setUpdateModal] = useState(false);
  const [discountRange, setDiscountRange] = useState({});
  const [participation, setParticipation] = useState("");
  const [productUpdate, setProductUpdate] = useState({});
  const [deletedPhotos, setDeletedPhotos] = useState([]);
  const [selectedVendors, setSelectedVendors] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  console.log(
    "🚀 ~ file: UpdateEventPage.jsx:64 ~ UpdateEventPage ~ selectedCategories",
    selectedCategories
  );

  const dateConverter = (data) => {
    let curr = new Date(data);
    let date = curr.toISOString().substr(0, 10);
    return date;
  };

  const handleSelectCategory = async (ct) => {
    if (ct !== "-1") {
      const cat = categories.find((item) => item._id === ct);
      setSelectedCategories([...selectedCategories, cat]);
    }
  };

  const handleSelectVendors = async (ct) => {
    if (ct !== "-1") {
      const cat = vendors.find((item) => item._id === ct);
      setSelectedVendors([...selectedVendors, cat]);
    }
  };

  const fetchData = async () => {
    const response = await getEventDetails(slug);
    if (response && response.isSuccess) {
      setTitle(response.data.event.title ? response.data.event.title : "");
      setBanners(
        response.data.event.banners ? response.data.event.banners : []
      );
      setValues({ ...values, draggable: response.data.event.discountRange });
      setStatus(response.data.event.status ? response.data.event.status : "");
      setStartedAt(
        response.data.event.startedAt
          ? dateConverter(response.data.event.startedAt)
          : ""
      );
      setExpiresAt(
        response.data.event.expiresAt
          ? dateConverter(response.data.event.expiresAt)
          : ""
      );
      setEvent(response.data.event);
      setParticipation(
        response.data.event.participation
          ? response.data.event.participation
          : ""
      );
      setSelectedCategories(
        response.data.event.selectedCategories
          ? response.data.event.selectedCategories
          : []
      );
      setSelectedVendors(
        response.data.event.selectedVendors
          ? response.data.event.selectedVendors
          : []
      );
      setDiscountRange(response.data.event.discountRange);
    }

    const response01 = await GetAllCategory();
    if (response01 && response01.isSuccess) {
      setCategories(response01.data.categories);
    }

    const response02 = await getEventProducts({
      event: response.data.event._id,
    });
    if (response02 && response02.isSuccess) {
      setEventProduct(response02.data.products);
    }

    const response03 = await GetAllProducts();
    if (response03 && response03.isSuccess) {
      setProducts(response03.data.products);
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  console.log("selectedCategories", selectedCategories);

  const handleUploadBanner = async ({ meta, file }, status, allFiles) => {
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        if (response && response.isSuccess) {
          setBanners((banners) => [
            ...banners,
            {
              name: "",
              id: response.data.fileName,
              url: response.data.fileName,
            },
          ]);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handlePhotoDelete = async (id) => {
    setDeletedPhotos([...deletedPhotos, id]);
    setBanners(banners.filter((f) => f.id !== id));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!title || !status || !startedAt || !expiresAt || !participation) {
      setError({
        title: !title ? true : false,
        status: !status ? true : false,
        startedAt: !startedAt ? true : false,
        expiresAt: !expiresAt ? true : false,
        participation: !participation ? true : false,
      });
    } else {
      setBtnLoading(true);
      const data = {
        title: title,
        status: status,
        banners: banners,
        startedAt: startedAt,
        expiresAt: expiresAt,
        participation: participation,
        discountRange: values.draggable,
        selectedCategories:
          participation === "private"
            ? selectedCategories.length > 0
              ? selectedCategories
              : null
            : null,
        selectedVendors:
          participation === "private"
            ? selectedVendors.length > 0
              ? selectedVendors
              : null
            : null,
      };

      if (participation === "private") {
        if (data.selectedCategories === null && data.selectedVendors === null) {
          setBtnLoading(false);
          setError({
            field:
              data.selectedCategories === null && data.selectedVendors === null
                ? true
                : false,
          });
        } else if (data.selectedCategories || data.selectedVendors) {
          const response = await updateEvent(event._id, data);
          if (response && response.isSuccess) {
            setBtnLoading(false);
            deletedPhotos.map((deletedPhoto) =>
              DeletePhoto({
                container: "ivcommerceoriginalcontainer",
                fileName: deletedPhoto,
              })
            );
            toast.success("Event Updated!", {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 3000,
            });
            history.push("/dashboard/all-events");
          }
        }
      } else if (participation === "public") {
        const response = await updateEvent(event._id, data);
        if (response && response.isSuccess) {
          setBtnLoading(false);
          deletedPhotos.map((deletedPhoto) =>
            DeletePhoto({
              container: "ivcommerceoriginalcontainer",
              fileName: deletedPhoto,
            })
          );
          toast.success("Event Updated!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
          history.push("/dashboard/all-events");
        }
      }
    }
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleCloseModal = () => {
    setUpdateModal(false);
  };

  const confirmDelete = async (id) => {
    const response = await deleteEventProduct(id);
    if (response && response.isSuccess) {
      setEventProduct(
        eventProduct.filter(
          (product) => product._id !== response.data.eventProduct._id
        )
      );
    }
  };

  const handleDelete = (id) => {
    SweetAlert.fire({
      icon: "warning",
      reverseButtons: true,
      title: "Are you sure?",
      showCancelButton: true,
      cancelButtonText: "cancel",
      confirmButtonText: "confirm",
      text: "Once deleted, you will not be able to recover this event!",
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Event deleted.", "success");
        });
      } else {
        SweetAlert.fire("Event is safe!");
      }
    });
  };

  return (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" sm="3">
              <Breadcrumbs title="Update Events" parent="Dashboard" />
            </Col>
            <Col lg="3" sm="3">
              {btnLoading ? (
                <Button color="primary btn-block" disabled={btnLoading}>
                  SAVING...
                </Button>
              ) : (
                <Button onClick={handleFormSubmit} color="primary btn-block">
                  <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </Container>
      {loading ? (
        <ComponentLoader height="80vh" />
      ) : (
        <div className="contentbar">
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Event Details
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      htmlFor="eventTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Event Title
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="text"
                        name="name"
                        value={title}
                        placeholder="Enter Event Title"
                        className="form-control-sm form-control"
                        onChange={(e) => setTitle(e.target.value)}
                      />
                      <span className="text-danger font-weight-bold">
                        {error.title && "Event title is required"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Event Banner
                  </h6>
                </div>
                <div className="card-body">
                  <div className="row">
                    {banners &&
                      banners.map((image, index) => (
                        <div
                          key={index}
                          className="col-lg-4 col-xs-12 my-2 mb-2"
                        >
                          <div className="border p-2">
                            <img
                              src={
                                image &&
                                imageExists(
                                  config.fileServer +
                                    config.imageContainer +
                                    image.url
                                )
                                  ? config.fileServer +
                                    config.imageContainer +
                                    image.url
                                  : noImage
                              }
                              alt="Product"
                              className="img-fluid rounded mb-1"
                            />
                            <div className="d-flex">
                              <input
                                type="text"
                                className="form-control-sm form-control"
                                name="image"
                                defaultValue={image.link}
                                placeholder="Link"
                                onChange={(e) => {
                                  let arr = banners;
                                  for (var i in arr) {
                                    if (arr[i].id === image.id) {
                                      arr[i].link = e.target.value;
                                      break;
                                    }
                                  }
                                  setBanners(arr);
                                }}
                              />{" "}
                              <button
                                type="button"
                                className="btn btn-icon-split btn-sm"
                                onClick={() => handlePhotoDelete(image.id)}
                              >
                                <FontAwesomeIcon icon={faTimes} size="xs" />
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                  <Dropzone
                    accept="image/*"
                    PreviewComponent={null}
                    onChangeStatus={handleUploadBanner}
                  />
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Event Products
                  </h6>
                  <Button
                    color="primary"
                    className="btn-square"
                    onClick={() => setModal(true)}
                  >
                    <FontAwesomeIcon className="mr-1" icon={faSave} />
                    ADD EVENT PRODUCT
                  </Button>
                  {modal && (
                    <ProductModal
                      open={modal}
                      event={event}
                      status={status}
                      company={company}
                      products={products}
                      categories={categories}
                      handleClose={handleClose}
                      siteSettings={siteSettings}
                      discountRange={discountRange}
                      selectedCategories={selectedCategories}
                      fetchDataUpdated={fetchData}
                    />
                  )}
                </div>
                <div className="card-body">
                  <div className="table-responsive text-capitalize border">
                    <table className="table table-md">
                      <thead>
                        <tr>
                          <th className="border">Iamge</th>
                          <th className="border">Product Name</th>
                          <th className="border">Company Name</th>
                          <th className="border">Price</th>
                          <th className="border">Discount</th>
                          <th className="border">Sold</th>
                          <th className="border">Stock Limit</th>
                          <th className="border">Active Status</th>
                          <th className="border">Created At</th>
                          <th className="border">Updated At</th>
                          <th className="border">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventProduct.map((product) => {
                          return (
                            <tr key={product._id}>
                              <td className="border">
                                <img
                                  alt="event cover"
                                  width="40px"
                                  src={
                                    product.product.photos &&
                                    product.product.photos[0] &&
                                    product.product.photos[0].url &&
                                    imageExists(
                                      config.fileServer +
                                        config.thumbnailContainer +
                                        product.product.photos[0].url
                                    )
                                      ? config.fileServer +
                                        config.thumbnailContainer +
                                        product.product.photos[0].url
                                      : noImage
                                  }
                                />
                              </td>
                              <td className="border">
                                {product.product.name
                                  ? product.product.name
                                  : ""}
                              </td>
                              <td className="border">
                                {product.product.vendor
                                  ? product.product.vendor.name
                                  : " "}
                              </td>
                              <td className="border">
                                {product.product.price}
                              </td>
                              <td className="border">{`${product.discount}%`}</td>
                              <td className="border">{product.sold}</td>
                              <td className="border">{product.stockLimit}</td>
                              <td className="border">
                                {product.activeStatus ? "✅" : "🚫"}
                              </td>
                              <td className="border">
                                {product.createdAt.toString().slice(0, 10)}
                              </td>
                              <td className="border">
                                {product.updatedAt.toString().slice(0, 10)}
                              </td>
                              <td className="border">
                                <div className="button-list">
                                  <button
                                    href="#"
                                    className="btn btn-dark p-0"
                                    onClick={() => {
                                      setProductUpdate(product);
                                      setUpdateModal(!updateModal);
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        padding: 11,
                                        fontSize: 16,
                                        color: "rgb(40, 167, 69)",
                                      }}
                                    ></i>
                                  </button>
                                  <button
                                    href="#"
                                    onClick={(e) => handleDelete(product._id)}
                                    className="btn btn-text p-0"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        width: 35,
                                        padding: 11,
                                        fontSize: 16,
                                        color: "#e4566e",
                                      }}
                                    ></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-xl-3">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Participation Status
                  </h6>
                </div>
                <div className="card-body">
                  <label className="font-weight-bold small">
                    Select Participation Status
                  </label>
                  <select
                    value={participation}
                    onChange={(e) => setParticipation(e.target.value)}
                    className="form-select form-control-sm form-control mb-2"
                  >
                    <option>Open to select status</option>
                    <option value="public">Public</option>
                    <option value="private">Private</option>
                  </select>
                  <span className="text-danger font-weight-bold">
                    {error?.participation &&
                      "Event participation status is required"}
                  </span>
                </div>
              </div>
              {participation && participation === "private" && (
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Selected Categories
                    </h6>
                  </div>
                  <div className="card-body">
                    <label className="font-weight-bold small">
                      Select Event Categories
                    </label>
                    {selectedCategories &&
                      selectedCategories?.map((cat, index) => (
                        <Badge
                          key={index}
                          color={"primary"}
                          className="mb-1 w-100"
                        >
                          <span className="flex-grow-1">{cat.name}</span>
                          <button
                            type="button"
                            className="btn btn-icon-split btn-xs"
                            onClick={() =>
                              setSelectedCategories(
                                selectedCategories?.filter(
                                  (t) => t?._id !== cat?._id
                                )
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} size="xs" />
                          </button>
                        </Badge>
                      ))}
                    {categories && (
                      <>
                        <select
                          value="-1"
                          className="form-select form-control-sm form-control mb-2"
                          onChange={(e) => handleSelectCategory(e.target.value)}
                        >
                          <option value="-1">Open this select menu</option>
                          {categories &&
                            categories
                              ?.filter(
                                (item) =>
                                  !selectedCategories?.find(
                                    (c) => c._id === item._id
                                  )
                              )
                              ?.map((category) => (
                                <option value={category._id} key={category._id}>
                                  {category.name}
                                </option>
                              ))}
                        </select>
                        <span className="text-danger font-weight-bold">
                          {error.field && "Both fields can not be empty"}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}
              {participation && participation === "private" && (
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Selected Vendors
                    </h6>
                  </div>
                  <div className="card-body">
                    <label className="font-weight-bold small">
                      Select Event Vendors
                    </label>
                    {selectedVendors &&
                      selectedVendors.map((vendor, index) => (
                        <Badge
                          key={index}
                          color={"primary"}
                          className="mb-1 w-100"
                        >
                          <span className="flex-grow-1">{vendor.name}</span>
                          <button
                            type="button"
                            className="btn btn-icon-split btn-xs"
                            onClick={() =>
                              setSelectedVendors(
                                selectedVendors?.filter(
                                  (t) => t._id !== vendor._id
                                )
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faTimes} size="xs" />
                          </button>
                        </Badge>
                      ))}
                    {vendors && (
                      <>
                        <select
                          value="-1"
                          className="form-select form-control-sm form-control mb-2"
                          onChange={(e) => handleSelectVendors(e.target.value)}
                        >
                          <option value="-1">Open this select menu</option>
                          {vendors
                            .filter(
                              (item) =>
                                !selectedVendors?.find(
                                  (c) => c._id === item._id
                                )
                            )
                            .map((vendor) => (
                              <option value={vendor._id} key={vendor._id}>
                                {vendor.name}
                              </option>
                            ))}
                        </select>
                        <span className="text-danger font-weight-bold">
                          {error.field && "Both fields can not be empty"}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              )}
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Discount Range
                  </h6>
                </div>
                <div className="card-body">
                  <div className="theme-form form-label-align-right range-slider">
                    <div className="row mb-0">
                      <div className="col-md-12">
                        <InputRange
                          draggableTrack
                          minValue={0}
                          maxValue={100}
                          value={values.draggable}
                          onChange={(value) =>
                            setValues({ ...values, draggable: value })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Other Info
                  </h6>
                </div>
                <div className="card-body">
                  <div className="form-group row">
                    <label
                      htmlFor="eventTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Event Start Time
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="date"
                        name="name"
                        value={startedAt}
                        placeholder="Enter Event Starting Time"
                        className="form-control-sm form-control"
                        onChange={(e) => setStartedAt(e.target.value)}
                      />
                      <span className="text-danger font-weight-bold">
                        {error.startedAt && "Event start date is required"}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="eventTitle"
                      className="col-sm-12 font-weight-bold small"
                    >
                      Event End Time
                    </label>
                    <div className="col-sm-12">
                      <input
                        type="date"
                        name="name"
                        value={expiresAt}
                        placeholder="Enter Event Ending time"
                        className="form-control-sm form-control"
                        onChange={(e) => setExpiresAt(e.target.value)}
                      />
                      <span className="text-danger font-weight-bold">
                        {error.expiresAt && "Event expire date is required"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Event Status
                  </h6>
                </div>
                <div className="card-body">
                  <label className="font-weight-bold small">
                    Select Event Status
                  </label>
                  <select
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                    className="form-select form-control-sm form-control mb-2"
                  >
                    <option>Open to select status</option>
                    <option value="active">Active</option>
                    <option value="expired">Expired</option>
                    <option value="scheduled">Scheduled</option>
                  </select>
                  <span className="text-danger font-weight-bold">
                    {error.status && "Event status is required"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {updateModal && (
        <ProductUpdateModel
          company={company}
          open={updateModal}
          product={productUpdate}
          siteSettings={siteSettings}
          discountRange={discountRange}
          handleClose={handleCloseModal}
        />
      )}
    </>
  );
};

function mapStateToProps(state, props) {
  return {
    vendors: state.mainReducers.main.vendors,
    company: state.mainReducers.main.company,
    products: state.mainReducers.main.products,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

export default connect(mapStateToProps)(UpdateEventPage);
