import React from "react";
import {
  Card,
  Input,
  CardBody,
  ListGroup,
  CardHeader,
  CardFooter,
  ListGroupItem,
} from "reactstrap";
import { connect } from "react-redux";

const ReferenceID = ({ item, list, setHomeSections, homeSections, states }) => {
  const handleSetField = async (cat) => {
    if (cat !== "-1") {
      if (item.fieldType === "array") {
        setHomeSections({
          ...homeSections,
          [item.id]: [
            ...list,
            {
              id: cat,
              title: states[item.ref].find((x) => x._id === cat).name,
            },
          ],
        });
      }
    }
  };

  console.log(homeSections);

  return (
    <div>
      <Card>
        <CardHeader>
          <h5>{item.name}</h5>
        </CardHeader>
        <CardBody>
          {list && (
            <>
              {item.fieldType === "array" ? (
                <ListGroup>
                  {list.map((listItem, index) => (
                    <ListGroupItem className="d-flex justify-content-between align-items-center">
                      {listItem.title}

                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          console.log(list.filter((f) => f.id === listItem.id));
                          setHomeSections({
                            ...homeSections,
                            [item.id]: list.filter((f) => f.id !== listItem.id),
                          });
                        }}
                      >
                        x
                      </button>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              ) : (
                <div className="mb-1 col-lg-12"></div>
              )}
            </>
          )}
        </CardBody>
        <CardFooter>
          {(!list || list.length < item.length) && (
            <select
              className="form-select form-control-sm form-control mb-2"
              onChange={(e) => handleSetField(e.target.value)}
              value="-1"
            >
              <option value="-1">Open this select menu</option>
              {states[item.ref]
                .filter((it) => it.isRoot && !list.find((l) => l.id === it._id))
                .map((category) => (
                  <option value={category._id}>{category.name}</option>
                ))}
            </select>
          )}
        </CardFooter>
      </Card>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    states: state.mainReducers.main,
  };
}

export default connect(mapStateToProps)(ReferenceID);
