import jwtDecode from "jwt-decode";
import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import config from "../../service/api/config";
import { Link, Navigate } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";
import { bindActionCreators } from "redux";
import operations from "../../redux/Main/operations";
import { Login, LoginVendor } from "../../service/service";
import { Card } from "reactstrap";
import setAuthToken from "../../service/setAuthToken";
import { ToastContainer, toast } from "react-toastify";
import noImage from "../../assets/images/notFound.png";
import PageLoader from "../Dashboard/Components/Loader/PageLoader";
import { useNavigate } from "react-router-dom";

const VendorLogin = (props) => {
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { siteSettings } = props;
  const history = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (e.target.email.value && e.target.password.value) {
      setButtonLoading(true);
      const data = {
        userID: e.target.email.value,
        password: e.target.password.value,
      };
      console.log("🚀 ~ file: VendorLogin.jsx:31 ~ handleLogin ~ data", data);
      const response = await LoginVendor(data);
      if (response && response.isSuccess) {
        const token = response.data.token;
        localStorage.setItem("auth_token", token);
        setAuthToken(token);
        const user = jwtDecode(token);
        props.setUser(user);
        setButtonLoading(false);
        history("/dashboard");
      } else {
        setButtonLoading(false);
        toast.error("INVALID USER NAME OR PASSWORD", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  useEffect(() => {
    if (siteSettings) {
      setLoading(false);
    }
  }, [siteSettings, props.user]);
  console.log("siteSettings--->", siteSettings);
  if (!props.user.loading && props.user && props.user.id) {
    return history("/dashboard");
  }

  if (siteSettings.siteType === "singleVendor") {
    return history("/admin-login");
  }

  return loading || props.user.loading ? (
    <PageLoader />
  ) : (
    <Fragment>
      <div className="page-wrapper">
        <ToastContainer
          position="top-center"
          draggable
          rtl={false}
          closeOnClick
          pauseOnHover
          autoClose={5000}
          pauseOnFocusLoss
          newestOnTop={false}
          hideProgressBar={false}
        />
        <Container fluid={true}>
          <div className="authentication-main">
            <Row>
              <Col md="5" className="p-0">
                <div
                  className="auth-innerleft"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../../assets/images/auth-layer.png") +
                      ")",
                  }}
                >
                  <div className="d-flex flex-column">
                    <div className="text-center d-block">
                      <img
                        height="280px"
                        src={
                          siteSettings.shop &&
                          config.fileServer +
                            config.thumbnailContainer +
                            siteSettings.shop.logo
                            ? config.fileServer +
                              config.thumbnailContainer +
                              siteSettings.shop.logo
                            : noImage
                        }
                        alt="seller logo"
                      />
                    </div>
                    <h4 className="text-center" style={{ fontSize: "30px" }}>
                      {siteSettings.shop && siteSettings.shop.name}
                    </h4>
                  </div>
                </div>
              </Col>

              <Col md="7" className="p-0 m-0">
                <div className="auth-innerright">
                  <div className="authentication-box">
                    <h2 className="text-center">Vendor Login</h2>
                    <h6 className="text-center mb-2">
                      Enter vendor Information
                    </h6>

                    <Card className="mt-4 p-4">
                      <form className="theme-form" onSubmit={handleLogin}>
                        <div className="form-group">
                          <label className="col-form-label pt-0">Email</label>
                          <input
                            type="email"
                            placeholder="Email"
                            name="email"
                            className="form-control rounded-0"
                          />
                        </div>
                        <div className="form-group">
                          <label className="col-form-label" name="password">
                            Password
                          </label>
                          <input
                            type="password"
                            className="form-control rounded-0"
                            placeholder="Password"
                            name="password"
                          />
                        </div>
                        <div className="form-group form-row mt-3 mb-0">
                          <div className="col-md-12">
                            {buttonLoading ? (
                              <Button
                                color="primary btn-block rounded-0"
                                disabled={buttonLoading}
                              >
                                LOADING...
                              </Button>
                            ) : (
                              <Button
                                type="submit"
                                color="primary btn-block rounded-0"
                              >
                                LOGIN
                              </Button>
                            )}
                          </div>
                        </div>
                      </form>
                      <div className="mt-4 w-100 text-right font-weight-bold">
                        <Link
                          to="/forget-password?type=vendor"
                          style={{ color: "red" }}
                        >
                          Forget Password
                        </Link>
                      </div>
                      {/* <div className="w-100 text-right font-weight-bold mt-2">
                        <Link to="/">Site Admin Login</Link>
                      </div> */}
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    user: state.mainReducers.main.user,
    siteSettings: state.mainReducers.main.siteSettings,
    auth: state.mainReducers.main.isAuthenticated,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorLogin);
