import React, { useState, useEffect } from "react";
import { Col, Row, Container } from "reactstrap";
import { dateTime } from "../../../../service/dateTimeFormat";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { GetMessageDetails } from "../../../../service/service";
import Breadcrumbs from "../../Components/Breadcrumbs";
import errorImg from "../../../../assets/images/search-not-found.png";
import { useParams } from "react-router-dom";
import { Navigate, Route, Routes } from "react-router-dom";

const MessageDetails = ({ match }) => {
  let { id } = useParams();
  console.log(id);
  const [message, setMessage] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  // const dateTimeDetails = dateTime(message.createdAt)
  //   .replace(/\s\s+/g, " ")
  //   .split(" ");

  const { time, date } = dateTime(message.createdAt);

  useEffect(() => {
    async function fetch() {
      try {
        const response = await GetMessageDetails(id);
        console.log(response);
        setMessage(response?.data?.message);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        setIsError(true);
      }
    }

    fetch();
  }, [id]);

  return (
    <Container fluid={true}>
      <div className="page-header">
        <Row>
          <Col lg="9" xs="12">
            <Breadcrumbs title={`Details`} parent={`Dashboard / Message `} />
          </Col>
        </Row>
        {isLoading ? (
          <ComponentLoader height="80vh" />
        ) : (
          <div className="card shadow mt-5">
            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
              <h6 className="m-0 font-weight-bold text-primary">
                Contact Message Details
              </h6>
            </div>
            <div className="card-body " style={{ minHeight: "300px" }}>
              {isError ? (
                <div className="search-not-found text-center my-auto">
                  <div>
                    <img
                      className="img-fluid second-search"
                      src={errorImg}
                      alt=""
                    />
                    <p>Sorry, We didn't find Message Details</p>
                  </div>
                </div>
              ) : (
                <div className="blog-details">
                  <ul
                    className="blog-social"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      overflowX: "hidden",
                    }}
                  >
                    <li className="digits">
                      {date} | {time}
                    </li>
                    <li>
                      {message.name && (
                        <>
                          <i className="icofont icofont-user"></i>
                          <span className="ml-1">{message.name}</span>
                        </>
                      )}
                    </li>
                    <li className="digits">
                      {message.email && (
                        <>
                          <i className="icofont icofont-email"></i>
                          <span className="ml-1">{message.email}</span>
                        </>
                      )}
                    </li>
                    <li className="digits">
                      {message.phoneNumber && (
                        <>
                          <i className="icofont icofont-phone"></i>
                          <span className="ml-1">{message.phoneNumber}</span>
                        </>
                      )}
                    </li>
                  </ul>

                  <div
                    className="single-blog-content-top"
                    style={{ paddingTop: "30px" }}
                  >
                    <p style={{ fontSize: "1rem" }}>{message.message}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default MessageDetails;
