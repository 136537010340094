import {
  Card,
  Row,
  Col,
  Table,
  Button,
  CardBody,
  CardHeader,
  Container,
} from "reactstrap";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Pagination } from "@material-ui/lab";
import React, { Fragment, useState } from "react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import PermissionsList from "./Components/PermissionsList";
import ConfirmDelete from "../../Components/ConfirmDelete";
import operations from "../../../../redux/Main/operations";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import UpdatePermission from "./Components/UpdatePermission";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateNewPermission from "./Components/CreateNewPermission";
import { DeleteVendorPermission } from "../../../../service/service";
import errorImg from "../../../../assets/images/search-not-found.png";
import { getComparator, stableSort } from "../../../../service/sort";

const VendorPermissionsPage = (props) => {
  const { vendorPermissions, setVendorPermissions, company } = props;
  const [page, setPage] = useState(1);
  const [filteredPermissions, setFilteredPermissions] = useState(null);
  const [openCreate, setOpenCreate] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [permissionToUpdate, setPermissionToUpdate] = useState({});
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("createdAt");

  const handleClickOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleClose = () => {
    setOpenCreate(false);
    setOpenUpdate(false);
    setOpenDelete(false);
  };

  const confirmDelete = async (id) => {
    const response = await DeleteVendorPermission(id);
    if (response && response.isSuccess) {
      setVendorPermissions(
        vendorPermissions.filter(
          (perm) => perm._id !== response.data.vendorPermission._id
        )
      );
      handleClose();
    }
  };

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this permission!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmDelete(id).then(() => {
          SweetAlert.fire("Deleted!", "Permission deleted.", "success");
        });
      } else {
        SweetAlert.fire("Permission is safe!");
      }
    });
  };

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "name": {
        setOrderBy("title");
        setOrder("asc");
        break;
      }
      default:
        break;
    }
  };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleSearch = async (searchKey) => {
    if (searchKey.length > 0) {
      setFilteredPermissions(
        vendorPermissions.filter((perm) =>
          perm.title.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilteredPermissions(null);
    }
  };

  const handleFiltervendorPermissions = async (value) => {
    if (value === "company") {
      setFilteredPermissions(
        vendorPermissions.filter((perm) => perm.company === company._id)
      );
    } else {
      setFilteredPermissions(null);
    }
  };
  console.log("permissions:", vendorPermissions)
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="Vendor Permissions" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12">
              <Button onClick={handleClickOpenCreate} color="primary btn-block">
                <FontAwesomeIcon className="mr-1" icon={faPlus} /> Create New
                Permission
              </Button>
              <CreateNewPermission
                open={openCreate}
                company={company}
                vendorPermissions={vendorPermissions}
                handleClose={handleClose}
                setVendorPermissions={setVendorPermissions}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6" sm="12" className="text-right">
                    <div className="form-inline search-form">
                      <div className="form-group w-100">
                        <input
                          type="search"
                          id="search"
                          className="form-control-plaintext"
                          placeholder="Search.."
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col md="3" sm="12" className="products-total">
                    <div className="select2-drpdwn-product select-options d-inline-block w-100">
                      <div className="form-group w-100">
                        <select
                          name="createdAt"
                          className="form-control btn-square"
                          onChange={(e) =>
                            handleFiltervendorPermissions(e.target.value)
                          }
                        >
                          <option value="all">All Permissions</option>
                          <option value="company">Company Permissions</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                  <Col md="3" sm="12" className="products-total">
                    <div className="select2-drpdwn-product select-options d-inline-block w-100">
                      <div className="form-group w-100">
                        <select
                          name="createdAt"
                          className="form-control btn-square"
                          onChange={(e) => handleRequestSort(e, e.target.value)}
                        >
                          <option value="createdAt">Latest</option>
                          <option value="name">Name</option>
                        </select>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {(filteredPermissions ? filteredPermissions : vendorPermissions)
                  .length > 0 ? (
                  <div className="table-responsive">
                    <Table hover size="small">
                      <thead>
                        <tr>
                          <th className="border">Title</th>
                          <th className="border">Accessibilities</th>
                          <th className="text-right border">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stableSort(
                          filteredPermissions
                            ? filteredPermissions
                            : vendorPermissions,
                          getComparator(order, orderBy)
                        )
                          .slice(page * 10 - 10, page * 10)
                          .map((permission, index) => (
                            <tr key={index}>
                              <td className="border">{permission.title}</td>
                              <td className="border">
                                <PermissionsList permission={permission} />
                              </td>

                              <td className="text-right border">

                                <div className="button-list">
                                  <button
                                    onClick={() => {
                                      setPermissionToUpdate(permission);
                                      setOpenUpdate(true);
                                    }}
                                    className="btn btn-text p-0"
                                  >
                                    <i
                                      className="fa fa-pencil"
                                      style={{
                                        width: 35,
                                        fontSize: 16,
                                        padding: 11,
                                        color: "rgb(40, 167, 69)",
                                      }}
                                    ></i>
                                  </button>
                                  <button
                                    onClick={() => {
                                      handleDelete(permission._id);
                                    }}
                                    className="btn btn-text p-0"
                                  >
                                    <i
                                      className="fa fa-trash"
                                      style={{
                                        width: 35,
                                        fontSize: 16,
                                        padding: 11,
                                        color: "#e4566e",
                                      }}
                                    ></i>
                                  </button>
                                </div>

                              </td>
                            </tr>
                          ))}
                        {vendorPermissions.length > 10 && (
                          <tr>
                            <td colSpan="6" align="right">
                              <Pagination
                                count={parseInt(
                                  filteredPermissions
                                    ? filteredPermissions.length / 10 + 1
                                    : vendorPermissions.length / 10 + 1
                                )}
                                page={page}
                                onChange={handlePageChange}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="search-not-found text-center">
                    <div>
                      <img
                        className="img-fluid second-search"
                        src={errorImg}
                        alt="Error Logo"
                      />
                      <p>Sorry, We didn't find any permission</p>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <UpdatePermission
        open={openUpdate}
        handleClose={handleClose}
        permissionToUpdate={permissionToUpdate}
        setVendorPermissions={setVendorPermissions}
        vendorPermissions={vendorPermissions}
      />
      <ConfirmDelete
        open={openDelete}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    vendorPermissions: state.mainReducers.main.vendorPermissions,
    company: state.mainReducers.main.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VendorPermissionsPage);
