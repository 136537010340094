import { connect } from "react-redux";
import { toast } from "react-toastify";
import config from "../../../../service/api/config";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Button, Col, Container, Row } from "reactstrap";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import React, { Fragment, useEffect, useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { GetSiteAbout, UpdateSiteSettings } from "../../../../service/service";

const AboutSitePage = ({ siteSettings }) => {
  console.log(
    "🚀 ~ file: AboutSitePage.jsx:15 ~ AboutSitePage ~ siteSettings",
    siteSettings
  );
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [themeId, setThemeId] = useState(siteSettings.theme._id);
  console.log(
    "🚀 ~ file: AboutSitePage.jsx:23 ~ AboutSitePage ~ themeId",
    themeId
  );

  const getSiteSettings = async () => {
    try {
      const response = await GetSiteAbout(siteSettings?._id);
      if (response && response.isSuccess) {
        setContent(response.data.siteSettings?.about);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoading(true);
    const data = {
      themeId: themeId,
      about: content,
    };
    try {
      const response = await UpdateSiteSettings(siteSettings._id, data);
      if (response && response.isSuccess) {
        // getSiteSettings();
        toast.success("Site About Updated!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        console.log(response);
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log(error);
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    getSiteSettings();
  }, []);

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <div>
      <form onSubmit={handleSubmit} className="login">
        <Fragment>
          <Container fluid={true}>
            <div className="page-header">
              <Row>
                <Col lg="9" sm="3">
                  <Breadcrumbs title="About Site" parent="Dashboard" />
                </Col>
                <Col lg="3" sm="3">
                  {buttonLoading ? (
                    <Button color="primary btn-block" disabled={buttonLoading}>
                      SAVING...
                    </Button>
                  ) : (
                    <Button type="submit" color="primary btn-block">
                      <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                    </Button>
                  )}
                </Col>
              </Row>
            </div>

            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">
                  About Site
                </h6>
              </div>
              <div className="card-body">
                <div className="form-group row">
                  <div className="col-sm-12">
                    <div className="border h-100">
                      <CKEditor
                        editor={ClassicEditor}
                        onReady={(editor) => {}}
                        data={content}
                        config={{
                          ckfinder: {
                            uploadUrl: config.serverURL + "file/editor-file",
                          },
                        }}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setContent(editor.getData());
                          console.log({ event, editor, data });
                        }}
                        onFilesChange={(e) => console.log(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Fragment>
      </form>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

export default connect(mapStateToProps)(AboutSitePage);
