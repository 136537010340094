import React from "react";
import { Button } from "reactstrap";
import config from "../../../../../../service/api/config";

const ModalProductList = ({
  product,
  productArray,
  setProductArray,
  productIdList,
  setProductIdList,
}) => {
  const addProductToArray = (e) => {
    e.preventDefault();
    setProductArray([
      ...productArray,
      {
        name: product.name,
        _id: product._id,
        photos: product.photos[0].url,
        price: product.price,
      },
    ]);
    setProductIdList([...productIdList, product._id]);
  };

  return (
    <tr>
      <td className="border">
        <img
          width="35"
          alt="brand logo"
          src={
            config.fileServer +
            config.thumbnailContainer +
            product.photos[0].url
          }
          className="img-fluid"
        />
      </td>
      <td className="border">{product.name}</td>
      <td className="border">
        <b>৳</b>
        {product.price}
      </td>
      <td className="text-right border">
        <div className="button-list">
          <Button
            onClick={addProductToArray}
            disabled={productIdList.includes(product._id)}
          >
            {productIdList.includes(product._id) ? "ADDED" : "ADD"}
          </Button>
        </div>
      </td>
    </tr>
  );
};

export default ModalProductList;
