import React from "react";
import CountUp from "react-countup";
import { Card, CardBody } from "reactstrap";

const CountCard01 = (props) => {
  const { bgColor, icon, title, countNumber } = props;
  return (
    <div>
      <Card className="o-hidden">
        <CardBody className={`${bgColor} b-r-4`}>
          <div className="media static-top-widget">
            <div className="align-self-center text-center">
              <i className={icon}></i>
            </div>
            <div className="media-body">
              <span className="m-0">{title}</span>
              <h4 className="counter mb-0">
                <CountUp end={countNumber} />
              </h4>
              <i className={`${icon} icon-bg`}></i>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default CountCard01;
