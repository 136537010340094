import React, { useState, useEffect } from "react";
import { Col, Row, Button, Container } from "reactstrap";
import Breadcrumbs from "../../Components/Breadcrumbs";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import errorImg from "../../../../assets/images/search-not-found.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CreatePromotion from "./Components/CreatePromotion";
import { GetAllPromotions } from "../../../../service/service";
import AllPromotion from "./Components/AllPromotion";

const PromotionPage = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [dataSubmit, setDataSubmit] = useState(false);
  const [data, setData] = useState([]);
  const [isDelete, setDelete] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleToggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const fetchData = async () => {
    const response = await GetAllPromotions();

    if (response && response.isSuccess) {
      setData(response.data.allPromotion);
      setIsLoading(false);
      setUpdate(false);
      setDelete(false);
      setDataSubmit(false);
      console.log(response);
    }

    if (!response || !response.isSuccess) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [dataSubmit, isDelete, isUpdate]);

  return (
    <Container>
      {" "}
      <div className="page-header">
        <Row>
          <Col lg="8" xs="12">
            <Breadcrumbs title="Promotion" parent="Dashboard" />
          </Col>
          <Col lg="4" xs="12">
            <Button color="primary btn-block" onClick={handleToggleModal}>
              <FontAwesomeIcon
                className="mr-1"
                icon={faPlus}
                onClick={handleToggleModal}
              />
              Create Promotion
            </Button>
            <CreatePromotion
              toggle={handleToggleModal}
              open={isOpenModal}
              setDataSubmit={setDataSubmit}
            />
          </Col>
        </Row>
      </div>
      {isLoading ? (
        <ComponentLoader height="80vh" />
      ) : isError ? (
        <div className="search-not-found text-center my-auto">
          <div>
            <img className="img-fluid second-search" src={errorImg} alt="" />
            <p>Sorry, We didn't find any Brands</p>
          </div>
        </div>
      ) : (
        <AllPromotion data={data} setUpdate={setUpdate} setDelete={setDelete} />
      )}
    </Container>
  );
};

export default PromotionPage;
