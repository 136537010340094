import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@material-ui/core";

const ConfirmDelete = (props) => {
  const { open, handleClose, handleDelete } = props;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <DialogContentText>Do you want to delete ?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <button onClick={handleClose} className="btn btn-info btn-sm">
          No
        </button>
        <button onClick={handleDelete} className="btn btn-danger btn-sm">
          Yes
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDelete;
