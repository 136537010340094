import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import jwtDecode from "jwt-decode";
import "./style.css";
import "./index.scss";
// import "./theme.css";

import store from "./redux/Store/Store";
import { types } from "./redux/Main";
import { GetSiteSettings, ReAuth } from "./service/service";
import setShopHeader from "./service/setShopHeader";
import NotFoundPage from "./Pages/NotFound";

// updating to react v18
const root = ReactDOM.createRoot(document.getElementById("root"));

const getSiteSetting = async () => {
  const siteURL = window.location.href.split("/")[2];
  const siteResponse = await GetSiteSettings(siteURL);
  console.log(siteResponse);
  if (siteResponse && siteResponse.isSuccess) {
    setShopHeader(siteResponse.data);
    store.dispatch({
      type: types.SITE_SETTINGS,
      status: "success",
      payload: {
        siteSettings: siteResponse.data,
      },
    });
    const token = localStorage.getItem("auth_token");
    if (token) {
      let user = jwtDecode(token);
      ReAuth({
        token: token,
        type: user.type,
      }).then((response) => {
        console.log(response);
        if (response && response.isSuccess) {
          const user = jwtDecode(response.data.token);
          store.dispatch({
            type: types.USER,
            status: "success",
            payload: {
              user: user,
            },
          });
        } else {
          store.dispatch({
            type: types.USER,
            status: "success",
            payload: {
              user: { loading: false },
            },
          });
        }
      });

      // console.log(user)
    } else {
      store.dispatch({
        type: types.USER,
        status: "success",
        payload: {
          user: { loading: false },
        },
      });
    }
  } else {
    root.render(<NotFoundPage />);
  }
};

getSiteSetting();

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);
