import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Pagination } from "@material-ui/lab";
import React, { Fragment, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  CardBody,
  Container,
  CardHeader,
} from "reactstrap";
import moment from "moment";
// import SweetAlert from "sweetalert2";
import { bindActionCreators } from "redux";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import errorImg from "../../../../assets/images/search-not-found.png";
import { getComparator, stableSort } from "../../../../service/sort";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { DeleteOrder, GetSubscriptions } from "../../../../service/service";

const SubscriptionsPage = (props) => {
  const { company } = props;
  const [page, setPage] = useState(1);
  const [filteredSubscriptions, setFilteredSubscriptions] = useState(null);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");

  const fetchData = async () => {
    const response = await GetSubscriptions();
    console.log(response);
    if (response && response.isSuccess) {
      setSubscriptions(response.data.subscriptions);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [company]);

  // const confirmDelete = async (id) => {
  //   const response = await DeleteOrder(id);
  //   if (response && response.isSuccess) {
  //     console.log(response.data);
  //     setSubscriptions(
  //       subscriptions.filter((order) => order._id !== response.data.order._id)
  //     );
  //   }
  // };

  // const handleDelete = async (id) => {
  //   SweetAlert.fire({
  //     title: "Are you sure?",
  //     text: "Once deleted, you will not be able to recover this order!",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "confirm",
  //     cancelButtonText: "cancel",
  //     reverseButtons: true,
  //   }).then((result) => {
  //     if (result.value) {
  //       confirmDelete(id).then(() => {
  //         SweetAlert.fire("Deleted!", "Order deleted.", "success");
  //       });
  //     } else {
  //       SweetAlert.fire("Order is safe!");
  //     }
  //   });
  // };

  const handlePageChange = async (event, value) => {
    setPage(value);
  };

  const handleSearch = async (searchKey) => {
    if (searchKey.length > 0) {
      setFilteredSubscriptions(
        subscriptions.filter((order) =>
          order.orderID.toLowerCase().includes(searchKey.toLowerCase())
        )
      );
    } else {
      setFilteredSubscriptions(null);
    }
  };

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "amountAsc": {
        setOrderBy("amount");
        setOrder("asc");
        break;
      }
      case "amountDesc": {
        setOrderBy("amount");
        setOrder("desc");
        break;
      }
      default:
        break;
    }
  };
  console.log("subs", subscriptions);
  console.log("FILTER", filteredSubscriptions);
  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col sm="12">
              <Breadcrumbs title="Subscriptions" parent="Dashboard" />
            </Col>
          </Row>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="10" sm="12">
                    <form className="form-inline search-form">
                      <div className="form-group w-100">
                        <input
                          type="search"
                          id="search"
                          className="form-control-plaintext"
                          placeholder="Search.."
                          onChange={(e) => {
                            handleSearch(e.target.value);
                          }}
                        />
                      </div>
                    </form>
                  </Col>
                  <Col md="2" sm="12">
                    <div className="form-group w-100">
                      <select
                        className="form-control btn-square"
                        name="select"
                        onChange={(e) => handleRequestSort(e, e.target.value)}
                      >
                        <option value="createdAt" selected>
                          Latest
                        </option>
                        <option value="amountAsc">Price: low to high</option>
                        <option value="amountDesc">Price: high to low</option>
                      </select>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {(filteredSubscriptions ? filteredSubscriptions : subscriptions)
                  .length > 0 ? (
                  <div className="table-responsive">
                    <Table hover size="small">
                      <thead>
                        <tr>
                          <th>Vendor</th>
                          <th>Package</th>
                          <th>Created</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {stableSort(
                          filteredSubscriptions
                            ? filteredSubscriptions
                            : subscriptions,
                          getComparator(order, orderBy)
                        )
                          .filter((sub) => sub.company)
                          .slice(page * 10 - 10, page * 10)
                          .map((subs) => (
                            <tr>
                              <td>{subs.vendor && subs.vendor.name}</td>
                              <td>{subs.package && subs.package.name}</td>
                              <td>
                                {moment(subs.createdAt).format("D MMM YYYY")}
                              </td>
                              <td>
                                <span
                                  className={`p-1 m-0 text-uppercase font-weight-bold badge ${subs.status === "pending" && "badge-warning"
                                    } ${subs.status === "active" && "badge-success"
                                    } ${subs.status === "expired" && "badge-danger"
                                    }`}
                                >
                                  {subs.status}
                                </span>
                              </td>
                              <td>
                                <div className="button-list">
                                  <Link
                                    to={`/dashboard/update-subscription/${subs._id}`}
                                  >
                                    <button
                                      href="#"
                                      className="btn btn-text p-0"
                                    >
                                      <i
                                        className="fa fa-pencil"
                                        style={{
                                          width: 35,
                                          fontSize: 16,
                                          padding: 11,
                                          color: "rgb(40, 167, 69)",
                                        }}
                                      ></i>
                                    </button>
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        {subscriptions.length > 10 && (
                          <tr>
                            <td colspan="6" align="right">
                              <Pagination
                                count={
                                  filteredSubscriptions
                                    ? (
                                      filteredSubscriptions.length / 10
                                    ).toFixed(0)
                                    : (subscriptions.length / 10).toFixed(0)
                                }
                                page={page}
                                onChange={handlePageChange}
                              />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="search-not-found text-center">
                    <div>
                      <img
                        className="img-fluid second-search"
                        src={errorImg}
                        alt=""
                      />
                      <p>Sorry, We didn't find any Subscription</p>
                    </div>
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    subscriptions: state.mainReducers.main.subscriptions,
    company: state.mainReducers.main.company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionsPage);
