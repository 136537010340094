import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import operations from "../../../../redux/Main/operations";
import { GetAllVendors } from "../../../../service/service";
import noImage from "../../../../assets/images/notFound.png";
import React, { Fragment, useEffect, useState } from "react";
import { imageExists } from "../../../../service/imageExists";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import errorImg from "../../../../assets/images/search-not-found.png";
import ComponentLoader from "../../Components/Loader/ComponentLoader";

const VendorsPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("createdAt");
  const [vendors, setVendors] = useState([]);
  const [limit, setLimit] = React.useState(20);
  const [searchKey, setSearchKey] = useState("");
  const [category, setCategory] = useState(null);
  const [vendorsCount, setVendorsCount] = useState(0);
  const [buttonLoading, setButtonLoading] = useState(false);

  const getData = async () => {
    setLoading(true);

    const data = {
      limit: limit,
      sortBy: {
        field: orderBy,
        order: order,
      },
      searchKey: searchKey ? searchKey : null,
    };

    const response = await GetAllVendors(data);
    if (response && response.isSuccess) {
      setVendors(response.data.vendors);
      setVendorsCount(response.data.vendorsCount);
    } else {
      setVendors([]);
      setVendorsCount(0);
    }
    setLoading(false);
  };

  const loadMore = async () => {
    setButtonLoading(true);
    const data = {
      skip: vendors.length,
      limit: limit,
      sortBy: {
        field: orderBy,
        order: order,
      },
      searchKey: searchKey ? searchKey : null,
    };

    const response = await GetAllVendors(data);

    if (response && response.isSuccess) {
      setVendors([...vendors, ...response.data.vendors]);
      setVendorsCount(response.data.vendorsCount);
    }
    setButtonLoading(false);
  };

  useEffect(() => {
    getData();
  }, [order, orderBy, category, limit]);

  const handleRequestSort = (event, property) => {
    switch (property) {
      case "createdAt": {
        setOrderBy(property);
        setOrder("desc");
        break;
      }
      case "name": {
        setOrderBy(property);
        setOrder("asc");
        break;
      }
      default:
        break;
    }
  };
  console.log("venors", vendors);
  const handleSearch = async (e) => {
    e.preventDefault();
    getData();
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="Vendors" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12"></Col>
          </Row>
        </div>

        <Card className="w-100">
          <CardBody className="w-100">
            <Row>
              <Col lg="10" md="12" sm="12" className="text-right">
                <form
                  className="form-inline search-form"
                  onSubmit={handleSearch}
                >
                  <div className="form-group w-100">
                    <input
                      type="text"
                      id="search"
                      name="search"
                      className="form-control-plaintext rounded-0"
                      placeholder="Search.."
                      onChange={(e) => setSearchKey(e.target.value)}
                      value={searchKey}
                    />
                  </div>
                </form>
              </Col>
              <Col lg="2" md="12" sm="12" className="text-left mb-2">
                <div className="form-inline">
                  <div className="form-group w-100">
                    <select
                      name="select"
                      className="form-control btn-square border"
                      onChange={(e) => handleRequestSort(e, e.target.value)}
                    >
                      <option value="createdAt">Latest</option>
                      <option value="name">Name</option>
                    </select>
                  </div>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
        {loading ? (
          <ComponentLoader height="80vh" />
        ) : (
          <div>
            {vendors.length > 0 ? (
              <Row>
                {vendors.map((company) => (
                  <Col md="4" lg="4" xl="3" key={company._id}>
                    <Card className="custom-card pb-0">
                      <Link to={`/dashboard/company-details/${company.slug}`}>
                        <div className="card-profile">
                          <img
                            src={
                              company.logo &&
                              imageExists(
                                config.fileServer +
                                  config.thumbnailContainer +
                                  company.logo
                              )
                                ? config.fileServer +
                                  config.thumbnailContainer +
                                  company.logo
                                : noImage
                            }
                            className="rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="text-center profile-details">
                          <h5>{company.name}</h5>
                          <h6>{company.companyTypes.map((c) => c + ", ")}</h6>
                        </div>
                      </Link>
                      <Row className="card-footer align-items-center justify-content-center">
                        <Col className="col-12 p-2" sm="4">
                          <h6 className="font-size-18 font-weight-bold">
                            Status
                          </h6>
                          <p className="font-size-12 font-weight-bold">
                            <span className="text-uppercase">
                              <span
                                className={`p-1 m-0 text-uppercase font-weight-bold badge ${
                                  company.activeStatus === "pending" &&
                                  "badge-warning"
                                }  ${
                                  (company.activeStatus === "true" ||
                                    company.activeStatus === "active") &&
                                  "badge-success"
                                } ${
                                  company.activeStatus === "disabled" &&
                                  "badge-danger"
                                }`}
                              >
                                {company.activeStatus === "true"
                                  ? "active"
                                  : company.activeStatus}
                              </span>
                            </span>
                          </p>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <div className="search-not-found text-center">
                <div>
                  <img
                    className="img-fluid second-search"
                    src={errorImg}
                    alt=""
                  />
                  <p>Sorry, We didn't find any company</p>
                </div>
              </div>
            )}
          </div>
        )}
        {vendors.length < vendorsCount && (
          <tr>
            <td colSpan="7" align="center">
              <button
                href="#"
                onClick={loadMore}
                className="btn btn-primary btn-sm"
              >
                {buttonLoading ? "LOADING" : "Load More"}
              </button>
            </td>
          </tr>
        )}
      </Container>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    user: state.mainReducers.main.user,
    vendors: state.mainReducers.main.vendors,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(VendorsPage);
