import {
  UploadPhoto,
  DeletePhoto,
  CreateCategory,
} from "../../../../../service/service";
import { toast } from "react-toastify";
import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import config from "../../../../../service/api/config";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

export default function CreateCategor(props) {
  const { open, handleClose, setCategories, categories, company } = props;
  const [photo, setPhoto] = useState(null);
  const [isRoot, setIsRoot] = useState(false);
  const [isLeaf, setIsLeaf] = useState(false);
  const [parentCategory, setParentCategory] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const handleUploadPhoto = async ({ meta, file }, status, allFiles) => {
    console.log(status);
    if (status === "done") {
      allFiles.forEach((f) => f.remove());
      try {
        const response = await UploadPhoto(file);
        console.log(response);
        if (response && response.isSuccess) {
          setPhoto(response.data.fileName);
        }
      } catch (err) {
        if (err.response.status === 500) {
          console.log("There was a problem with the server");
        } else {
          console.log(err.response.data.msg);
        }
      }
    }
  };

  const handleDeletePhoto = async (photo) => {
    await DeletePhoto({
      fileName: photo,
      container: "ivcommerceoriginalcontainer",
    });
    setPhoto(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (e.target.name.value) {
      if (!isRoot && !parentCategory) {
        toast.error("Category must be root or parent category", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        setButtonLoading(true);
        const data = {
          name: e.target.name.value,
          photo: photo,
          isLeaf: isLeaf,
          isRoot: isRoot,
          parentCategory: isRoot ? null : parentCategory,
          company: company,
        };
        const response = await CreateCategory(data);
        if (response && response.isSuccess) {
          setCategories([response.data.category, ...categories]);
          toast.success("Category Created!", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
        setButtonLoading(false);
        setPhoto("");
        setIsLeaf("");
        setIsRoot("");
        handleClose();
        setParentCategory("");
      }
    } else {
      toast.error("Name & Unit fields can't be empty !!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
  };

  return (
    <Modal isOpen={open} toggle={handleClose} centered>
      <ModalHeader toggle={handleClose}>Create Category</ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            <label
              htmlFor="productTitle"
              className="col-sm-12 font-weight-bold small"
            >
              Name
            </label>
            <div className="col-sm-12 mb-2">
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="form-control-sm form-control"
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-6 mb-2">
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isRoot"
                    checked={isRoot}
                    onChange={() => {
                      setIsRoot(!isRoot);
                      setIsLeaf(false);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="isRoot">
                    Root Category
                  </label>
                </div>
              </div>
            </div>
            <div className="col-sm-6 mb-2">
              <div className="form-group">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isLeaf"
                    checked={isLeaf}
                    onChange={() => {
                      setIsLeaf(!isLeaf);
                      setIsRoot(false);
                    }}
                  />
                  <label className="custom-control-label" htmlFor="isLeaf">
                    Leaf Category
                  </label>
                </div>
              </div>
            </div>
          </div>
          {!isRoot && (
            <div className="form-group row">
              <label
                htmlFor="productTitle"
                className="col-sm-12 font-weight-bold small"
              >
                Parent Category
              </label>
              <div className="col-sm-12 mb-2">
                <select
                  value={parentCategory}
                  aria-label="Default select example"
                  onChange={(e) => setParentCategory(e.target.value)}
                  className="form-select form-control-sm form-control mb-2"
                >
                  <option>Open this select menu</option>
                  {categories
                    .filter((item) => !item.isLeaf)
                    .map((cat) => (
                      <option value={cat._id} key={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          )}
          <div className="form-group row mb-3">
            <label
              htmlFor="productTitle"
              className="col-sm-12 font-weight-bold small"
            >
              Image
            </label>
            <div className="col-sm-12">
              {photo ? (
                <div className="w-100 text-center">
                  <div className="w-100">
                    <img
                      src={config.fileServer + config.imageContainer + photo}
                      alt=""
                      className="mb-2"
                      width="50%"
                    />
                  </div>
                  <button
                    type="button"
                    className="btn btn-icon-split btn-sm btn-danger"
                    onClick={() => handleDeletePhoto(photo)}
                  >
                    Remove Photo
                  </button>
                </div>
              ) : (
                <Dropzone
                  onChangeStatus={handleUploadPhoto}
                  accept="image/*"
                  PreviewComponent={null}
                  maxFiles={1}
                  multiple={false}
                  canCancel={false}
                />
              )}
            </div>
          </div>
          {buttonLoading ? (
            <Button color="primary btn-block" disabled={buttonLoading}>
              SAVING...
            </Button>
          ) : (
            <Button type="submit" color="primary btn-block">
              <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
            </Button>
          )}
        </form>
      </ModalBody>
    </Modal>
  );
}
