import { connect } from "react-redux";
import { toast } from "react-toastify";
import Invoice from "./Components/Invoice";
import { bindActionCreators } from "redux";
import React, { useEffect, useState } from "react";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import { Button, Col, Container, Row } from "reactstrap";
import operations from "../../../../redux/Main/operations";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import { faFileInvoice, faSave } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";

import {
  UpdateOrder,
  GetOrderDetails,
  AddNewVendorRequest,
} from "../../../../service/service";
import { Link } from "react-router-dom";

const UpdateOrderPage = (props) => {
  const { company, match, siteSettings } = props;
  const [paid, setPaid] = useState(null);
  const [order, setOrder] = useState(null);
  const [buyer, setBuyer] = useState(null);
  const [amount, setAmount] = useState(null);
  const [status, setStatus] = useState(null);
  const [seller, setSeller] = useState(null);
  const [loading, setLoading] = useState(true);
  const [dueAmount, setDueAmount] = useState(null);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [shipmentAddress, setShipmentAddress] = useState(null);
  let { id } = useParams();
  console.log("order", order);
  const fetchData = async () => {
    const response = await GetOrderDetails(id);
    if (response && response.isSuccess) {
      setOrder(response.data.order);
      setBuyer(response.data.order.buyer);
      setSeller(response.data.order.seller);
      setAmount(response.data.order.amount);
      setStatus(response.data.order.status);
      setPaid(
        response.data.order.paidAmount ? response.data.order.paidAmount : 0
      );

      setDueAmount(
        response?.data?.order?.totalAmount - response?.data?.order?.paidAmount
      );
      setShipmentAddress(response?.data?.order?.shipmentAddress);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInvoiceModal = () => {
    setOpenInvoice(!openInvoice);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (status !== order?.status || paid !== order?.paidAmount) {
      setButtonLoading(true);
      const data = {
        status: status,
        paidAmount: paid,
      };

      if (
        !siteSettings?.features?.vendorAccessManagement ||
        !company?.isVendor ||
        (company?.permissionType &&
          company?.permissionType?.orderManagement?.updateOrder === 3)
      ) {
        const response = await UpdateOrder(order._id, data);
        if (response && response.isSuccess) {
          await fetchData();

          toast.success("Order Updated !", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      } else if (
        company?.permissionType &&
        company?.permissionType.productManagement.updateProduct === 2
      ) {
        const response = await AddNewVendorRequest({
          vendor: company._id,
          requestType: "updateOrder",
          requestData: data,
          collectionName: "Order",
          docID: order._id,
        });

        console.log(response);

        if (response && response.isSuccess) {
          toast.success("Order Update Requested! Wait for Admin Approval", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 3000,
          });
        }
      }
      setButtonLoading(false);
    }
  };

  const orderStatus = () => {
    if (dueAmount === 0) return "Paid";
    if (dueAmount > 0 && dueAmount < amount) return "Partially Paid";
    if (dueAmount === amount) return order?.paymentType;
  };

  return loading ? (
    <ComponentLoader height="80vh" />
  ) : (
    <div>
      <div className="text-capitalize">
        <Container fluid={true}>
          <div className="page-header">
            <Row>
              <Col lg="9" sm="3">
                <Breadcrumbs title="Order Details" parent="Orders" />
              </Col>
              <Col lg="3" sm="3">
                <Button onClick={handleInvoiceModal} color="primary btn-block">
                  <FontAwesomeIcon className="mr-1" icon={faFileInvoice} />{" "}
                  Invoice
                </Button>
                <Invoice
                  buyer={buyer}
                  order={order}
                  open={openInvoice}
                  cart={order.products}
                  shipmentAddress={shipmentAddress}
                  handleInvoiceModal={handleInvoiceModal}
                />
              </Col>
            </Row>
          </div>
          <div className="row">
            <div className="col-lg-8 col-xl-9">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Order Details
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-md">
                      <tbody>
                        <tr>
                          <td className="border">OrderID</td>
                          <td className="border">{order?.orderID}</td>
                          <td className="border">Total Amount</td>
                          <td className="border">
                            {amount.toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td className="border">Date</td>
                          <td>{order?.createdAt.toString().slice(0, 10)}</td>
                          <td className="border">Offer Amount</td>
                          <td className="border font-weight-bold">
                            {order?.offerAmount.toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td className="border">Status</td>
                          <td className="border">{order?.status}</td>
                          <td className="border">Paid Amount</td>
                          <td className="border">
                            {paid.toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                        <tr>
                          <td className="border">Shipping Type</td>
                          <td className="border">Regular</td>
                          <td className="border">Amount</td>
                          <td className="border">{order?.shipping}</td>
                        </tr>
                        <tr>
                          <td className="border">payment Type</td>
                          <td className="border">{orderStatus()}</td>
                          <td className="border font-weight-bold">
                            Due Amount
                          </td>
                          <td className="border font-weight-bold">
                            {dueAmount.toLocaleString("en-IN", {
                              maximumFractionDigits: 2,
                            })}
                          </td>
                        </tr>
                        {siteSettings &&
                          siteSettings?.features?.globalSeller && (
                            <tr>
                              <td className="border">Ordered Currency</td>
                              <td className="border">{order?.currencyUnit}</td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Products
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-md">
                      <thead>
                        <tr>
                          <td className="border">product img</td>
                          <td className="border">product name</td>
                          <td className="border">product price</td>
                          <td className="border">product quantity</td>
                          <td className="border">total prize</td>
                          <td className="border">SKU</td>
                          <td className="border">Action</td>
                        </tr>
                      </thead>
                      <tbody>
                        {order?.products.map((product) => (
                          <tr className="border" key={product._id}>
                            <td className="border">
                              <img
                                width="40"
                                alt="product"
                                className="img-fluid"
                                src={
                                  config?.fileServer +
                                    config?.thumbnailContainer +
                                    product?.photo &&
                                  imageExists(
                                    config?.fileServer +
                                      config?.thumbnailContainer +
                                      product?.photo
                                  )
                                    ? config?.fileServer +
                                      config?.thumbnailContainer +
                                      product?.photo
                                    : noImage
                                }
                              />
                            </td>
                            <td className="border">{product?.name}</td>
                            <td className="border">
                              {product?.price.toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                              })}
                            </td>
                            <td className="border">
                              {product?.qty ? product?.qty : product?.quantity}
                            </td>
                            <td className="border">
                              {/* {(product.price * product.qty
                                ? product.qty
                                : product.quantity
                              ).toLocaleString("en-IN", {
                                maximumFractionDigits: 2,
                              })} */}

                              {product?.qty
                                ? (product?.qty * product?.price).toLocaleString(
                                    "en-IN",
                                    {
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : (
                                    product?.quantity * product?.price
                                  ).toLocaleString("en-IN", {
                                    maximumFractionDigits: 2,
                                  })}
                            </td>
                            <td className="border">
                              {product?.sku ? product?.sku : ""}
                            </td>
                            <td className="border">
                              <Link
                                to={`/dashboard/update-product/${product?.slug}`}
                              >
                                <button className="btn btn-dark p-0">
                                  <i
                                    className="fa fa-pencil"
                                    style={{
                                      width: 35,
                                      fontSize: 16,
                                      padding: 11,
                                      color: "rgb(40, 167, 69)",
                                    }}
                                  ></i>
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Shipment Address
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-md">
                      <thead>
                        <tr>
                          <td className="border">name</td>
                          <td className="border">phone</td>
                          <td className="border">organization Name</td>
                          <td className="border">Flat Number</td>
                          <td className="border">Addinational Info</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border">{shipmentAddress?.name}</td>
                          <td className="border">{shipmentAddress?.phone}</td>
                          <td className="border">
                            {shipmentAddress?.orgName &&
                              shipmentAddress?.orgName}
                          </td>
                          <td className="border">
                            {shipmentAddress?.flatNumber &&
                              shipmentAddress?.flatNumber}
                          </td>
                          <td className="border">
                            {shipmentAddress?.additionalInfo &&
                              shipmentAddress?.additionalInfo}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-xl-3">
              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Buyer Information
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-md">
                      <tbody>
                        <tr>
                          <td className="border">Photo</td>
                          <td className="border">
                            <img
                              src={
                                buyer?.userInfo &&
                                buyer?.userInfo?.photoURL &&
                                imageExists(
                                  config?.fileServer +
                                    config?.thumbnailContainer +
                                    buyer?.userInfo?.photoURL
                                )
                                  ? config?.fileServer +
                                    config?.thumbnailContainer +
                                    buyer?.userInfo?.photoURL
                                  : noImage
                              }
                              className="img-fluid"
                              width="40px"
                              alt="product"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="border">Name</td>
                          <td className="border">
                            {buyer?.userInfo && buyer?.userInfo?.firstName}{" "}
                            {buyer?.userInfo && buyer?.userInfo?.lastName}
                          </td>
                        </tr>
                        <tr>
                          <td className="border">Email</td>
                          <td className="border text-lowercase">
                            {buyer?.email}
                          </td>
                        </tr>
                        <tr>
                          <td className="border">Phone</td>
                          <td className="border">
                            {order?.shipmentAddress?.phone}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="card shadow mb-4">
                <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                  <h6 className="m-0 font-weight-bold text-primary">
                    Seller Information
                  </h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-md">
                      <thead>
                        <tr>
                          <td className="border">logo</td>
                          <td className="border">seller name</td>
                          <td className="border">email</td>
                          <td className="border">phone</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="border">
                            <img
                              width="40px"
                              alt="seller logo"
                              className="img-fluid"
                              src={
                                config?.fileServer +
                                config?.thumbnailContainer +
                                seller?.logo
                                  ? config?.fileServer +
                                    config?.thumbnailContainer +
                                    seller?.logo
                                  : noImage
                              }
                            />
                          </td>
                          <td className="border">{seller?.name}</td>
                          <td className="border">{seller?.email}</td>
                          <td className="border">{seller?.phone}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              {!company?.isVendor ||
              !siteSettings?.features?.vendorAccessManagement ||
              (company?.permissionType &&
                company?.permissionType?.orderManagement?.updateOrder !== 1) ? (
                <div className="card shadow mb-4">
                  <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h6 className="m-0 font-weight-bold text-primary">
                      Change Status
                    </h6>
                  </div>
                  <div className="card-body">
                    <label className="col-sm-12 font-weight-bold small">
                      Status
                    </label>
                    <select
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      className="form-select form-control-sm form-control mb-2"
                    >
                      <option value="pending">Pending</option>
                      <option value="processing">Processing</option>
                      <option value="shipping">Shipping</option>
                      <option value="shipped">Shipped</option>
                      <option value="delivered">Delivered</option>
                      <option value="return">Return</option>
                      <option value="request for refund">
                        Request for refund
                      </option>
                      <option value="cancelled">Cancelled</option>
                    </select>
                    <label className="col-sm-12 font-weight-bold small">
                      Paid Amount
                    </label>
                    <input
                      required
                      type="text"
                      name="paid"
                      value={paid}
                      placeholder="0"
                      className="form-control-sm form-control"
                      onChange={(e) => setPaid(e.target.value)}
                    />
                  </div>

                  <div className="card-footer text-right">
                    {buttonLoading ? (
                      <Button
                        color="primary btn-block"
                        disabled={buttonLoading}
                      >
                        SAVING...
                      </Button>
                    ) : (
                      <Button
                        onClick={handleSubmit}
                        disabled={
                          status === order?.status && paid === order?.paidAmount
                        }
                        color="primary btn-block"
                      >
                        <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
                      </Button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

function mapStateToProps(state, props) {
  return {
    company: state?.mainReducers?.main?.company,
    products: state?.mainReducers?.main?.products,
    siteSettings: state?.mainReducers?.main?.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrderPage);
