import React from "react";

const AddedList = ({
  product,
  productArray,
  setProductArray,
  productIdList,
  setProductIdList,
}) => {
  const removeProduct = (e) => {
    e.preventDefault();
    const newProductArray = productArray.filter((p) => p._id !== product._id);
    setProductArray(newProductArray);
    const newProductIdList = productIdList.filter((p) => p !== product._id);
    setProductIdList(newProductIdList);
  };
  return (
    <div className="d-flex m-1 justify-content-between">
      <h6>{product.name}</h6>
      <button onClick={removeProduct} className="btn btn-text p-0">
        <i
          className="fa fa-trash"
          style={{
            width: 35,
            fontSize: 16,
            padding: 11,
            color: "#e4566e",
          }}
        ></i>
      </button>
    </div>
  );
};

export default AddedList;
