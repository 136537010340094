import {
  SubmitOtp,
  ForgotPassword,
  SubmitNewPassword,
} from "../../service/service";
import SweetAlert from "sweetalert2";
import { connect } from "react-redux";
import queryString from "query-string";
import { Link } from "react-router-dom";
import React, { Fragment, useState } from "react";
import { Container, Row, Col, Card } from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { DialogContent, Step, StepLabel, Stepper } from "@material-ui/core";

const ForgetPassword = ({ siteSettings, history, location }) => {
  const background = require("../../assets/images/auth-layer.png");

  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [newPassword, setNewPassWord] = useState("");
  const [emailorphone, setEmailOrPhone] = useState("");

  const { type } = queryString.parse(location.search);

  const steps = ["Email", "OTP", "New Password"];

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // const validatePhone = (phone) => {
  //   const re = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  //   return re.test(phone);
  // };

  const handleForgetPass = async () => {
    if (validateEmail(emailorphone)) {
      setLoading(true);
      const email = emailorphone;
      const response = await ForgotPassword({
        type: type,
        userID: email,
        companyID: siteSettings.shop._id,
      });
      if (response && response.isSuccess) {
        handleNext();
        toast.success("OTP Sent to your Email or Phone", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        toast.error(response.message.toString(), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
      setLoading(false);
    }
    // else if (validatePhone(parseInt(emailorphone))) {
    //   setLoading(true);
    //   const phoneNumber = "+88" + emailorphone;

    //   let rechaptcha = new firebase.auth.RecaptchaVerifier(
    //     "recaptha-container",
    //     {
    //       size: "invisible",
    //       callback: (response) => {
    //         console.log(response, "captcha verified");
    //       },
    //     }
    //   );

    //   firebase
    //     .auth()
    //     .signInWithPhoneNumber(phoneNumber, rechaptcha)
    //     .then((confirmationResult) => {
    //       handleNext();
    //       window.confirmationResult = confirmationResult;
    //       toast.success("Otp code sent to phone number !!", {
    //         position: toast.POSITION.TOP_RIGHT,
    //         autoClose: 3000,
    //       });
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // }
    // || !validatePhone(emailorphone)
    else if (!validateEmail(emailorphone)) {
      toast.error("Enter email or password !!", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    } else {
      toast.error("Please Insert Value", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    setLoading(false);
  };

  const handleSubmitOtp = async (e) => {
    e.preventDefault();
    if (otp) {
      setLoading(true);
      if (validateEmail(emailorphone)) {
        const email = emailorphone;
        const response = await SubmitOtp({
          otp: otp,
          type: type,
          userID: email,
          companyID: siteSettings.shop._id,
        });
        if (response && response.isSuccess) {
          handleNext();
          toast.success("Success!", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        } else {
          toast.error(response.message.toString(), {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      }
      // else if (validatePhone(parseInt(emailorphone))) {
      //   window.confirmationResult
      //     .confirm(otp)
      //     .then((result) => {
      //       handleNext();
      //     })
      //     .catch((error) => {
      //       console.log(error);
      //     });
      // }
      setLoading(false);
    } else {
      toast.error("Please Insert Value", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleSubmitNewPassword = async () => {
    if (newPassword) {
      setLoading(true);
      const response = await SubmitNewPassword({
        otp: otp,
        type: type,
        userID: emailorphone,
        password: newPassword,
        companyID: siteSettings.shop._id,
      });

      if (response && response.isSuccess) {
        if (type === "seller") history.push("/admin-login");
        else history.push("/");
        SweetAlert.fire("Password Reset!", "Please Login Now.", "success");
      } else {
        toast.error(response.message.toString(), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
      }
      setLoading(false);
    } else {
      toast.error("Please Insert Value", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  return (
    <Fragment>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="page-wrapper">
        <Container fluid={true}>
          <div className="authentication-main">
            <Row>
              <Col md="5" className="p-0">
                <div
                  className="auth-innerleft"
                  style={{ backgroundImage: "url(" + background + ")" }}
                >
                  <div className="text-center">
                    <img
                      src={require("../../assets/images/key.png")}
                      className="img-fluid security-icon"
                      alt=""
                    />
                  </div>
                </div>
              </Col>
              <Col md="7" className="p-0 w-100">
                <div className="auth-innerright w-100">
                  <div className="reset-password-box">
                    <h3>
                      RESET PASSWORD FOR{" "}
                      {type === "seller" ? "SITE ADMIN" : "VENDOR"}
                    </h3>
                    <Card className="mt-4  mb-0">
                      <Row>
                        <Col sm="12" md="12" lg="12">
                          <DialogContent className="p-1">
                            {activeStep !== steps.length && (
                              <Stepper
                                className="text-primary"
                                activeStep={activeStep}
                              >
                                {steps.map((label) => (
                                  <Step className="text-primary" key={label}>
                                    <StepLabel>{label}</StepLabel>
                                  </Step>
                                ))}
                              </Stepper>
                            )}
                          </DialogContent>
                        </Col>

                        <Col sm="12" md="12" lg="12">
                          <div className="mt-2 pt-5">
                            {activeStep === 0 && (
                              <div className="form-group">
                                <div id="recaptha-container"></div>
                                <input
                                  type="text"
                                  value={emailorphone}
                                  name="emailorphone"
                                  className="form-control mb-1"
                                  placeholder="Enter Email Or Phone Number"
                                  onChange={(event) =>
                                    setEmailOrPhone(event.target.value)
                                  }
                                />
                                <div className="d-flex justify-content-center mt-4">
                                  <button
                                    disabled={loading}
                                    type="button"
                                    className="btn btn-primary m-0 w-100"
                                    onClick={handleForgetPass}
                                  >
                                    {loading ? "Loading" : "Get OTP"}
                                  </button>
                                </div>
                              </div>
                            )}
                            {activeStep === 1 && (
                              <div className="form-group">
                                <input
                                  type="text"
                                  value={otp}
                                  name="emailorphone"
                                  className="form-control mb-1"
                                  placeholder="Enter OTP Code"
                                  onChange={(event) =>
                                    setOtp(event.target.value)
                                  }
                                />
                                <div className="d-flex justify-content-center mt-4">
                                  <button
                                    disabled={loading}
                                    type="button"
                                    className="btn btn-primary m-0 w-100"
                                    onClick={handleSubmitOtp}
                                  >
                                    {loading ? "Loading" : "Submit OTP"}
                                  </button>
                                </div>
                              </div>
                            )}
                            {activeStep === 2 && (
                              <div className="form-group">
                                <input
                                  type="password"
                                  value={newPassword}
                                  name="emailorphone"
                                  className="form-control mb-1"
                                  placeholder="Enter New Password"
                                  onChange={(event) =>
                                    setNewPassWord(event.target.value)
                                  }
                                />
                                <div className="d-flex justify-content-center mt-4">
                                  <button
                                    disabled={loading}
                                    type="button"
                                    className="btn btn-primary m-0 w-100"
                                    onClick={handleSubmitNewPassword}
                                  >
                                    {loading ? "Loading" : "Submit"}
                                  </button>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className="mt-2 w-100 text-right font-weight-bold">
                            <Link to={type === "seller" ? "/admin-login" : "/"}>
                              Login Page
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

export default connect(mapStateToProps)(ForgetPassword);
