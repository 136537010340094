import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Form,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { CreateShipping } from "../../../../../service/service";

const CreteShipping = ({ toggle, isOpen, setIsUpdated, isUpdated }) => {
  const [title, setTitle] = useState(null);
  const [shippingType, setShippingType] = useState("regular");
  const [shippingAmountInsideDhaka, setShippingAmountInsideDhaka] =
    useState(null);
  const [shippingInsideDhakaFrom, setShippingInsideDhakaFrom] = useState(null);
  const [shippingInsideDhakaTo, setShippingInsideDhakaTo] = useState(null);
  const [shippingAmountOutsideDhaka, setShippingAmountOutsideDhaka] =
    useState(null);
  const [shippingOutsideDhakaTo, setShippingOutsideDhakaTo] = useState(null);
  const [shippingOutsideDhakaFrom, setShippingOutsideDhakaFrom] =
    useState(null);

  const [errorMessage, setErrorMessage] = useState("");

  const hanldeSubmitCreateShipping = async (e) => {
    e.preventDefault();

    if (
      !title ||
      !shippingType ||
      !shippingAmountInsideDhaka ||
      !shippingAmountOutsideDhaka ||
      !shippingInsideDhakaFrom ||
      !shippingInsideDhakaTo ||
      !shippingOutsideDhakaTo ||
      !shippingOutsideDhakaFrom
    ) {
      console.log("Error");
      console.log({
        title,
        shippingType,
        shippingAmountInsideDhaka,
        shippingAmountOutsideDhaka,
        shippingInsideDhakaTo,
        shippingInsideDhakaFrom,
        shippingOutsideDhakaTo,
        shippingOutsideDhakaFrom,
      });
    }

    const data = {
      title,
      shippingType,
      shippingAmountInsideDhaka,
      shippingAmountOutsideDhaka,
      shippingInsideDhakaTo,
      shippingInsideDhakaFrom,
      shippingOutsideDhakaTo,
      shippingOutsideDhakaFrom,
    };

    try {
      const shipping = await CreateShipping(data);

      if (shipping.isSuccess) {
        toggle();
        toast.success("Shipping Created!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.error(shipping.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
      setIsUpdated(!isUpdated);
      console.log("shipping: ", shipping);
    } catch (error) {
      console.log(error);
      setErrorMessage((errorMessage) => (errorMessage = error));
      console.log(errorMessage);
    }
    // console.log({
    //   title,
    //   shippingType,
    //   shippingAmountInsideDhaka,
    //   shippingAmountOutsideDhaka,
    //   shippingInsideDhakaTo,
    // });
  };

  return (
    <Modal isOpen={isOpen}>
      <ModalHeader toggle={toggle}>Create Shipping</ModalHeader>
      <ModalBody>
        <Form onSubmit={hanldeSubmitCreateShipping}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label> Title</Label>
                <Input
                  type="text"
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label> Shipping Type</Label>
                <FormGroup>
                  <select
                    className="form-control btn-square border"
                    onChange={(e) => setShippingType(e.target.value)}
                  >
                    <option value="regular">Regular</option>
                    <option value="weight">Weight</option>
                  </select>
                </FormGroup>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <Label>Amount Inside Dhaka</Label>
                <Input
                  type="number"
                  onChange={(e) =>
                    setShippingAmountInsideDhaka(+e.target.value)
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label> Outside Dhaka</Label>
                <Input
                  type="number"
                  onChange={(e) =>
                    setShippingAmountOutsideDhaka(+e.target.value)
                  }
                  required
                />
              </FormGroup>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <FormGroup>
                <Label>Shipping Inside Dhaka</Label>
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  type="number"
                  onChange={(e) => setShippingInsideDhakaFrom(+e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Input placeholder="To" disabled type="text" />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  type="number"
                  onChange={(e) => setShippingInsideDhakaTo(+e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={12} lg={12} xl={12}>
              <FormGroup>
                <Label>Shipping Ouside Dhaka</Label>
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  type="number"
                  onChange={(e) => setShippingOutsideDhakaFrom(+e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
            <Col md={2}>
              <FormGroup>
                <Input placeholder="To" disabled type="text" />
              </FormGroup>
            </Col>
            <Col md={5}>
              <FormGroup>
                <Input
                  type="number"
                  onChange={(e) => setShippingOutsideDhakaTo(+e.target.value)}
                  required
                />
              </FormGroup>
            </Col>
          </Row>
          <Button type="submit" color="primary btn-block">
            <FontAwesomeIcon className="mr-1" icon={faSave} /> Save
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default CreteShipping;
