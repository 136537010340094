import {
  AddProduct,
  UpdateOrder,
  DeleteOrder,
  DeleteProduct,
  UpdateProduct,
  addEventProduct,
  GetRequestDetails,
  updateEventProduct,
  deleteEventProduct,
  RejectVendorRequest,
  ApproveVendorRequest,
} from "../../../../service/service";
import moment from "moment";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config from "../../../../service/api/config";
import Breadcrumbs from "../../Components/Breadcrumbs";
import noImage from "../../../../assets/images/notFound.png";
import { imageExists } from "../../../../service/imageExists";
import ComponentLoader from "../../Components/Loader/ComponentLoader";
import OrderComponent from "../../Components/VendorRequest/OrderComponent";
import StockComponent from "../../Components/VendorRequest/StockComponent";
import EventComponent from "../../Components/VendorRequest/EventComponent";
import { Row, Col, Card, CardBody, CardHeader, Container } from "reactstrap";
import ProductComponent from "../../Components/VendorRequest/ProductComponent";

const VendorRequestDetailsPage = (props) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(true);
  const [vendorRequest, setVendorRequest] = useState([]);

  const fetchData = async () => {
    const response = await GetRequestDetails(id);
    if (response && response.isSuccess) {
      setVendorRequest(response.data.vendorRequest);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [vendorRequest]);

  const confirmReject = async (id) => {
    const response = await RejectVendorRequest(id);
    if (response && response.isSuccess) {
      toast.success("Request Rejected", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    props.history.push("/dashboard/vendor-requests");
  };

  const handleReject = async (id) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once rejected, The operation will be cancelled",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmReject(id);
      } else {
        SweetAlert.fire("Request is safe!");
      }
    });
  };

  const apporveStatus = async () => {
    const response = await ApproveVendorRequest(vendorRequest._id);
    if (response && response.isSuccess) {
      setVendorRequest(response.data.vendorRequest);
      toast.success("Request Approved!", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    }
    props.history.push("/dashboard/vendor-requests");
  };

  const confirmApprove = async () => {
    if (vendorRequest.requestType === "addProduct") {
      const response = await AddProduct(vendorRequest.requestData);
      console.log(response);
      if (response && response.isSuccess) {
        apporveStatus();
      }
    } else if (vendorRequest.requestType === "updateProduct") {
      const response = await UpdateProduct(
        vendorRequest.docID,
        vendorRequest.requestData
      );
      console.log(response);
      if (response && response.isSuccess) {
        apporveStatus();
      }
    } else if (vendorRequest.requestType === "deleteProduct") {
      const response = await DeleteProduct(vendorRequest.docID);
      console.log(response);
      if (response && response.isSuccess) {
        apporveStatus();
      }
    } else if (vendorRequest.requestType === "updateOrder") {
      const response = await UpdateOrder(
        vendorRequest.docID,
        vendorRequest.requestData
      );
      console.log(response);
      if (response && response.isSuccess) {
        apporveStatus();
      }
    } else if (vendorRequest.requestType === "deleteOrder") {
      const response = await DeleteOrder(vendorRequest.docID);
      console.log(response);
      if (response && response.isSuccess) {
        apporveStatus();
      }
    } else if (vendorRequest.requestType === "updateStock") {
      const response = await UpdateProduct(
        vendorRequest.docID,
        vendorRequest.requestData
      );
      console.log(response);
      if (response && response.isSuccess) {
        apporveStatus();
      }
    } else if (vendorRequest.requestType === "addEventProduct") {
      const response = await addEventProduct(vendorRequest.requestData);
      console.log(response);
      if (response && response.isSuccess) {
        apporveStatus();
      }
    } else if (vendorRequest.requestType === "updateEventProduct") {
      const response = await updateEventProduct(
        vendorRequest.docID,
        vendorRequest.requestData
      );
      console.log(response);
      if (response && response.isSuccess) {
        apporveStatus();
      }
    } else if (vendorRequest.requestType === "deleteEventProduct") {
      const response = await deleteEventProduct(vendorRequest.docID);
      console.log(response);
      if (response && response.isSuccess) {
        apporveStatus();
      }
    }
  };

  const handleAprove = async () => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once aproved, The operation will be done",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "confirm",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        confirmApprove();
      }
    });
  };

  return (
    <>
      <Container fluid={true}>
        <div className="page-header">
          <Row>
            <Col lg="9" xs="12">
              <Breadcrumbs title="Vendor Request Details" parent="Dashboard" />
            </Col>
            <Col lg="3" xs="12">
              {vendorRequest.status === "pending" && (
                <>
                  <button
                    onClick={handleAprove}
                    className="btn btn-primary btn-block"
                  >
                    <span className="text">Approve</span>
                  </button>
                  <div
                    onClick={() => handleReject(vendorRequest._id)}
                    className="btn btn-danger btn-block"
                  >
                    <span className="text">Reject</span>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>
        <Row>
          {loading ? (
            <ComponentLoader height="80vh" />
          ) : (
            <>
              {(vendorRequest.requestType === "addProduct" ||
                vendorRequest.requestType === "updateProduct" ||
                vendorRequest.requestType === "deleteProduct") && (
                <ProductComponent vendorRequest={vendorRequest} />
              )}
              {(vendorRequest.requestType === "addOrder" ||
                vendorRequest.requestType === "updateOrder" ||
                vendorRequest.requestType === "deleteOrder") && (
                <OrderComponent vendorRequest={vendorRequest} />
              )}
              {vendorRequest.requestType === "updateStock" && (
                <StockComponent vendorRequest={vendorRequest} />
              )}
              {(vendorRequest.requestType === "addEventProduct" ||
                vendorRequest.requestType === "updateEventProduct" ||
                vendorRequest.requestType === "deleteEventProduct") && (
                <EventComponent vendorRequest={vendorRequest} />
              )}
              <Col sm="12">
                <Card>
                  <CardHeader>
                    <h6 className="m-0 font-weight-bold text-primary">
                      Vendor Details
                    </h6>
                  </CardHeader>
                  <CardBody>
                    <div className="table-responsive">
                      <table className="table table-hover table-md text-center">
                        <thead>
                          <tr>
                            <th className="border">Image</th>
                            <th className="border">Name</th>
                            <th className="border">Company Types</th>
                            <th className="border">Country</th>
                            <th className="border">Status</th>
                            <th className="border">Total Employees</th>
                            <th className="border">Created At</th>
                            <th className="border">Updated At</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border">
                              <img
                                src={
                                  vendorRequest.vendor.logo &&
                                  imageExists(
                                    config.fileServer +
                                      config.thumbnailContainer +
                                      vendorRequest.vendor.logo
                                  )
                                    ? config.fileServer +
                                      config.thumbnailContainer +
                                      vendorRequest.vendor.logo
                                    : noImage
                                }
                                width="60px"
                                className="img-fluid"
                                alt={vendorRequest.vendor.name}
                              />
                            </td>
                            <td className="border">
                              {vendorRequest.vendor.name}
                            </td>
                            <td className="border text-left">
                              {vendorRequest.vendor.companyTypes &&
                                vendorRequest.vendor.companyTypes.map(
                                  (companyType, index) => (
                                    <li key={index}>{companyType}</li>
                                  )
                                )}
                            </td>
                            <td className="border">
                              {vendorRequest.vendor.country}
                            </td>
                            <td className="border">
                              {vendorRequest.vendor.activeStatus ? "✅" : "🚫"}
                            </td>
                            <td className="border">
                              {vendorRequest.vendor.totalEmployees}
                            </td>
                            <td className="border">
                              {moment(vendorRequest.vendor.createdAt).format(
                                "D MMM YYYY"
                              )}
                            </td>
                            <td className="border">
                              {moment(vendorRequest.vendor.updatedAt).format(
                                "D MMM YYYY"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default VendorRequestDetailsPage;
