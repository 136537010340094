import jwtDecode from "jwt-decode";
import { Button, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Login, LoginAdmin } from "../../service/service";
import config from "../../service/api/config";
import React, { Fragment, useState } from "react";
import operations from "../../redux/Main/operations";
import setAuthToken from "../../service/setAuthToken";
import { Card } from "reactstrap";
import noImage from "../../assets/images/notFound.png";
import { ToastContainer, toast } from "react-toastify";
import { Link, Navigate } from "react-router-dom";
import PageLoader from "../Dashboard/Components/Loader/PageLoader";
import { useNavigate } from "react-router-dom";

const SellerLogin = (props) => {
  const history = useNavigate();
  const { siteSettings } = props;

  const [buttonLoading, setButtonLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (e.target.email.value && e.target.password.value) {
      setButtonLoading(true);
      const data = {
        email: e.target.email.value,
        password: e.target.password.value,
      };
      const response = await LoginAdmin(data);
      console.log("====================================");
      console.log(response);
      console.log("====================================");
      if (response && response.isSuccess) {
        const token = response.data.token;
        localStorage.setItem("auth_token", token);
        setAuthToken(token);
        const user = jwtDecode(token);
        props.setUser(user);
        setButtonLoading(false);
        history("/dashboard");
      } else {
        setButtonLoading(false);
        toast.error(response.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  if (!props.user.loading && props.user && props.user.id) {
    return <Navigate to="/dashboard" />;
  }

  return props.user.loading ? (
    <PageLoader />
  ) : (
    <Fragment>
      <ToastContainer
        draggable
        rtl={false}
        closeOnClick
        pauseOnHover
        autoClose={5000}
        pauseOnFocusLoss
        position="top-center"
        newestOnTop={false}
        hideProgressBar={false}
      />
      <div className="page-wrapper">
        <div className="auth-bg">
          {siteSettings ? (
            <div className="authentication-box">
              <h4 className="text-center">
                <img
                  src={
                    siteSettings.shop &&
                    config.fileServer +
                      config.thumbnailContainer +
                      siteSettings.shop.logo
                      ? config.fileServer +
                        config.thumbnailContainer +
                        siteSettings.shop.logo
                      : noImage
                  }
                  alt="company logo"
                  width="80px"
                />
              </h4>
              <h4 className="text-center" style={{ fontSize: "30px" }}>
                {siteSettings.shop && siteSettings.shop.name}
              </h4>
              <h5 className="text-center">Site Admin Login</h5>

              <h6 className="text-center">
                Enter your Email and Password For Login
              </h6>
              <div className="card mt-4 p-4 mb-0">
                <form className="theme-form" onSubmit={handleLogin}>
                  <div className="form-group">
                    <label className="col-form-label pt-0">Email</label>
                    <input
                      type="email"
                      placeholder="Email"
                      name="email"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label className="col-form-label" name="password">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                    />
                  </div>
                  <div className="form-group form-row mt-3 mb-0">
                    <div className="col-md-12">
                      {buttonLoading ? (
                        <Button
                          color="primary btn-block"
                          disabled={buttonLoading}
                        >
                          LOADING...
                        </Button>
                      ) : (
                        <Button type="submit" color="primary btn-block">
                          LOGIN
                        </Button>
                      )}
                    </div>
                  </div>
                </form>
                <div className="mt-4 w-100 text-right font-weight-bold">
                  <Link
                    to="/forget-password?type=seller"
                    style={{ color: "red" }}
                  >
                    Forget Password
                  </Link>
                </div>
                {siteSettings.siteType === "multiVendor" && (
                  <div className="mt-2 w-100 text-right font-weight-bold">
                    <Link to="/vendor-login">Vendor Login</Link>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <Card>
              <CardBody>
                <h1 className="display-4 text-uppercase">Page not found</h1>
              </CardBody>
            </Card>
          )}
        </div>
        <div className="auth-bg-effect">
          <div className="first-effect"></div>
          <div className="second-effect"></div>
          <div className="third-effect"></div>
          <div className="fourth-effect"></div>
        </div>
      </div>
    </Fragment>
  );
};

function mapStateToProps(state, props) {
  return {
    user: state.mainReducers.main.user,
    auth: state.mainReducers.main.isAuthenticated,
    siteSettings: state.mainReducers.main.siteSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(operations, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SellerLogin);
