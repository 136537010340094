import React, { useState } from "react";

const TreeComponents = ({ data = [] }) => {
  return (
    <div className="d-tree">
      <ul className="d-flex d-tree-container flex-column">
        {data.map((tree, index) => (
          <TreeNode node={tree} key={index} />
        ))}
      </ul>
    </div>
  );
};

const TreeNode = ({ node }) => {
  const [childVisible, setChildVisiblity] = useState(false);
  const hasChild = node.children ? true : false;

  return (
    <li className="d-tree-node border-0">
      <div className="d-flex" onClick={(e) => setChildVisiblity((v) => !v)}>
        {hasChild && (
          <div
            className={`d-inline d-tree-toggler ${
              childVisible ? "active" : ""
            }`}
          ></div>
        )}

        <div className="col d-tree-head">
          {node.children.length > 0 ? (
            <>
              {childVisible ? (
                <i
                  className={`mr-1 icofont icofont-caret-down`}
                  style={{ cursor: "pointer" }}
                ></i>
              ) : (
                <i
                  className={`mr-1 icofont icofont-caret-right`}
                  style={{ cursor: "pointer" }}
                ></i>
              )}

              {node.name}
            </>
          ) : (
            <>
              <i className={`mr-1 fa fa-circle`}></i>

              {node.name}
            </>
          )}
        </div>
      </div>

      {hasChild && childVisible && (
        <div className="d-tree-content">
          <ul className="d-flex d-tree-container flex-column">
            <TreeComponents data={node.children} />
          </ul>
        </div>
      )}
    </li>
  );
};

export default TreeComponents;
