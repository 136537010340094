import React from "react";
import { Col, Row, Button } from "reactstrap";
import Breadcrumbs from "../../../Components/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faSave } from "@fortawesome/free-solid-svg-icons";

const PageHeader = ({ title, buttonText, handleSubmitForm }) => {
  return (
    <div className="page-header">
      <Row>
        <Col lg="8" xs="12">
          <Breadcrumbs title={title} parent="Dashboard" />
        </Col>
        <Col lg="4" xs="12">
          <Button color="primary btn-block" onClick={handleSubmitForm}>
            {buttonText !== "Save" && buttonText !== "Update" ? (
              <FontAwesomeIcon className="mr-1" icon={faPlus} />
            ) :
              <FontAwesomeIcon className="mr-1" icon={faSave} />
            }
            {buttonText}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default PageHeader;
